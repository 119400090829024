import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import HeadBack from './redirect';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './clients-constants';
import { ClientResource, useClient, useClientResources, useUpdateClient } from '../../hooks/useClientResources';
import { Spinner, ToggleButton } from '../../components/common';
import { normalizeMobilePhone } from '../../components/common/phone-validation';
import EditClientResourceDetails from './components/resource-edit';

const ClientResources: React.FC = () => {
  const clientId = +localStorage.getItem('clientid')!;
  const { data: client } = useClient(clientId);
  const [includeHidden, setIncludeHidden] = useState(true);
  const [omitGlobal, setOmitGlobal] = useState(client?.use_global_resources || false);
  const {
    data: resources,
    isFetching,
  } = useClientResources(clientId, omitGlobal, includeHidden);
  const switchBarSelectedTab = 4;

  return (
    <div>
      <Header userName={''} heading={<HeadBack clientName={client?.name || ''} />} />
      <SwitchBar heading={HeaderOptions} position={switchBarSelectedTab} />
      <Filters
        clientId={clientId}
        omitGlobal={omitGlobal}
        includeHidden={includeHidden}
        setOmitGlobal={setOmitGlobal}
        setIncludeHidden={setIncludeHidden}
      />
      {isFetching && <Spinner />}
      <div className="max-window-height-table overflow-y-auto px-7 ">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white sticky top-0">
            <tr className="text-left px-7">
              <th className="w-2/12" />
              <th className="w-2/12" />
              <th className="w-3/12">Name</th>
              <th className="w-3/12">Phone</th>
              <th className="w-3/12">URL</th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {resources?.length ? (
              resources?.map((resource: ClientResource) => {
                return (
                  <>
                    <tr className="bg-gray-200 text-gray-dark text-left border-t-8 border-white">
                      <td className="px-5 pt-5 w-2/12">
                        <EditClientResourceDetails resource={resource} clientId={clientId} />
                      </td>
                      <td className="pt-5 w-2/12">
                        {resource?.image_url ? (
                          <img width="60" src={resource?.image_url} alt="Resource Image" />
                        ) : (
                          <div className="w-24" />
                        )}
                      </td>
                      <td className="pt-5 w-3/12">
                        <div className="text-gray-dark font-extrabold truncate">
                          {resource?.name}
                        </div>
                      </td>
                      <td className="pt-5 w-3/12">
                        {normalizeMobilePhone(resource?.phone_number?.replace('+1', ''))}
                      </td>
                      <td className="pt-5 pl-1 w-3/12">
                        <div className="truncate">{resource?.url}</div>
                      </td>
                    </tr>
                     <tr className="bg-gray-200 text-gray-dark text-left h-6 group">
                      <td />
                      <td colSpan={2}>
                        <div className="pb-2 max-w-2xl truncate group-hover:overflow-clip group-hover:whitespace-normal group-hover:overflow-y-auto">
                          {resource?.description}
                        </div>
                      </td>
                      <td colSpan={2}>
                        <div className="pb-2 max-w-2xl truncate group-hover:overflow-clip group-hover:whitespace-normal group-hover:overflow-y-auto">
                          {resource?.long_description}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="flex justify-center items-center">
                    <p className="text-gray-dark text-2xl">No Resources Found</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const Filters: React.FC<{
  clientId: number;
  omitGlobal: boolean;
  includeHidden: boolean;
  setOmitGlobal: React.Dispatch<React.SetStateAction<boolean>>;
  setIncludeHidden: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ clientId, omitGlobal, includeHidden, setOmitGlobal, setIncludeHidden }) => {
  const { updateClient } = useUpdateClient(clientId);
  const newResource = {
    name: '',
    description: '',
    long_description: '',
    phone_number: '',
    url: '',
    url_description: '',
    image_url: '',
    sort_weight: 0,
    status: 'live',
    text_number: '',
    notes: '',
    identifier: '',
    client_id: clientId,
  };

  const handleUseGlobal = (data: boolean) => {
    updateClient.mutate({
      use_global_resources: data,
    });
  }

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7">
      <div className="flex items-center py-4">
        <span className="px-3">Use Global Resources</span>{' '}
        <ToggleButton
          id={'omitGlobal'}
          status={!omitGlobal}
          initialstatus={false}
          setStatus={(data: boolean) => {
            data ? setOmitGlobal(false) : setOmitGlobal(true);
            handleUseGlobal(data);
          }}
        />
      </div>
      <div className="flex items-center py-4">
        <span className="px-3">Show Hidden Resources</span>{' '}
        <ToggleButton
          id={'includeHidden'}
          status={includeHidden}
          initialstatus={true}
          setStatus={(data: boolean) => {
            data ? setIncludeHidden(true) : setIncludeHidden(false);
          }}
        />
      </div>
      <div className="my-auto">
        <EditClientResourceDetails resource={newResource} clientId={clientId} />
      </div>
    </div>
  );
};

export default ClientResources;
