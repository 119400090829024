import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Select from 'react-select';
import { fetchClientsRequest } from '../../containers/clients/clients-redux/actions';
import { ROUTE_PATH } from '../../routes/paths';

const customStyles = {
  menuList: (styles: any) => ({
    ...styles,
    background: 'white',
  }),
  //@ts-ignore
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      background:
        isFocused && !isSelected
          ? 'hsl(204, 67%, 94%)'
          : isSelected
          ? 'hsl(218, 100%, 50%)'
          : undefined,
      paddingLeft: 30,
      zIndex: 1,
    };
  },
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  //@ts-ignore
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: 'none',
      border: state.isFocused && 'none',
    };
  },
};

type props = {
  clients?: any;
  type?: string;
  handleChange?: (data: any) => void;
};

const ClientAdminGroupDropDown: React.FunctionComponent<props> = ({
  clients,
  type,
  handleChange,
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const packageId = new URLSearchParams(search).get('packageId');
  const clientId = new URLSearchParams(search).get('clientId');
  const [selectedValue, setSelectedValue] = useState<any>();
  const [groupClientList, setGroupClientList] = useState<any>([]);
  // const [isOnload, setIsOnload] = useState<boolean>(true);

  const handleGroupId = (value: any) => {
    const packageData = value.label !== 'All Groups' ? value : 'All Groups';
    let url = ROUTE_PATH.ENAGEMENT;
    if (packageData !== 'All Groups') {
      url = `${ROUTE_PATH.ENAGEMENT}/?packageId=${packageData.value.packageId}&clientId=${packageData.value.clientId}`;
    }
    if (type === 'engagement') {
      history.push(url);
    } else if (type === 'eligibility') {
      // localStorage.setItem('clientid', packageData.value);
      setSelectedValue(packageData);
      handleChange &&
        handleChange({
          clientId: packageData.value,
        });
    }
  };

  const changeDropDown = (packageId: any, clientId: any, clients: any) => {
    if (packageId && clientId && clients?.length > 0 && groupClientList.length > 0) {
      const find = clients
        .find((item: any) => item.id === parseInt(clientId))
        ?.packages.find((packItem: any) => packItem.id === parseInt(packageId));
      setSelectedValue({
        label: find.name,
        value: { packageId: find.id, clientId: find.client_id },
      });
    } else {
      setSelectedValue({
        label: 'All Groups',
        value: {},
      });
    }
  };

  useEffect(() => {
    if (type === 'engagement') {
      changeDropDown(packageId, clientId, clients);
    }
  }, [packageId, clientId, clients, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'engagement') {
      const filters =
        clients?.length > 0
          ? clients?.map((item: any) => {
              return {
                label: item.name,
                options: item?.packages.map((packageItem: any) => {
                  return {
                    label: packageItem.name,
                    value: {
                      packageId: packageItem.id,
                      clientId: packageItem.client_id,
                    },
                  };
                }),
              };
            })
          : [];
      setGroupClientList([
        {
          label: 'All Groups',
          value: {},
        },
        ...filters,
      ]);
    } else if (clients && type === 'eligibility') {
      const filters =
        clients?.length > 0 &&
        clients?.filter((item: any) => !!!item.eligibility_config?.hide_client_admin_eligibility)
          .length > 0
          ? clients
              ?.filter((item: any) => !!!item.eligibility_config?.hide_client_admin_eligibility)
              .map((item: any) => {
                return {
                  label: item.name,
                  value: item.id,
                };
              })
          : [];

      setSelectedValue(filters?.length > 0 ? filters[0] : null);
      setGroupClientList(filters?.length > 0 ? filters : null);
      handleChange &&
        handleChange({
          clientId: filters?.length > 0 ? filters[0].value : null,
        });
      // setIsOnload(false);
    }
  }, [clients, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchClientsRequest({ include_packages: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full">
      <Select
        styles={customStyles}
        onChange={handleGroupId}
        options={groupClientList || []}
        value={selectedValue}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    clients: state.Client.clients?.data || null,
    clientPending: state.ClientPortal.clientPending,
  };
};

export default connect(mapStateToProps)(ClientAdminGroupDropDown);
