import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput, DropDown, ToggleButton } from '../../../components/common';
import AccordionTile from '../Accordion';
// import {props.EmailTemplates} from "../clients-constants"
import {
  postCreatePackageProductRequest,
  postUpdatePackageProductRequest,
} from '../clients-redux/actions';

type props = {
  data: any;
  default: any;
  EmailTemplates?: any;
  UpdateSaveOption: (data: boolean) => void;
};

const ConsumerSubscriptionModel: React.FunctionComponent<props> = (props): JSX.Element => {
  const [consumerSubscriptionModel, setconsumerSubscriptionModel] = useState(false);
  const [monthlyCost, setmonthlyCost] = useState(0.0);
  const [bonusDiscountToggle, setbonusDiscountToggle] = useState(false);
  const [minutesinclidedPerMonth, setminutesinclidedPerMonth] = useState(0);
  const [discountedMinumumRate, setdiscountedMinumumRate] = useState<any>(0);
  const [bonusdiscount, setbonusdiscount] = useState(0);
  const [hidePaymentSetupNotice, sethidePaymentSetupNotice] = useState(true);
  const [EmailLabels, setEmailLabels] = useState<any>([]);
  const [matchingTemplates, setmatchingTemplates] = useState<any>([]);
  const [selectedEmailLabel, setselectedEmailLabel] = useState<any>();
  const [selectedTemplate, setselectedTemplate] = useState<any>();
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState<any>({});
  const [id, setid] = useState();

  const dispatch = useDispatch();

  const getUniqueEmailId = () => {
    if (props.EmailTemplates?.length > 0) {
      const uniquieresults = props.EmailTemplates.reduce((obj: any, item: any) => {
        const { name, id } = item;
        const label = item.labels[0] ? item.labels[0] : 'No Label';
        if (!obj[label] && label === 'No Label') {
          obj[label] = [
            {
              id: 'Do not Send Email',
              name: 'Do not Send Email',
            },
            { name, id },
          ];
        } else if (!obj[label]) {
          obj[label] = [{ name, id }];
        } else {
          obj[label] = [{ name, id }, ...obj[label]];
        }
        return obj;
      }, {});
      setEmailLabels(uniquieresults);
    }
  };

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //     if (props.EmailTemplates?.length > 0 ){
  //     let results: any = [];
  //     const newTemapltes = [
  //         {
  //             id: 'Do not Send Email',
  //             name: 'Do not Send Email',
  //             labels: [],
  //         },
  //         ...props?.EmailTemplates? props?.EmailTemplates: [],
  //     ];
  //      if (selectedEmailLabel !== 'No Label') {
  //         results = newTemapltes.filter((item: any) => {
  //             if (item.labels[0] === selectedEmailLabel) {
  //                 return item;
  //             }
  //             return false;
  //         });
  //     } else {
  //         results = newTemapltes.filter((item: any) => item.labels.length === 0);
  //     }
  //     if (results?.length > 0) {
  //         setselectedTemplate(results[0].id);
  //         setmatchingTemplates(results);
  //     }
  // }
  // }, [selectedEmailLabel]); // eslint-disable-line react-hooks/exhaustive-deps

  const validation = () => {
    if (consumerSubscriptionModel) {
      if (!discountedMinumumRate) {
        setErrorMessage({ discountedMinumumRate: 'Field is required' });
        return false;
      } else if (discountedMinumumRate < 0.001 || discountedMinumumRate > 2) {
        setErrorMessage({ discountedMinumumRate: 'Must be a number between 0.01 and 2.00' });
        return false;
      } else if (!minutesinclidedPerMonth) {
        setErrorMessage({ minutesinclidedPerMonth: 'Field is required' });
        return false;
      } else if (minutesinclidedPerMonth.toString().indexOf('.') !== -1) {
        setErrorMessage({
          minutesinclidedPerMonth: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (minutesinclidedPerMonth < 1 || minutesinclidedPerMonth > 5000) {
        setErrorMessage({ minutesinclidedPerMonth: 'Must be a number between 1 and 5000' });
        return false;
      } else if (!bonusdiscount) {
        setErrorMessage({ bonusdiscount: 'Field is required' });
        return false;
      } else if (bonusdiscount.toString().indexOf('.') !== -1) {
        setErrorMessage({
          bonusdiscount: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (bonusdiscount < 1 || bonusdiscount > 100) {
        setErrorMessage({ bonusdiscount: 'Must be a number between 1 and 100' });
        return false;
      }
    }
    setErrorMessage({});
    return true;
  };

  const deriveLabelFromTemplate = (template: string) => {
    if (props?.EmailTemplates?.length > 0) {
      const newTemapltes = [
        {
          id: 'Do not Send Email',
          name: 'Do not Send Email',
          labels: [],
        },
        ...(props.EmailTemplates ? props.EmailTemplates : []),
      ];
      const templates = newTemapltes?.find((item) => item.id === template);
      return templates ? (templates.labels[0] ? templates.labels[0] : 'No Label') : 'No Label';
    }
  };

  const onSave = () => {
    const payload = {
      status: consumerSubscriptionModel ? 'enabled' : 'disabled',
      configuration: {
        minutes: +minutesinclidedPerMonth,
        rate: +discountedMinumumRate * 100,
        auto_renew: true,
        discount_enabled: bonusDiscountToggle,
        additional_discount: +bonusdiscount / 100,
        email_template:
          selectedTemplate === 'Do not Send Email' || !selectedTemplate ? null : selectedTemplate,
      },
    };
    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };
    if (validation()) {
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  useEffect(() => {
    if (props.data && Object.keys(EmailLabels)?.length > 0) {
      const packageProducts = props.data.package_products;
      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'SUBSCRIPTION') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setconsumerSubscriptionModel(selectedItem?.status === 'enabled');

        const configuration = selectedItem.configuration;
        const monthlyCost = configuration.minutes * (configuration.rate / 100);
        const EmailLabel = configuration.email_template
          ? deriveLabelFromTemplate(configuration.email_template)
          : 'No Label';
        setmonthlyCost(monthlyCost);
        sethidePaymentSetupNotice(configuration.auto_renew);
        setminutesinclidedPerMonth(configuration.minutes);
        setdiscountedMinumumRate(configuration.rate / 100);
        setbonusDiscountToggle(configuration.discount_enabled);
        setbonusdiscount(configuration.additional_discount * 100);
        setselectedEmailLabel(EmailLabel);
        setmatchingTemplates(EmailLabels[EmailLabel]);
        setselectedTemplate(
          configuration.email_template ? configuration.email_template : 'Do not Send Email'
        );
        setproductId(selectedItem.product_id);
        setid(selectedItem.id);
      } else {
        setconsumerSubscriptionModel(false);
        const configuration = props.default.configuration;
        const monthlyCost = configuration.minutes * (configuration.rate / 100);
        setmonthlyCost(monthlyCost);
        sethidePaymentSetupNotice(configuration.auto_renew);
        setminutesinclidedPerMonth(configuration.minutes);
        setdiscountedMinumumRate(configuration.rate / 100);
        setbonusDiscountToggle(configuration.discount_enabled);
        setselectedEmailLabel('No Label');
        setmatchingTemplates(EmailLabels['No Label']);
        setselectedTemplate('Do not Send Email');
        setbonusdiscount(configuration.additional_discount * 100);
        setpackageId(props.data.id);
        setid(props.default.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data, EmailLabels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUniqueEmailId();
  }, [props.EmailTemplates]);

  useEffect(() => {
    setmonthlyCost(minutesinclidedPerMonth * discountedMinumumRate);
  }, [minutesinclidedPerMonth, discountedMinumumRate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccordionTile
      setStatus={(data: boolean) => {
        if ((!data && validation()) || data) {
          !enableSaveOption && setenableSaveOption(true);
          setconsumerSubscriptionModel(data);
        }
      }}
      Label={'CONSUMER SUBSCRIPTION MODEL  '}
      buttonText={'SAVE'}
      status={consumerSubscriptionModel}
      id={'consumerSubscriptionModel'}
      initialstatus={true}
      disableButton={enableSaveOption}
      onButtonClick={() => {
        if (enableSaveOption) onSave();
      }}
      InnerComponent={
        <div className="flex justify-evenly gap-7 my-10">
          <div className="w-full ">
            <div className="py-4 ">
              <CustomInput
                inputType={'text'}
                disabled={true}
                value={'$ ' + monthlyCost.toFixed(2)}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setmonthlyCost(data);
                }}
                Label={'Monthly Cost'}
              />
            </div>
            <div className="py-4">
              <CustomInput
                inputType={'number'}
                value={minutesinclidedPerMonth}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setminutesinclidedPerMonth(data);
                }}
                Label={'Minutes Included/Month'}
                Error={ErrorMessage?.minutesinclidedPerMonth?.length > 0 ? true : false}
                ErrorMessage={ErrorMessage?.minutesinclidedPerMonth}
              />
            </div>
            <div className="py-4">
              <div className="py-4">
                <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">
                  Discounted Minute Rate
                </label>
                <input
                  type={'text'}
                  value={discountedMinumumRate}
                  className="py-2 px-3 w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                  onChange={(e: any) => {
                    const validNumber = new RegExp(/^\d*\.?\d{0,3}$/);
                    const data = e.target.value;
                    if (!validNumber.test(data)) {
                      e.preventDefault();
                    } else {
                      !enableSaveOption && setenableSaveOption(true);
                      setdiscountedMinumumRate(data);
                    }
                  }}
                  // onKeyDown={(e)=>{
                  //     e.preventDefault()

                  // }}
                />
                {ErrorMessage?.discountedMinumumRate?.length > 0 && (
                  <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                    {' '}
                    {ErrorMessage?.discountedMinumumRate}
                  </label>
                )}
              </div>
            </div>
            <div className="w-full flex justify-between  py-4">
              <div className="mx-2">
                <p className="py-2 text-blue-dark font-medium text-sm">Bonus Discount Included</p>
              </div>
              <div>
                <ToggleButton
                  status={bonusDiscountToggle}
                  setStatus={(data) => {
                    !enableSaveOption && setenableSaveOption(true);
                    setbonusDiscountToggle(data);
                  }}
                  initialstatus={false}
                  id={'hidePaymentSetupNotice'}
                />
              </div>
            </div>
            <div className="py-4">
              <CustomInput
                inputType={'number'}
                value={bonusdiscount}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setbonusdiscount(data);
                }}
                Label={'Bonus Discount (%)'}
                Error={ErrorMessage?.bonusdiscount?.length > 0 ? true : false}
                ErrorMessage={ErrorMessage?.bonusdiscount}
              />
            </div>
          </div>
          <div className="w-full pt-4">
            <div className="w-full flex justify-between  py-4">
              <div className="mx-2">
                <p className="py-2 text-blue-dark font-medium text-sm">Auto Renews Monthly</p>
              </div>
              <div>
                <ToggleButton
                  status={hidePaymentSetupNotice}
                  setStatus={(data) => {
                    !enableSaveOption && setenableSaveOption(true);
                    sethidePaymentSetupNotice(data);
                  }}
                  initialstatus={true}
                  id={'AutorenewsMonthly'}
                />
              </div>
            </div>
            <div className="py-4">
              <div className="flex justify-between w-full">
                <div className="w-full">
                  <DropDown
                    Label={'Email Template Label'}
                    value={selectedEmailLabel}
                    setValue={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setselectedEmailLabel(data);
                      setmatchingTemplates(EmailLabels[data]);
                      setselectedTemplate(EmailLabels[data][0].id);
                    }}
                  >
                    {Object.keys(EmailLabels)?.length > 0 &&
                      Object.keys(EmailLabels)?.map((item: any) => (
                        <option value={item}>{item}</option>
                      ))}
                  </DropDown>
                </div>
              </div>
            </div>
            <div className="py-4">
              <div className=" w-full">
                <DropDown
                  Label={'Email Template '}
                  value={selectedTemplate}
                  setValue={(data) => {
                    if (!enableSaveOption) {
                      !enableSaveOption && setenableSaveOption(true);
                    }
                    setselectedTemplate(data);
                  }}
                >
                  {matchingTemplates?.length > 0 &&
                    matchingTemplates?.map((item: any) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </DropDown>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ConsumerSubscriptionModel;
