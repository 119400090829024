import React, { useEffect, useState } from 'react';
import SideBarChild from '../../components/side-bar/side-child';
import Logo from '../../components/side-bar/logo';
import {
  UserRole,
  Engagement,
  users,
  Clients,
  DashBoard,
  clientPortal,
  textBlock,
  admin,
} from './roles';

type props = {
  selectedId: number;
};

const SideBar: React.FunctionComponent<props> = (props): JSX.Element => {
  const userRole = localStorage.getItem('role_type');

  let navigationItems: any = [];

  if (userRole === UserRole.ADMIN) {
    navigationItems = [
      { name: '', child: DashBoard },
      { name: 'Clients', child: Clients },
      // // { name: 'Engagement', child: Engagement },
      { name: 'Users', child: users },
      { name: 'Settings', child: textBlock },
      { name: 'Admin', child: admin },
    ];
  } else if (userRole === UserRole.MANAGERS) {
    navigationItems = [
      { name: 'Clients', child: Clients },
      { name: 'Engagement', child: Engagement },
      { name: 'Users', child: users },
    ];
  } else if (userRole === UserRole.CLIENTS) {
    navigationItems = [{ name: '', child: clientPortal }];
  } else {
  }

  const [selection, setSelection] = useState(props.selectedId);

  const setSelectedOption = (id: number) => {
    setSelection(id);
  };

  useEffect(() => {
    setSelection(props.selectedId);
  }, [props.selectedId]);

  return (
    <div className="fixed h-full bg-gray-background-dark text-white  w-64 ">
      <Logo />
      <div className="max-h-screen pb-32 overflow-y-auto">
        {navigationItems.map((item: any) => {
          return (
            <div>
              <SideBarChild
                heading={item.name}
                childArray={item.child}
                setSelectedOption={setSelectedOption}
                selected={selection}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
