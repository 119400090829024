import React from 'react';
type props = {
  label: string;
  labelClass?: string;
  Error?: boolean;
  ErrorMessage?: string;
  disabled?: boolean;
  children: JSX.Element | JSX.Element[] | boolean;
};

const UseFormDropDown: React.FunctionComponent<props> = ({
  label,
  labelClass,
  children,
}): JSX.Element => {
  return (
    <div>
      {label.length > 0 && (
        <label className={`${labelClass ? labelClass : ' block leading-4 text-gray-dark mb-2'} `}>
          {label}
        </label>
      )}
      <div className="relative inline-flex w-full mb-2">
        <svg
          className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 412 232"
        >
          <path
            d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
            fill="#648299"
            fill-rule="nonzero"
          />
        </svg>
        <> {children}</>
      </div>
    </div>
  );
};

export default UseFormDropDown;
