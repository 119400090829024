import ToggleButton from '../../../components/common/toggle-button';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AccordionTile from '../Accordion';
import {
  postAvailableCreditRequest,
  postCreatePackageProductRequest,
  postUpdatePackageProductRequest,
} from '../clients-redux/actions';
import Model from '../../../components/common/model';
import { CustomInput } from '../../../components/common';

type props = {
  client: any;
  data: any;
  default: any;
  UpdateSaveOption: (data: boolean) => void;
};

const CappedAccess: React.FunctionComponent<props> = (props): JSX.Element => {
  const [renew, setrenew] = useState(true);
  const [hidePaymentSetupNotice, sethidePaymentSetupNotice] = useState(true);
  const [cappedAccessTab, setcappedAccessTab] = useState(false);
  const [MonthlyRenewals, setMonthlyRenewals] = useState(0);
  const [minutesPerMember, setminutesPerMember] = useState(0);
  const [maxmeberusagePerMonth, setmaxmeberusagePerMonth] = useState('');
  const [accountBalance, setAccountBalance] = useState(0);
  const [reservedBalance, setReservedBalance] = useState(0);
  const [transferCredit, settransferCredit] = useState(0);
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const dispatch = useDispatch();
  const [productId, setproductId] = useState();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [popUperrormessage, setpopUperrormessage] = useState('');
  const [ErrorMessage, setErrorMessage] = useState<any>({});
  const [id, setid] = useState();

  const Validation = () => {
    if (cappedAccessTab) {
      if (!minutesPerMember) {
        setErrorMessage({ minutesPerMember: 'Field is required' });
        return false;
      } else if (minutesPerMember.toString().indexOf('.') !== -1) {
        setErrorMessage({
          minutesPerMember: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (+minutesPerMember <= 0 || +minutesPerMember > 240) {
        setErrorMessage({ minutesPerMember: 'Must be a number between 1 and 240' });
        return false;
      } else if (renew && !MonthlyRenewals && MonthlyRenewals !== 0) {
        setErrorMessage({ MonthlyRenewals: 'Field is required' });
        return false;
      } else if (MonthlyRenewals.toString().indexOf('.') !== -1) {
        setErrorMessage({
          MonthlyRenewals: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (renew && (MonthlyRenewals < 0 || MonthlyRenewals > 60)) {
        setErrorMessage({ MonthlyRenewals: 'Must be a number between 1 and 60' });
        return false;
      }
    }
    setErrorMessage({});
    return true;
  };

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;
      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'CAPPED_ACCESS') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setcappedAccessTab(selectedItem?.status === 'enabled' ? true : false);
        const configuration = selectedItem.configuration;
        const autoRenewToggle = configuration.auto_renew_frequency ? true : false;
        sethidePaymentSetupNotice(configuration.hide_payment_tiles);
        setrenew(autoRenewToggle);
        setminutesPerMember(Number(configuration.maximum_minutes_per_user));
        setMonthlyRenewals(configuration.auto_renew_maximum_uses);
        setpackageId(selectedItem && selectedItem.package_id);
        setproductId(selectedItem && selectedItem.product_id);
        setAccountBalance(selectedItem.credit_balance);
        setReservedBalance(selectedItem.unused_credit_balance);
        setid(selectedItem && selectedItem.id);
      } else {
        const configuration = props.default.configuration;
        const autoRenewToggle = configuration.auto_renew_frequency ? true : false;
        setcappedAccessTab(false);
        sethidePaymentSetupNotice(configuration.hide_payment_tiles);
        setrenew(autoRenewToggle);
        setminutesPerMember(+configuration.maximum_minutes_per_user);
        setMonthlyRenewals(configuration.auto_renew_maximum_uses);
        setid(props.default.id);
        setpackageId(props.data.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!renew) {
      setMonthlyRenewals(0);
    }
  }, [renew]);

  useEffect(() => {
    props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    if (Validation()) {
      const payload = {
        status: cappedAccessTab ? 'enabled' : 'disabled',
        configuration: {
          expires_after: null,
          hide_payment_tiles: hidePaymentSetupNotice,
          auto_renew_frequency: renew ? 'monthly' : null,
          auto_renew_maximum_uses: +MonthlyRenewals,
          maximum_minutes_per_user: +minutesPerMember,
          maximum_redemptions_allowed: 0,
        },
      };

      const linkInput = {
        clientId,
        packageId,
        productId,
        id,
      };
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  return (
    <>
      <AccordionTile
        setStatus={(data: boolean) => {
          if ((!data && Validation()) || data) {
            !enableSaveOption && setenableSaveOption(true);
            setcappedAccessTab(data);
          }
        }}
        // disableToggleButton={Object.keys(ErrorMessage).length > 0 ? true : false}
        Label={'CAPPED ACCESS'}
        buttonText={'SAVE'}
        status={cappedAccessTab}
        id={'CappedAcess'}
        disableButton={enableSaveOption}
        onButtonClick={() => {
          if (enableSaveOption) onSave();
        }}
        initialstatus={true}
        InnerComponent={
          <div>
            <div className="flex ">
              <div className="w-1/3 pt-2 px-2">
                <div>
                  <label className="block text-sm leading-4  font-medium text-blue-dark mb-2 pt-2">
                    Minutes Per Member Per Month
                  </label>
                  <input
                    type="text"
                    onWheel={(e: any) => e.target.blur()}
                    min={0}
                    value={minutesPerMember}
                    className="py-2 px-3 w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                    onChange={(e: any) => {
                      const val = e.target.value.replace(/\D/g, '');
                      if (val !== minutesPerMember.toString()) {
                        !enableSaveOption && setenableSaveOption(true);
                      }
                      setminutesPerMember(val);
                    }}
                  />
                  {ErrorMessage?.minutesPerMember && (
                    <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                      {ErrorMessage.minutesPerMember}
                    </label>
                  )}
                </div>
              </div>
              <div className="w-1/3 flex flex-between px-2">
                <div className="mx-2">
                  <p className="py-2 text-blue-dark font-medium text-sm">Renews Monthly</p>
                  <ToggleButton
                    status={renew}
                    setStatus={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setrenew(data);
                    }}
                    initialstatus={true}
                    id={'Renews Monthly'}
                  />
                </div>
              </div>
              <div className={'w-1/3 flex flex-start px-2 ' + (!renew && ' cursor-not-allowed ')}>
                <div>
                  <div className="text-blue-dark font-medium  text-sm pt-2">Monthly Renewals</div>
                  <div className="flex pt-2">
                    <input
                      type="text"
                      onWheel={(e: any) => e.target.blur()}
                      min={0}
                      disabled={!renew}
                      value={MonthlyRenewals}
                      className={
                        'py-2 px-3 w-1/6 rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none ' +
                        (!renew && ' cursor-not-allowed pointer-events-none')
                      }
                      onChange={(e: any) => {
                        const val = e.target.value.replace(/\D/g, '');
                        if (val !== MonthlyRenewals.toString()) {
                          !enableSaveOption && setenableSaveOption(true);
                        }
                        setMonthlyRenewals(val);
                      }}
                    />
                    <p className="pl-3 text-gray-dark font-medium ">Enter 0 for no renewal limit</p>
                  </div>
                  {ErrorMessage?.MonthlyRenewals && (
                    <label className="block text-sm leading-4  font-medium text-red mb-2 pt-2">
                      {ErrorMessage.MonthlyRenewals}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-start pt-2 px-2">
              <div className="w-1/3 flex  items-center ">
                <p className="text-blue-dark font-medium text-sm">
                  Reserved Balance: {`$ ${(reservedBalance / 100).toFixed(2)}`}
                </p>
              </div>
              <div className="flex w-1/3 items-center">
                <p className="text-blue-dark font-medium text-sm px-4">Hide Payment Setup Notice</p>
                <ToggleButton
                  setStatus={(data) => {
                    !enableSaveOption && setenableSaveOption(true);
                    sethidePaymentSetupNotice(data);
                  }}
                  status={hidePaymentSetupNotice}
                  id={'hidepaymentStatus'}
                  initialstatus={true}
                />
              </div>
            </div>
            {!productId && (
              <p className="block text-sm leading-4  font-medium text-blue-dark mb-2 pt-2">
                You must save this product to add credit
              </p>
            )}
            <div className="flex ">
              <div className="w-1/3 pt-2  px-2">
                <div>
                  <label className="block text-sm leading-4  font-medium text-blue-dark mb-2 pt-2">
                    Max Member Usage Per Month
                  </label>
                  <input
                    type="text"
                    onWheel={(e: any) => e.target.blur()}
                    min={0}
                    value={maxmeberusagePerMonth}
                    className="py-2 px-3 w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                    onChange={(e) => {
                      const val = e.target.value.replace(/\D/g, '');
                      if (val !== maxmeberusagePerMonth.toString()) {
                        !enableSaveOption && setenableSaveOption(true);
                      }
                      setmaxmeberusagePerMonth(val);
                    }}
                  />
                </div>
              </div>
              <div className="w-1/3 flex flex-between px-2"></div>
            </div>
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(CappedAccess);
