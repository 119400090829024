export const FETCH_CLIENT_ADMINSTRATORS_REQUEST = 'FETCH_CLIENT_ADMINSTRATORS_REQUEST';
export const FETCH_CLIENT_ADMINSTRATORS_SUCCESS = 'FETCH_CLIENT_ADMINSTRATORS_SUCCESS';
export const FETCH_CLIENT_ADMINSTRATORS_FAILURE = 'FETCH_CLIENT_ADMINSTRATORS_FAILURE';

export const FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST = 'FETCH_CLIENT_ADMINSTRATORS_COUNT_REQUEST';
export const FETCH_CLIENT_ADMINSTRATORS_COUNT_SUCCESS = 'FETCH_CLIENT_ADMINSTRATORS_COUNT_SUCCESS';
export const FETCH_CLIENT_ADMINSTRATORS_COUNT_FAILURE = 'FETCH_CLIENT_ADMINSTRATORS_COUNT_FAILURE';

//Listener Grade
export const POST_INVITE_CLIENT_ADMIN_REQUEST = 'POST_INVITE_CLIENT_ADMIN_REQUEST';
export const POST_INVITE_CLIENT_ADMIN_SUCCESS = 'POST_INVITE_CLIENT_ADMIN_SUCCESS';
export const POST_INVITE_CLIENT_ADMIN_FAILURE = 'POST_INVITE_CLIENT_ADMIN_FAILURE';

//Message Quality
export const DELETE_CLIENT_ADMIN_REQUEST = 'DELETE_CLIENT_ADMIN_REQUEST';
export const DELETE_CLIENT_ADMIN_SUCCESS = 'DELETE_CLIENT_ADMIN_SUCCESS';
export const DELETE_CLIENT_ADMIN_FAILURE = 'DELETE_CLIENT_ADMIN_FAILURE';

export const DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST =
  'DELETE_REVOKE_CLIENT_ADMIN_ACCESS_REQUEST';
export const DELETE_REVOKE_CLIENT_ADMIN_ACCESS_SUCCESS =
  'DELETE_REVOKE_CLIENT_ADMIN_ACCESS_SUCCESS';
export const DELETE_REVOKE_CLIENT_ADMIN_ACCESS_FAILURE =
  'DELETE_REVOKE_CLIENT_ADMIN_ACCESS_FAILURE';

export const POST_INVITE_VIA_EMAIL_REQUEST = 'POST_INVITE_VIA_EMAIL_REQUEST';
export const POST_INVITE_VIA_EMAIL_SUCCESS = 'POST_INVITE_VIA_EMAIL_SUCCESS';
export const POST_INVITE_VIA_EMAIL_FAILURE = 'POST_INVITE_VIA_EMAIL_FAILURE';
