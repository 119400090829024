export const convertObjectToParams = (obj: any): string => {
  return (
    '/?' +
    Object.keys(obj)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]);
      })
      .join('&')
  );
};

export const paramsToObjects = () => {
  const search = window.location.search.substring(1);
  return search?.length > 0
    ? JSON.parse(
        '{"' +
          decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      )
    : undefined;
};
