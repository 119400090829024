import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface ListenerRoleResponse {
  count: number;
  data: ListenerRole[];
}

export interface ListenerRole {
  about_me: string;
  about_me_rejected: boolean;
  age_group: string;
  background_tags: BackgroundTag[];
  calls_succeeded: number;
  can_take_calls: boolean;
  connection_percent: number;
  credentials_region: string;
  credentials_type: any;
  credentials_value: string;
  current_profile_photo: ProfilePhoto;
  earnings: number;
  num_experiences: number;
  num_playbacks: number;
  num_followers: number;
  id: number;
  impact_score: number;
  is_active: boolean;
  is_peer_active: boolean;
  listener_grade: string;
  listener_tags: [string, number][];
  notes: string;
  onboarding_completed: string;
  premier_listener: boolean;
  profile_photo_approved: boolean;
  profile_photo_file_url: string;
  profile_photo_original_file_path: string;
  profile_photo_process_configuration: ProfilePhotoProcessConfiguration;
  proposed_about_me: string;
  state: string;
  tier_advancement: number;
  tier_id: number;
  user: User;
  verification_type: any;
  verified_listener: boolean;
}

export interface BackgroundTag {
  id: number;
  tag_id: number;
  created_at: Date;
  is_approved: boolean;
  media: Media[];
  tag: Tag;
}

export interface Media {
  file_key: string;
  file_url: string;
  file_name?: string;
}

export interface Tag {
  name: string;
}

export interface ProfilePhoto {
  id: number;
  status: string;
  file_url: string;
  original_file_path: string;
  process_configuration: ProfilePhotoProcessConfiguration;
}

export interface ProfilePhotoProcessConfiguration {
  offset: any;
  rotation: any;
}

export interface User {
  created_at: string;
  date_of_birth: string;
  display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  first_name: string;
  id: number;
  last_name: string;
  proposed_display_name: string;
  test_user: boolean;
}

export interface PeerOptions {
  limit?: number;
  page?: number;
  search?: string;
  activeListeners?: boolean;
  userType?: string[];
  tierId?: number[];
  ageGroup?: string[];
  ethnicityTags?: number[];
  genderTags?: number[];
  familyTags?: number[];
  languageTags?: number[];
  relationshipTags?: number[];
  spiritualityTags?: number[];
  hasBackgroundTags?: boolean;
  backgroundTagApproved?: boolean;
}

const buildQueryParams = (peerOptions: PeerOptions): any => {
  let params: any = {};

  const mapOptionToParam = {
    limit: peerOptions.limit,
    page: peerOptions.page,
    search_query: peerOptions.search,
    active_listeners: peerOptions.activeListeners,
    user_type: peerOptions.userType?.length ? peerOptions.userType.join(',') : undefined,
    tier_id: peerOptions.tierId?.length ? peerOptions.tierId.join(',') : undefined,
    age_group: peerOptions.ageGroup?.length ? peerOptions.ageGroup.join(',') : undefined,
    ethnicity_tags: peerOptions.ethnicityTags?.length
      ? peerOptions.ethnicityTags.join(',')
      : undefined,
    gender_tags: peerOptions.genderTags?.length ? peerOptions.genderTags.join(',') : undefined,
    family_tags: peerOptions.familyTags?.length ? peerOptions.familyTags.join(',') : undefined,
    language_tags: peerOptions.languageTags?.length
      ? peerOptions.languageTags.join(',')
      : undefined,
    relationship_tags: peerOptions.relationshipTags?.length
      ? peerOptions.relationshipTags.join(',')
      : undefined,
    spirituality_tags: peerOptions.spiritualityTags?.length
      ? peerOptions.spiritualityTags.join(',')
      : undefined,
    has_background_tags: peerOptions.hasBackgroundTags ? peerOptions.hasBackgroundTags : undefined,
    is_background_tag_approved:
      peerOptions.backgroundTagApproved === true
        ? true
        : peerOptions.backgroundTagApproved === false
        ? false
        : undefined,
  };

  Object.entries(mapOptionToParam).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      params[key] = value;
    }
  });

  return params;
};

const getPeers = async (peerOptions: PeerOptions): Promise<ListenerRoleResponse> => {
  const params = buildQueryParams(peerOptions);

  return await axiosGet(
    '/listeners/',
    {
      ...params,
    },
    'v3'
  ).then((listenerRoleResponse) => listenerRoleResponse.data);
};

export const usePeers = (peerOptions: PeerOptions) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ListenerRoleResponse>(
    ['peers', ...Object.values(peerOptions)],
    () => getPeers(peerOptions)
  );
  return { data, isLoading, error, refetch, isFetching };
};
