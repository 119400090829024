import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput, DropDown, SectionLabel, ToggleButton } from '../../../components/common';
import Model from '../../../components/common/model';
import AccordionTile from '../Accordion';
import {
  postCreatePackageProductRequest,
  postForceCheckInRequest,
  postUpdatePackageProductRequest,
} from '../clients-redux/actions';
import moment from 'moment';
type props = {
  data: any;
  default: any;
  SystemMessages: any;
  UpdateSaveOption: (data: boolean) => void;
};

const Checkins: React.FunctionComponent<props> = (props): JSX.Element => {
  const [checkins, setCheckins] = useState(false);
  const [question, setQuestion] = useState('');
  const [incentivePoints, setincentivePoints] = useState(0);
  const [interimQuestiondelay, setinterimQuestiondelay] = useState(0);
  const [onBoardingMessage, setonBoardingMessage] = useState(0);
  const [onBoardingMessagedelay, setonBoardingMessagedelay] = useState(0);
  const [followQuestion, setFollowQuestion] = useState('');
  const [dayofTheWeek, setdayofTheWeek] = useState('Monday');
  const [checkInCandence, setcheckInCandence] = useState('weekly');
  const [timeOftheday, settimeOftheday] = useState('00:00:00');
  const [followUpTimeType, setfollowUpTimeType] = useState('minutes');
  const [onboardingTime, setonboardingTime] = useState('minutes');
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [enableforceCheckInPopUp, setenableforceCheckInPopUp] = useState(false);
  const [checkInOnboarding, setcheckInOnboarding] = useState();

  const [checkInNextRun, setcheckInNextRun] = useState<String | null | undefined>('Not Scheduled');
  const [interimNextRun, setinterimNextRun] = useState<String | null | undefined>('Not Scheduled');
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [id, setid] = useState();
  const dispatch = useDispatch();
  const [Weeks] = React.useState([
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
  ]);
  const [error, seterror] = useState(false);

  const timeType = [
    {
      label: 'Minutes',
      value: 'minutes',
    },
    {
      label: 'Hours',
      value: 'hours',
    },
    {
      label: 'Days',
      value: 'days',
    },
  ];
  const weekType = [
    {
      label: 'Weekly',
      value: 'weekly',
    },
  ];

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const convertSecondsToMinutes = (time: any) => {
    const seconds = Number(time);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60);
    const days = Math.floor(seconds / (3600 * 24));

    if (days > 0) {
      return { type: 'days', time: days };
    } else if (hours > 0) {
      return { type: 'hours', time: hours };
    } else {
      return { type: 'minutes', time: minutes };
    }
  };

  const convertTimetoSeconds = (time: any, type: any) => {
    const seconds = Number(time);
    const hours = Math.floor(seconds * 3600);
    const minutes = Math.floor(seconds * 60);
    const days = Math.floor(seconds * (3600 * 24));

    if (type === 'days') {
      return days;
    } else if (type === 'hours') {
      return hours;
    } else {
      return minutes;
    }
  };

  const Validation = () => {
    if (checkins) {
      if (
        !question ||
        !incentivePoints ||
        incentivePoints <= 0 ||
        incentivePoints > 50 ||
        incentivePoints.toString().indexOf('.') !== -1 ||
        !interimQuestiondelay ||
        (checkInOnboarding && onBoardingMessagedelay < 0)
      ) {
        seterror(true);
        return false;
      }
    }

    seterror(false);
    return true;
  };

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data?.package_products;
      const selectedItem = packageProducts?.find((item: any) => {
        if (item.product.key === 'CHECKIN_WITH_INTERIM') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setCheckins(selectedItem?.status === 'enabled' ? true : false);
        const configuration = selectedItem.configuration;
        setQuestion(configuration.primary_question);

        setincentivePoints(configuration.engagement_points);
        setFollowQuestion(configuration.interim_question);
        const interim = convertSecondsToMinutes(configuration.interim_question_delay);
        setinterimQuestiondelay(interim.time);
        setfollowUpTimeType(interim.type);
        const onboarding = configuration.onboarding;
        setcheckInOnboarding(onboarding.enabled);
        setonBoardingMessage(
          onboarding.system_message_id === null ? 0 : onboarding.system_message_id
        );
        const OnboardingTime = convertSecondsToMinutes(onboarding.delay);
        setonBoardingMessagedelay(OnboardingTime.time);
        setonboardingTime(OnboardingTime.type);

        const cadence = configuration.cadence;
        setdayofTheWeek(cadence.on);
        setcheckInCandence(cadence.period);
        settimeOftheday(configuration.cadence_time);
        setpackageId(selectedItem && selectedItem.package_id);
        setproductId(selectedItem && selectedItem.product_id);
        setid(selectedItem && selectedItem.id);
        if (selectedItem && selectedItem.id) {
          const checkinNextrun = genrateNextCheckIn(cadence, configuration.cadence_time);
          setcheckInNextRun(checkinNextrun?.format('MMM. D, YYYY / h:mma z'));
          const interimNextrun = moment(moment(checkinNextrun).format('YYYY-MM-DD HH:mm:ss'))
            .add(configuration.interim_question_delay, 's')
            .format('MMM. D, YYYY / h:mma z');
          setinterimNextRun(interimNextrun);
        } else {
          setcheckInNextRun('Not Scheduled');
          setinterimNextRun('Not Scheduled');
        }
      } else {
        const configuration = props.default?.configuration;
        setCheckins(false);
        setQuestion(configuration.primary_question);
        setincentivePoints(configuration.engagement_points);
        setFollowQuestion(configuration.interim_question);
        const interim = convertSecondsToMinutes(configuration.interim_question_delay);
        setinterimQuestiondelay(interim.time);
        setfollowUpTimeType(interim.type);
        const onboarding = configuration.onboarding;
        setcheckInOnboarding(onboarding.enabled);
        setonBoardingMessage(
          onboarding.system_message_id === null ? 0 : onboarding.system_message_id
        );
        const OnboardingTime = convertSecondsToMinutes(onboarding.delay);
        setonBoardingMessagedelay(OnboardingTime.time);
        setonboardingTime(OnboardingTime.type);
        const cadence = configuration.cadence;
        setdayofTheWeek(cadence.on);
        setcheckInCandence(cadence.period);
        settimeOftheday(configuration.cadence_time);
        setid(props.default.id);
        setpackageId(props.data.id);
        setcheckInNextRun('Not Scheduled');
        setinterimNextRun('Not Scheduled');
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    const calculatedonBoardingMessagedelay = convertTimetoSeconds(
      onBoardingMessagedelay,
      onboardingTime
    );
    const interimTime = convertTimetoSeconds(interimQuestiondelay, followUpTimeType);

    const payload = {
      status: checkins ? 'enabled' : 'disabled',
      configuration: {
        cadence: {
          on: dayofTheWeek,
          period: checkInCandence,
        },
        cadence_time: timeOftheday,
        engagement_points: +incentivePoints,
        interim_question: followQuestion,
        primary_question: question,
        onboarding: {
          enabled: checkInOnboarding,
          delay: +calculatedonBoardingMessagedelay,
          system_message_id: checkInOnboarding ? +onBoardingMessage : null,
        },
        interim_question_delay: +interimTime,
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };
    if (Validation()) {
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  useEffect(() => {
    if (!checkInOnboarding) {
      setonBoardingMessage(0);
    }
  }, [checkInOnboarding]);

  const forceCheckIn = () => {
    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };
    if (productId) {
      dispatch(postForceCheckInRequest({ linkInput }));
      setenableforceCheckInPopUp(false);
    }
  };

  const enablePopUp = () => {
    setenableforceCheckInPopUp(true);
  };
  const genrateNextCheckIn = (cadence: any, cadenceTime: any) => {
    let dayDiff = null;
    if (cadence.period === 'weekly') {
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      const configured_weekday = days.indexOf(cadence.on) + 1;
      dayDiff = configured_weekday - moment().isoWeekday();
      if (dayDiff < 0) {
        dayDiff = 7 + dayDiff;
      }
    }
    const time = moment.duration(cadenceTime);
    const nextrun = moment()
      .millisecond(0)
      .seconds(0)
      .minutes(time.minutes())
      .hours(time.hours())
      .add(dayDiff, 'days');

    return moment(nextrun);
  };

  return (
    <div className="relative w-full">
      <AccordionTile
        setStatus={(data: boolean) => {
          if ((!data && Validation()) || data) {
            !enableSaveOption && setenableSaveOption(true);
            setCheckins(data);
          }
        }}
        Label={'CHECK-INS'}
        buttonText={'SAVE'}
        status={checkins}
        id={'Checkins'}
        disableButton={enableSaveOption}
        onButtonClick={() => {
          if (enableSaveOption) onSave();
        }}
        initialstatus={true}
        InnerComponent={
          <div>
            <div className="flex justify-evenly gap-7 my-10">
              <div className="w-full ">
                <div className="py-4">
                  <SectionLabel Label="PRIMARY CHECK-IN" />
                </div>
                <div className="py-4">
                  <CustomInput
                    inputType={'text'}
                    value={question}
                    setValue={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setQuestion(data);
                    }}
                    Label={'Question'}
                    Error={error && !question}
                    ErrorMessage={'Field is Required'}
                  />
                </div>
                <div className="py-4">
                  <CustomInput
                    inputType={'number'}
                    value={incentivePoints}
                    setValue={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setincentivePoints(data);
                    }}
                    Label={'Incentive Points Per Check-in'}
                    Error={
                      error && (!incentivePoints || incentivePoints <= 0 || incentivePoints > 50)
                    }
                    ErrorMessage={
                      !incentivePoints && incentivePoints !== 0
                        ? 'Field is Required'
                        : incentivePoints.toString().indexOf('.') !== -1
                        ? 'Number may not include decimals - whole numbers only'
                        : incentivePoints <= 0 || incentivePoints > 50
                        ? 'Must be a number between 1 and 50'
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="w-full  ">
                <div className="w-full flex flex-between">
                  <div className="w-full">
                    <div>Next Scheduled Check-in</div>
                    <div>{`Primary : ${checkInNextRun}`}</div>
                    <div>{`Interim :  ${interimNextRun}`}</div>
                  </div>
                  <div className="py-4 w-full flex justify-end items-center ">
                    <button
                      className={
                        'stacked-bar-blue-400 rounded-sm  text-center  text-sm text-white  p-4' +
                        (!productId ? ' opacity-50 ' : ' ')
                      }
                      onClick={enablePopUp}
                      disabled={!productId}
                    >
                      CHECK IN NOW
                    </button>
                  </div>
                </div>
                <div className="py-4">
                  <div className="w-full">
                    <div className=" w-full">
                      <DropDown
                        Label={'Check in Cadence'}
                        value={checkInCandence}
                        setValue={(data) => {
                          !enableSaveOption && setenableSaveOption(true);
                          setcheckInCandence(data);
                        }}
                      >
                        {weekType.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <div className="flex justify-between w-full">
                    <div className="w-full">
                      <div className=" w-full">
                        <DropDown
                          Label={'Day of Week'}
                          value={dayofTheWeek}
                          setValue={(data) => {
                            !enableSaveOption && setenableSaveOption(true);
                            setdayofTheWeek(data);
                          }}
                        >
                          {Weeks.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </DropDown>
                      </div>
                    </div>
                    <div className="w-full  pl-4">
                      <CustomInput
                        inputType={'time'}
                        value={timeOftheday}
                        setValue={(data) => {
                          !enableSaveOption && setenableSaveOption(true);
                          settimeOftheday(data);
                        }}
                        Label={'Time of the day'}
                        Error={error && !timeOftheday}
                        ErrorMessage={'Field is Required'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid gap-7 my-10">
              <SectionLabel Label="FOLLOW-UP CHECK-IN" />
              <div className="w-full grid grid-cols-4 gap-4">
                <div className="col-span-2 ">
                  <CustomInput
                    inputType={'text'}
                    value={followQuestion}
                    setValue={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setFollowQuestion(data);
                    }}
                    Label={'Question'}
                    Error={error && !followQuestion}
                    ErrorMessage={'Field is Required'}
                  />
                </div>
                <div className="col-span-1 ">
                  <CustomInput
                    inputType={'number'}
                    value={interimQuestiondelay}
                    setValue={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setinterimQuestiondelay(data);
                    }}
                    Label={'Interim Question Delay'}
                    Error={error && !interimQuestiondelay}
                    ErrorMessage={'Field is Required'}
                  />
                </div>
                <div className="col-span-1 ">
                  <div className="flex justify-between w-full">
                    <div className="w-full">
                      <DropDown
                        Label={'Day of Week'}
                        value={followUpTimeType}
                        setValue={(data) => {
                          !enableSaveOption && setenableSaveOption(true);
                          setfollowUpTimeType(data);
                        }}
                      >
                        {timeType.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </DropDown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid gap-7 my-10">
              <div className="w-full ">
                <div className="flex  w-full justify-between  py-3 border-b gray-border-line">
                  <div className="text-md font-bold text-gray-dark">{`CHECK-IN ONBOARDING`}</div>
                  <ToggleButton
                    setStatus={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setcheckInOnboarding(data);
                    }}
                    status={checkInOnboarding}
                    id={'checkInOnboarding'}
                    initialstatus={true}
                  />
                </div>
              </div>
              <div
                className={
                  ' grid grid-cols-4 gap-4 ' +
                  (!checkInOnboarding && ' opacity-50 pointer-events-none cursor-not-allowed ')
                }
              >
                <div className="col-span-2 ">
                  <label className="block text-sm leading-4  font-medium text-blue-dark ">
                    Select System Comms
                  </label>
                  <div className="flex justify-between w-full">
                    <div className="w-full">
                      <div className=" w-full">
                        <div className="block text-sm leading-4  font-medium text-blue-dark mt-2"></div>

                        <DropDown
                          Label={''}
                          value={onBoardingMessage}
                          setValue={(data) => {
                            !enableSaveOption && setenableSaveOption(true);
                            setonBoardingMessage(data === 0 ? null : data);
                          }}
                        >
                          {/* {!checkInOnboarding && ( */}
                          <option key={0} value={0}>
                            No Message
                          </option>
                          {/* )} */}
                          {props.SystemMessages?.length > 0 &&
                            props.SystemMessages.map((item: any) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </DropDown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 ">
                  <CustomInput
                    inputType={'number'}
                    value={onBoardingMessagedelay}
                    setValue={(data) => {
                      !enableSaveOption && setenableSaveOption(true);
                      setonBoardingMessagedelay(data);
                    }}
                    Label={'Onboard Message Delay'}
                    Error={error && onBoardingMessagedelay < 0}
                    ErrorMessage={'Field is Required'}
                  />
                </div>
                <div className="col-span-1 ">
                  <div className="flex justify-between w-full">
                    <div className="w-full">
                      <div className=" w-full">
                        <div className="block text-sm leading-4  font-medium text-blue-dark mt-6"></div>

                        <DropDown
                          Label={''}
                          value={onboardingTime}
                          setValue={(data) => {
                            !enableSaveOption && setenableSaveOption(true);
                            setonboardingTime(data);
                          }}
                        >
                          {timeType.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </DropDown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <div>
        {
          <Model
            isModel={enableforceCheckInPopUp}
            InnerComponent={
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        className="h-6 w-6 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        Force Check-in?
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          This will reset any current check-in states, including interim check-ins
                          and begin the check-in process now.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={forceCheckIn}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    FORCE CHECK IN
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setenableforceCheckInPopUp(false);
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            }
          />
        }
      </div>
    </div>
  );
};

export default Checkins;
