import React from 'react';
import Header from '../../components/common/global-top-bar';
import { StackedBarChart } from '../../components/charts';
import { NumericLabel, CustomSlider } from '../../components/common';
import Kohli from '../../assets/images/kohli.svg';
import PlayIcon from '../../assets/images/play-icon.svg';
import { Headeroptions } from './member-constants';
import SwitchBar from '../../components/switch-bar';
import SwitchBarButton from './switch-bar-button';
import HeadBack from './redirect';

const Card = () => {
  return (
    <div className="w-full h-32 bg-gray-200 rounded grid grid-cols-12 px-7 my-2">
      <div className="col-span-3 flex items-center justify-center px-2">
        <div className="rounded-full h-24 w-24 bg-gray-100">
          <img className="h-full w-full " src={Kohli} alt="kohli" />
        </div>
      </div>
      <div className="col-span-6 py-4 px-4">
        <p className="text-2xl font-normal blue-primary  py-1">Kohli</p>
        <p className="text-base font-normal text-black  py-1">Indian captian</p>
        <p className="text-base font-normal text-black  py-1">Sep 16 ,2021</p>
      </div>
      <div className="col-span-3 p-2 flex items-center justify-center">
        <div className="h-16 p-1 w-16">
          <img src={PlayIcon} alt="playicon" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

type props = {
  Label: string;
  Numericvalue: string;
};

const NumberLabel: React.FunctionComponent<props> = ({ Numericvalue, Label }): JSX.Element => {
  return (
    <div className="relative   py-3 ">
      <div className="text-4xl font-normal blue-primary font-montseerat">{Numericvalue}</div>
      <div className="text-base font-montseerat font-normal text-gray-dark">{Label}</div>
    </div>
  );
};

const Impact = () => {
  return (
    <div className=" flex justify-between w-full pr-7">
      <div className="w-1/3 py-7">
        <NumericLabel numericValue={'14'} label={'Total'} />
      </div>

      <div className="w-2/3 py-7">
        <div className=" flex justify-between w-full  h-2/3 items-end">
          <StackedBarChart xwidth={'14%'} backGroundColor={'stacked-bar-red-primary'} />
          <StackedBarChart xwidth={'63%'} backGroundColor={'stacked-bar-orange-primary'} />
          <StackedBarChart xwidth={'23%'} backGroundColor={'stacked-bar-green-primary'} />
        </div>
        <div className="text-sm text-gray-dark flex items-center py-1">
          {` Negative / Neutral / Positive `}
        </div>
      </div>
    </div>
  );
};

const MemberEngagements: React.FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <Header userName={'Jason'} heading={<HeadBack />} />

      <SwitchBar heading={Headeroptions} position={1} optionalButton={<SwitchBarButton />} />

      {/* </div> */}
      <div className="max-window-height  overflow-y-auto  px-7 ">
        <div className="flex flex-between ">
          <div className="w-1/2 ">
            <div className="flex justify-between py-4">
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark ">
                Connected Requests
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark ">
                Unanswered Connections
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark ">
                Passed Connections
              </div>
            </div>
            <div className="flex justify-between py-4">
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'10'} Label={'Total'} />
              </div>
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'3'} Label={'30%'} />
              </div>
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'1'} Label={'10%'} />
              </div>
            </div>
            <div className="w-full mt-4">
              <div className="relative  w-full   border-b gray-border-line  font-bold text-gray-dark">
                Mood Measeurements
              </div>
            </div>
            <Impact />
            <div className="w-full mt-4 flex flex-between ">
              <div className="w-7/12 flex px-2 items-center">
                {' '}
                <p>Sep 10 @4:30pm</p>
                <button className={'w-28 rounded-full py-2 px-2 text-white magenta '}>
                  Post-call
                </button>
              </div>
              <div className="w-full px-2 py-4">
                <CustomSlider value={'75'} />
              </div>
            </div>
            <div className="w-full mt-4 flex flex-between ">
              <div className="w-7/12 flex px-2 items-center">
                {' '}
                <p>Sep 10 @4:30pm</p>
                <button className={'w-28 rounded-full py-2 px-2 text-white magenta '}>
                  Post-call
                </button>
              </div>
              <div className="w-full px-2 py-4">
                <CustomSlider value={'38'} />
              </div>
            </div>
            <div className="w-full mt-4 flex flex-between ">
              <div className="w-7/12 flex px-2 items-center">
                {' '}
                <p>Sep 10 @4:30pm</p>
                <button className={'w-28 rounded-full py-2 px-2 text-white magenta '}>
                  Post-call
                </button>
              </div>
              <div className="w-full px-2 py-4">
                <CustomSlider value={'20'} />
              </div>
            </div>
          </div>
          <div className="w-1/2 px-7">
            <div className="flex justify-between py-4">
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark ">
                Calls
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark ">
                Total Call Minutes
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark ">
                Avg. Call Length
              </div>
            </div>
            <div className="flex justify-between py-4">
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'6'} Label={'60%'} />
              </div>
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'180'} Label={'Minutes'} />
              </div>
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'21'} Label={'Minutes'} />
              </div>
            </div>

            <div className="flex justify-between py-4">
              <div className="w-1/3 text-gray-dark font-bold font-base  border-b border-gray-dark ">
                Reward points
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base  border-b border-gray-dark ">
                Rewards Earned
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base  border-b border-gray-dark ">
                Rewards Redeemed
              </div>
            </div>
            <div className="flex justify-between py-4">
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'14'} Label={'Total'} />
              </div>
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'$10.00'} Label={'Total'} />
              </div>
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'$1.00'} Label={'Total'} />
              </div>
            </div>
            <div className="flex justify-between py-4">
              <div className="w-full ">
                <div className="relative  w-full   border-b gray-border-line  font-bold text-gray-dark">
                  Message Plays
                </div>
              </div>
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark "></div>
              <div className="w-1/3 text-gray-dark font-bold font-base pr-7 border-b border-gray-dark "></div>
            </div>
            <div className="flex justify-between py-4">
              <div className="w-1/3  ">
                <NumberLabel Numericvalue={'10'} Label={'Total'} />
              </div>
            </div>
            <Card />
            <Card />
            <Card />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberEngagements;
