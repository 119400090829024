import React, { useEffect, useState } from 'react';
import { options } from './calender-page-constants';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import moment from 'moment';

type props = {
  changedDate?: (startDate: string, endDate: string) => void;
  handleDownload?: (data: any) => void;
  disableDownload?: boolean;
  dateLimit?: any;
};

const CalendarPaginator: React.FunctionComponent<props> = (props): JSX.Element => {
  const [dateType, setDateType] = useState('YTD');
  const [dateCounter, setdateCounter] = useState(0);
  const [time, settime] = useState('time');
  const clientId = localStorage.getItem('clientId');
  const [disableLeftArrow, setdisableLeftArrow] = useState(false);

  const getDateLimit = (type: 'isoWeek' | 'year' | 'month') => {
    const date = moment(props?.dateLimit || '2021-08-02T07:26:03.744844+00:00');

    return date.startOf(type);
  };

  const calculatedDates = () => {
    let startDate: any = new Date();
    let endDate: any = new Date();

    if (dateType === 'Week') {
      startDate = moment()
        .subtract(7 * dateCounter, 'days')
        .startOf('isoWeek');
      endDate = moment()
        .subtract(7 * dateCounter, 'days')
        .endOf('isoWeek');

      if (startDate < getDateLimit('isoWeek').add(7, 'days')) setdisableLeftArrow(true);
      else setdisableLeftArrow(false);

      if (dateCounter) {
        settime(`Week of ${startDate.format('MMMM D, YYYY')} - ${endDate.format('MMMM D, YYYY')}`);
      } else {
        settime('This Week');
      }
    } else if (dateType === 'Month') {
      // current month
      startDate = moment().subtract(dateCounter, 'months').startOf('month');
      endDate = moment().subtract(dateCounter, 'months').endOf('month');

      if (startDate < getDateLimit('month').add(1, 'month')) setdisableLeftArrow(true);
      else setdisableLeftArrow(false);

      if (dateCounter) {
        settime(`${startDate.format('MMMM, YYYY')}`);
      } else {
        settime('This Month');
      }
    } else {
      startDate = moment(new Date()).subtract(dateCounter, 'year').startOf('year');
      endDate = moment(new Date()).subtract(dateCounter, 'year').endOf('year');

      if (startDate < getDateLimit('year').add(1, 'year')) setdisableLeftArrow(true);
      else setdisableLeftArrow(false);

      if (dateCounter) {
        settime(`${startDate.format('YYYY')} `);
      } else {
        settime('This Year');
      }
    }
    return { startDate, endDate };
  };

  const calculateTime = () => {
    const dates = calculatedDates();

    if (props.changedDate)
      props.changedDate(dates.startDate.format('YYYY-MM-DD'), dates.endDate.format('YYYY-MM-DD'));
  };

  const handleDownlaod = () => {
    const date = calculatedDates();
    //@ts-ignore
    props.handleDownload(date);
  };

  useEffect(() => {
    calculateTime();
  }, [dateCounter, dateType, clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setdisableLeftArrow(false);
  }, [setDateType]);

  return (
    <div className="w-full bg-gray-background-light px-6  h-full">
      <div className="flex justify-between items-center  gray-background-light text-gray-dark h-full">
        <div
          className="flex justify-end bg-white rounded-full"
          onClick={() => {
            setdateCounter((counter: number) => counter + 1);
          }}
        >
          {!disableLeftArrow && <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>}
        </div>

        <div className=" flex justify-center font-medium font-montserrat text-sm">{time}</div>
        <div className="flex justify-end  bg-white  transform rotate-180  rounded-full">
          {dateCounter > 0 && (
            <img
              className="h-6 w-6"
              src={RightArrow}
              alt="Right Arrow"
              onClick={() => {
                setdateCounter((counter: number) => counter - 1);
              }}
            ></img>
          )}
        </div>

        <div className="flex font-medium justify-between items-center text-gray-dark ">
          {/* {!props.disableDownload && (
                        <button
                            className="rounded-full text-sm bg-white mx-2 px-5 py-1"
                            onClick={handleDownlaod}
                        >
                            DOWNLOAD
                        </button>
                    )} */}
          <div className="flex justify-between items-center py-1  gray-background-dark">
            {options.map((item, index) => (
              <button
                key={`cal_${index}`}
                className={
                  ' text-sm  px-5 py-1 ' +
                  (item === dateType
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setDateType(item);
                  setdateCounter(0);
                }}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPaginator;
