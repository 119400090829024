import React from "react";
import NumericDisplayLarge from "../../components/common/numeric-header-label";
import { GlobalDashboardResponse } from "../../hooks/useReporting";
import { safeDiv } from "./helpers";

const DashboardOverviewPage: React.FC<{
  clientName?: string;
  packageName?: string;
  reportType: string;
  reportingOverviewData?: GlobalDashboardResponse;
  reportingDataExtras?: GlobalDashboardResponse;
}> = ({clientName, packageName, reportType, reportingOverviewData, reportingDataExtras}) => (
<div>
  <div className="w-full blue-primary text-3xl font-bold font-['Montserrat'] border-b-2 border-gray-dark-1 py-2 pb-2">
    Overview{clientName && `: ${clientName}`} {packageName && `: ${packageName}`}
  </div>
  <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xsm:grid-cols-1 rows-2 gap-6 mt-4">
    {reportType === 'all_groups' && (
      <NumericDisplayLarge
        bigNumber={reportingOverviewData?.active_groups || 0}
        header={'Total Active Groups'}
        subHeader={'Does not include demos or tests'}
      />
    )}
    <NumericDisplayLarge
      bigNumber={reportingOverviewData?.total_members || 0}
      header={'Total Eligible Members'}
      subHeader={'From all eligibility files'}
    />
    <NumericDisplayLarge
      bigNumber={reportingOverviewData?.activated_members || 0}
      header={'Total Activated Members'}
      subHeader={'Members who have activated their account via sign-in'}
      eligiblePercent={safeDiv(reportingOverviewData?.activated_members, reportingOverviewData?.total_members)}
    />
    <NumericDisplayLarge
      bigNumber={reportingOverviewData?.engaged_members || 0}
      header={'Total Engaged Members'}
      subHeader={'Members who have at least one engagement once Activated'}
      activePercent={safeDiv(reportingOverviewData?.engaged_members, reportingOverviewData?.activated_members)}
      eligiblePercent={safeDiv(reportingOverviewData?.engaged_members, reportingOverviewData?.total_members)}
    />
  </div>
  <div className="w-full blue-primary text-3xl font-bold font-['Montserrat'] border-b-2 border-gray-dark-1 py-2">
    Peer Listener Calls
  </div>
  <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 xsm:grid-cols-1 rows-2 gap-6 mt-4">
    <NumericDisplayLarge
      bigNumber={reportingDataExtras?.calls_count !== undefined ? reportingDataExtras?.calls_count : 0}
      header={'Number of calls'}
      subHeader={'within selected dates'}
    />
  </div>
</div>
);
export default DashboardOverviewPage;