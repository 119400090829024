import React from 'react';

type props = {
  label: string;
  numericValue: string;
  header?: string;
  pending?: boolean;
  subHeader?: string;
};

const NumberLabel: React.FunctionComponent<props> = ({
  numericValue,
  label,
  header,
  subHeader,
  pending,
}): JSX.Element => {
  return (
    <div className="relative  mx-2 ">
      <div className="text-base font-bold text-gray-dark">{header}</div>
      <div className="text-base font-montseerat font-normal text-gray-dark">{subHeader}</div>
      <div className="text-4xl font-normal blue-primary font-montseerat mt-2">
        {!pending ? numericValue : 0}
      </div>
      <div className="text-base font-montseerat font-normal text-gray-dark">{label}</div>
    </div>
  );
};

export default NumberLabel;
