import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENT_COUNT_REQUEST,
  FETCH_CLIENT_COUNT_FAILURE,
  FETCH_CLIENT_COUNT_SUCCESS,
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_FAILURE,
  FETCH_TAGS_SUCCESS,
  POST_AVAILABLE_CREDIT_REQUEST,
  POST_AVAILABLE_CREDIT_FAILURE,
  POST_AVAILABLE_CREDIT_SUCCESS,
  GET_ADMIN_DETAILS_REQUEST,
  GET_ADMIN_DETAILS_FAILURE,
  GET_ADMIN_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_SUCCESS,
  POST_CREATE_PACKAGE_REQUEST,
  POST_CREATE_PACKAGE_FAILURE,
  POST_CREATE_PACKAGE_SUCCESS,
  POST_UPDATE_PACKAGE_REQUEST,
  POST_UPDATE_PACKAGE_FAILURE,
  POST_UPDATE_PACKAGE_SUCCESS,
  POST_UPDATE_PACKAGE_PRODUCT_REQUEST,
  POST_UPDATE_PACKAGE_PRODUCT_FAILURE,
  POST_UPDATE_PACKAGE_PRODUCT_SUCCESS,
  POST_CREATE_PACKAGE_PRODUCT_REQUEST,
  POST_CREATE_PACKAGE_PRODUCT_FAILURE,
  POST_CREATE_PACKAGE_PRODUCT_SUCCESS,
  POST_ADD_COMMS_REQUEST,
  POST_ADD_COMMS_FAILURE,
  POST_ADD_COMMS_SUCCESS,
  FETCH_WEB_APP_URL_SETTING_REQUEST,
  FETCH_WEB_APP_URL_SETTING_FAILURE,
  FETCH_WEB_APP_URL_SETTING_SUCCESS,
  POST_FORCE_CHECK_IN_REQUEST,
  POST_FORCE_CHECK_IN_FAILURE,
  POST_FORCE_CHECK_IN_SUCCESS,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_CAMPAIGN_FAILURE,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_PRODUCT_TEMPLATE_REQUEST,
  FETCH_PRODUCT_TEMPLATE_FAILURE,
  FETCH_PRODUCT_TEMPLATE_SUCCESS,
  REMOVED_COMMS_REQUEST,
  REMOVED_COMMS_FAILURE,
  REMOVED_COMMS_SUCCESS,
  POST_CREATE_CLIENT_REQUEST,
  POST_CREATE_CLIENT_FAILURE,
  POST_CREATE_CLIENT_SUCCESS,
  POST_UPDATE_CLIENT_REQUEST,
  POST_UPDATE_CLIENT_FAILURE,
  POST_UPDATE_CLIENT_SUCCESS,
  FETCH_EMAIL_TEMPLATE_REQUEST,
  FETCH_EMAIL_TEMPLATE_SUCCESS,
  FETCH_EMAIL_TEMPLATE_FAILURE,
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_INCENTIVES_REQUEST,
  FETCH_INCENTIVES_FAILURE,
  FETCH_INCENTIVES_SUCCESS,
  FETCH_SYSTEM_MESSAGES_REQUEST,
  FETCH_SYSTEM_MESSAGES_SUCCESS,
  FETCH_SYSTEM_MESSAGES_FAILURE,
  FETCH_REPORTING_REQUEST,
  FETCH_REPORTING_FAILURE,
  FETCH_REPORTING_SUCCESS,
  DELETE_PACKAGE_REQUEST,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAILURE,
  DELETE_CLIENT_DETAILS_REQUEST,
  DELETE_CLIENT_DETAILS_SUCCESS,
  DELETE_CLIENT_DETAILS_FAILURE,
  FETCH_APP_SETTINGS_FAILURE,
  FETCH_APP_SETTINGS_REQUEST,
  FETCH_APP_SETTINGS_SUCCESS,
  FETCH_EXTERNAL_PROVIDER_PLANS_FAILURE,
  FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST,
  FETCH_EXTERNAL_PROVIDER_PLANS_SUCCESS,
  POST_SUBSCRIPTIONS_FAILURE,
  POST_SUBSCRIPTIONS_REQUEST,
  POST_SUBSCRIPTIONS_SUCCESS,
  REMOVE_SUBSCRIPTIONS_FAILURE,
  REMOVE_SUBSCRIPTIONS_REQUEST,
  REMOVE_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTIONS_REQUEST,
  UPDATE_SUBSCRIPTIONS_SUCCESS,
  GET_DEFAULT_SUBSCRIPTIONS_REQUEST,
  GET_DEFAULT_SUBSCRIPTIONS_SUCCESS,
  GET_DEFAULT_SUBSCRIPTIONS_FAILURE,
} from './actionTypes';
import {
  FetchClientsRequest,
  FetchClientsSuccess,
  FetchClientsSuccessPayload,
  FetchClientsFailure,
  FetchClientsFailurePayload,
  FetchClientCountRequest,
  FetchClientCountSuccess,
  FetchClientCountSuccessPayload,
  FetchClientCountFailure,
  FetchClientCountFailurePayload,
  FetchTagsRequest,
  FetchTagsSuccess,
  FetchTagsSuccessPayload,
  FetchTagsFailure,
  FetchTagsFailurePayload,
  PostAvailableCreditRequest,
  PostAvailableCreditSuccess,
  PostAvailableCreditSuccessPayload,
  PostAvailableCreditFailure,
  PostAvailableCreditFailurePayload,
  GetAdminDetailsRequest,
  GetAdminDetailsSuccess,
  GetAdminDetailsSuccessPayload,
  GetAdminDetailsFailure,
  GetAdminDetailsFailurePayload,
  GetClientDetailsRequest,
  GetClientDetailsSuccess,
  GetClientDetailsSuccessPayload,
  GetClientDetailsFailure,
  GetClientDetailsFailurePayload,
  PostCreatePackageRequest,
  PostCreatePackageSuccess,
  PostCreatePackageSuccessPayload,
  PostCreatePackageFailure,
  PostCreatePackageFailurePayload,
  PostUpdatePackageRequest,
  PostUpdatePackageSuccess,
  PostUpdatePackageSuccessPayload,
  PostUpdatePackageFailure,
  PostUpdatePackageFailurePayload,
  PostUpdatePackageProductRequest,
  PostUpdatePackageProductSuccess,
  PostUpdatePackageProductSuccessPayload,
  PostUpdatePackageProductFailure,
  PostUpdatePackageProductFailurePayload,
  PostCreatePackageProductRequest,
  PostCreatePackageProductSuccess,
  PostCreatePackageProductSuccessPayload,
  PostCreatePackageProductFailure,
  PostCreatePackageProductFailurePayload,
  PostAddCommsRequest,
  PostAddCommsSuccess,
  PostAddCommsSuccessPayload,
  PostAddCommsFailure,
  PostAddCommsFailurePayload,
  FetchWebAppUrlSettingRequest,
  FetchWebAppUrlSettingSuccess,
  FetchWebAppUrlSettingSuccessPayload,
  FetchWebAppUrlSettingFailure,
  FetchWebAppUrlSettingFailurePayload,
  PostForceCheckInRequest,
  PostForceCheckInSuccess,
  PostForceCheckInFailure,
  PostForceCheckInFailurePayload,
  FetchCampaignRequest,
  FetchCampaignSuccess,
  FetchCampaignSuccessPayload,
  FetchCampaignFailure,
  FetchCampaignFailurePayload,
  GetProductTemplateRequest,
  GetProductTemplateSuccess,
  GetProductTemplateSuccessPayload,
  GetProductTemplateFailure,
  GetProductTemplateFailurePayload,
  RemovedCommsRequest,
  RemovedCommsSuccess,
  RemovedCommsSuccessPayload,
  RemovedCommsFailure,
  RemovedCommsFailurePayload,
  PostCreateClientRequest,
  PostCreateClientSuccess,
  PostCreateClientFailure,
  PostCreateClientFailurePayload,
  PostUpdateClientRequest,
  PostUpdateClientSuccess,
  PostUpdateClientFailure,
  PostUpdateClientFailurePayload,
  FetchEmailTemplateRequest,
  FetchEmailTemplateSuccess,
  FetchEmailTemplateFailure,
  FetchEmailTemplateSuccessPayload,
  FetchEmailTemplateFailurePayload,
  FetchCampaignsRequest,
  FetchCampaignsSuccess,
  FetchCampaignsSuccessPayload,
  FetchCampaignsFailure,
  FetchCampaignsFailurePayload,
  fetchIncentiveRequest,
  fetchIncentiveSuccess,
  fetchIncentiveSuccessPayload,
  fetchIncentiveFailure,
  fetchIncentiveFailurePayload,
  FetchSystemMessagesRequest,
  FetchSystemMessagesSuccess,
  FetchSystemMessagesFailure,
  FetchSystemMessagesSuccessPayload,
  FetchSystemMessagesFailurePayload,
  FetchReportingRequest,
  FetchReportingSuccess,
  FetchReportingSuccessPayload,
  FetchReportingFailure,
  FetchReportingFailurePayload,
  DeletePackageRequest,
  DeletePackageSuccessPayload,
  DeletePackageFailurePayload,
  DeletePackageSuccess,
  DeletePackageFailure,
  DeleteClientDetailsRequest,
  DeleteClientDetailsSuccessPayload,
  DeleteClientDetailsSuccess,
  DeleteClientDetailsFailurePayload,
  DeleteClientDetailsFailure,
  FetchAppSettingsFailure,
  FetchAppSettingsFailurePayload,
  FetchAppSettingsRequest,
  FetchAppSettingsSuccess,
  FetchAppSettingsSuccessPayload,
  FetchExternalProviderPlansFailure,
  FetchExternalProviderPlansFailurePayload,
  FetchExternalProviderPlansRequest,
  FetchExternalProviderPlansSuccess,
  FetchExternalProviderPlansSuccessPayload,
  FetchSubscriptionsListFailurePayload,
  FetchSubscriptionsListSuccessPayload,
  PostSubscriptionsFailure,
  PostSubscriptionsFailurePayload,
  PostSubscriptionsRequest,
  PostSubscriptionsSuccess,
  PostSubscriptionsSuccessPayload,
  RemoveSubscriptionsFailure,
  RemoveSubscriptionsFailurePayload,
  RemoveSubscriptionsRequest,
  RemoveSubscriptionsSuccess,
  RemoveSubscriptionsSuccessPayload,
  UpdateSubscriptionsFailure,
  UpdateSubscriptionsFailurePayload,
  UpdateSubscriptionsRequest,
  UpdateSubscriptionsSuccess,
  UpdateSubscriptionsSuccessPayload,
  GetDefaultSubscriptionsRequest,
  GetDefaultSubscriptionsSuccess,
  GetDefaultSubscriptionsFailure,
} from './types';

export const fetchClientsRequest = (payload: any): FetchClientsRequest => ({
  type: FETCH_CLIENTS_REQUEST,
  payload,
});

export const fetchClientsSuccess = (payload: FetchClientsSuccessPayload): FetchClientsSuccess => ({
  type: FETCH_CLIENTS_SUCCESS,
  payload,
});

export const fetchClientsFailure = (payload: FetchClientsFailurePayload): FetchClientsFailure => ({
  type: FETCH_CLIENTS_FAILURE,
  payload,
});

export const fetchClientCountRequest = (payload: any): FetchClientCountRequest => ({
  type: FETCH_CLIENT_COUNT_REQUEST,
  payload,
});

export const fetchClientCountSuccess = (
  payload: FetchClientCountSuccessPayload
): FetchClientCountSuccess => ({
  type: FETCH_CLIENT_COUNT_SUCCESS,
  payload,
});

export const fetchClientCountFailure = (
  payload: FetchClientCountFailurePayload
): FetchClientCountFailure => ({
  type: FETCH_CLIENT_COUNT_FAILURE,
  payload,
});

export const fetchTagsRequest = (payload: any): FetchTagsRequest => ({
  type: FETCH_TAGS_REQUEST,
  payload,
});

export const fetchTagsSuccess = (payload: FetchTagsSuccessPayload): FetchTagsSuccess => ({
  type: FETCH_TAGS_SUCCESS,
  payload,
});

export const fetchTagsFailure = (payload: FetchTagsFailurePayload): FetchTagsFailure => ({
  type: FETCH_TAGS_FAILURE,
  payload,
});

export const postAvailableCreditRequest = (payload: any): PostAvailableCreditRequest => ({
  type: POST_AVAILABLE_CREDIT_REQUEST,
  payload,
});

export const postAvailableCreditSuccess = (
  payload: PostAvailableCreditSuccessPayload
): PostAvailableCreditSuccess => ({
  type: POST_AVAILABLE_CREDIT_SUCCESS,
  payload,
});

export const postAvailableCreditFailure = (
  payload: PostAvailableCreditFailurePayload
): PostAvailableCreditFailure => ({
  type: POST_AVAILABLE_CREDIT_FAILURE,
  payload,
});

//admin Details
export const getAdminDetailsRequest = (payload: any): GetAdminDetailsRequest => ({
  type: GET_ADMIN_DETAILS_REQUEST,
  payload,
});

export const getAdminDetailsSuccess = (
  payload: GetAdminDetailsSuccessPayload
): GetAdminDetailsSuccess => ({
  type: GET_ADMIN_DETAILS_SUCCESS,
  payload,
});

export const getAdminDetailsFailure = (
  payload: GetAdminDetailsFailurePayload
): GetAdminDetailsFailure => ({
  type: GET_ADMIN_DETAILS_FAILURE,
  payload,
});

export const getClientDetailsRequest = (payload: any): GetClientDetailsRequest => ({
  type: GET_CLIENT_DETAILS_REQUEST,
  payload,
});

export const getClientDetailsSuccess = (
  payload: GetClientDetailsSuccessPayload
): GetClientDetailsSuccess => ({
  type: GET_CLIENT_DETAILS_SUCCESS,
  payload,
});

export const getClientDetailsFailure = (
  payload: GetClientDetailsFailurePayload
): GetClientDetailsFailure => ({
  type: GET_CLIENT_DETAILS_FAILURE,
  payload,
});

export const deleteClientDetailsRequest = (payload: any): DeleteClientDetailsRequest => ({
  type: DELETE_CLIENT_DETAILS_REQUEST,
  payload,
});

export const deleteClientDetailsSuccess = (
  payload: DeleteClientDetailsSuccessPayload
): DeleteClientDetailsSuccess => ({
  type: DELETE_CLIENT_DETAILS_SUCCESS,
  payload,
});

export const deleteClientDetailsFailure = (
  payload: DeleteClientDetailsFailurePayload
): DeleteClientDetailsFailure => ({
  type: DELETE_CLIENT_DETAILS_FAILURE,
  payload,
});

export const postCreatePackageRequest = (payload: any): PostCreatePackageRequest => ({
  type: POST_CREATE_PACKAGE_REQUEST,
  payload,
});

export const postCreatePackageSuccess = (
  payload: PostCreatePackageSuccessPayload
): PostCreatePackageSuccess => ({
  type: POST_CREATE_PACKAGE_SUCCESS,
  payload,
});

export const postCreatePackageFailure = (
  payload: PostCreatePackageFailurePayload
): PostCreatePackageFailure => ({
  type: POST_CREATE_PACKAGE_FAILURE,
  payload,
});

export const postUpdatePackageRequest = (payload: any): PostUpdatePackageRequest => ({
  type: POST_UPDATE_PACKAGE_REQUEST,
  payload,
});

export const postUpdatePackageSuccess = (
  payload: PostUpdatePackageSuccessPayload
): PostUpdatePackageSuccess => ({
  type: POST_UPDATE_PACKAGE_SUCCESS,
  payload,
});

export const postUpdatePackageFailure = (
  payload: PostUpdatePackageFailurePayload
): PostUpdatePackageFailure => ({
  type: POST_UPDATE_PACKAGE_FAILURE,
  payload,
});

export const postUpdatePackageProductRequest = (payload: any): PostUpdatePackageProductRequest => ({
  type: POST_UPDATE_PACKAGE_PRODUCT_REQUEST,
  payload,
});

export const postUpdatePackageProductSuccess = (
  payload: PostUpdatePackageProductSuccessPayload
): PostUpdatePackageProductSuccess => ({
  type: POST_UPDATE_PACKAGE_PRODUCT_SUCCESS,
  payload,
});

export const postUpdatePackageProductFailure = (
  payload: PostUpdatePackageProductFailurePayload
): PostUpdatePackageProductFailure => ({
  type: POST_UPDATE_PACKAGE_PRODUCT_FAILURE,
  payload,
});

export const postCreatePackageProductRequest = (payload: any): PostCreatePackageProductRequest => ({
  type: POST_CREATE_PACKAGE_PRODUCT_REQUEST,
  payload,
});

export const postCreatePackageProductSuccess = (
  payload: PostCreatePackageProductSuccessPayload
): PostCreatePackageProductSuccess => ({
  type: POST_CREATE_PACKAGE_PRODUCT_SUCCESS,
  payload,
});

export const postCreatePackageProductFailure = (
  payload: PostCreatePackageProductFailurePayload
): PostCreatePackageProductFailure => ({
  type: POST_CREATE_PACKAGE_PRODUCT_FAILURE,
  payload,
});

export const postAddCommsRequest = (payload: any): PostAddCommsRequest => ({
  type: POST_ADD_COMMS_REQUEST,
  payload,
});

export const postAddCommsSuccess = (payload: PostAddCommsSuccessPayload): PostAddCommsSuccess => ({
  type: POST_ADD_COMMS_SUCCESS,
  payload,
});

export const postAddCommsFailure = (payload: PostAddCommsFailurePayload): PostAddCommsFailure => ({
  type: POST_ADD_COMMS_FAILURE,
  payload,
});

export const fetchWebAppUrlSettingRequest = (payload: any): FetchWebAppUrlSettingRequest => ({
  type: FETCH_WEB_APP_URL_SETTING_REQUEST,
  payload,
});

export const fetchWebAppUrlSettingSuccess = (
  payload: FetchWebAppUrlSettingSuccessPayload
): FetchWebAppUrlSettingSuccess => ({
  type: FETCH_WEB_APP_URL_SETTING_SUCCESS,
  payload,
});

export const fetchWebAppUrlSettingFailure = (
  payload: FetchWebAppUrlSettingFailurePayload
): FetchWebAppUrlSettingFailure => ({
  type: FETCH_WEB_APP_URL_SETTING_FAILURE,
  payload,
});

export const postForceCheckInRequest = (payload: any): PostForceCheckInRequest => ({
  type: POST_FORCE_CHECK_IN_REQUEST,
  payload,
});

export const postForceCheckInSuccess = (payload: any): PostForceCheckInSuccess => ({
  type: POST_FORCE_CHECK_IN_SUCCESS,
  payload,
});

export const postForceCheckInFailure = (
  payload: PostForceCheckInFailurePayload
): PostForceCheckInFailure => ({
  type: POST_FORCE_CHECK_IN_FAILURE,
  payload,
});

export const fetchCampaignRequest = (): FetchCampaignRequest => ({
  type: FETCH_CAMPAIGN_REQUEST,
});

export const fetchCampaignSuccess = (
  payload: FetchCampaignSuccessPayload
): FetchCampaignSuccess => ({
  type: FETCH_CAMPAIGN_SUCCESS,
  payload,
});

export const fetchCampaignFailure = (
  payload: FetchCampaignFailurePayload
): FetchCampaignFailure => ({
  type: FETCH_CAMPAIGN_FAILURE,
  payload,
});

export const getProductTemplateRequest = (payload: any): GetProductTemplateRequest => ({
  type: FETCH_PRODUCT_TEMPLATE_REQUEST,
  payload,
});

export const getProductTemplateSuccess = (
  payload: GetProductTemplateSuccessPayload
): GetProductTemplateSuccess => ({
  type: FETCH_PRODUCT_TEMPLATE_SUCCESS,
  payload,
});

export const getProductTemplateFailure = (
  payload: GetProductTemplateFailurePayload
): GetProductTemplateFailure => ({
  type: FETCH_PRODUCT_TEMPLATE_FAILURE,
  payload,
});

export const RemoveCommRequest = (payload: any): RemovedCommsRequest => ({
  type: REMOVED_COMMS_REQUEST,
  payload,
});

export const removedCommsSuccess = (payload: RemovedCommsSuccessPayload): RemovedCommsSuccess => ({
  type: REMOVED_COMMS_SUCCESS,
  payload,
});

export const removedCommsFailure = (payload: RemovedCommsFailurePayload): RemovedCommsFailure => ({
  type: REMOVED_COMMS_FAILURE,
  payload,
});

export const postCreateClientRequest = (payload: any): PostCreateClientRequest => ({
  type: POST_CREATE_CLIENT_REQUEST,
  payload,
});

export const postCreateClientSuccess = (payload: any): PostCreateClientSuccess => ({
  type: POST_CREATE_CLIENT_SUCCESS,
  payload,
});

export const postCreateClientFailure = (
  payload: PostCreateClientFailurePayload
): PostCreateClientFailure => ({
  type: POST_CREATE_CLIENT_FAILURE,
  payload,
});

export const postUpdateClientRequest = (payload: any): PostUpdateClientRequest => ({
  type: POST_UPDATE_CLIENT_REQUEST,
  payload,
});

export const postUpdateClientSuccess = (payload: any): PostUpdateClientSuccess => ({
  type: POST_UPDATE_CLIENT_SUCCESS,
  payload,
});

export const postUpdateClientFailure = (
  payload: PostUpdateClientFailurePayload
): PostUpdateClientFailure => ({
  type: POST_UPDATE_CLIENT_FAILURE,
  payload,
});

export const fetchEmailTemplateRequest = (payload: any): FetchEmailTemplateRequest => ({
  type: FETCH_EMAIL_TEMPLATE_REQUEST,
  payload,
});

export const fetchEmailTemplateSuccess = (
  payload: FetchEmailTemplateSuccessPayload
): FetchEmailTemplateSuccess => ({
  type: FETCH_EMAIL_TEMPLATE_SUCCESS,
  payload,
});

export const fetchEmailTemplateFailure = (
  payload: FetchEmailTemplateFailurePayload
): FetchEmailTemplateFailure => ({
  type: FETCH_EMAIL_TEMPLATE_FAILURE,
  payload,
});

export const fetchCampaignsRequest = (payload: any): FetchCampaignsRequest => ({
  type: FETCH_CAMPAIGNS_REQUEST,
  payload,
});

export const fetchCampaignsSuccess = (
  payload: FetchCampaignsSuccessPayload
): FetchCampaignsSuccess => ({
  type: FETCH_CAMPAIGNS_SUCCESS,
  payload,
});

export const fetchCampaignsFailure = (
  payload: FetchCampaignsFailurePayload
): FetchCampaignsFailure => ({
  type: FETCH_CAMPAIGNS_FAILURE,
  payload,
});

export const FetchIncentiveRequest = (payload: any): fetchIncentiveRequest => ({
  type: FETCH_INCENTIVES_REQUEST,
  payload,
});

export const FetchIncentiveSuccess = (
  payload: fetchIncentiveSuccessPayload
): fetchIncentiveSuccess => ({
  type: FETCH_INCENTIVES_SUCCESS,
  payload,
});

export const FetchIncentiveFailure = (
  payload: fetchIncentiveFailurePayload
): fetchIncentiveFailure => ({
  type: FETCH_INCENTIVES_FAILURE,
  payload,
});

export const fetchSystemMessagesRequest = (payload: any): FetchSystemMessagesRequest => ({
  type: FETCH_SYSTEM_MESSAGES_REQUEST,
  payload,
});

export const fetchSystemMessagesSuccess = (
  payload: FetchSystemMessagesSuccessPayload
): FetchSystemMessagesSuccess => ({
  type: FETCH_SYSTEM_MESSAGES_SUCCESS,
  payload,
});

export const fetchSystemMessagesFailure = (
  payload: FetchSystemMessagesFailurePayload
): FetchSystemMessagesFailure => ({
  type: FETCH_SYSTEM_MESSAGES_FAILURE,
  payload,
});

export const fetchReportingRequest = (payload: any): FetchReportingRequest => ({
  type: FETCH_REPORTING_REQUEST,
  payload,
});

export const fetchReportingSuccess = (
  payload: FetchReportingSuccessPayload
): FetchReportingSuccess => ({
  type: FETCH_REPORTING_SUCCESS,
  payload,
});

export const fetchReportingFailure = (
  payload: FetchReportingFailurePayload
): FetchReportingFailure => ({
  type: FETCH_REPORTING_FAILURE,
  payload,
});

export const deletePackageRequest = (payload: any): DeletePackageRequest => ({
  type: DELETE_PACKAGE_REQUEST,
  payload,
});

export const deletePackageSuccess = (
  payload: DeletePackageSuccessPayload
): DeletePackageSuccess => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload,
});

export const deletePackageFailure = (
  payload: DeletePackageFailurePayload
): DeletePackageFailure => ({
  type: DELETE_PACKAGE_FAILURE,
  payload,
});

export const fetchAppSettingsRequest = (payload: any): FetchAppSettingsRequest => ({
  type: FETCH_APP_SETTINGS_REQUEST,
  payload,
});

export const fetchAppSettingsSuccess = (
  payload: FetchAppSettingsSuccessPayload
): FetchAppSettingsSuccess => ({
  type: FETCH_APP_SETTINGS_SUCCESS,
  payload,
});

export const fetchAppSettingsFailure = (
  payload: FetchAppSettingsFailurePayload
): FetchAppSettingsFailure => ({
  type: FETCH_APP_SETTINGS_FAILURE,
  payload,
});

export const fetchExternalProviderPlansRequest = (): FetchExternalProviderPlansRequest => ({
  type: FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST,
});

export const fetchExternalProviderPlansSuccess = (
  payload: FetchExternalProviderPlansSuccessPayload
): FetchExternalProviderPlansSuccess => ({
  type: FETCH_EXTERNAL_PROVIDER_PLANS_SUCCESS,
  payload,
});

export const fetchExternalProviderPlansFailure = (
  payload: FetchExternalProviderPlansFailurePayload
): FetchExternalProviderPlansFailure => ({
  type: FETCH_EXTERNAL_PROVIDER_PLANS_FAILURE,
  payload,
});

export const postSubscriptionsRequest = (payload: any): PostSubscriptionsRequest => ({
  type: POST_SUBSCRIPTIONS_REQUEST,
  payload,
});

export const postSubscriptionsSuccess = (
  payload: PostSubscriptionsSuccessPayload
): PostSubscriptionsSuccess => ({
  type: POST_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const postSubscriptionsFailure = (
  payload: PostSubscriptionsFailurePayload
): PostSubscriptionsFailure => ({
  type: POST_SUBSCRIPTIONS_FAILURE,
  payload,
});

export const updateSubscriptionsRequest = (payload: any): UpdateSubscriptionsRequest => ({
  type: UPDATE_SUBSCRIPTIONS_REQUEST,
  payload,
});

export const updateSubscriptionsSuccess = (
  payload: UpdateSubscriptionsSuccessPayload
): UpdateSubscriptionsSuccess => ({
  type: UPDATE_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const updateSubscriptionsFailure = (
  payload: UpdateSubscriptionsFailurePayload
): UpdateSubscriptionsFailure => ({
  type: UPDATE_SUBSCRIPTIONS_FAILURE,
  payload,
});

export const removeSubscriptionsRequest = (payload: any): RemoveSubscriptionsRequest => ({
  type: REMOVE_SUBSCRIPTIONS_REQUEST,
  payload,
});

export const removeSubscriptionsSuccess = (
  payload: RemoveSubscriptionsSuccessPayload
): RemoveSubscriptionsSuccess => ({
  type: REMOVE_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const removeSubscriptionsFailure = (
  payload: RemoveSubscriptionsFailurePayload
): RemoveSubscriptionsFailure => ({
  type: REMOVE_SUBSCRIPTIONS_FAILURE,
  payload,
});

export const getDefaultSubscriptionsRequest = (payload: any): GetDefaultSubscriptionsRequest => ({
  type: GET_DEFAULT_SUBSCRIPTIONS_REQUEST,
  payload,
});

export const getDefaultSubscriptionsSuccess = (payload: any): GetDefaultSubscriptionsSuccess => ({
  type: GET_DEFAULT_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const getDefaultSubscriptionsFailure = (payload: any): GetDefaultSubscriptionsFailure => ({
  type: GET_DEFAULT_SUBSCRIPTIONS_FAILURE,
  payload,
});
