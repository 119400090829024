import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import {
  fetchUserListRequest,
  postBlackListRequest,
  updateUserRequest,
} from './redux-tags/actions';
import { CustomInput, ToggleButton } from '../../components/common';
import {
  defaultModal,
  emailMapping,
  Headeroptions,
  userManagementFilter,
  userStatusOptions,
  UserTypes,
} from './user-safety-constants';
import SwitchBar from '../../components/switch-bar';
import { fetchAppSettingsRequest } from '../clients/clients-redux/actions';
import Model from '../../components/common/model';
import { convertObjectToParams } from '../../components/common/create-params';
import AlertBox from '../../components/route-leaving-prompt/alert-box';

type props = {
  tagsList: any;
  pending: any;
  TagsCount: any;
  appSettings: any;
};

type FilterProps = {
  addFunctionality: () => void;
  fetchData: (data: any) => void;
  count: number | undefined;
};

export type CardProps = {
  photo: any;
  displayName: string;
  firstName: string;
  lastname: string;
  peerType: string;
  userEvents: any;
  email: string;
  phoneNumber: string;
  userId: number;
  filterType: string;
  appSettings: any;
  listenerDetails: any;
  callerDetails: any;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
    status: UserTypes['Under Review'],
    include_ratings: true,
    include_topics: true,
    include_call_summary: true,
    include_user_safety: true,
    include_requests: true,
  });

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/3"></div>
      <div className="w-1/4 "></div>
      <div className="w-2/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(UserTypes).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (UserTypes[item] === filters.status
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  //@ts-ignore
                  setfilters((filter) => {
                    //@ts-ignore
                    return { ...filter, status: UserTypes[item], page: 1 };
                  });
                  //@ts-ignore
                  fetchData({ ...filters, status: UserTypes[item], page: 1 });
                }}
              >
                {/* @ts-ignore */}
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Card: React.FunctionComponent<CardProps> = ({
  photo,
  firstName,
  lastname,
  peerType,
  userEvents,
  email,
  phoneNumber,
  userId,
  filterType,
  appSettings,
  listenerDetails,
  callerDetails,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [modal, setModalState] = useState<any>(defaultModal);

  const handleBlackList = (type: string) => {
    dispatch(
      postBlackListRequest({
        payload: {
          entity_type: type,
          value: type === 'email_address' ? email : phoneNumber,
        },
        filters: { ...userManagementFilter, status: filterType },
        from: 'users',
      })
    );
  };

  const handleStatus = (status: 'banned' | 'enabled' | 'suspended' | 'under_review') => {
    setModalState({
      email: appSettings[emailMapping[status].body],
      subject: appSettings[emailMapping[status].subject],
      toggle: true,
      status: status,
      enable: true,
    });
  };

  const handleSave = () => {
    dispatch(
      updateUserRequest({
        id: userId,
        payload: {
          status: modal.status,
          message: modal.toggle ? modal.email : undefined,
          subject: modal.toggle ? modal.subject : undefined,
        },
        filterType,
      })
    );
    setModalState(defaultModal);
  };

  const handlePopUp = (key: string, value: boolean | string) => {
    setModalState((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <>
      <Model
        isModel={modal.enable}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className=" w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="w-full  my-2">
                    <CustomInput
                      inputType={'text'}
                      value={modal?.subject}
                      setValue={(data: string) => {
                        handlePopUp('subject', data);
                      }}
                      Label={'Subject'}
                      labelClass={'block text-sm leading-4   text-blue-dark mb-2'}
                    />
                  </div>

                  <h3 className="block text-sm leading-4   text-blue-dark mb-2" id="modal-title">
                    Body
                  </h3>

                  <div className="w-full  my-2">
                    <textarea
                      className="py-2 px-3 w-full rounded-md  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                      value={modal.email}
                      onChange={(evt) => {
                        handlePopUp('email', evt.target.value);
                      }}
                    />
                  </div>

                  <div className="w-1/2 ">
                    <div className="flex justify-between items-center">
                      <label className="block text-sm leading-4   text-blue-dark mb-2">
                        {' '}
                        Send Message{' '}
                      </label>

                      <ToggleButton
                        id={'Send Message'}
                        status={modal?.toggle}
                        setStatus={(data) => {
                          handlePopUp('toggle', data);
                        }}
                        initialstatus={true}
                      ></ToggleButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleSave}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                SAVE
              </button>

              <button
                type="button"
                onClick={() => {
                  handlePopUp('enable', false);
                }}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                CLOSE
              </button>
            </div>
          </div>
        }
      />
      <div className="h-auto border-b-2  border-blue-primary rounded-sm px-3 py-3 my-3">
        <div className={'w-full h-48 bg-white rounded flex  '}>
          {photo && (
            <div className=" flex items-center justify-center px-2">
              <div
                className={`rounded-full h-28 w-28 bg-gray-100 border-4  transform rotate-45 ${
                  peerType === 'peer'
                    ? `border-gray-dark  `
                    : peerType === 'upgrade'
                    ? `upgrade-border `
                    : `  border-blue-primary `
                }`}
              >
                <img
                  className="h-full w-full transform -rotate-45 "
                  src={photo}
                  alt="listenerProfile"
                />
                <div></div>
              </div>
            </div>
          )}

          <div className="w-full py-4 pl-4">
            <p className="text-2xl font-normal blue-primary  py-1 pt-5">{`${firstName} ${lastname}`}</p>
            <p className="text-xl font-normal text-gray-dark  py-1">{`${email}`}</p>
            <p className="text-xl font-normal text-gray-dark   py-1 mb-1">{`${phoneNumber}`}</p>
            <div className="flex space-x-3">
              {' '}
              {listenerDetails && (
                <Link
                  className="p-2 bg-blue-primary rounded-md font-bold text-white mx-2 cursor-pointer mt-2"
                  to={`${ROUTE_PATH.LISTENERS_PROFILE}${convertObjectToParams(listenerDetails)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Listener Profile
                </Link>
              )}
              {callerDetails && (
                <Link
                  className="p-2 bg-blue-primary rounded-md font-bold text-white mx-2 cursor-pointer mt-2"
                  to={`${ROUTE_PATH.MEMBERS_PROFILE}${convertObjectToParams(callerDetails)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Caller Profile
                </Link>
              )}
            </div>
          </div>
          <div className="h-44 border-2 border-gray-300 w-full mt-4 px-3">
            <p className="text-xl font-normal blue-primary  py-1">Report</p>
            <p className="text-gray-dark">{userEvents?.length > 0 ? userEvents[0].message : ''}</p>
            <p className="text-gray-dark pt-4">
              This user has been reported {userEvents?.length > 0 ? userEvents.length : 0} time(s)
            </p>
          </div>
        </div>
        <div className="flex mt-3 space-x-3">
          <div className="h-auto border-2  w-1/2 px-3 py-3 pb-5">
            <p className="text-xl font-normal blue-primary  py-2 ml-3">Black List</p>
            <button
              className="p-2 bg-blue-primary rounded-md font-bold text-white mx-2"
              onClick={() => {
                handleBlackList('email_address');
              }}
            >
              Email Address
            </button>
            <button
              className="p-2 bg-blue-primary rounded-md font-bold text-white"
              onClick={() => {
                handleBlackList('mobile_phone');
              }}
            >
              Phone Number
            </button>
          </div>
          <div className="h-auto border-2 border-gray-300 w-1/2 px-3 py-3 pb-5">
            <p className="text-xl font-normal blue-primary  pt-2 ml-3">Actions</p>
            {userStatusOptions.map((option: any) => (
              <button
                className="p-2 bg-blue-primary rounded-md font-bold text-white mx-2 my-2"
                onClick={() => {
                  handleStatus(option.action);
                }}
              >
                {option.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const UserManagement: React.FunctionComponent<props> = (props): JSX.Element => {
  const [filterType, setfilterType] = useState(UserTypes['Under Review']);

  const dispatch = useDispatch();

  const fetchData = (filter: any) => {
    const { page, limit, ...rest } = filter;
    setfilterType(filter.status);
    dispatch(fetchUserListRequest(filter));
  };

  useEffect(() => {
    dispatch(fetchAppSettingsRequest({ type: 'email' }));
    dispatch(fetchUserListRequest(userManagementFilter));
  }, []);

  return (
    <div>
      <Header heading={'Safety'} />
      <SwitchBar heading={Headeroptions} position={0} />

      <Filters count={props.TagsCount?.count} fetchData={fetchData} addFunctionality={() => {}} />

      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-7">
          {props.pending && <Spinner />}

          <div className={'w-full  bg-white rounded  px-7 '}>
            {props.tagsList?.length > 0 ? (
              props.tagsList?.map((user: any) => (
                <Card
                  photo={user?.listener_role?.profile_photo_file_url}
                  displayName={''}
                  firstName={user?.first_name}
                  lastname={user?.last_name}
                  peerType={user?.listener_role?.can_take_calls}
                  userEvents={user?.user_events}
                  phoneNumber={user?.mobile_phone}
                  email={user?.email_address}
                  userId={user?.id}
                  filterType={filterType}
                  appSettings={props.appSettings?.data?.message}
                  listenerDetails={
                    user?.listener_role
                      ? {
                          listenerRoleId: user.listener_role.id,
                          listenerId: user.id,
                          listenerType: user.can_take_calls ? 'listener' : 'peer',
                          listenerName: user.first_name,
                        }
                      : null
                  }
                  callerDetails={
                    user?.caller_role_id
                      ? {
                          name: user.first_name,
                          id: user.id,
                          callerRoleId: user.caller_role_id,
                        }
                      : null
                  }
                />
              ))
            ) : (
              <p className="text-xl font-normal blue-primary  py-2 ml-3">No Users Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tagsList: state.safety.UserList?.data || null,
    appSettings: state.Client.AppSettings?.data || null,
    pending: state.safety?.pending || state.Client?.pending || null,
  };
};

export default connect(mapStateToProps)(UserManagement);
