import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CustomInput, DropDown, ToggleButton } from '../../../components/common';
import {
  fetchExternalProviderPlansRequest,
  postUpdatePackageRequest,
} from '../clients-redux/actions';
import useOutsideAlerter from '../../../components/common/outside-check';
import RichTextEditor from '../../../components/common/rich-text-editor';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
//@ts-ignore
import htmlToDraft from 'html-to-draftjs';

type props = {
  data: any;
  webAppUrl: any;
  UpdateSaveOption: any;
  providerOptions: any;
};

const PackageDetails: React.FunctionComponent<props> = (props): JSX.Element => {
  const [packageName, setpackageName] = useState('');
  const [packageCode, setpackageCode] = useState('');
  const [internalNotes, setinternalNotes] = useState<any>();
  const [webAppUrl, setwebAppUrl] = useState<string>('');
  const [saveWebAppUrl, setsaveWebAppUrl] = useState<any>('');
  const [packageId, setpackageId] = useState();
  const [webappDropDown, setwebappDropDown] = useState(false);
  const [dropDownoption, setdropDownoption] = useState('');
  const [serviceProvider, setServiceProvider] = useState(null);
  const [costPerMinuteCents, setCostPerMinuteCents] = useState(0);
  const clientId = localStorage.getItem('clientid');

  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [error, seterror] = useState(false);
  const [webappTheme, setWebappTheme] = useState<string | null>(null);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setwebappDropDown);

  const isURL = (str: string) => {
    var urlRegex =
      '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str?.length < 2083 && url.test(str);
  };

  const Validation = () => {
    if (
      !packageName ||
      !packageCode ||
      (saveWebAppUrl !== null &&
        saveWebAppUrl?.trim() !== '' &&
        saveWebAppUrl &&
        !isURL(saveWebAppUrl?.trim()))
    ) {
      seterror(true);
      return false;
    }
    seterror(false);
    return true;
  };

  const handleSave = () => {
    const linkInput = {
      clientId,
      packageId,
    };

    const payload = {
      benefits_description: internalNotes
        ? draftToHtml(convertToRaw(internalNotes.getCurrentContent()))
        : null,
      code: packageCode?.trim(),
      cost_per_minute_cents: +costPerMinuteCents,
      name: packageName?.trim(),
      external_plan_id: serviceProvider === 'none' ? null : serviceProvider,
      web_app_url:
        saveWebAppUrl === null || saveWebAppUrl?.trim() === '' || !saveWebAppUrl
          ? null
          : saveWebAppUrl?.trim(),
      theme_name: webappTheme,
    };
    if (clientId && Validation()) {
      dispatch(postUpdatePackageRequest({ payload, linkInput }));
    }
  };

  const setWebAppUrl = (data: string) => {
    if (data === 'https://listeners.app.link') {
      return 'Native App Only';
    }
    if (data === props.webAppUrl?.LOC_WEB_APP_URL || data === null || data === '') {
      return `Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`;
    }
    return data;
  };

  useEffect(() => {
    if (props.data && props.webAppUrl) {
      const data = props.data;
      setpackageName(data.name);
      setpackageCode(data.code);
      setwebAppUrl(setWebAppUrl(data.web_app_url));
      setsaveWebAppUrl(data.web_app_url);
      setpackageId(data.id);
      setenableSaveOption(false);
      setWebappTheme(data?.theme_name);
      setServiceProvider(data.external_plan_id === null ? 'none' : data.external_plan_id);
      setCostPerMinuteCents(data.cost_per_minute_cents);
      const contentBlock = htmlToDraft(data?.benefits_description || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setinternalNotes(editorState);
      }
    }
  }, [props.data, props.webAppUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="h-10  w-full bg-transparent px-2 flex justify-between items-center border-b text-gray-dark gray-border-line text-sm font-bold mb-2">
        <div>PLAN CONFIGURATION</div>
        <div>
          <button
            className={
              ' rounded-full w-full text-center h-6 text-sm text-white px-2 ' +
              (enableSaveOption
                ? ' stacked-bar-blue-400  '
                : '  bg-gray-background-light cursor-not-allowed ')
            }
            onClick={handleSave}
          >
            SAVE
          </button>
        </div>
      </div>
      <div className="flex justify-evenly gap-7 ">
        <div className="py-4  w-full">
          <CustomInput
            inputType={'text'}
            value={packageName}
            setValue={(data) => {
              setenableSaveOption(true);
              setpackageName(data);
            }}
            Label={'Plan Name'}
            Error={error && !packageName}
            ErrorMessage={'Field is Required'}
          />
        </div>
        <div className="py-4  w-full">
          <CustomInput
            inputType={'text'}
            value={packageCode}
            setValue={(data) => {
              setenableSaveOption(true);
              setpackageCode(data);
            }}
            Label={'Plan Code'}
            Error={error && !packageCode}
            ErrorMessage={'Field is Required'}
          />
        </div>
      </div>
      <div className="flex justify-evenly gap-7">
        <div className="py-4  w-full">
          <label className="block leading-4 text-gray-dark mb-2">Benefits Description</label>
          <RichTextEditor
            RichText={internalNotes}
            setRichText={(data) => {
              setinternalNotes(data);
              setenableSaveOption(true);
            }}
          />
          {/* <textarea
                        value={internalNotes}
                        className="py-2 px-3  rounded-md  h-28 w-full text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                        onChange={(e) => {
                            setenableSaveOption(true);
                            setinternalNotes(e.target.value);
                        }}
                    /> */}
        </div>
        <div className="py-4  w-full">
          <div className=" relative ">
            <label className="block leading-4 text-gray-dark mb-2">Web App URL</label>
            <input
              name="browser"
              type="url"
              onFocus={() => {
                setwebappDropDown(true);
              }}
              value={webAppUrl}
              onChange={(e) => {
                setenableSaveOption(true);
                setwebAppUrl(e.target.value);
              }}
              onKeyPress={(event) => {
                if (
                  event.key === 'Enter' &&
                  webAppUrl !== `Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})` &&
                  webAppUrl !== 'Native App Only'
                ) {
                  setdropDownoption(webAppUrl);
                  setsaveWebAppUrl(webAppUrl);
                  setwebappDropDown(true);
                }
              }}
              id="browser"
              className="w-full py-2 px-3  h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
            />
            {error &&
              saveWebAppUrl !== null &&
              saveWebAppUrl?.trim() !== '' &&
              saveWebAppUrl &&
              !isURL(saveWebAppUrl?.trim()) &&
              !webappDropDown && <p className="text-red -z-10 ">Please enter valid URL</p>}

            {webappDropDown && (
              <div
                className="z-10 overflow-visible absolute h-auto w-full text-white opacity-90 bg-gray-dark rounded-md py-2"
                ref={wrapperRef}
              >
                <div
                  className="  w-full h-8 z-10 px-2 py-2 rounded-md hover:bg-blue-primary flex items-center"
                  role="button"
                  onClick={() => {
                    setwebAppUrl(`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`);
                    setenableSaveOption(true);
                    setdropDownoption('');
                    setsaveWebAppUrl(null);
                    setwebappDropDown(false);
                  }}
                >
                  {' '}
                  <p>{`Use Global Setting (${props.webAppUrl?.LOC_WEB_APP_URL || ''})`}</p>
                </div>
                <div
                  className=" w-full h-8 z-10 p-2 hover:bg-blue-primary rounded-md flex items-center"
                  role="button"
                  onClick={() => {
                    setenableSaveOption(true);
                    setwebAppUrl('Native App Only');
                    setsaveWebAppUrl('https://listeners.app.link');
                    setdropDownoption('');
                    setwebappDropDown(false);
                  }}
                >
                  <p>Native App Only</p>
                </div>
                {dropDownoption?.length > 0 && (
                  <div
                    className=" w-full h-8 z-10 p-2 hover:bg-blue-primary flex items-center rounded-md "
                    role="button"
                    onClick={() => {
                      setenableSaveOption(true);
                      setwebAppUrl(dropDownoption);
                      setsaveWebAppUrl(dropDownoption);
                      setwebappDropDown(false);
                    }}
                  >
                    <p>{dropDownoption}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={'mt-5'}>
            <DropDown
              Label={'Web App Theme'}
              value={webappTheme !== null ? webappTheme : ''}
              setValue={(data) => {
                setWebappTheme(data === '' ? null : data);
                setenableSaveOption(true);
              }}
            >
              <option value="">Default Theme</option>
              <option value="Clever">CleverRx</option>
            </DropDown>
          </div>
          <div className={'mt-5'}>
            <DropDown
              Label={'Professional Services Provider'}
              value={serviceProvider}
              setValue={(data) => {
                setenableSaveOption(true);
                setServiceProvider(data);
              }}
            >
              <option value={'none'}>None</option>
              {props.providerOptions?.length > 0 &&
                props.providerOptions?.map((item: any) => (
                  <option value={item.id}>{item.name}</option>
                ))}
            </DropDown>
          </div>
          <div className={'mt-5'}>
            <CustomInput
              Label={'Cost Per Minute (cents)'}
              inputType={'number'}
              value={costPerMinuteCents}
              setValue={(data) => {
                setenableSaveOption(true);
                setCostPerMinuteCents(data);
              }}
              Error={error && !costPerMinuteCents}
              ErrorMessage={'Field is Required'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    providerOptions: state.Client.ExternalProviderPlans?.data || null,
  };
};

export default connect(mapStateToProps)(PackageDetails);
