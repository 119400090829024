import { useHistory } from 'react-router';
import { Spinner } from '../../../components/common';
import { ROUTE_PATH } from '../../../routes/paths';
import Header from '../../../components/common/global-top-bar';
import { connect, useDispatch } from 'react-redux';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useEffect, useState } from 'react';
import RichTextEditor from '../../../components/common/rich-text-editor';
import { Location } from 'history';

//@ts-ignore
import htmlToDraft from 'html-to-draftjs';
import { fetchTosRequest, updateTosRequest } from './text-blocks-redux/actions';
import draftToHtml from 'draftjs-to-html';
import RouteLeavingGuard from '../../../components/route-leaving-prompt';

type props = {
  pending: boolean;
  policy: any;
  aboutTos: any;
  termsOfService: any;
};

const MembersTOS: React.FunctionComponent<props> = ({
  pending,
  policy,
  aboutTos,
  termsOfService,
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setstate] = useState({
    aboutUs: EditorState.createEmpty(),
    privacyPolicy: EditorState.createEmpty(),
    termsOfService: EditorState.createEmpty(),
    aboutUsSave: true,
    privacyPolicySave: true,
    termsOfServiceSave: true,
  });

  const handleMessage = (messageType: string, data: any, fromRedux?: true) => {
    setstate({
      ...state,
      [messageType]: data,
      [messageType + 'Save']: fromRedux ? true : false,
    });
  };

  const handleSave = (messageType: string, data: any) => {
    const payload = {
      type: messageType,
      payload: {
        value: convertHTMLtoText(data),
      },
    };
    dispatch(updateTosRequest(payload));
  };

  const convertTextToHTML = (data: string) => {
    const contentBlock = htmlToDraft(data);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }
  };

  const convertHTMLtoText = (data: any) => {
    return draftToHtml(convertToRaw(data.getCurrentContent()));
  };

  useEffect(() => {
    if (aboutTos?.status === 200) {
      handleMessage('aboutUs', convertTextToHTML(aboutTos?.data?.ABOUT_LOC), true);
    }
  }, [aboutTos]);

  useEffect(() => {
    if (policy?.status === 200) {
      handleMessage('privacyPolicy', convertTextToHTML(policy?.data?.PRIVACY_POLICY), true);
    }
  }, [policy]);

  useEffect(() => {
    if (termsOfService?.status === 200) {
      handleMessage(
        'termsOfService',
        convertTextToHTML(termsOfService?.data?.TERMS_OF_SERVICE),
        true
      );
    }
  }, [termsOfService]);

  useEffect(() => {
    dispatch(fetchTosRequest({ type: 'TERMS_OF_SERVICE' }));
    dispatch(fetchTosRequest({ type: 'ABOUT_LOC' }));
    dispatch(fetchTosRequest({ type: 'PRIVACY_POLICY' }));
  }, []);

  return (
    <div>
      {pending && <Spinner />}
      <RouteLeavingGuard
        when={!state.privacyPolicySave || !state.termsOfServiceSave || !state.aboutUsSave}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: Location) => {
          if (!state.privacyPolicySave || !state.termsOfServiceSave || !state.aboutUsSave) {
            if (location.pathname !== ROUTE_PATH.MEMBERSTOC) {
              return true;
            }
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={
          'You have unsaved changes. Do you want to leave this page and lose your changes?'
        }
        cancelButtonText={'Cancel'}
        confirmButtonText={'Continue and lose changes'}
      />
      <Header heading={'Members'} />

      <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-4  h-10 ">
        <div className="   gray-background-dark h-full">
          <button
            className={' text-sm  px-5 py-2  bg-blue-primary text-white h-full  font-bold'}
            onClick={() => {
              history.push(ROUTE_PATH.MEMBERSTOC);
            }}
          >
            Members
          </button>
          <button
            className={' text-sm  px-5 py-2   '}
            onClick={() => {
              history.push(ROUTE_PATH.CLIENTADMINTOC);
            }}
          >
            Client Administrators
          </button>
          <button
            className={' text-sm  px-5 py-2   '}
            onClick={() => {
              history.push(ROUTE_PATH.LISTENRFAQTOC);
            }}
          >
            Listeners
          </button>
        </div>
      </div>

      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div>
          <div className="mt-4 w-full px-7">
            <div className="flex justify-between items-end py-2 border-b border-gray-500">
              <p className="pt-4 font-bold text-gray-dark">ABOUT US</p>
              <button
                className=" rounded-full  text-center h-6 text-sm text-white px-2 stacked-bar-blue-400 disabled:bg-gray-400"
                onClick={() => {
                  handleSave('ABOUT_LOC', state.aboutUs);
                }}
                disabled={state.aboutUsSave}
              >
                SAVE
              </button>
            </div>
            <div className="mt-4 ">
              <RichTextEditor
                RichText={state.aboutUs}
                setRichText={(data) => {
                  handleMessage('aboutUs', data);
                }}
              />
            </div>
          </div>

          <div className="mt-4 w-full px-7">
            <div className="flex justify-between items-end py-2 border-b border-gray-500">
              <p className="pt-4 font-bold text-gray-dark">PRIVACY POLICY</p>
              <button
                className=" rounded-full  text-center h-6 text-sm text-white px-2 stacked-bar-blue-400 disabled:bg-gray-400"
                onClick={() => {
                  handleSave('PRIVACY_POLICY', state.privacyPolicy);
                }}
                disabled={state.privacyPolicySave}
              >
                SAVE
              </button>
            </div>
            <div className="mt-4 ">
              <RichTextEditor
                RichText={state.privacyPolicy}
                setRichText={(data) => {
                  handleMessage('privacyPolicy', data);
                }}
              />
            </div>
          </div>
          <div className="mt-4 w-full px-7">
            <div className="flex justify-between items-end py-2 border-b border-gray-500">
              <p className="pt-4 font-bold text-gray-dark">TERMS OF SERVICE</p>
              <button
                className=" rounded-full  text-center h-6 text-sm text-white px-2 stacked-bar-blue-400 disabled:bg-gray-300"
                disabled={state.termsOfServiceSave}
                onClick={() => {
                  handleSave('TERMS_OF_SERVICE', state.termsOfService);
                }}
              >
                SAVE
              </button>
            </div>

            <div className="mt-4 ">
              <RichTextEditor
                RichText={state.termsOfService}
                setRichText={(data) => {
                  handleMessage('termsOfService', data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pending: state.Tos?.pending || null,
    policy: state.Tos.Policy || null,
    aboutTos: state.Tos.aboutTos || null,
    termsOfService: state.Tos.termsOfService || null,
  };
};

export default connect(mapStateToProps)(MembersTOS);
