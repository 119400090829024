import React, { useEffect, useState } from 'react';
import { DropDown } from '../../../components/common';
import { ToggleButton, CustomInput } from '../../../components/common';
import { useDispatch } from 'react-redux';
import { updateListenersProfileRejectedRequest } from '../listeners-redux/actions';
import Model from '../../../components/common/model';

export type CardProps = {
  currentPhotoUrl: any;
  proposedPhotoUrl: string;
  displayName: string;
  firstName: string;
  lastName: string;
  comms: any;
  profilePicVerified: boolean;
  peerState: string;
  email?: string;
  filters?: any;
  userId: number;
  listenerId: number;
  switchboardArea: string;
};

const ProfileStatusOptions = [
  { label: 'Needs Review', value: 'review' },
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
];

const ProfileGradeCard: React.FunctionComponent<CardProps> = ({
  currentPhotoUrl,
  proposedPhotoUrl,
  firstName,
  lastName,
  comms,
  profilePicVerified,
  peerState,
  userId,
  listenerId,
  switchboardArea,
  filters,
}): JSX.Element => {
  const [ProfileStatus, setProfileStatus] = useState('review');
  const [SendEmail, setSendEmail] = useState(comms?.length > 0 && comms[0]?.id);
  const [RejectModeldata, setRejectModeldata] = useState<any>();
  const [messagePopUp, setmessagePopUp] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (SendEmail && comms) {
      const message = comms.find((item: any) => item.id === SendEmail);
      setRejectModeldata({ ...message, send_message: true });
    } else if (comms) {
      setRejectModeldata({ ...comms[0], send_message: true });
    }
  }, [SendEmail, comms]);

  const handleSendRejectMessage = () => {
    const input = {
      id: listenerId,
      userId,
      type: ProfileStatus,
      from: switchboardArea,
      filters,
      payload:
        ProfileStatus === 'reject'
          ? {
              system_message_id: RejectModeldata.id,
              send_message: RejectModeldata.send_message,
              message: RejectModeldata.message,
              subject: RejectModeldata.subject,
            }
          : undefined,
    };

    dispatch(updateListenersProfileRejectedRequest(input));

    setmessagePopUp(false);
  };

  const determineProfileCSS = (): string => {
    if (['active_peer', 'onboarding_peer', 'rejected_peer'].includes(peerState)) {
      return 'border-gray-dark';
    } else if (peerState === 'upgrading_peer') {
      return 'upgrade-border';
    } else {
      return 'border-blue-primary';
    }
  };

  return (
    <>
      <Model
        isModel={messagePopUp}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className=" w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="w-full  my-2">
                    <CustomInput
                      inputType={'text'}
                      value={RejectModeldata?.subject}
                      setValue={(data) => {
                        setRejectModeldata((prev: any) => ({
                          ...prev,

                          subject: data,
                        }));
                      }}
                      Label={'Subject'}
                      labelClass={'block text-sm leading-4   text-blue-dark mb-2'}
                    />
                  </div>

                  <h3 className="block text-sm leading-4   text-blue-dark mb-2" id="modal-title">
                    Body
                  </h3>

                  <div className="w-full  my-2">
                    <textarea
                      className="py-2 px-3 w-full rounded-md  h-28 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
                      value={RejectModeldata?.message}
                      onChange={(evt) => {
                        setRejectModeldata((prev: any) => ({
                          ...prev,

                          message: evt.target.value,
                        }));
                      }}
                    />
                  </div>

                  <div className="w-1/2 ">
                    <div className="flex justify-between items-center">
                      <label className="block text-sm leading-4   text-blue-dark mb-2">
                        {' '}
                        Send Message{' '}
                      </label>

                      <ToggleButton
                        id={'Send Message'}
                        status={RejectModeldata?.send_message}
                        setStatus={(data) => {
                          setRejectModeldata((prev: any) => ({
                            ...prev,

                            send_message: data,
                          }));
                        }}
                        initialstatus={true}
                      ></ToggleButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleSendRejectMessage}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                SEND MESSAGE
              </button>

              <button
                type="button"
                onClick={() => {
                  setmessagePopUp(false);
                }}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                CLOSE
              </button>
            </div>
          </div>
        }
      />

      <div
        className={
          'w-full h-40 bg-white rounded flex px-7 ' +
          (profilePicVerified && proposedPhotoUrl ? 'mb-10' : '')
        }
      >
        {currentPhotoUrl && (
          <div className="flex flex-col p-3">
            <div className=" flex items-center justify-center px-2">
              <div
                className={`rounded-full h-28 w-28 bg-gray-100 border-4 transform rotate-45 ${determineProfileCSS()}`}
              >
                <img
                  className="h-full w-full transform -rotate-45 "
                  src={currentPhotoUrl}
                  alt="listenerProfile"
                />
                <div></div>
              </div>
            </div>
            <div className="flex justify-center">Current</div>
          </div>
        )}
        {proposedPhotoUrl && (
          <div className="flex flex-col p-3">
            <div className=" flex items-center justify-center px-2">
              <div
                className={`rounded-full h-28 w-28 bg-gray-100 border-4 transform rotate-45 ${determineProfileCSS()}`}
              >
                <img
                  className="h-full w-full transform -rotate-45 "
                  src={proposedPhotoUrl}
                  alt="listenerProfile"
                />
                <div></div>
              </div>
            </div>
            <div className="flex justify-center">Proposed</div>
          </div>
        )}

        <div className="w-full py-4 pl-4">
          <p className="text-2xl font-normal blue-primary  py-1">{`${firstName} ${lastName}`}</p>
          {proposedPhotoUrl && (
            <div className=" flex justify-between gap-x-2">
              <div className="w-1/2">
                <DropDown
                  Label={'Profile Status'}
                  value={ProfileStatus}
                  setValue={setProfileStatus}
                  labelClass={' text-sm leading-4   text-blue-dark '}
                >
                  {ProfileStatusOptions.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </DropDown>
              </div>

              <div className="w-1/2">
                {ProfileStatus === 'reject' && (
                  <DropDown
                    Label={'Send Email'}
                    value={SendEmail}
                    setValue={setSendEmail}
                    labelClass={' text-sm leading-4  text-blue-dark '}
                  >
                    {comms?.length > 0 &&
                      comms.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </DropDown>
                )}
              </div>
            </div>
          )}
          {ProfileStatus !== 'review' && proposedPhotoUrl && (
            <button
              className="bg-blue-primary rounded-full  text-center  text-sm text-white px-4 py-2 my-3"
              onClick={() => {
                ProfileStatus === 'reject' ? setmessagePopUp(true) : handleSendRejectMessage();
              }}
            >
              Save and notify
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileGradeCard;
