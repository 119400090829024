import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import CalendarPaginator from '../../components/calender-paginator';
import { VerticalBarChart } from '../../components/charts';
import { StackedBarChart } from '../../components/charts';
import Header from '../../components/common/global-top-bar';
import moment from 'moment';
import { fetchEngagementDetailsRequest } from './client-portal-redux/actions';
import Spinner from '../../components/common/spinner';
import { ClientAdminGroupDropDown } from '../../components/common';

type props = {
  engagement: any;
  pending: any;
  clients: any;
  clientPending: boolean;
};

type moodChartprops = {
  data: any;
};
const MoodDistributions: React.FC<moodChartprops> = ({ data }): JSX.Element => {
  const { positive, neutral, negative } = data;
  const [Nodata, setNodata] = useState(false);

  const arrayData = [
    {
      bgColor: 'stacked-bar-red-primary',
      width: negative?.response_percentage,
    },
    {
      bgColor: 'stacked-bar-orange-primary',
      width: neutral?.response_percentage,
    },
    {
      bgColor: 'stacked-bar-green-primary',
      width: positive?.response_percentage,
    },
  ];

  useEffect(() => {
    if (
      parseInt(positive?.response_percentage) === 0 &&
      parseInt(neutral?.response_percentage) === 0 &&
      parseInt(negative?.response_percentage) === 0
    ) {
      setNodata(true);
    } else {
      setNodata(false);
    }
  }, [data]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full py-3">
      <div className=" flex justify-between w-full  h-2/3 items-end">
        {!Nodata ? (
          arrayData.map((item) => (
            <StackedBarChart xwidth={`${item.width}%`} backGroundColor={item.bgColor} />
          ))
        ) : (
          <div className="text-center h-10 flex items-center justify-center text-monsterrat border-white border text-white font-bold bg-gray-light w-full">
            No check-in responses
          </div>
        )}
      </div>
      <div className="text-sm text-gray-dark flex items-center py-1">
        Negative / Neutral / Positive
      </div>
    </div>
  );
};

const Engagement: React.FunctionComponent<props> = (props): JSX.Element => {
  const search = useLocation().search;
  const packageId = new URLSearchParams(search).get('packageId');
  const clientId = new URLSearchParams(search).get('clientId');
  const dispatch = useDispatch();
  const [topics, settopics] = useState<any>([]);
  const checkins = props.engagement?.check_ins || null;
  const stroyListens = props.engagement?.story_listens || null;
  const calls = props.engagement?.calls || null;

  useEffect(() => {
    const fetchCharData = () => {
      const engagementByTopic = props.engagement?.engagement_by_topic || null;
      const TopicChart =
        engagementByTopic?.length > 0
          ? engagementByTopic.map((item: any) => [item.label, item.value, '', item.value])
          : [];
      const TopicChartData = [
        ['Topic', 'Topics of intrests', { role: 'style' }, { role: 'annotation' }],
        ...TopicChart,
      ];
      return [...TopicChartData];
    };
    settopics(fetchCharData());
  }, [props.engagement]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = {
    title: '',
    chartArea: { width: '30%', height: '90%' },
    colors: ['#1D1183'],
    legend: { position: 'none' },
    hAxis: {
      title: '',
      minValue: 0,
    },
    vAxis: {
      title: '',
    },
  };

  const fetchData = (startdate: string | null, enddate: string | null) => {
    const packageID = new URLSearchParams(search).get('packageId');
    const clientID = new URLSearchParams(search).get('clientId');

    let input: any = {
      linkInput: {
        clientId: clientID,
      },
      payload: {
        start_date: startdate ? startdate : moment(new Date()).startOf('year').format('YYYY-MM-DD'),
        end_date: enddate ? enddate : moment(new Date()).endOf('year').format('YYYY-MM-DD'),
      },
    };
    if (packageID) {
      input.linkInput['packageId'] = packageID;
    }
    dispatch(fetchEngagementDetailsRequest(input));
  };

  // useEffect(()=>{
  //      fetchData(null, null);
  // },[packageId,clientId])

  return (
    <div>
      {props.pending && <Spinner />}
      <Header
        disableText={true}
        heading={
          <div className="w-full px-2 text-xl">
            <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">
              Group
            </label>
            <div className="relative w-3/4">
              <ClientAdminGroupDropDown type="engagement" />
            </div>
          </div>
        }
      />
      {/* <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light h-10 "> */}
      <CalendarPaginator
        key={clientId && packageId ? clientId + packageId : 'All Groups'}
        changedDate={fetchData}
        disableDownload={true}
      />

      <div className="max-window-height-group pb-32 overflow-y-auto ">
        <div className="flex justify-between ">
          <div className="w-full px-7 flex-col">
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Total Eligible Members
            </div>
            <div className="w-3/4 ">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-2xl py-3 blue-primary">
                {props.engagement?.members?.total || 0}
              </div>
            </div>

            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Check-ins
            </div>
            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-2xl py-3 blue-primary">{checkins?.total_responses || 0}</div>
              <div className="flex-col">
                <div className="text-2xl py-3 blue-primary">
                  {checkins?.response_percentage || 0} %
                </div>
                <span>{checkins?.total_sent || 0} Sent</span>
              </div>
            </div>

            <div className="relative  w-full  py-3 border-b text-base font-bold text-gray-dark">
              Mood Distribution
            </div>
            <MoodDistributions data={checkins?.mood_distribution || {}} />

            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Story Listens
            </div>
            <div className="w-3/4 flex justify-between">
              <div className="text-2xl py-3 blue-primary">{stroyListens?.total_listens || 0}</div>
              <div className="flex-col">
                <div className="text-2xl py-3 blue-primary">
                  {stroyListens?.member_percentage || 0} %
                </div>
                <span>Of Members</span>
              </div>
            </div>

            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Calls
            </div>
            <div className="w-3/4 flex justify-between">
              <div className="text-2xl py-3 blue-primary">{calls?.total || 0}</div>
              <div className="flex-col">
                <div className="text-2xl py-3 blue-primary">{calls?.member_percentage} %</div>
                <span>Of Members</span>
              </div>
            </div>
          </div>
          <div className="w-full px-7">
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark flex">
              Topics of Interests
            </div>
            <div className="w-full">
              {/* <WeeklyChart error={false} /> */}
              {topics && topics?.length > 1 ? (
                <VerticalBarChart data={topics} options={options} key={Math.random()} />
              ) : (
                'No Data Available'
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-evenly px-7"></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    engagement: state.ClientPortal.enagagementDetails?.data || null,
    pending: state.ClientPortal.pending || null,
    clients: state.Client.clients?.data || null,
    clientPending: state.ClientPortal.clientPending,
  };
};

export default connect(mapStateToProps)(Engagement);
