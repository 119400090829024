import {
  POST_COMMS_REQUEST,
  POST_COMMS_FAILURE,
  POST_COMMS_SUCCESS,
  FETCH_ELIGILITY_REQUEST,
  FETCH_ELIGILITY_FAILURE,
  FETCH_ELIGILITY_SUCCESS,
  FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST,
  FETCH_SYSTEM_MESSAGE_GROUPS_FAILURE,
  FETCH_SYSTEM_MESSAGE_GROUPS_SUCCESS,
  FETCH_COMMS_LIST_REQUEST,
  FETCH_COMMS_LIST_FAILURE,
  FETCH_COMMS_LIST_SUCCESS,
  UPDATE_COMMS_REQUEST,
  UPDATE_COMMS_FAILURE,
  UPDATE_COMMS_SUCCESS,
  REMOVE_COMMS_REQUEST,
  REMOVE_COMMS_FAILURE,
  REMOVE_COMMS_SUCCESS,
  POST_REPAIR_TEMPLATE_REQUEST,
  POST_REPAIR_TEMPLATE_FAILURE,
  POST_REPAIR_TEMPLATE_SUCCESS,
  POST_TEST_COMMS_REQUEST,
  POST_TEST_COMMS_FAILURE,
  POST_TEST_COMMS_SUCCESS,
  POST_CAMPAIGNS_REQUEST,
  POST_CAMPAIGNS_FAILURE,
  POST_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_LIST_REQUEST,
  FETCH_CAMPAIGNS_LIST_FAILURE,
  FETCH_CAMPAIGNS_LIST_SUCCESS,
  UPDATE_CAMPAIGNS_REQUEST,
  UPDATE_CAMPAIGNS_FAILURE,
  UPDATE_CAMPAIGNS_SUCCESS,
  REMOVE_CAMPAIGNS_REQUEST,
  REMOVE_CAMPAIGNS_FAILURE,
  REMOVE_CAMPAIGNS_SUCCESS,
  POST_TEST_CAMPAIGNS_REQUEST,
  POST_TEST_CAMPAIGNS_FAILURE,
  POST_TEST_CAMPAIGNS_SUCCESS,
} from './action-types';

import {
  PostTestCommsRequest,
  PostTestCommsSuccess,
  PostTestCommsSuccessPayload,
  PostTestCommsFailure,
  PostTestCommsFailurePayload,
  PostRepairTemplateRequest,
  PostRepairTemplateSuccess,
  PostRepairTemplateSuccessPayload,
  PostRepairTemplateFailure,
  PostRepairTemplateFailurePayload,
  PostCommsRequest,
  PostCommsSuccess,
  PostCommsSuccessPayload,
  PostCommsFailure,
  PostCommsFailurePayload,
  FetchCommsRequest,
  FetchCommsSuccess,
  FetchCommsSuccessPayload,
  FetchCommsFailure,
  FetchCommsFailurePayload,
  FetchSystemMessageGroupsRequest,
  FetchSystemMessageGroupsSuccess,
  FetchSystemMessageGroupsFailure,
  FetchSystemMessageGroupsFailurePayload,
  FetchCommsListRequest,
  FetchCommsListSuccess,
  FetchCommsListSuccessPayload,
  FetchCommsListFailure,
  FetchCommsListFailurePayload,
  UpdateCommsRequest,
  UpdateCommsSuccess,
  UpdateCommsSuccessPayload,
  UpdateCommsFailure,
  UpdateCommsFailurePayload,
  RemoveCommsRequest,
  RemoveCommsSuccess,
  RemoveCommsSuccessPayload,
  RemoveCommsFailure,
  RemoveCommsFailurePayload,
  PostTestCampaignsRequest,
  PostTestCampaignsSuccess,
  PostTestCampaignsSuccessPayload,
  PostTestCampaignsFailure,
  PostTestCampaignsFailurePayload,
  PostCampaignsRequest,
  PostCampaignsSuccess,
  PostCampaignsSuccessPayload,
  PostCampaignsFailure,
  PostCampaignsFailurePayload,
  FetchCampaignsRequest,
  FetchCampaignsSuccess,
  FetchCampaignsSuccessPayload,
  FetchCampaignsFailure,
  FetchCampaignsFailurePayload,
  FetchCampaignsListRequest,
  FetchCampaignsListSuccess,
  FetchCampaignsListSuccessPayload,
  FetchCampaignsListFailure,
  FetchCampaignsListFailurePayload,
  UpdateCampaignsRequest,
  UpdateCampaignsSuccess,
  UpdateCampaignsSuccessPayload,
  UpdateCampaignsFailure,
  UpdateCampaignsFailurePayload,
  RemoveCampaignsRequest,
  RemoveCampaignsSuccess,
  RemoveCampaignsSuccessPayload,
  RemoveCampaignsFailure,
  RemoveCampaignsFailurePayload,
} from './types';

export const postCommsRequest = (payload: any): PostCommsRequest => ({
  type: POST_COMMS_REQUEST,
  payload,
});

export const postCommsSuccess = (payload: PostCommsSuccessPayload): PostCommsSuccess => ({
  type: POST_COMMS_SUCCESS,
  payload,
});

export const postCommsFailure = (payload: PostCommsFailurePayload): PostCommsFailure => ({
  type: POST_COMMS_FAILURE,
  payload,
});

export const fetchCommsRequest = (payload: any): FetchCommsRequest => ({
  type: FETCH_ELIGILITY_REQUEST,
  payload,
});

export const fetchCommsSuccess = (payload: FetchCommsSuccessPayload): FetchCommsSuccess => ({
  type: FETCH_ELIGILITY_SUCCESS,
  payload,
});

export const fetchCommsFailure = (payload: FetchCommsFailurePayload): FetchCommsFailure => ({
  type: FETCH_ELIGILITY_FAILURE,
  payload,
});

export const fetchSystemMessageGroupsRequest = (payload: any): FetchSystemMessageGroupsRequest => ({
  type: FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST,
  payload,
});

export const fetchSystemMessageGroupsSuccess = (payload: any): FetchSystemMessageGroupsSuccess => ({
  type: FETCH_SYSTEM_MESSAGE_GROUPS_SUCCESS,
  payload,
});

export const fetchSystemMessageGroupsFailure = (
  payload: FetchSystemMessageGroupsFailurePayload
): FetchSystemMessageGroupsFailure => ({
  type: FETCH_SYSTEM_MESSAGE_GROUPS_FAILURE,
  payload,
});

export const fetchCommsListRequest = (payload: any): FetchCommsListRequest => ({
  type: FETCH_COMMS_LIST_REQUEST,
  payload,
});

export const fetchCommsListSuccess = (
  payload: FetchCommsListSuccessPayload
): FetchCommsListSuccess => ({
  type: FETCH_COMMS_LIST_SUCCESS,
  payload,
});

export const fetchCommsListFailure = (
  payload: FetchCommsListFailurePayload
): FetchCommsListFailure => ({
  type: FETCH_COMMS_LIST_FAILURE,
  payload,
});

export const updateCommsRequest = (payload: any): UpdateCommsRequest => ({
  type: UPDATE_COMMS_REQUEST,
  payload,
});

export const updateCommsSuccess = (payload: UpdateCommsSuccessPayload): UpdateCommsSuccess => ({
  type: UPDATE_COMMS_SUCCESS,
  payload,
});

export const updateCommsFailure = (payload: UpdateCommsFailurePayload): UpdateCommsFailure => ({
  type: UPDATE_COMMS_FAILURE,
  payload,
});

export const removeCommsRequest = (payload: any): RemoveCommsRequest => ({
  type: REMOVE_COMMS_REQUEST,
  payload,
});

export const removeCommsSuccess = (payload: RemoveCommsSuccessPayload): RemoveCommsSuccess => ({
  type: REMOVE_COMMS_SUCCESS,
  payload,
});

export const removeCommsFailure = (payload: RemoveCommsFailurePayload): RemoveCommsFailure => ({
  type: REMOVE_COMMS_FAILURE,
  payload,
});

export const postRepairTemplateRequest = (payload: any): PostRepairTemplateRequest => ({
  type: POST_REPAIR_TEMPLATE_REQUEST,
  payload,
});

export const postRepairTemplateSuccess = (
  payload: PostRepairTemplateSuccessPayload
): PostRepairTemplateSuccess => ({
  type: POST_REPAIR_TEMPLATE_SUCCESS,
  payload,
});

export const postRepairTemplateFailure = (
  payload: PostRepairTemplateFailurePayload
): PostRepairTemplateFailure => ({
  type: POST_REPAIR_TEMPLATE_FAILURE,
  payload,
});

export const postTestCommsRequest = (payload: any): PostTestCommsRequest => ({
  type: POST_TEST_COMMS_REQUEST,
  payload,
});

export const postTestCommsSuccess = (
  payload: PostTestCommsSuccessPayload
): PostTestCommsSuccess => ({
  type: POST_TEST_COMMS_SUCCESS,
  payload,
});

export const postTestCommsFailure = (
  payload: PostTestCommsFailurePayload
): PostTestCommsFailure => ({
  type: POST_TEST_COMMS_FAILURE,
  payload,
});

export const postCampaignsRequest = (payload: any): PostCampaignsRequest => ({
  type: POST_CAMPAIGNS_REQUEST,
  payload,
});

export const postCampaignsSuccess = (
  payload: PostCampaignsSuccessPayload
): PostCampaignsSuccess => ({
  type: POST_CAMPAIGNS_SUCCESS,
  payload,
});

export const postCampaignsFailure = (
  payload: PostCampaignsFailurePayload
): PostCampaignsFailure => ({
  type: POST_CAMPAIGNS_FAILURE,
  payload,
});

export const fetchCampaignsRequest = (payload: any): FetchCampaignsRequest => ({
  type: FETCH_ELIGILITY_REQUEST,
  payload,
});

export const fetchCampaignsSuccess = (
  payload: FetchCampaignsSuccessPayload
): FetchCampaignsSuccess => ({
  type: FETCH_ELIGILITY_SUCCESS,
  payload,
});

export const fetchCampaignsFailure = (
  payload: FetchCampaignsFailurePayload
): FetchCampaignsFailure => ({
  type: FETCH_ELIGILITY_FAILURE,
  payload,
});

export const fetchCampaignsListRequest = (payload: any): FetchCampaignsListRequest => ({
  type: FETCH_CAMPAIGNS_LIST_REQUEST,
  payload,
});

export const fetchCampaignsListSuccess = (
  payload: FetchCampaignsListSuccessPayload
): FetchCampaignsListSuccess => ({
  type: FETCH_CAMPAIGNS_LIST_SUCCESS,
  payload,
});

export const fetchCampaignsListFailure = (
  payload: FetchCampaignsListFailurePayload
): FetchCampaignsListFailure => ({
  type: FETCH_CAMPAIGNS_LIST_FAILURE,
  payload,
});

export const updateCampaignsRequest = (payload: any): UpdateCampaignsRequest => ({
  type: UPDATE_CAMPAIGNS_REQUEST,
  payload,
});

export const updateCampaignsSuccess = (
  payload: UpdateCampaignsSuccessPayload
): UpdateCampaignsSuccess => ({
  type: UPDATE_CAMPAIGNS_SUCCESS,
  payload,
});

export const updateCampaignsFailure = (
  payload: UpdateCampaignsFailurePayload
): UpdateCampaignsFailure => ({
  type: UPDATE_CAMPAIGNS_FAILURE,
  payload,
});

export const removeCampaignsRequest = (payload: any): RemoveCampaignsRequest => ({
  type: REMOVE_CAMPAIGNS_REQUEST,
  payload,
});

export const removeCampaignsSuccess = (
  payload: RemoveCampaignsSuccessPayload
): RemoveCampaignsSuccess => ({
  type: REMOVE_CAMPAIGNS_SUCCESS,
  payload,
});

export const removeCampaignsFailure = (
  payload: RemoveCampaignsFailurePayload
): RemoveCampaignsFailure => ({
  type: REMOVE_CAMPAIGNS_FAILURE,
  payload,
});

export const postTestCampaignsRequest = (payload: any): PostTestCampaignsRequest => ({
  type: POST_TEST_CAMPAIGNS_REQUEST,
  payload,
});

export const postTestCampaignsSuccess = (
  payload: PostTestCampaignsSuccessPayload
): PostTestCampaignsSuccess => ({
  type: POST_TEST_CAMPAIGNS_SUCCESS,
  payload,
});

export const postTestCampaignsFailure = (
  payload: PostTestCampaignsFailurePayload
): PostTestCampaignsFailure => ({
  type: POST_TEST_CAMPAIGNS_FAILURE,
  payload,
});
