import { axiosGet } from '../../api/axios';

export const validateMobilePhone = async (mobile_phone: string) => {
    const errorMessage = await axiosGet(
      `/users/mobile_phone_valid?mobile_phone=${mobile_phone}`,
      {}
    )
      .then((res) => {
        return '';
      })
      .catch((e) => {
        return e.response?.data?.description;
      });
    return errorMessage;
  };
  
export const normalizeMobilePhone = (value: string) : string => {
  // return nothing if no value
  if (!value) return value;
  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  return currentValue;
};