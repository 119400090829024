import React, { useCallback, useEffect, useState } from 'react';
import Searchbar from '../../../components/common/searchbar';
import { Pagination } from '../../../components/common';

export interface FilterOptionsType {
  [key: string]: string;
}
export interface FilterType {
  page: number;
  limit: number;
  search: string;
  filterValue: string;
}

interface FilterProps {
  count: number | undefined;
  updateFilter: (data: FilterType) => void;
  filterOptions: FilterOptionsType;
  defaultFilterValue: string;
}

const ReviewPageControls: React.FunctionComponent<FilterProps> = ({
  count,
  updateFilter,
  filterOptions,
  defaultFilterValue,
}): JSX.Element => {
  const [filters, setFilters] = useState<FilterType>({
    page: 1,
    limit: 25,
    search: '',
    filterValue: defaultFilterValue,
  });
  const triggerUpdate = useCallback(
    (updatedFilters: FilterType) => {
      updateFilter(updatedFilters);
    },
    [updateFilter]
  );

  useEffect(() => {
    triggerUpdate(filters);
  }, [filters, triggerUpdate]);

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7 h-10">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setFilters((prev) => {
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) =>
            setFilters((filter) => {
              return { ...filter, page: pages + 1 };
            })
          }
        />
      </div>
      <div className="w-1/3">
        <div className="flex justify-end">
          <div className="flex justify-between items-center py-4 gray-background-dark">
            {Object.keys(filterOptions).map((item: string) => (
              <button
                key={item}
                className={
                  'text-sm px-5 py-1 ' +
                  (item === filters.filterValue
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setFilters((filter: any) => {
                    return {
                      ...filter,
                      page: 1,
                      filterValue: item,
                    };
                  });
                }}
              >
                {filterOptions[item]}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPageControls;
