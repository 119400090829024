import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import ClientHeader from '../../components/client-header';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { deleteClientDetailsRequest, fetchClientsRequest } from './clients-redux/actions';
import Spinner from '../../components/common/spinner';
import { sortArrow } from '../../assets/images';
import { ROUTE_PATH } from '../../routes/paths';
import AlertBox from '../../components/route-leaving-prompt/alert-box';

type props = {
  clients: any;
  clientData: any;
  pending: any;
  clientPending: any;
};

const Clients: React.FunctionComponent<props> = (props): JSX.Element => {
  const [selection, setSelection] = useState(0);
  const [searchBar, setSearchBar] = useState('');
  const [selectedClient, setsSelectedClient] = useState<any>();
  const [isShowAlertmodel, setIsShowAlertModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [filters, setFilters] = useState({
    search: '',
    type: 'ALL',
    order_by_direction: 'desc',
    order_by: 'date',
  });

  const fetchData = (filter: any, from?: string) => {
    if (filter.type !== 'ALL') {
      filter = { ...filter, [filter.type]: true };
    }
    if (from === 'filters') {
      filter = {
        ...filter,
        order_by_direction: filters.order_by_direction,
        order_by: filters.order_by,
      };
    }
    setFilters(filter);
    dispatch(fetchClientsRequest(filter));
  };

  const handleSort = (type: any) => {
    fetchData({
      ...filters,
      order_by: type,
      order_by_direction:
        filters.order_by === type
          ? filters?.order_by_direction === 'desc'
            ? 'asc'
            : 'desc'
          : 'desc',
    });
  };

  const filterClientType = (type: any, search: string = '') => {
    setSearchBar(search);
    const clientType: any = {
      Setup: 'setup',
      Live: 'active',
      Inactive: 'inactive',
    };
    if (type === 'All') {
      dispatch(
        fetchClientsRequest({
          search,
        })
      );
    } else {
      dispatch(fetchClientsRequest({ status: clientType[type], search }));
    }
  };

  const addClient = () => {
    localStorage.removeItem('clientid');
    history.push('/groups/configure');
  };

  const handleRemoveClient = () => {
    dispatch(deleteClientDetailsRequest({ clientId: +selectedClient?.id, search: searchBar }));
    setIsShowAlertModel(false);
  };

  return (
    <div>
      <Header heading={'All Groups'} />
      <ClientHeader selectedClientType={filterClientType} addFunctionality={addClient} />
      <div className="max-window-height overflow-y-auto px-7">
        <table className="table-fixed table-row-format w-full relative border-collapse mb-5">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7 text-sm">
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div className="flex items-center pl-1 ">Status</div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div
                  className="flex items-center"
                  role="button"
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Name{' '}
                  {filters.order_by === 'name' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="sort by name"
                    ></img>
                  )}
                </div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div className="flex items-center">Reseller</div>
              </th>
              <th className="px-2 py-2  w-3/12 sticky top-0 bg-white  h-full mb-4">
                <div
                  className="flex items-center"
                  role="button"
                  onClick={() => {
                    handleSort('contract_date');
                  }}
                >
                  Contract Date{' '}
                  {filters.order_by === 'contract_date' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="sort by date"
                    ></img>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {props.pending || props.clientPending ? (
              <Spinner />
            ) : (
              props.clients?.map((item: any, index: number) => {
                return (
                  <tr
                    key={item.id}
                    className={
                      ' rounded h-12 w-full text-left blue-primary border-y border-white border-4 space-y-6' +
                      (index === selection
                        ? ' text-white  bg-blue-primary '
                        : ' bg-gray-200 text-gray-dark')
                    }
                    onClick={() => {
                      setSelection(index);
                      localStorage.setItem('clientid', item.id.toString());
                    }}
                  >
                    <td className="py-1 w-3/12 pl-1">
                      <button
                        className={
                          'rounded-full  py-2 text-sm  text-white w-24 text-center' +
                          (item.status === 'active'
                            ? ' toggle-input-back-ground '
                            : item.status === 'inactive'
                            ? ' stacked-bar-red-primary '
                            : ' stacked-bar-orange-primary ')
                        }
                        onClick={() => {
                          history.push(ROUTE_PATH.REPORTING);
                        }}
                      >
                        {item.status.toUpperCase()}
                      </button>
                    </td>
                    <td
                      className="font-bold font-base cursor-pointer py-1 w-3/12"
                      role="button"
                      onClick={() => {
                        history.push(ROUTE_PATH.GROUPS_CONFIGURE);
                      }}
                    >
                      {item.name}
                    </td>
                    <td className="font-bold font-base py-1 w-3/12">
                      {item.reseller?.length ? item.reseller : '-'}
                    </td>
                    <td className="font-bold font-base py-1 w-3/12">
                      {item.contract_date?.length ? item.contract_date : '-'}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <AlertBox
        visible={isShowAlertmodel}
        handlePrompt={handleRemoveClient}
        closeModal={() => setIsShowAlertModel(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove this ${selectedClient?.name}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    clients: state.Client.clients?.data || null,
    pending: state.Client?.pending || null,
    clientPending: state.Client?.clientPending || null,
  };
};

export default connect(mapStateToProps)(Clients);
