import React from 'react';
import Header from '../../components/common/global-top-bar';
import Kohli from '../../assets/images/kohli.svg';
import PlayIcon from '../../assets/images/play-icon.svg';
import { SectionLabel } from '../../components/common';
import { Headeroptions } from './member-constants';
import SwitchBar from '../../components/switch-bar';
import SwitchBarButton from './switch-bar-button';
import HeadBack from './redirect';

const Card = () => {
  return (
    <div className="w-full h-32 bg-gray-200 rounded grid grid-cols-12 px-7 my-2">
      <div className="col-span-3 flex items-center justify-center px-2">
        <div className="rounded-full h-24 w-24 bg-gray-100">
          <img className="h-full w-full " src={Kohli} alt="kohli" />
        </div>
      </div>
      <div className="col-span-6 py-4 px-4">
        <p className="text-2xl font-normal blue-primary  py-1">Kohli</p>
        <p className="text-base font-normal text-black  py-1">2:45</p>
        <button className="w-1/2 stacked-bar-green-primary text-white rounded-full px-2">
          Called
        </button>
      </div>
      <div className="col-span-3 p-2 flex items-center justify-center">
        <div className="h-16 p-1 w-16">
          <img src={PlayIcon} alt="playicon" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

const FavouriteCard = () => {
  return (
    <div className="w-full h-32 bg-gray-200 rounded grid grid-cols-12 px-7 my-2">
      <div className="col-span-3 flex items-center justify-center px-2">
        <div className="rounded-full h-24 w-24 bg-gray-100">
          <img className="h-full w-full " src={Kohli} alt="kohli" />
        </div>
      </div>
      <div className="col-span-6 py-4 px-4">
        <p className="text-2xl font-normal blue-primary  py-1">Kohli</p>
        <p className="text-base font-normal text-gray-dark font-bold py-1">My story</p>
        <p className="text-sm font-normal text-gray-dark  py-1">2:45</p>
      </div>
      <div className="col-span-3 p-2 flex items-center justify-center">
        <div className="h-16 p-1 w-16">
          <img src={PlayIcon} alt="playicon" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

const Listeners: React.FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <Header userName={'Jason'} heading={<HeadBack />} />
      <SwitchBar heading={Headeroptions} position={3} optionalButton={<SwitchBarButton />} />

      <div className="max-window-height  overflow-y-auto  px-7 ">
        <div className="flex flex-evenly">
          <div className="w-1/2 py-2">
            <SectionLabel Label={'Connected Listeners'} />
            <div className=" my-2">
              <Card />
              <Card />
              <Card />
            </div>
          </div>
          <div className="w-1/2 py-2 pl-7">
            <div className="">
              <SectionLabel Label={'Favorite Listeners'} />
            </div>
            <div className=" my-2">
              <FavouriteCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listeners;
