import * as React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Login } from '../containers';
import PrivateRoute from './privateRoute';
import { AdminRoutes, ROUTE_PATH } from './paths';
import NotFound404 from '../components/common/not-found-404';
import NotifyMessage from '../components/account-login/notify-message';
import ResetPassword from '../components/account-login/reset-password';
import OnBoarding from '../containers/onboarding';
import InvitationExpired from '../containers/onboarding/invitation-expired';
import InvitationRevoked from '../containers/onboarding/invitation-revoked';
import InvitationCompleted from '../containers/onboarding/invitation-complete';

const Routes: React.FunctionComponent = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact component={Login} path="/" />
        <Route exact={true} component={NotifyMessage} path="/notify-message" />
        <Route exact component={ResetPassword} path={'/reset-password'}></Route>
        <Route exact component={OnBoarding} path={ROUTE_PATH.ONBOARDING}></Route>
        <Route exact component={InvitationCompleted} path={ROUTE_PATH.INVITATION_COMPLETED}></Route>
        <Route exact component={InvitationExpired} path={ROUTE_PATH.REQUESTNEWINVITE}></Route>
        <Route exact component={InvitationRevoked} path={ROUTE_PATH.INVITEREVOKE}></Route>
        {AdminRoutes.map((item: any) => (
          <PrivateRoute
            exact={true}
            component={item.component}
            path={item.path}
            role={item.role}
            selectedId={item.id}
          />
        ))}
        <Route exact={true} component={Login} path="/" />
        <Route path="*" exact={true} component={NotFound404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
