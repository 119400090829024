import { toast } from 'react-toastify';
import { Toast } from 'react-toastify/dist/components';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  fetchResourceRequest,
  fetchResourceSuccess,
  fetchResourceFailure,
} from '../../resources/redux-resources/actions';

import {
  fetchClientsSuccess,
  fetchClientsFailure,
  fetchClientCountSuccess,
  fetchClientCountFailure,
  postAvailableCreditSuccess,
  postAvailableCreditFailure,
  getAdminDetailsSuccess,
  getAdminDetailsFailure,
  postCreatePackageSuccess,
  postCreatePackageFailure,
  postUpdatePackageSuccess,
  postUpdatePackageFailure,
  postUpdatePackageProductSuccess,
  postUpdatePackageProductFailure,
  postCreatePackageProductSuccess,
  postCreatePackageProductFailure,
  getClientDetailsSuccess,
  getClientDetailsFailure,
  postForceCheckInSuccess,
  postForceCheckInFailure,
  fetchWebAppUrlSettingSuccess,
  fetchWebAppUrlSettingFailure,
  postAddCommsSuccess,
  postAddCommsFailure,
  fetchCampaignSuccess,
  fetchCampaignFailure,
  getProductTemplateFailure,
  getProductTemplateSuccess,
  removedCommsSuccess,
  removedCommsFailure,
  postUpdateClientSuccess,
  postUpdateClientFailure,
  postCreateClientSuccess,
  postCreateClientFailure,
  fetchEmailTemplateSuccess,
  fetchEmailTemplateFailure,
  FetchIncentiveSuccess,
  FetchIncentiveFailure,
  fetchCampaignsFailure,
  fetchSystemMessagesSuccess,
  fetchSystemMessagesFailure,
  fetchTagsSuccess,
  fetchTagsFailure,
  fetchReportingSuccess,
  fetchReportingFailure,
  deletePackageSuccess,
  deletePackageFailure,
  deleteClientDetailsSuccess,
  deleteClientDetailsFailure,
  fetchAppSettingsFailure,
  fetchAppSettingsSuccess,
  fetchExternalProviderPlansFailure,
  fetchExternalProviderPlansSuccess,
  postSubscriptionsFailure,
  removeSubscriptionsFailure,
  removeSubscriptionsSuccess,
  getDefaultSubscriptionsSuccess,
  getDefaultSubscriptionsFailure,
} from './actions';
import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENT_COUNT_REQUEST,
  POST_AVAILABLE_CREDIT_REQUEST,
  GET_ADMIN_DETAILS_REQUEST,
  GET_CLIENT_DETAILS_REQUEST,
  POST_CREATE_PACKAGE_REQUEST,
  POST_UPDATE_PACKAGE_REQUEST,
  POST_UPDATE_PACKAGE_PRODUCT_REQUEST,
  POST_CREATE_PACKAGE_PRODUCT_REQUEST,
  POST_FORCE_CHECK_IN_REQUEST,
  FETCH_WEB_APP_URL_SETTING_REQUEST,
  POST_ADD_COMMS_REQUEST,
  FETCH_CAMPAIGN_REQUEST,
  FETCH_PRODUCT_TEMPLATE_REQUEST,
  REMOVED_COMMS_REQUEST,
  POST_CREATE_CLIENT_REQUEST,
  POST_UPDATE_CLIENT_REQUEST,
  FETCH_EMAIL_TEMPLATE_REQUEST,
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_INCENTIVES_REQUEST,
  FETCH_SYSTEM_MESSAGES_REQUEST,
  FETCH_TAGS_REQUEST,
  FETCH_REPORTING_REQUEST,
  DELETE_PACKAGE_REQUEST,
  DELETE_CLIENT_DETAILS_REQUEST,
  FETCH_APP_SETTINGS_REQUEST,
  FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST,
  POST_SUBSCRIPTIONS_REQUEST,
  REMOVE_SUBSCRIPTIONS_REQUEST,
  UPDATE_SUBSCRIPTIONS_REQUEST,
  GET_DEFAULT_SUBSCRIPTIONS_REQUEST,
} from './actionTypes';

function* fetchClientsSaga(action: any): any {
  try {
    const res = Api.getClientsList(action.payload);
    const response = yield call(res);

    yield put(fetchClientsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchClientsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchClientCountSaga(action: any): any {
  try {
    const active: any = yield call(Api.getClientCount({ status: 'active' }));
    const inactive: any = yield call(Api.getClientCount({ status: 'inactive' }));
    const setup: any = yield call(Api.getClientCount({ status: 'setup' }));
    const all: any = yield call(Api.getClientCount({}));

    const data = [all.data.count, setup.data.count, active.data.count, inactive.data.count];

    yield put(fetchClientCountSuccess({ CLIENT_COUNT: data }));
  } catch (e: unknown) {
    yield put(
      fetchClientCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* PostAvailableCreditSaga(action: any): any {
  try {
    const res = Api.addCredits(action.payload);
    const response = yield call(res);
    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }

    yield put(postAvailableCreditSuccess(response));
  } catch (e: any) {
    const alertMessage = JSON.parse(e);
    toast.error(alertMessage.data.description);
    yield put(
      postAvailableCreditFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* GetAdminDetailsSaga(action: any): any {
  try {
    const res = Api.authenticate(action.payload);
    const response = yield call(res);

    yield put(getAdminDetailsSuccess(response));
  } catch (e: unknown) {
    yield put(
      getAdminDetailsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* GetClientDetailsSaga(action: any): any {
  try {
    const response = yield call(Api.getClient(action.payload));
    yield put(getClientDetailsSuccess(response));
  } catch (e: unknown) {
    yield put(
      getClientDetailsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* DeleteClientDetailsSaga(action: any): any {
  try {
    const search = action.payload?.search;
    const response = yield call(Api.deleteClient(action.payload));
    yield put(deleteClientDetailsSuccess(response));
    if (response.status === 200) {
      toast.success('Group removed successfully');
      yield fetchClientCountSaga({});
      yield fetchClientsSaga({ payload: { search } });
    }
  } catch (e: any) {
    const error = JSON.parse(e);
    yield put(deleteClientDetailsFailure(error));
    toast.error(error?.data?.description);
  }
}

function* PostCreatePackageSaga(action: any): any {
  try {
    const res = Api.createPackage(action.payload);

    const response = yield call(res);
    yield put(postCreatePackageSuccess(response));
    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
    toast.success('Package created successfully');
  } catch (e: unknown) {
    yield put(
      postCreatePackageFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
    toast.error('Package creation failed');
  }
}

function* PostUpdatePackageSaga(action: any): any {
  try {
    const res = Api.updatePackage(action.payload);
    const response = yield call(res);
    yield put(postUpdatePackageSuccess(response));

    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
      toast.success('Package updation successfully');
    }
  } catch (e: unknown) {
    yield put(
      postUpdatePackageFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
    toast.error('Package updation failed');
  }
}

function* PostUpdatePackageProductSaga(action: any): any {
  try {
    const res = Api.updatePackageProduct(action.payload);
    const response = yield call(res);
    yield put(postUpdatePackageProductSuccess(response));

    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
  } catch (e: any) {
    const id = localStorage.getItem('clientid');
    const clientresponse = yield call(Api.getClient({ clientId: id }));
    yield put(getClientDetailsSuccess(clientresponse));

    yield put(
      postUpdatePackageProductFailure({
        error: e instanceof Error ? e.message : e ? JSON.parse(e) : {},
      })
    );
  }
}

function* PostCreatePackageProductSaga(action: any): any {
  try {
    const response = yield call(Api.createPackageProduct(action.payload));
    yield put(postCreatePackageProductSuccess(response));
    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
  } catch (e: any) {
    const id = localStorage.getItem('clientid');
    const clientresponse = yield call(Api.getClient({ clientId: id }));
    yield put(getClientDetailsSuccess(clientresponse));

    yield put(
      postCreatePackageProductFailure({
        error: e instanceof Error ? e.message : e ? JSON.parse(e) : {},
      })
    );
  }
}

function* postGroupAddCommsSaga(action: any): any {
  try {
    const response = yield call(Api.addComms(action.payload));
    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
    yield put(postAddCommsSuccess(response));
  } catch (e: any) {
    const alertMessage = JSON.parse(e);

    yield put(
      postAddCommsFailure({
        error: e instanceof Error ? e.message : alertMessage,
      })
    );
  }
}

function* fetchWebAppUrlSettingSaga(action: any): any {
  try {
    const response = yield call(Api.appSettings(action.payload));
    yield put(fetchWebAppUrlSettingSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchWebAppUrlSettingFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}
function* PostForceCheckInSaga(action: any): any {
  try {
    const response = yield call(Api.forceCheckIn(action.payload));
    yield put(postForceCheckInSuccess(response));
  } catch (e: unknown) {
    yield put(
      postForceCheckInFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchCampaignSaga(): any {
  try {
    const response = yield call(Api.getcampaigns({}));
    yield put(
      fetchCampaignSuccess({
        Campaign: response,
      })
    );
  } catch (e: unknown) {
    yield put(
      fetchCampaignFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* GetProductTemplate(action: any): any {
  try {
    const response = yield call(Api.getproductTemplate(action.payload));
    yield put(getProductTemplateSuccess(response));
  } catch (e: unknown) {
    yield put(
      getProductTemplateFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}
function* RemoveddCommsSaga(action: any): any {
  try {
    const response = yield call(Api.deleteComms(action.payload));
    if (response.status === 200) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
    yield put(removedCommsSuccess(response));
  } catch (e: unknown) {
    yield put(
      removedCommsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* PostCreateClientSaga(action: any): any {
  try {
    const response = yield call(Api.createClient(action.payload));
    if (response.status === 200) {
      const clientId = response.data.id;
      localStorage.setItem('clientid', clientId.toString());
    }
    yield put(postCreateClientSuccess(response));
  } catch (e: unknown) {
    yield put(
      postCreateClientFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* PostUpdateClientSaga(action: any): any {
  try {
    const response = yield call(Api.updateClient(action.payload));
    yield put(postUpdateClientSuccess(response));
    toast.success('Client Updated Successfully');
  } catch (e: unknown) {
    console.error('err--', e);
    yield put(
      postUpdateClientFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchEmailTemplate(action: any): any {
  try {
    const response = yield call(Api.getEmailTemplate(action.payload));
    yield put(fetchEmailTemplateSuccess(response));
  } catch (e: unknown) {
    console.error('experience:fetchEmailTemplate::error--', e);
    yield put(
      fetchEmailTemplateFailure({
        experiencesFavoritesError: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* FetchCampaignsSaga(action: any): any {
  try {
    const response = yield call(Api.getcampaigns(action.payload));

    yield put(fetchCampaignSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchCampaignsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchIncentiveSaga(action: any): any {
  try {
    const response = yield call(Api.getincentive(action.payload));
    yield put(FetchIncentiveSuccess(response));
  } catch (e: unknown) {
    console.error('err--', e);
    yield put(
      FetchIncentiveFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchSystemMessages(action: any): any {
  try {
    const response = yield call(Api.getSystemMessages(action.payload));
    yield put(fetchSystemMessagesSuccess(response));
  } catch (e: unknown) {
    console.error('experience:fetchSystemMessages::error--', e);
    yield put(
      fetchSystemMessagesFailure({
        experiencesFavoritesError: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchTags(action: any): any {
  try {
    const response = yield call(Api.getTags(action.payload));
    yield put(fetchTagsSuccess(response));
  } catch (e: unknown) {
    console.error(':fetchSystemMessages::error--', e);
    yield put(
      fetchTagsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchReportingSaga(action: any): any {
  try {
    const res = Api.getReports(action.payload);
    const response = yield call(res);
    yield put(fetchReportingSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchReportingFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* deletePackageSaga(action: any): any {
  try {
    const response = yield call(Api.deletePackage(action.payload));
    yield put(deletePackageSuccess(response));
    if (response.status === 200) {
      toast.success(`${action.payload?.packageName} removed successfully`);
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(deletePackageFailure(JSON.parse(e)));
  }
}

function* fetchAppSettingsSaga(action: any): any {
  try {
    const response = yield call(Api.fetchappSettings(action.payload));
    yield put(fetchAppSettingsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchAppSettingsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchExternalProviderPlansSaga(): any {
  try {
    const response = yield call(Api.FetchExternalProviderPlans({}));
    yield put(
      fetchExternalProviderPlansSuccess({
        ExternalProviderPlans: response,
      })
    );
  } catch (e: unknown) {
    yield put(
      fetchExternalProviderPlansFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* postSubscriptionsSaga(action: any): any {
  try {
    const input = action.payload;
    const isDefault = action.payload.isDefault;
    const isGetClient = action.payload.isGetClient;
    delete input['isDefault'];
    delete input['isGetClient'];
    delete input.payload['id'];
    yield call(Api.createSubscriptions(input));
    if (!isDefault) {
      toast.success('Subscription Created Successfully');
    }

    if (!isGetClient) {
      const id = localStorage.getItem('clientid');
      const clientresponse = yield call(Api.getClient({ clientId: id }));
      yield put(getClientDetailsSuccess(clientresponse));
    }
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postSubscriptionsFailure(JSON.parse(e)));
  }
}

function* UpdateSubscriptionsSaga(action: any): any {
  try {
    yield call(Api.updateSubscriptions(action.payload));
    toast.success('Resource Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const id = localStorage.getItem('clientid');
  const clientresponse = yield call(Api.getClient({ clientId: id }));
  yield put(getClientDetailsSuccess(clientresponse));
}

function* removeSubscriptionsSaga(action: any): any {
  try {
    const response = yield call(Api.deleteSubscriptions(action.payload));
    yield put(removeSubscriptionsSuccess(response));
    toast.success(`Subscription removed sucessfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeSubscriptionsFailure(JSON.parse(e)));
  }
  const id = localStorage.getItem('clientid');
  const clientresponse = yield call(Api.getClient({ clientId: id }));
  yield put(getClientDetailsSuccess(clientresponse));
}

function* fetchResourceSaga(action: any): any {
  try {
    const res = Api.fetchResource(action.payload);
    const response = yield call(res);
    yield put(fetchResourceSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchResourceFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* getDefaultSubcriptionTemplateSaga(action: any): any {
  try {
    const res = Api.getDefaultSubscriptions(action.payload);
    const response = yield call(res);
    yield put(getDefaultSubscriptionsSuccess(response));
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      yield put(getDefaultSubscriptionsFailure(error));
    } catch (error) {}
  }
}

function* todoSaga() {
  yield all([
    takeLatest(FETCH_CLIENTS_REQUEST, fetchClientsSaga),
    takeLatest(FETCH_CLIENT_COUNT_REQUEST, fetchClientCountSaga),
    takeLatest(POST_AVAILABLE_CREDIT_REQUEST, PostAvailableCreditSaga),
    takeLatest(GET_ADMIN_DETAILS_REQUEST, GetAdminDetailsSaga),
    takeLatest(GET_CLIENT_DETAILS_REQUEST, GetClientDetailsSaga),
    takeLatest(DELETE_CLIENT_DETAILS_REQUEST, DeleteClientDetailsSaga),
    takeLatest(POST_CREATE_PACKAGE_REQUEST, PostCreatePackageSaga),
    takeLatest(POST_UPDATE_PACKAGE_REQUEST, PostUpdatePackageSaga),
    takeLatest(POST_UPDATE_PACKAGE_PRODUCT_REQUEST, PostUpdatePackageProductSaga),
    takeLatest(POST_CREATE_PACKAGE_PRODUCT_REQUEST, PostCreatePackageProductSaga),
    takeLatest(POST_ADD_COMMS_REQUEST, postGroupAddCommsSaga),
    takeLatest(FETCH_WEB_APP_URL_SETTING_REQUEST, fetchWebAppUrlSettingSaga),
    takeLatest(POST_FORCE_CHECK_IN_REQUEST, PostForceCheckInSaga),
    takeLatest(FETCH_CAMPAIGN_REQUEST, fetchCampaignSaga),
    takeLatest(FETCH_PRODUCT_TEMPLATE_REQUEST, GetProductTemplate),
    takeLatest(REMOVED_COMMS_REQUEST, RemoveddCommsSaga),
    takeLatest(POST_CREATE_CLIENT_REQUEST, PostCreateClientSaga),
    takeLatest(POST_UPDATE_CLIENT_REQUEST, PostUpdateClientSaga),
    takeLatest(FETCH_EMAIL_TEMPLATE_REQUEST, fetchEmailTemplate),
    takeLatest(FETCH_CAMPAIGNS_REQUEST, FetchCampaignsSaga),
    takeLatest(FETCH_INCENTIVES_REQUEST, fetchIncentiveSaga),
    takeLatest(FETCH_SYSTEM_MESSAGES_REQUEST, fetchSystemMessages),
    takeLatest(FETCH_TAGS_REQUEST, fetchTags),
    takeLatest(FETCH_REPORTING_REQUEST, fetchReportingSaga),
    takeLatest(DELETE_PACKAGE_REQUEST, deletePackageSaga),
    takeLatest(FETCH_APP_SETTINGS_REQUEST, fetchAppSettingsSaga),
    takeLatest(FETCH_EXTERNAL_PROVIDER_PLANS_REQUEST, fetchExternalProviderPlansSaga),
    takeEvery(POST_SUBSCRIPTIONS_REQUEST, postSubscriptionsSaga),
    takeLatest(UPDATE_SUBSCRIPTIONS_REQUEST, UpdateSubscriptionsSaga),
    takeLatest(REMOVE_SUBSCRIPTIONS_REQUEST, removeSubscriptionsSaga),
    takeLatest(GET_DEFAULT_SUBSCRIPTIONS_REQUEST, getDefaultSubcriptionTemplateSaga),
  ]);
}

export default todoSaga;
