import { Button, SelectionChip, AreaChip } from '@kindlyhuman/component-library';
import React from 'react';
import { useState } from 'react';
import { TagGroup, useTagGroups } from '../../hooks/useTagGroups';
import '@kindlyhuman/component-library/dist/style.css';

export type TagFilters = {
  tag_group_ids: number[];
  tag_ids: number[];
};

export const FilterMenuModal: React.FC<{
  selected: TagFilters;
  onChange: (selectionOptions: TagFilters) => void;
  onExit: () => void;
  fomOptions?: TagGroup[];
  hasTags?: boolean;
}> = ({ onChange, onExit, fomOptions, selected, hasTags }) => {
  const [currentlySelected, setCurrentlySelected] = useState(selected);
  const { getParentByParentId } = useTagGroups();

  const toggleSelectedTagGroup = (id: number) => {
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      tag_group_ids = tag_group_ids.includes(id)
        ? tag_group_ids
            // remove selected if
            .filter((currentId) => currentId !== id)
            // remove selected id's children
            .filter(
              (currentId) =>
                !getParentByParentId(id)
                  ?.children?.map((subject) => subject.id)
                  .includes(currentId)
            )
        : [...tag_group_ids, id];
      return {
        tag_ids,
        tag_group_ids,
      };
    });
  };

  const toggleSelectedTag = (id: number) => {
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      tag_ids = tag_ids.includes(id)
        ? tag_ids.filter((currentId) => currentId !== id)
        : [...tag_ids, id];
      return {
        tag_ids,
        tag_group_ids,
      };
    });
  };

  const removeSubjects = () => {
    const challengeAreaIds = fomOptions?.map((challengeArea) => challengeArea.id);
    setCurrentlySelected(({ tag_ids, tag_group_ids }) => {
      return {
        tag_ids,
        tag_group_ids: tag_group_ids.filter((id) => challengeAreaIds?.includes(id)),
      };
    });
  };

  const removeTags = () => {
    setCurrentlySelected(({ tag_group_ids }) => {
      return {
        tag_ids: [],
        tag_group_ids,
      };
    });
  };

  const removeAll = () => {
    setCurrentlySelected({
      tag_group_ids: [],
      tag_ids: [],
    });
  };

  const handleFilterSave = () => {
    onChange(currentlySelected);
  };

  return fomOptions ? (
    <>
      <div
        className="fixed inset-0 bg-gray-600 bg-opacity-50 z-30 h-full w-screen"
        id="my-modal"
      ></div>
      <div className="fixed bottom-0 left-0 right-0 rounded-t-r-15 overflow-y-auto  bg-[#E6E6E6] h-5/6 z-40 w-full">
        <FilterMenuHeader onExit={onExit} />
        <FilterMenu
          selected={currentlySelected}
          fomOptions={fomOptions}
          toggleSelectedTagGroup={toggleSelectedTagGroup}
          toggleSelectedTag={toggleSelectedTag}
          removeSubjects={removeSubjects}
          removeTags={removeTags}
          currentlySelected={currentlySelected}
          hasTags={hasTags}
        />
        <div className="pt-20" />
      </div>
      <FilterMenuFooter removeAll={removeAll} onChange={handleFilterSave} />
    </>
  ) : null;
};

export const FilterMenu: React.FC<{
  selected: TagFilters;
  fomOptions?: TagGroup[];
  toggleSelectedTagGroup: (id: number) => void;
  toggleSelectedTag: (id: number) => void;
  removeSubjects: () => void;
  removeTags: () => void;
  currentlySelected: TagFilters;
  hasTags?: boolean;
}> = ({
  fomOptions,
  toggleSelectedTagGroup,
  toggleSelectedTag,
  removeSubjects,
  removeTags,
  currentlySelected,
  hasTags,
}) => {
  return fomOptions ? (
    <>
      <div className="bg-white px-4 py-5 flex gap-x-2 gap-y-3 flex-wrap">
        {fomOptions.map((challengeArea) => {
          return (
            <div key={challengeArea.name}>
              <AreaChip
                // @ts-ignore
                variant={challengeArea.name}
                selected={currentlySelected.tag_group_ids.includes(challengeArea.id)}
                onClick={() => toggleSelectedTagGroup(challengeArea.id)}
              />
            </div>
          );
        })}
      </div>
      {/* Subjects */}
      {/* this tests if a challenge area is in the tag group list */}
      {currentlySelected.tag_group_ids.some((id) =>
        fomOptions.map((challengeArea) => challengeArea.id).includes(id)
      ) && (
        <>
          <hr className="h-2 bg-neutral-200" />
          <div className="bg-white mt-2 p-4 mb-2 flex flex-col justify-between">
            <FilterSelectionAreaHeader remove={() => removeSubjects()} title="Subjects" />
            <div className="flex pt-4 flex-row flex-wrap gap-y-4 gap-x-3">
              {fomOptions
                .filter((challengeArea) =>
                  currentlySelected.tag_group_ids.includes(challengeArea.id)
                )
                .map((challengeArea) => {
                  return challengeArea?.children.map((subject) => {
                    return (
                      <div key={subject.id}>
                        <SelectionChip
                          onSelect={() => toggleSelectedTagGroup(subject.id)}
                          // @ts-ignore
                          variant={
                            currentlySelected.tag_group_ids.includes(subject.id)
                              ? challengeArea.key
                              : null
                          }
                        >
                          {subject.name}
                        </SelectionChip>
                      </div>
                    );
                  });
                })}
            </div>
          </div>
        </>
      )}

      {/* Tags */}
      {hasTags &&
        currentlySelected.tag_group_ids.some((id) =>
          fomOptions
            .map((challengeArea) => challengeArea.children.map((subjects) => subjects.id))
            .flat()
            .includes(id)
        ) && (
          <>
            <hr className="h-2 bg-neutral-200" />
            <div className="bg-white mt-2 p-4 mb-2 flex flex-col justify-between">
              <FilterSelectionAreaHeader remove={() => removeTags()} title="Topics" />
              <div className="flex pt-4 flex-row flex-wrap gap-y-4 gap-x-3">
                {fomOptions
                  .filter((challengeArea) =>
                    currentlySelected.tag_group_ids.includes(challengeArea.id)
                  )
                  .map((challengeArea) => {
                    return challengeArea?.children
                      .filter((subject) => currentlySelected.tag_group_ids.includes(subject.id))
                      .map((subject) => {
                        return subject?.tags?.map((tag) => {
                          return (
                            <div key={tag.id}>
                              <SelectionChip
                                onSelect={() => toggleSelectedTag(tag.id)}
                                // @ts-ignore
                                variant={
                                  currentlySelected.tag_ids.includes(tag.id)
                                    ? challengeArea.key
                                    : null
                                }
                              >
                                {tag.name}
                              </SelectionChip>
                            </div>
                          );
                        });
                      });
                  })}
              </div>
            </div>
          </>
        )}
    </>
  ) : null;
};

const FilterMenuHeader: React.FC<{ onExit: () => void }> = ({ onExit }) => (
  <div className="sticky top-0 right-0 left-0">
    <div className="bg-white mb-2 p-4 rounded-t-r-15 flex justify-between">
      <div onClick={() => onExit()}>
        <ExitIcon />
      </div>
      <div className="text-gray-800 text-xl font-bold leading-loose">Frames of Mind</div>
      <div></div>
    </div>
  </div>
);

const FilterMenuFooter: React.FC<{
  onChange: () => void;
  removeAll: () => void;
}> = ({ onChange, removeAll }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 py-4 px-6 bg-white border-t border-neutral-200 z-100">
      <div className="flex justify-between items-center">
        <div
          onClick={removeAll}
          className="cursor-pointer text-center text-violet-950 text-base font-semibold leading-normal"
        >
          Clear all
        </div>
        <Button onClick={onChange} variant="primary">
          Save Changes
        </Button>
      </div>
    </div>
  );
};

const FilterSelectionAreaHeader: React.FC<{
  title: string;
  remove: () => void;
}> = ({ title, remove }) => (
  <div className="w-full flex justify-between">
    <div className="inline-flex text-center flex-nowrap items-center">
      <span className="text-gray-800 text-sm font-bold leading-tight">{title}</span>
      <span className="text-stone-500 pl-2 text-sm font-normal leading-tight">(optional)</span>
    </div>
    <div className="inline-flex">
      <div
        onClick={() => remove()}
        className="text-center text-violet-950 text-sm font-semibold leading-tight pr-6"
      >
        Clear
      </div>
    </div>
  </div>
);

export const ExitIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_266_8736)">
      <rect x="2" y="1" width="40" height="40" rx="20" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4697 15.4697C16.7626 15.1768 17.2374 15.1768 17.5303 15.4697L22 19.9393L26.4697 15.4697C26.7626 15.1768 27.2374 15.1768 27.5303 15.4697C27.8232 15.7626 27.8232 16.2374 27.5303 16.5303L23.0607 21L27.5303 25.4697C27.8232 25.7626 27.8232 26.2374 27.5303 26.5303C27.2374 26.8232 26.7626 26.8232 26.4697 26.5303L22 22.0607L17.5303 26.5303C17.2374 26.8232 16.7626 26.8232 16.4697 26.5303C16.1768 26.2374 16.1768 25.7626 16.4697 25.4697L20.9393 21L16.4697 16.5303C16.1768 16.2374 16.1768 15.7626 16.4697 15.4697Z"
        fill="#22282F"
      />
      <rect x="2.5" y="1.5" width="39" height="39" rx="19.5" stroke="#E6E6E6" />
    </g>
    <defs>
      <filter
        id="filter0_d_266_8736"
        x="0"
        y="0"
        width="44"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_266_8736" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_266_8736"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
