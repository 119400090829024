import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
import { CustomInput, ToggleButton, Spinner, DropDown } from '../../components/common';
import { fetchEmailTemplateRequest } from '../clients/clients-redux/actions';
import { useHistory, useLocation } from 'react-router';
import { Location } from 'history';
import { ROUTE_PATH } from '../../routes/paths';
import {
  fetchCommsListRequest,
  fetchSystemMessageGroupsRequest,
  postCommsFailure,
  postCommsRequest,
  postRepairTemplateRequest,
  postRepairTemplateSuccess,
  postTestCommsRequest,
  removeCommsFailure,
  removeCommsRequest,
  updateCommsFailure,
  updateCommsRequest,
} from './redux-templates/actions';
import Model from '../../components/common/model';
import TemplateModel from '../../components/templates/templateModel';
import Searchbar from '../../components/common/searchbar';
import { toast } from 'react-toastify';
import RouteLeavingGuard from '../../components/route-leaving-prompt';

import { useSMSChannels, SMSChannel } from '../../hooks/useSMSChannels';
import { SystemMessageType } from './redux-templates/types';

type props = {
  comms: any;
  EmailTemplates: any;
  SystemMessageGroups: any;
  pending: boolean;
  UpdateComms: any;
  RemoveComms: any;
  CreateComms: any;
  RepairTemplate: any;
};

type CommDetailsProps = {
  data: any;
  SystemMessageGroups: any;
  EmailTemplates: string[];
  commsType: any;
  RemoveComms: any;
  handleContinueSave: (path: string) => void;
};

const CommsDetails: React.FunctionComponent<CommDetailsProps> = (CommDetailsProps): JSX.Element => {
  const [messageName, setMessageName] = useState<string>('');
  const [messageGroup, setMessageGroup] = useState<string>();
  const [messagekey, setMessageKey] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [textMessage, setTextMessage] = useState<string>('');
  const [enableEmail, setEnableEmail] = useState<boolean>(false);
  const [emailTemplate, setEmailTemplate] = useState<string>('No Template');
  const [sendAsSMS, setSendAsSMS] = useState<boolean>(false);
  const [sendSMSAsPrimary, setSendSMSAsPrimary] = useState<boolean>(false);
  const [internalNotes, setInternalNotes] = useState<string>('');
  const [delayWhileOnCall, setDelayWhileOnCall] = useState<boolean>(true);
  const [testEmailAddress, setTestEmailAddress] = useState<string>(
    localStorage.getItem('commsTestEmail') || ''
  );
  const [enableTestPopUP, setEnableTestPopUP] = useState<boolean>(false);
  const [veriyCheckOldMessage, setVerifyCheckOldMessage] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [enableSaveOption, setEnableSaveOption] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [smsChannelId, setSMSChannelId] = useState<string>();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: availableSMSChannels } = useSMSChannels();

  const getAvailableSMSChannelOptions = () => {
    const options = [];
    options.push(
      <option key="channel_none" value={undefined}>
        Use Default
      </option>
    );

    if (availableSMSChannels) {
      return options.concat(
        availableSMSChannels.map((item: SMSChannel) => {
          return (
            <option key={`channel_${item.id}`} value={item.id}>
              {item.name} ({item.channel_type})
            </option>
          );
        })
      );
    }
    return options;
  };

  useEffect(() => {
    if (CommDetailsProps?.data && CommDetailsProps.SystemMessageGroups) {
      const data = CommDetailsProps?.data;
      if (data) {
        setEnableSaveOption(false);
        setMessageName(data?.name);
        setMessageGroup(data?.group_id);
        setMessageKey(data.key);
        setVerifyCheckOldMessage(false);
        setEmailTemplate(data.email_template);
        setTextMessage(data.message);
        setEnableEmail(data.send_email);
        setSendSMSAsPrimary(data.send_sms_as_primary);
        setInternalNotes(data.description);
        setSubject(data.subject);
        setSendAsSMS(data.send_sms);
        setSMSChannelId(data.sms_channel_id);
        setDelayWhileOnCall(data.delay_while_on_call);
      }
    } else {
      setDefaultValues();
    }
  }, [CommDetailsProps?.data, CommDetailsProps.SystemMessageGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  const setDefaultValues = useCallback(() => {
    setMessageName('');
    setMessageGroup('');
    setMessageKey('');
    setEmailTemplate('');
    setTextMessage('');
    setVerifyCheckOldMessage(false);
    setEnableEmail(false);
    setSendSMSAsPrimary(false);
    setInternalNotes('');
    setSubject('');
    setSendAsSMS(false);
    CommDetailsProps.handleContinueSave('');
    setSMSChannelId('');
    setDelayWhileOnCall(true);
  }, [CommDetailsProps]);

  useEffect(() => {
    setEnableSaveOption(false);
  }, []);

  useEffect(() => {
    if (
      emailTemplate &&
      CommDetailsProps.EmailTemplates &&
      CommDetailsProps.EmailTemplates?.length > 0
    ) {
      const check = CommDetailsProps.EmailTemplates?.some((item: any) => item === emailTemplate);

      if (check || emailTemplate === 'No Template') {
        setVerifyCheckOldMessage(false);
      } else {
        setVerifyCheckOldMessage(true);
      }
    }
  }, [emailTemplate, CommDetailsProps.EmailTemplates]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (CommDetailsProps.RemoveComms?.status === 200) {
      setDefaultValues();
      dispatch(removeCommsFailure({ error: '' }));
    }
  }, [CommDetailsProps?.RemoveComms]); //eslint-disable-line react-hooks/exhaustive-deps

  const validForm = () => {
    if (!messageName || !messageName.trim().length) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const handleSave = () => {
    if (validForm()) {
      const payload: SystemMessageType = {
        message: textMessage?.trim(),
        name: messageName?.trim(),
        send_sms: sendAsSMS,
        email_template: emailTemplate === 'No Template' ? null : emailTemplate,
        group_id: messageGroup ? Number(messageGroup) : null,
        send_email: enableEmail,
        delay_while_on_call: delayWhileOnCall,
        send_sms_as_primary: sendSMSAsPrimary,
        key: messagekey?.trim(),
        subject: subject?.trim(),
        description: internalNotes?.trim(),
        sms_channel_id: smsChannelId ? Number(smsChannelId) : null,
      };

      if (!payload['send_sms']) {
        payload['sms_channel_id'] = null;
      }

      if (CommDetailsProps?.data?.id) {
        const updateCommsData = {
          commsId: CommDetailsProps.data.id,
          payload,
        };
        dispatch(updateCommsRequest(updateCommsData));
      } else {
        dispatch(postCommsRequest(payload));
      }
      setEnableSaveOption(false);
    } else {
      CommDetailsProps.handleContinueSave('');
    }
  };

  const handleDelete = () => {
    dispatch(
      removeCommsRequest({
        commsId: CommDetailsProps.data.id,
        name: CommDetailsProps.data.name,
      })
    );
    setIsDelete(false);
  };

  const handleRepairtemplate = () => {
    dispatch(postRepairTemplateRequest({ template: emailTemplate }));
  };

  const handleCommstest = () => {
    const re = /\S+@\S+\.\S+/;
    if (testEmailAddress?.length && re.test(testEmailAddress)) {
      localStorage.setItem('commsTestEmail', testEmailAddress ? testEmailAddress : '');
      setEnableTestPopUP(false);

      dispatch(
        postTestCommsRequest({
          commsId: CommDetailsProps.data.id,
          payload: { email_address: testEmailAddress },
        })
      );
    } else {
      if (!testEmailAddress) {
        toast.error('Please enter mail address');
      } else if (!re.test(testEmailAddress)) {
        toast.error('Please enter a valid mail address');
      }
    }
  };

  return (
    <>
      <RouteLeavingGuard
        when={enableSaveOption}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: Location) => {
          if (
            `${location.pathname}${location.search}` !==
              `${ROUTE_PATH.TEMPLATES_COMMS}?comms-type=system-comms` &&
            CommDetailsProps.commsType === 'system-comms'
          ) {
            return true;
          } else if (`${location.pathname}${location.search}` !== ROUTE_PATH.TEMPLATES_COMMS) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={
          'You have unsaved changes. Do you want to leave this page and lose your changes?'
        }
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        componentType={CommDetailsProps?.commsType}
        handleContinueSaveChange={(path) => {
          handleSave();
          CommDetailsProps.handleContinueSave(path);
          setEnableSaveOption(false);
        }}
      />
      <div className="w-3/4 ">
        <div className="flex justify-end items-center   gray-background-dark h-10 px-10 pt-5">
          {CommDetailsProps?.data?.id && (
            <>
              <button
                className="rounded-full text-center text-sm text-white py-1 px-5  mr-5 bg-gray-300"
                onClick={() => setIsDelete(true)}
              >
                REMOVE
              </button>

              <button
                className={
                  ' rounded-full text-center text-sm text-white py-1 px-5  mr-5 bg-blue-primary'
                }
                onClick={() => {
                  setEnableTestPopUP(true);
                }}
              >
                TEST
              </button>
            </>
          )}
          <button
            className={
              'rounded-full text-center text-sm text-white px-5 py-1 ' +
              (enableSaveOption
                ? ' bg-blue-primary '
                : ' bg-gray-background-dark cursor-not-allowed ')
            }
            disabled={!enableSaveOption}
            onClick={handleSave}
          >
            SAVE
          </button>
        </div>
        {veriyCheckOldMessage && CommDetailsProps?.data && (
          <div className=" text-red text-lg text-center w-full py-5  ">
            {' '}
            This is an Old Message Please change the Email Template to change{' '}
          </div>
        )}
        <div className="w-full px-5 my-5">
          <CustomInput
            inputType={'text'}
            value={messageName}
            setValue={(data) => {
              setEnableSaveOption(true);
              setMessageName(data);
            }}
            Label={'Message Name'}
            ErrorMessage={'Field is Required'}
            Error={error && (!messageName || !messageName.trim().length)}
          />
        </div>
        <div className="w-full px-5 my-5">
          <CustomInput
            inputType={'text'}
            value={internalNotes}
            setValue={(data) => {
              setEnableSaveOption(true);
              setInternalNotes(data);
            }}
            Label={'Internal Description'}
          />
        </div>
        <div className="flex justify-between px-5 my-5">
          <div
            className={
              '  ' +
              (CommDetailsProps.commsType === 'system-comms' || CommDetailsProps.data
                ? ' w-1/2  '
                : ' hidden ')
            }
          >
            <div className=" w-full ">
              <DropDown
                value={messageGroup}
                setValue={(data) => {
                  setEnableSaveOption(true);
                  setMessageGroup(data);
                }}
                Label={'Message Group'}
              >
                <option key="group_none" value={undefined}>
                  No Group
                </option>
                {CommDetailsProps.SystemMessageGroups?.length > 0 &&
                  CommDetailsProps.SystemMessageGroups.map((item: any) => (
                    <option key={`group_${item.id}`} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </DropDown>
            </div>
          </div>
          <div
            className={
              CommDetailsProps.commsType === 'system-comms' || CommDetailsProps.data
                ? ' w-1/2  pl-2'
                : ' w-full '
            }
          >
            <CustomInput
              inputType={'text'}
              value={messagekey}
              setValue={(data) => {
                setEnableSaveOption(true);
                setMessageKey(data);
              }}
              Label={'Message Key'}
            />
          </div>
        </div>
        <div className="w-full px-5 my-5">
          <CustomInput
            inputType={'text'}
            value={subject}
            setValue={(data) => {
              setEnableSaveOption(true);
              setSubject(data);
            }}
            Label={'Subject'}
          />
        </div>
        <div className="w-full px-5  py-5 border-b gray-border-line">
          <span className="block text-sm leading-4  font-medium text-blue-dark mb-2">Message</span>
          <textarea
            value={textMessage}
            onChange={(e: any) => {
              setTextMessage(e.target.value);
              setEnableSaveOption(true);
            }}
            className="py-2 px-3 w-full rounded-md  h-28  text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
          />
        </div>
        <div className="w-full px-2 flex relative  py-5 border-b gray-border-line">
          <div className="flex justify-between items-center w-1/4">
            <label className=" text-sm leading-4  font-bold text-blue-dark flex items-center">
              <ToggleButton
                id={'enable-email'}
                status={enableEmail}
                setStatus={(data) => {
                  setEnableSaveOption(true);
                  setEnableEmail(data);
                }}
                initialstatus={true}
              ></ToggleButton>
              EMAIL{' '}
            </label>
          </div>
          {enableEmail && (
            <div className="w-3/4 flex items-center h-full">
              <DropDown
                Label={' Email Template'}
                value={emailTemplate}
                setValue={(data) => {
                  setEnableSaveOption(true);
                  setEmailTemplate(data);
                }}
              >
                {CommDetailsProps.EmailTemplates?.length > 0 &&
                  CommDetailsProps.EmailTemplates.map((item) => <option>{item}</option>)}
              </DropDown>
              <div className=" w-1/4 px-4 pt-2">
                {emailTemplate && emailTemplate !== 'No Template' && CommDetailsProps.data && (
                  <button
                    className="bg-blue-primary rounded-full text-center text-sm text-white px-5 py-1"
                    onClick={handleRepairtemplate}
                  >
                    REPAIR
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="w-full px-2 flex py-3 border-b gray-border-line">
          <div className="flex justify-between items-center w-1/4">
            <label className="block text-sm leading-4 font-bold text-blue-dark items-center">
              <ToggleButton
                id={'send-as-sms'}
                status={sendAsSMS}
                setStatus={(data) => {
                  setEnableSaveOption(true);
                  setSendAsSMS(data);
                  if (!data) {
                    setSMSChannelId('');
                  }
                }}
                initialstatus={true}
              ></ToggleButton>
              SMS TEXT
            </label>
          </div>
          <div className="w-3/4 flex">
            <div className="mr-2">
              <DropDown
                value={smsChannelId}
                setValue={(data) => {
                  setSMSChannelId(data);
                  setEnableSaveOption(true);
                }}
                disabled={!sendAsSMS}
                Label={'SMS Marketing Channel'}
              >
                {getAvailableSMSChannelOptions()}
              </DropDown>
            </div>
            <div className="ml-2">
              <label className=" text-sm leading-4  font-medium text-blue-dark flex items-center">
                <ToggleButton
                  id={'send-sms-as-primary'}
                  status={sendSMSAsPrimary}
                  setStatus={(data) => {
                    setEnableSaveOption(true);
                    setSendSMSAsPrimary(data);
                  }}
                  initialstatus={true}
                  disabled={!sendAsSMS}
                ></ToggleButton>
                SMS is a Primary Channel
              </label>
            </div>
          </div>
        </div>
        <div className="px-2  py-3 border-b gray-border-line">
          <label className=" text-sm leading-4  font-medium text-blue-dark mb-2 flex items-center">
            <ToggleButton
              id={'delay-while-on-call'}
              status={delayWhileOnCall}
              setStatus={(data) => {
                setEnableSaveOption(true);
                setDelayWhileOnCall(data);
              }}
              initialstatus={true}
            ></ToggleButton>
            Delay sending while user is on call{' '}
          </label>
        </div>
        {
          <Model
            isModel={enableTestPopUP}
            InnerComponent={
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className=" w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                        TEST COMMS EMAIL
                      </h3>
                      <div className="w-full  my-5">
                        <CustomInput
                          inputType={'text'}
                          value={testEmailAddress}
                          setValue={setTestEmailAddress}
                          Label={'Enter Email Address'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={handleCommstest}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    TEST
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEnableTestPopUP(false);
                    }}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            }
          />
        }
      </div>
      <Model
        isModel={isDelete}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Alert
                  </h3>
                  <div className="mt-2 flex">
                    <p className="text-sm text-gray-500 flex items-center">
                      Are you sure you want to remove this {messageName}?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleDelete}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm stacked-bar-blue-400 px-4 py-2  text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Remove
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setIsDelete(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        }
      ></Model>
    </>
  );
};

const CommsTemplate: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedCommsData, setselectedCommsData] = useState<any>();
  const [EmailLabels, setEmailLabels] = useState(['No Template']);
  const [SystemMessageGroups, setSystemMessageGroups] = useState([]);
  const [EmailCommsLabels, setEmailCommsLabels] = useState(['All Comms']);
  const [FilterTag, setFilterTag] = useState('All Comms');
  const [CommsType, setCommsType] = useState('Comms');
  const [isTemplateModel, setIsTemplateModel] = useState<boolean>(false);
  const search = useLocation().search;
  const commsType = new URLSearchParams(search).get('comms-type');
  const [searchText, setsearchText] = useState('');
  const [enableRepairTemplate, setenableRepairTemplate] = useState<boolean>(false);
  const [saveLocationPath, setSaveLocationPath] = useState<string>('');
  const history = useHistory();

  const handleTemplatesModel = () => {
    setIsTemplateModel(!isTemplateModel);
  };
  useEffect(() => {
    dispatch(fetchEmailTemplateRequest({}));
    dispatch(fetchSystemMessageGroupsRequest({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (commsType) {
      setCommsType(commsType);
    } else {
      setCommsType('Comms');
    }
    setselectedCommsData(null);
    setFilterTag('All Comms');
  }, [commsType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchLatestData();
  }, [FilterTag, CommsType, searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLatestData = () => {
    if (CommsType && FilterTag) {
      let input = {};
      if (CommsType === 'Comms') {
        input = { only_group_key: 'comms' };
      } else {
        input = { omit_group_key: 'comms' };
      }
      if (FilterTag !== 'All Comms') {
        input = { ...input, label: FilterTag };
      }
      if (searchText.length > 0) {
        input = { ...input, search_term: searchText };
      }
      dispatch(fetchCommsListRequest(input));
    }
  };

  useEffect(() => {
    if (props.EmailTemplates) {
      const items = props.EmailTemplates?.map((item: any) => {
        return item.id;
      });
      const uniquieresults = ['No Template', ...items];
      setEmailLabels(uniquieresults);

      const results: any = [];
      props.EmailTemplates?.map((item: any) => {
        return item.labels.length > 1
          ? item.labels.forEach((item: string) => {
              results.push(item);
            })
          : item.labels.length > 0 && results.push(item.labels[0]);
      });
      const setvalue: any = new Set(results);
      const uniquieLabelsresults = ['All Comms', ...setvalue];
      setEmailCommsLabels(uniquieLabelsresults);
    }
  }, [props.EmailTemplates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.SystemMessageGroups) {
      setSystemMessageGroups(props.SystemMessageGroups);
    }
  }, [props.SystemMessageGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.RemoveComms?.status === 200 ||
      props.UpdateComms?.status === 200 ||
      props.CreateComms?.status === 200
    ) {
      if (props.RemoveComms?.status === 200) {
        dispatch(removeCommsFailure({ error: '' }));
        // saveLocationPath !== '' &&  history.push(saveLocationPath);
      }
      if (props.CreateComms?.status === 200) {
        setselectedCommsData(props.CreateComms.data);
        dispatch(postCommsFailure({ error: '' }));
      }
      if (props.UpdateComms?.status === 200) {
        const data = props.UpdateComms.data;
        setselectedCommsData(data);
        saveLocationPath !== '' && history.push(saveLocationPath);
        let groupId: any = SystemMessageGroups.find((item: any) => item.key === 'comms');
        dispatch(updateCommsFailure({ error: '' }));
        if (commsType === null && data.group_id !== groupId?.id) {
          toast.success('Your message has been moved to System Comms');
        } else if (commsType === 'system-comms' && data.group_id === groupId?.id) {
          toast.success('Your message has been moved to Comms');
        }
      }
      fetchLatestData();
    }
  }, [props.UpdateComms, props.RemoveComms, props.CreateComms]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.RepairTemplate?.data) {
      setenableRepairTemplate(true);
    }
  }, [props.RepairTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!enableRepairTemplate) {
      dispatch(postRepairTemplateSuccess({ LISTENERS: null }));
    }
  }, [enableRepairTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.comms && props.comms?.length > 0) {
      const selectedId = selectedCommsData?.id || null;
      if (selectedId) {
        const selectedCapiagn = props.comms.find((item: any) => item.id === selectedId);
        setselectedCommsData(selectedCapiagn ? selectedCapiagn : null);
      }
    } else {
      setselectedCommsData(null);
    }
    saveLocationPath !== '' && history.push(saveLocationPath);
  }, [props.comms]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <Header userName={"Jason"} heading={<HeadBack clientName={ClientName}/>} /> */}
      {props.pending && <Spinner />}
      <Header heading={'Templates'} />

      <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-4  h-10 ">
        <div className="flex justify-center items-center   gray-background-dark h-full">
          <button
            className={
              ' text-sm  px-5 py-2   h-full ' +
              (CommsType === 'Comms' && ' text-white font-bold bg-blue-primary ')
            }
            onClick={() => {
              history.push(ROUTE_PATH.TEMPLATES_COMMS);
            }}
          >
            Comms
          </button>
          <button
            className={' text-sm  px-5 py-2 '}
            onClick={() => {
              history.push(ROUTE_PATH.TEMPLATES_CAMPAIGNS);
            }}
          >
            Campaigns
          </button>
          <button
            className={
              ' text-sm  px-5 py-2 h-full' +
              (CommsType === 'system-comms' && ' text-white font-bold bg-blue-primary ')
            }
            onClick={() => {
              history.push(`${ROUTE_PATH.TEMPLATES_COMMS}?comms-type=system-comms`);
            }}
          >
            System Comms
          </button>
        </div>
        <div className="flex justify-center items-center   gray-background-dark h-full">
          <button
            className="bg-gray-light rounded-full text-center text-sm text-white py-1 w-full mr-5"
            type="button"
            onClick={handleTemplatesModel}
          >
            VARIABLE KEY
          </button>

          <div className="w-full h-full py-1 mr-2">
            <Searchbar
              search={(data: string) => {
                setsearchText(data);
              }}
            />
          </div>

          {selectedCommsData && (
            <button
              className="bg-blue-primary rounded-full text-center text-sm text-white px-5 py-1"
              onClick={() => {
                setselectedCommsData(undefined);
              }}
            >
              +NEW
            </button>
          )}
        </div>
      </div>

      <div className="max-window-height  px-7 flex overflow-y-auto">
        <div className="flex flex-col w-1/4 gray-background-dark h-full ml-5 mr-10 ">
          <div className="h-14 bg-transparent my-4">
            <div className=" w-full pr-4">
              <label className="block text-sm leading-4  font-medium text-blue-dark mb-2">
                Filter By Tag
              </label>
              <div className="relative inline-flex w-full mb-2">
                <svg
                  className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 412 232"
                >
                  <path
                    d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                    fill="#648299"
                    fill-rule="nonzero"
                  />
                </svg>
                <select
                  className="w-full py-2 px-3  h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none"
                  onChange={(e: any) => {
                    setFilterTag(e.target.value);
                  }}
                  value={FilterTag}
                >
                  {EmailCommsLabels.length > 0 &&
                    EmailCommsLabels.map((item) => <option value={item}>{item}</option>)}
                </select>
              </div>
            </div>
          </div>
          {props.comms?.length > 0 &&
            props.comms.map((item: any, index: number) => (
              <button
                key={Math.random()}
                className={
                  ' text-sm  px-5 py-3 my-1 rounded-md ' +
                  (selectedCommsData?.id === item.id
                    ? ' bg-blue-primary text-white font-bold'
                    : ' bg-gray-background-light ')
                }
                onClick={() => {
                  setselectedCommsData({ ...item });
                  setSaveLocationPath('');
                }}
              >
                {item.name}
              </button>
            ))}
        </div>

        <CommsDetails
          data={selectedCommsData}
          key={JSON.stringify(selectedCommsData?.data) || 0}
          EmailTemplates={EmailLabels}
          SystemMessageGroups={SystemMessageGroups}
          commsType={commsType}
          RemoveComms={props.RemoveComms}
          handleContinueSave={setSaveLocationPath}
        />
      </div>
      {isTemplateModel && <TemplateModel handleToggleModel={handleTemplatesModel} />}
      {
        <Model
          isModel={enableRepairTemplate}
          InnerComponent={
            <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-100 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-5xl min-w-3xl max-h-162.5 overflow-y-auto modal-border-shadow rounded-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Email Template Report</div>
                  </div>
                  <div className="relative px-10 pt-2 flex-auto">
                    <p className="text-sm leading-relaxed h-full text-gray-dark text-base font-normal">
                      Report Summary:
                    </p>
                    <p className="flex items-center">
                      <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                      Total Records:{' '}
                      {props.RepairTemplate?.data?.processed.length +
                        props.RepairTemplate?.data?.errors.length}
                    </p>
                    <p className="flex items-center">
                      <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                      Processed Templates : {props.RepairTemplate?.data?.processed.length}
                    </p>
                    <p className="flex items-center">
                      <p className="w-3 h-3 mr-2 rounded-full bg-gray-dark"></p>
                      Errored Templates: {props.RepairTemplate?.data?.errors.length}
                    </p>
                  </div>

                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Process Details:</div>
                  </div>
                  <table className="table-fixed table-row-format relative border-collapse mx-10 my-2">
                    <thead className="bg-white  h-full ">
                      <tr className=" text-left  w-full px-7">
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Template name
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.RepairTemplate?.data?.processed.length > 0 ? (
                        props.RepairTemplate?.data?.processed.map((item: any) => (
                          <tr
                            role="button"
                            className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                          >
                            <td className="py-1 w-6/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                  {item.name}
                                </p>
                              </div>
                            </td>
                            <td className="py-1 w-6/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                  {item.message}
                                </p>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          role="button"
                          className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                        >
                          <td className="py-1 w-1/2 border-bottom">
                            <div className="flex">
                              <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                No Data Available
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  {/* errors table */}
                  <div className="flex items-start justify-between">
                    <div className="text-2xl px-10 pt-10 blue-primary">Error Details:</div>
                  </div>
                  <table className="table-fixed table-row-format relative border-collapse mx-10 my-2">
                    <thead className="bg-white  h-full ">
                      <tr className=" text-left  w-full px-7">
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Template name
                        </th>
                        <th className="px-2 py-2  w-6/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.RepairTemplate?.data?.errors.length > 0 ? (
                        props.RepairTemplate?.data?.errors.map((item: any) => (
                          <tr
                            role="button"
                            className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                          >
                            <td className="py-1 w-2/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                  {item.name}
                                </p>
                              </div>
                            </td>
                            <td className="py-1 w-6/12 border-bottom">
                              <div className="flex">
                                <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                  {item.message}
                                </p>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          role="button"
                          className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                        >
                          <td className="py-1 w-1/2 border-bottom">
                            <div className="flex">
                              <p className="text-gray-dark text-bold font-bold pr-2 text-base">
                                No Data Available
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="flex items-center justify-center pb-6">
                    <button
                      className="px-7 py-2 text-sm bg-gray-light text-white text-cente rounded-full"
                      type="button"
                      onClick={() => {
                        setenableRepairTemplate(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      }
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    comms: state.Comms.CommsList?.data || null,
    EmailTemplates: state.Client.emailTemplate?.data || null,
    SystemMessageGroups: state.Comms.SystemMessageGroups?.data || null,
    pending: state.Comms.pending || null,
    UpdateComms: state.Comms.UpdateComms || null,
    RemoveComms: state.Comms.RemoveComms || null,
    CreateComms: state.Comms.Comms || null,
    RepairTemplate: state.Comms.RepairTemplate || null,
  };
};

export default connect(mapStateToProps)(CommsTemplate);
