import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { SectionLabel, Spinner, ToggleButton, UseFormDropDown } from '../../components/common';
import useDebounce from '../../components/common/debounce';
import Header from '../../components/common/global-top-bar';
import { Headeroptions } from './member-constants';
import SwitchBar from '../../components/switch-bar';
import { fetchTopicsRequest, getMemberProfileRequest } from './members-redux/actions';
import { updateListenerDetailsRequest } from '../listeners/listeners-redux/actions';
import { fetchTosRequest } from '../clients/text-blocks/text-blocks-redux/actions';
import {
  fetchEmailAvailableRequest,
  fetchMobileValidRequest,
} from '../../components/account-login/login-redux/actions';
import { fetchClientsRequest } from '../clients/clients-redux/actions';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import HeadBack from './redirect';
import { paramsToObjects } from '../../components/common/create-params';
import { fetchTagsGroupListRequest } from '../tags-management/redux-tags/actions';

const accountStatus = [
  { status: 'enabled', label: 'Enabled' },
  { status: 'disabled', label: 'Disabled' },
  { status: 'under_review', label: 'Under Review' },
  { status: 'suspended', label: 'Suspended' },
  { status: 'banned', label: 'Banned' },
];

type memberProfileProps = {
  memberProfile: any;
  memberProfileError: any;
  topics: any;
  topicsError: any;
  appSettings: any;
  mobileValid: any;
  emailAvailable: any;
  clients: any;
  pending: boolean;
  tags: any;
};

const MembersProfile: React.FunctionComponent<memberProfileProps> = (props): JSX.Element => {
  const { memberProfile, mobileValid, emailAvailable, topics, appSettings, clients, pending } =
    props;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      if (props.memberProfile?.length) return props.memberProfile[0];
    }, [props]),
  });

  const history = useHistory();
  const formSubmit = useRef<any>(null);

  const [isCallerRoleStatus, setIsCallerRoleStatus] = useState<boolean>(false);
  const [isPaymentStatus, setIsPaymentStatus] = useState<boolean>(false);
  const [isTextCompatiblePhone, setIsTextCompatiblePhone] = useState<boolean>(true);
  const [genders, setGenders] = useState<any>();
  const [language, setLanguage] = useState<any>([]);
  const [timeZones, setTimeZones] = useState<any>([]);
  const [email, setEmail] = useState<any>(getValues('email_address'));
  const [packages, setPackages] = useState<any>([]);
  const [selectedPackage, setSelectedPackage] = useState<any>();
  const [topicsArray, setTopicsArray] = useState<any>();
  const [isSpinner, setIsSpinner] = useState<boolean>(true);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [isOnloadMobileRequest, setIsOnloadMobileRequest] = useState<boolean>(true);
  const [tags, setTags] = useState<any>({
    pronouns: [],
    race: [],
    genders: [],
  });
  const debouncedEmail = useDebounce(email, 500);
  const onSubmit = (data: any) => {
    const packageName = memberProfile?.length
      ? memberProfile[0]?.caller_role?.active_subscription?.package.name
      : null;
    const packageCode = selectedPackage?.name !== packageName ? selectedPackage?.code : null;
    const payload = {
      id: data.id,
      memberId: data.caller_role.id,
      packageCode: packageCode,
      payload: {
        ...data,
      },
    };
    payload.payload['pronoun'] = getValues('pronoun') === '' ? null : getValues('pronoun');
    dispatch(updateListenerDetailsRequest(payload));
  };

  const normalizeInput = (value: string) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    // if (cvLength > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    // }
  };

  const regexPhoneNumber = (data: any) => {
    if (data?.length > 0) {
      const phone = data?.replace('+1', '');
      setValue('mobile_phone', normalizeInput(phone));
    } else {
      setValue('mobile_phone', '');
    }
    if (data.length >= 12 && data.length <= 14) {
      const phone = data?.replace('+1', '');
      dispatch(fetchMobileValidRequest({ mobile_phone: phone }));
    }
  };

  const selectGender = (data: any) => {
    const currentState = genders[data];
    const anyGender = { ...genders, [data]: !currentState };
    const noGenderCheck = (item: any) => {
      return !item;
    };
    const nogender = Object.values(anyGender).every(noGenderCheck);
    if (!nogender) {
      setGenders(anyGender);
      setValue('caller_role.listener_preferences.genders', anyGender);
    }
  };

  const selectLanguage = (data: any) => {
    if (language.includes(data)) {
      const filteredArray = language.filter((item: string) => item !== data);
      if (filteredArray.length >= 1) {
        setLanguage(filteredArray);
        setValue('caller_role.listener_preferences.languages', filteredArray);
      } else {
        setLanguage(['english']);
        setValue('caller_role.listener_preferences.languages', ['english']);
      }
    } else {
      setLanguage((languages: any) => [...languages, data]);
      setValue('caller_role.listener_preferences.languages', [...language, data]);
    }
  };

  const setToggle = (status: any, id: any) => {
    const selectedTopics = topicsArray?.map((item: any) => {
      if (item.id === id) {
        return { ...item, status };
      } else {
        return item;
      }
    });
    setTopicsArray(selectedTopics);
    const topics = selectedTopics
      .filter((item: any) => {
        if (item.status) return item.id;

        return false;
      })
      .map((item: any) => item.id);
    setValue('caller_role.listener_preferences.topics', topics);
  };

  const handlePackageChange = (name: any) => {
    const find = packages?.length ? packages?.find((item: any) => item.name === name) : null;
    if (find) {
      setSelectedPackage(find);
    }
  };

  const handleContinueSave = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    dispatch(fetchEmailAvailableRequest({ email }));
  }, [debouncedEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (appSettings) {
      const app = appSettings?.data?.app;
      setTimeZones(app.supported_timezones);
    }
  }, [appSettings]);

  useEffect(() => {
    const getTags = (type: string) => {
      const { tags } = props?.tags?.find((tag: any) => tag.name === type);
      return tags.map((tag: any) => tag.name);
    };

    if (props?.tags?.length > 0) {
      const pronouns = getTags('Pronoun');
      const races = getTags('Race/Ethnicity');
      const genders = getTags('Gender');
      setTags({ pronouns, races, genders });
    }
  }, [props?.tags]);

  useEffect(() => {
    if (memberProfile?.length > 0 && topics?.length > 0 && clients?.length > 0) {
      // @ts-ignore
      reset(memberProfile[0]);
      const caller_role = memberProfile[0]?.caller_role;
      setIsCallerRoleStatus(caller_role.status === 'enabled' ? true : false);
      setIsPaymentStatus(memberProfile[0]?.stripe_payment_method_id !== null ? true : false);
      setIsTextCompatiblePhone(!!memberProfile[0]?.is_text_compatible_phone);

      const gender = caller_role?.listener_preferences?.genders;
      setGenders(gender);
      const find =
        clients?.length && caller_role?.active_subscription
          ? clients.find(
              (item: any) => item.name === caller_role?.active_subscription?.package?.client?.name
            )
          : null;
      if (find && find?.packages?.length) {
        setPackages(find.packages);
        setValue(
          'caller_role.active_subscription.package.name',
          caller_role.active_subscription.package.name
        );
      }
      const language =
        caller_role?.listener_preferences?.languages?.length > 0
          ? caller_role?.listener_preferences?.languages
          : ['english'];
      setLanguage(language);
      const phone = memberProfile[0]?.mobile_phone.replace('+1', '');
      if (isOnloadMobileRequest) {
        regexPhoneNumber(memberProfile[0]?.mobile_phone);
        setIsOnloadMobileRequest(false);
      } else {
        const phoneNumber = normalizeInput(phone);
        setValue('mobile_phone', phoneNumber);
      }

      const tagsArr: any[] = topics?.map((item: any) => {
        if (caller_role?.listener_preferences?.topics?.includes(item.id)) {
          return { ...item, status: true };
        }
        return { ...item, status: false };
      });
      setTopicsArray(tagsArr);
      setIsSpinner(false);
      setIsModified(false);
    }
  }, [memberProfile, topics, clients]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      const params = paramsToObjects();
      const callerRoleId = params['callerRoleId'];
      dispatch(getMemberProfileRequest({ memberId: callerRoleId }));
      dispatch(fetchTopicsRequest());
      dispatch(fetchTosRequest({ type: 'app' }));
      dispatch(fetchClientsRequest({ include_packages: true }));
      dispatch(fetchTagsGroupListRequest({ keys: 'PRONOUN,RACE/ETHNICITY,GENDER' }));
    } catch (error) {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {pending || isSpinner ? <Spinner /> : null}
      <RouteLeavingGuard
        when={isModified}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.MEMBERS_PROFILE) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={
          'You have unsaved changes. Do you want to leave this page and lose your changes?'
        }
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText="Continue and Save changes"
        handleContinueSaveChange={(path) => {
          formSubmit?.current.click();
          setIsModified(false);
          setTimeout(() => {
            handleContinueSave(path);
          }, 100);
        }}
      />
      <Header heading={<HeadBack memberName={getValues('first_name')} />} />
      <SwitchBar
        heading={Headeroptions}
        position={0}
        forwardParams
        optionalButton={
          <button
            id="form-submit"
            type="submit"
            form="member-profile-form"
            className={`${
              isModified ? 'stacked-bar-blue-400' : 'bg-gray-300 cursor-not-allowed'
            }  rounded-full w-1/12 text-center h-6 text-sm text-white px-3`}
            disabled={!isModified}
            ref={formSubmit}
          >
            SAVE
          </button>
        }
      />
      <form onSubmit={handleSubmit(onSubmit)} id="member-profile-form">
        <div className="max-window-height  overflow-y-auto flex justify-between ">
          <div className="w-1/2 ">
            <div className="flex justify-between px-7 py-4">
              <div className="w-1/2 px-2">
                <label className="input-label">User ID#</label>
                <input {...register('id')} className="input cursor-not-allowed" disabled />
              </div>
              <div className="w-1/2 px-2">
                <label className="input-label">Minutes Remaining</label>
                <label className="input-label font-extrabold">
                  {(memberProfile?.length &&
                    memberProfile !== null &&
                    memberProfile[0]?.user_subscription?.remaining_minutes) ||
                    0}
                </label>
              </div>
            </div>
            <div className="flex justify-between px-7 py-4">
              <div className="w-1/2 px-2">
                <label className="input-label">First Name</label>
                <input
                  {...register('first_name', {
                    onBlur: (e: any) => setValue('first_name', e.target.value.trim()),
                    onChange: (e: any) => setIsModified(true),
                  })}
                  className="input"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="input-label">Last Name</label>
                <input
                  {...register('last_name', {
                    onBlur: (e: any) => setValue('last_name', e.target.value.trim()),
                    onChange: (e: any) => setIsModified(true),
                  })}
                  className="input"
                />
              </div>
            </div>
            <div className="flex justify-between px-7 py-4">
              <div className="w-full px-2">
                <UseFormDropDown label={'Group'}>
                  <select
                    className="select-box w-full"
                    {...register('caller_role.active_subscription.package.client.name', {
                      onChange: (e) => {
                        const find = clients?.length
                          ? clients.find((item: any) => item.name === e.target.value)
                          : null;
                        if (find && find?.packages?.length) {
                          setPackages(find.packages);
                          setSelectedPackage(find.packages[0]);
                        } else {
                          setPackages('');
                          setSelectedPackage('');
                        }
                        setIsModified(true);
                      },
                    })}
                  >
                    <option key={'group'} value={''}>
                      Select group
                    </option>
                    {clients?.length > 0 &&
                      clients.map((item: any) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </UseFormDropDown>
              </div>
              <div className="w-full px-2">
                <UseFormDropDown label={'Plan'}>
                  <select
                    className="select-box w-full"
                    {...register('caller_role.active_subscription.package.name', {
                      onChange: (e) => {
                        handlePackageChange(e.target.value);
                        setIsModified(true);
                      },
                    })}
                  >
                    {packages?.length > 0 &&
                      packages.map((item: any) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </UseFormDropDown>
              </div>
            </div>
            {/* <div className="flex justify-between px-7 py-4">
                            <div className="w-full px-2">
                                <label className="input-label">Date Invited</label>
                                <input {...register('last_name')} className="input" />
                            </div>
                            <div className="w-full px-2">
                                <label className="input-label">Date Activated</label>
                                <input {...register('last_name')} className="input" />
                            </div>
                        </div> */}
            <div className="flex justify-between px-7 py-4">
              <div className="w-full px-2">
                <label className="input-label">Email</label>
                <input
                  {...register('email_address', {
                    required: 'Enter email address',
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: 'Enter valid email address',
                    },
                    validate: (value) => {
                      if (value !== memberProfile[0].email_address && emailAvailable === false)
                        return false;
                    },
                    onChange: (e) => {
                      setEmail(e.target.value);
                      setIsModified(true);
                    },
                    onBlur: (e: any) => setValue('email_address', e.target.value.trim()),
                  })}
                  className="input"
                />
                {errors.email_address?.message ? (
                  <label className="input-error">
                    {/* @ts-ignore */}
                    {errors.email_address?.message}
                  </label>
                ) : null}
                {errors.email_address &&
                  // @ts-ignore
                  errors.email_address.type === 'validate' && (
                    <div className="input-error">Email already used, use different email.</div>
                  )}
              </div>
            </div>
            <div className="flex justify-between px-7 py-4">
              <div className="w-1/2 px-2">
                <label className="input-label">Phone</label>
                <input
                  {...register('mobile_phone', {
                    required: 'Enter phone number',
                    minLength: {
                      value: 13,
                      message: 'Enter valid phone number',
                    },
                    validate: (value) => mobileValid,
                    onChange: (e) => {
                      regexPhoneNumber(e.target.value);
                      setIsModified(true);
                    },
                    onBlur: (e: any) => setValue('mobile_phone', e.target.value.trim()),
                  })}
                  className="input"
                />
                {errors.mobile_phone?.message ? (
                  <label className="input-error">{errors.mobile_phone?.message}</label>
                ) : (errors.mobile_phone &&
                    // @ts-ignore
                    errors?.mobile_phone?.type === 'validate') ||
                  !mobileValid ? (
                  <div className="input-error">Enter valid phone number</div>
                ) : null}
              </div>
              <div className="w-1/2 px-2">
                <UseFormDropDown label={'Time Zone'}>
                  <select
                    className="select-box w-full"
                    {...register('timezone', {
                      onChange: () => setIsModified(true),
                    })}
                  >
                    <option key={'timeZone'} value="">
                      Select time zone
                    </option>
                    {timeZones.length > 0 &&
                      timeZones.map((item: any) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </UseFormDropDown>
              </div>
            </div>
            <div className="flex justify-between px-7 py-4">
              <div className="w-full px-2">
                <div className="flex items-center">
                  <ToggleButton
                    id={'is_text_compatible_phone'}
                    status={isTextCompatiblePhone}
                    setStatus={(data) => {
                      setIsTextCompatiblePhone(data);
                      const status = data ? true : false;
                      setValue('is_text_compatible_phone', status);
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                  <label className="block leading-4 pr-4 text-gray-dark">
                    Receive SMS Text Comms
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-between px-7 py-4">
              <div className="w-1/2 px-2">
                <label className="input-label">DOB (Age)</label>
                <input
                  {...register('date_of_birth', {
                    onChange: () => setIsModified(true),
                  })}
                  type="date"
                  className="input"
                />
                <label className="input-error">{errors.date_of_birth?.message}</label>
              </div>
              <div className="w-1/2 px-2">
                <UseFormDropDown label={'Gender'}>
                  <select
                    className="select-box w-full"
                    {...register('gender', {
                      onChange: () => setIsModified(true),
                    })}
                  >
                    {tags.genders.length > 0 &&
                      tags.genders.map((item: string) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </UseFormDropDown>
              </div>
            </div>
            <div className="flex justify-between px-7 py-4">
              <div className="w-1/2 px-2">
                {tags.pronouns?.length && (
                  <UseFormDropDown label={'Pronouns'}>
                    <select
                      className="select-box w-full"
                      {...register('pronoun', {
                        onChange: () => setIsModified(true),
                      })}
                    >
                      <option key={'pronoun'} value={''}>
                        Select pronoun
                      </option>
                      {tags.pronouns?.map((item: any) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </UseFormDropDown>
                )}
              </div>
              <div className="w-1/2 px-2">
                <div>
                  <UseFormDropDown label={'Race'}>
                    <select
                      className="select-box w-full"
                      {...register('race', {
                        onChange: () => setIsModified(true),
                      })}
                    >
                      <option key={'race'} value="">
                        Select race
                      </option>
                      {tags.races?.length > 0 &&
                        tags?.races.map((item: any) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </UseFormDropDown>
                </div>
              </div>
            </div>

            <div className="px-7">
              <SectionLabel Label={'Internal Notes'} />
            </div>
            <div className="px-7 py-2">
              <textarea
                {...register('caller_role.notes', {
                  onBlur: (e: any) => setValue('caller_role.notes', e.target.value.trim()),
                  onChange: () => setIsModified(true),
                })}
                className="py-2 px-3  rounded-md  h-28 w-full text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
              />
            </div>

            <div className="flex justify-between px-7 py-4">
              <div className="w-full px-2"></div>
            </div>
          </div>
          <div className="w-1/2 ">
            <div className="  px-7 ">
              <SectionLabel Label={'Status'} />
            </div>
            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <div className="">
                  <UseFormDropDown label={'User Account'}>
                    <select
                      className="select-box w-full"
                      {...register('status', {
                        onChange: () => setIsModified(true),
                      })}
                    >
                      {accountStatus.length > 0 &&
                        accountStatus.map((item) => (
                          <option key={item.status} value={item.status}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </UseFormDropDown>
                </div>
              </div>
            </div>

            <div className="flex justify-between px-7 py-2">
              <div className="w-1/2 px-2">
                <div className="flex items-center">
                  <label className="block leading-4 pr-4 text-gray-dark"> Caller Role </label>
                  <ToggleButton
                    id={'CallerRole'}
                    status={isCallerRoleStatus}
                    setStatus={(data) => {
                      setIsCallerRoleStatus(data);
                      const status = data ? 'enabled' : 'disabled';
                      setValue('caller_role.status', status);
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
              <div className="w-1/2 px-2">
                <div className="flex items-center">
                  <label className="block leading-4 pr-4 text-gray-dark"> Payment Setup </label>
                  <ToggleButton
                    id={'Paymentsetup'}
                    status={isPaymentStatus}
                    setStatus={(data) => {}}
                    disabled={true}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
            </div>
            <div className="  px-7 ">
              <SectionLabel Label={'Filters'} />
            </div>
            <div className="w-full px-7 py-2">
              <label className="block leading-4 font-bold text-gray-dark mb-2"> Genders </label>
              <div className="w-full grid grid-cols-3 gap-2">
                <div className="flex">
                  <label className="leading-4 pr-4 text-gray-dark flex items-center">
                    {' '}
                    Female{' '}
                  </label>
                  <ToggleButton
                    id={'female'}
                    status={genders && genders['female']}
                    setStatus={(data) => {
                      selectGender('female');
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
                <div className="flex">
                  <label className="leading-4 pr-4 text-gray-dark flex items-center"> Male </label>
                  <ToggleButton
                    id={'male'}
                    status={genders && genders['male']}
                    setStatus={(data) => {
                      selectGender('male');
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
                <div className="flex">
                  <label className="leading-4 pr-4 text-gray-dark flex items-center"> Other </label>
                  <ToggleButton
                    id={'other'}
                    status={genders && genders['other']}
                    setStatus={(data) => {
                      selectGender('other');
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
            </div>
            <div className="w-full px-7 py-2">
              <label className="block leading-4  font-bold text-gray-dark mb-2">
                {' '}
                Preferred Language{' '}
              </label>
              <div className="w-full grid grid-cols-2 gap-2">
                <div className="flex">
                  <label className="leading-4 pr-4 text-gray-dark flex items-center">
                    {' '}
                    English{' '}
                  </label>
                  <ToggleButton
                    id={'english'}
                    status={language && language.includes('english')}
                    setStatus={(data) => {
                      selectLanguage('english');
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
                <div className="flex">
                  <label className="leading-4 pr-4 text-gray-dark flex items-center">
                    {' '}
                    Spanish{' '}
                  </label>
                  <ToggleButton
                    id={'spanish'}
                    status={language && language.includes('spanish')}
                    setStatus={(data) => {
                      selectLanguage('spanish');
                      setIsModified(true);
                    }}
                    initialstatus={true}
                  ></ToggleButton>
                </div>
              </div>
            </div>

            <div className="  px-7 ">
              <SectionLabel Label={'Topics'} />
            </div>
            <div className="px-7 py-2 pb-20">
              <div className="grid grid-cols-2 gap-x-1 gap-y-px auto-rows-max">
                {topicsArray?.map((item: any) => {
                  return (
                    <div className="gap-y-0">
                      <button
                        className={
                          'h-8 w-full px-2 py-2 my-1 mx-1  text-white text-center flex items-center ' +
                          (item?.status ? ' bg-gray-light font-bold' : ' bg-gray-300 ')
                        }
                        onClick={() => {
                          setToggle(!item?.status, item?.id);
                          setIsModified(true);
                        }}
                        type="button"
                      >
                        {item?.name}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    memberProfile: state.Members?.memberProfile?.data || null,
    memberProfileError: state.Members?.memberProfileError,
    topics: state.Members?.topics?.data || null,
    topicsError: state.Members?.topicsError,
    appSettings: state.Tos.clientAdmin?.data || null,
    mobileValid: state.login.mobileValid?.mobileValid || false,
    emailAvailable: state.login.emailAvailable?.data,
    clients: state.Client.clients?.data,
    tags: state.tags.TagsGroupList?.data || null,
    pending:
      state.Members?.pending ||
      state.Client.pending ||
      state.Tos.pending ||
      state.Listeners?.pending,
  };
};

export default connect(mapStateToProps)(MembersProfile);
