import React from 'react';

type props = {
  status: any;
  initialstatus?: boolean;
  id: string;
  disabled?: boolean;
  setStatus: (data: any) => void;
};

const ToggleButton: React.FunctionComponent<props> = ({
  status,
  setStatus,
  id,
  disabled,
}): JSX.Element => {
  const randomid = Math.random().toString();

  return (
    <div>
      <div className="grid grid-cols-1 gap-0 ">
        <div className="relative inline-block w-16 mr-2 align-middle select-none transition duration-400 ease-in">
          <input
            type="checkbox"
            id={randomid}
            className={
              ' absolute block  rounded-full  appearance-none cursor-pointer ' +
              (!status
                ? 'w-8 h-8 border-gray-100 bg-white border-4 '
                : ' w-6 h-6 toggle-button stacked-bar-green-primary ') +
              'toggle-checkbox '
            }
            checked={status}
            onChange={(e) => !disabled && setStatus(e.target.checked)}
          />
          <label
            htmlFor={randomid}
            className={
              ' block overflow-hidden h-8 rounded-full cursor-pointer ' +
              (!status ? ' bg-gray-100 ' : ' toggle-back-ground ') +
              (disabled && ' cursor-not-allowed ')
            }
          >
            <span
              className={
                (status ? ' float-left ' : ' float-right ') +
                (disabled && ' cursor-not-allowed ') +
                ' px-2 py-1 font-extrabold '
              }
            ></span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
