import {
  POST_TIERS_REQUEST,
  POST_TIERS_FAILURE,
  POST_TIERS_SUCCESS,
  FETCH_TIERS_LIST_REQUEST,
  FETCH_TIERS_LIST_FAILURE,
  FETCH_TIERS_LIST_SUCCESS,
  UPDATE_TIERS_REQUEST,
  UPDATE_TIERS_FAILURE,
  UPDATE_TIERS_SUCCESS,
  REMOVE_TIERS_REQUEST,
  REMOVE_TIERS_FAILURE,
  REMOVE_TIERS_SUCCESS,
  POST_TEST_TIERS_REQUEST,
  POST_TEST_TIERS_FAILURE,
  POST_TEST_TIERS_SUCCESS,
  FETCH_TIER_REQUEST,
  FETCH_TIER_FAILURE,
  FETCH_TIER_SUCCESS,
} from './action-types';

import {
  PostTestTiersRequest,
  PostTestTiersSuccess,
  PostTestTiersSuccessPayload,
  PostTestTiersFailure,
  PostTestTiersFailurePayload,
  PostTiersRequest,
  PostTiersSuccess,
  PostTiersSuccessPayload,
  PostTiersFailure,
  PostTiersFailurePayload,
  FetchTiersListRequest,
  FetchTiersListSuccess,
  FetchTiersListSuccessPayload,
  FetchTiersListFailure,
  FetchTiersListFailurePayload,
  UpdateTiersRequest,
  UpdateTiersSuccess,
  UpdateTiersSuccessPayload,
  UpdateTiersFailure,
  UpdateTiersFailurePayload,
  RemoveTiersRequest,
  RemoveTiersSuccess,
  RemoveTiersSuccessPayload,
  RemoveTiersFailure,
  RemoveTiersFailurePayload,
  FetchTierRequest,
  FetchTierSuccess,
  FetchTierSuccessPayload,
  FetchTierFailure,
  FetchTierFailurePayload,
} from './types';

export const postTiersRequest = (payload: any): PostTiersRequest => ({
  type: POST_TIERS_REQUEST,
  payload,
});

export const postTiersSuccess = (payload: PostTiersSuccessPayload): PostTiersSuccess => ({
  type: POST_TIERS_SUCCESS,
  payload,
});

export const postTiersFailure = (payload: PostTiersFailurePayload): PostTiersFailure => ({
  type: POST_TIERS_FAILURE,
  payload,
});

export const fetchTiersListRequest = (payload: any): FetchTiersListRequest => ({
  type: FETCH_TIERS_LIST_REQUEST,
  payload,
});

export const fetchTiersListSuccess = (
  payload: FetchTiersListSuccessPayload
): FetchTiersListSuccess => ({
  type: FETCH_TIERS_LIST_SUCCESS,
  payload,
});

export const fetchTiersListFailure = (
  payload: FetchTiersListFailurePayload
): FetchTiersListFailure => ({
  type: FETCH_TIERS_LIST_FAILURE,
  payload,
});

export const updateTiersRequest = (payload: any): UpdateTiersRequest => ({
  type: UPDATE_TIERS_REQUEST,
  payload,
});

export const updateTiersSuccess = (payload: UpdateTiersSuccessPayload): UpdateTiersSuccess => ({
  type: UPDATE_TIERS_SUCCESS,
  payload,
});

export const updateTiersFailure = (payload: UpdateTiersFailurePayload): UpdateTiersFailure => ({
  type: UPDATE_TIERS_FAILURE,
  payload,
});

export const removeTiersRequest = (payload: any): RemoveTiersRequest => ({
  type: REMOVE_TIERS_REQUEST,
  payload,
});

export const removeTiersSuccess = (payload: RemoveTiersSuccessPayload): RemoveTiersSuccess => ({
  type: REMOVE_TIERS_SUCCESS,
  payload,
});

export const removeTiersFailure = (payload: RemoveTiersFailurePayload): RemoveTiersFailure => ({
  type: REMOVE_TIERS_FAILURE,
  payload,
});

export const postTestTiersRequest = (payload: any): PostTestTiersRequest => ({
  type: POST_TEST_TIERS_REQUEST,
  payload,
});

export const postTestTiersSuccess = (
  payload: PostTestTiersSuccessPayload
): PostTestTiersSuccess => ({
  type: POST_TEST_TIERS_SUCCESS,
  payload,
});

export const postTestTiersFailure = (
  payload: PostTestTiersFailurePayload
): PostTestTiersFailure => ({
  type: POST_TEST_TIERS_FAILURE,
  payload,
});

export const fetchTierRequest = (payload: any): FetchTierRequest => ({
  type: FETCH_TIER_REQUEST,
  payload,
});

export const fetchTierSuccess = (payload: FetchTierSuccessPayload): FetchTierSuccess => ({
  type: FETCH_TIER_SUCCESS,
  payload,
});

export const fetchTierFailure = (payload: FetchTierFailurePayload): FetchTierFailure => ({
  type: FETCH_TIER_FAILURE,
  payload,
});
