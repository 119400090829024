import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPut, axiosPost, axiosDelete } from '../api/axios';

export const resourceQueryKeys = {
  clientResources: ['clientResources'] as const,
  resource: ['resource'] as const,
};

export interface ClientResource {
  sort_weight: number;
  image_url: string;
  phone_number: string;
  identifier: string;
  url_description: any;
  status: string;
  description: string;
  long_description: string;
  url: string;
  notes: string;
  client_id: number;
  name: string;
  id?: number;
  text_number: any;
}

export interface Client {
  name: string;
  use_global_resources: boolean;
}

const getClientResources = async (
  clientId: number,
  omitGlobal: boolean,
  includeHidden: boolean
): Promise<ClientResource[]> => {
  const data: any = {};
  if (omitGlobal) {
    data['omit_global'] = true;
  }
  if (includeHidden) {
    data['include_hidden'] = true;
  }
  return await axiosGet(
    `/clients/${clientId}/resources`,
    {
      ...data,
    },
    'v2'
  ).then((resourceResponse) => resourceResponse.data);
};

const getClient = async (clientId: number): Promise<Client> => {
  return await axiosGet(`/clients/${clientId}`, null, 'v2').then(
    (clientResponse) => clientResponse.data
  );
};

const updateClientRequest = async (clientId: number, data: any): Promise<Client> => {
  return await axiosPut(`/clients/${clientId}`, data, 'v2').then(
    (response: { data: any }) => response.data
  );
};

const getClientResource = async (resourceId: number): Promise<ClientResource> => {
  return await axiosGet(`/resources/${resourceId}`, null, 'v2').then(
    (resourceResponse) => resourceResponse.data
  );
};

const updateResourceRequest = async (resourceId: number, data: any): Promise<ClientResource> => {
  return await axiosPut(`/resources/${resourceId}`, data, 'v2').then(
    (response: { data: any }) => response.data
  );
};

const addResourceRequest = async (data: any): Promise<ClientResource> => {
  return await axiosPost(`/resources/`, data, 'v2').then(
    (response: { data: any }) => response.data
  );
};

const removeResourceRequest = async (resourceId: number): Promise<ClientResource> => {
  return await axiosDelete(`/resources/${resourceId}`, null, 'v2').then(
    (response: { data: any }) => response.data
  );
};

export const useClientResources = (
  clientId: number,
  omitGlobal: boolean,
  includeHidden: boolean
) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ClientResource[]>(
    ['clientResources', clientId, omitGlobal, includeHidden],
    () => getClientResources(clientId, omitGlobal, includeHidden)
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useClient = (clientId: number) => {
  const { data, isLoading, error, refetch } = useQuery<Client>(
    [resourceQueryKeys.clientResources],
    () => getClient(clientId)
  );
  return { data, isLoading, error, refetch };
};

export const useUpdateClient = (clientId: number) => {
  const queryClient = useQueryClient();
  const updateClient = useMutation((data: any) => updateClientRequest(clientId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
      console.log(error);
    },
    onMutate: (data) => {
      queryClient.setQueryData(resourceQueryKeys.clientResources, (oldData: any) => {
        if (oldData) {
          return { ...oldData, ...data };
        }
        return oldData;
      });
    },
  });

  return {
    updateClient,
  };
}

export const useClientResource = (resourceId: number) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<ClientResource>(
    resourceQueryKeys.resource,
    () => getClientResource(resourceId)
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useUpdateResource = (resourceId: number) => {
  const queryClient = useQueryClient();
  const updateResource = useMutation((data: any) => updateResourceRequest(resourceId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
      console.log(error);
    },
    onMutate: (data) => {
      queryClient.setQueryData(resourceQueryKeys.clientResources, (oldData: any) => {
        if (oldData) {
          return oldData.map((resource: ClientResource) => {
            if (resource.id === resourceId) {
              return { ...resource, ...data };
            }
            return resource;
          });
        }
        return oldData;
      });
    },
  });

  return {
    updateResource,
  };
};

export const useAddResource = () => {
  const queryClient = useQueryClient();
  const addResource = useMutation((data: any) => addResourceRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
      console.log(error);
    },
    onMutate: (data) => {
      queryClient.setQueryData(resourceQueryKeys.clientResources, (oldData: any) => {
        if (oldData) {
          return [...oldData, data];
        }
        return oldData;
      });
    },
  });

  return {
    addResource,
  };
};

export const useRemoveResource = (resourceId: number) => {
  const queryClient = useQueryClient();
  const removeResource = useMutation(() => removeResourceRequest(resourceId), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.clientResources);
      console.log(error);
    },
    onMutate: () => {
      queryClient.setQueryData(resourceQueryKeys.clientResources, (oldData: any) => {
        if (oldData) {
          return oldData.filter((resource: ClientResource) => resource.id !== resourceId);
        }
        return oldData;
      });
    },
  });

  return {
    removeResource,
  };
};
