import React, { useEffect, useMemo, useRef } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { updateSettiingsRequest } from './redux-tags/actions';

import { fetchAppSettingsRequest } from '../clients/clients-redux/actions';
import { useForm } from 'react-hook-form';
import { CustomInput } from '../../components/common';
import { numberonBlur, numberOnChange } from '../../components/common/useForm';

export const blackListType = {
  'Phone Number': 'mobile_phone',
  'Email Address': 'email_address',
  'IP Address': 'ip_address',
};

type props = {
  appSettings: any;
  pending: any;
};

type state = {
  showModal: boolean;
  resourceId: number;
  name: string;
};

const defaultState = {
  showModal: false,
  resourceId: 0,
  name: '',
};

const LOCVARIABLES = [
  'LOC_CALLER_CALL_START_DEADMAN_TIMER',
  'LOC_CALLER_MOOD_COLLECTION_REMINDER_TIMER',
  'LOC_CALL_CENTS_PER_MINUTE',
  'LOC_CALL_LENGTH_SETTLE_THRESHOLD',
  'LOC_DEADMAN_TIMER',
  'LOC_DEFAULT_CALL_DURATION',
  'LOC_EXPERIENCE_PAYOUT_THRESHOLD',
  'LOC_FORCE_WEB_APP',
  'LOC_LISTENER_BACKGROUND_CHECK_FEE',
  'LOC_LISTENER_BACKGROUND_CHECK_REWARD',
  'LOC_LISTENER_CALL_START_DEADMAN_TIMER',
  'LOC_LISTENER_CALL_START_IVR_COUNT',
  'LOC_LISTENER_CALL_START_IVR_TIMEOUT',
  'LOC_LISTENER_DISCOVERY_MAX_DISPLAY',
  'LOC_LISTENER_INITIAL_CALLOUT_DELAY',
  'LOC_LISTENER_REWARD_CALL_REQUIREMENT',
  'LOC_LISTENER_ROLE_MAXIMUM_COUNT',
  'LOC_MINIMUM_LISTENER_EXPERIENCES',
  'LOC_MINIMUM_PEER_EXPERIENCES',
  'LOC_UNLIMITED_CREDIT_RESERVE_AMOUNT',
  'LOC_WEB_APP_URL',
];

const STRING_INPUTS = ['LOC_WEB_APP_URL'];

type IndividualSetting = {
  value: string;
  name: string;
  onSubmit: (data: any) => void;
};

const IndividualSetting: React.FunctionComponent<IndividualSetting> = ({
  value,
  name,
  onSubmit,
}): JSX.Element => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (value) {
        return { [name]: value };
      }
    }, [value, name]),
  });

  const cancelRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  useEffect(() => {
    value && reset({ [name]: value });
  }, [value, reset, name]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between my-5 mx-3">
        <p>{name}</p>
        <div className="flex">
          <CustomInput
            inputType="text"
            Label={''}
            register={{
              ...register(name, {
                onChange: (e) => {
                  if (!STRING_INPUTS.includes(name)) {
                    numberOnChange(e, name, setValue);
                  }
                  cancelRef.current.classList.remove('bg-gray-background-dark');
                  cancelRef.current.classList.add('bg-red');
                },
                onBlur: (e) => {
                  if (!STRING_INPUTS.includes(name)) {
                    numberonBlur(e, name, setValue);
                  }
                },
                valueAsNumber: !STRING_INPUTS.includes(name),
              }),
            }}
            //@ts-ignore
            Error={!!errors[name]?.message?.length > 0}
            //@ts-ignore
            ErrorMessage={errors[name]?.message}
          />

          <div className="flex justify-end">
            {' '}
            <button
              className=" rounded-full w-20 text-center h-6 text-sm text-white bg-bright-blue px-2 mx-2"
              type="submit"
              onClick={() => {
                cancelRef.current.classList.remove('bg-red');
                cancelRef.current.classList.add('bg-gray-background-dark');
              }}
              disabled={false}
            >
              Save
            </button>
            <button
              type="button"
              ref={cancelRef}
              className=" rounded-full w-20 text-center h-6 text-sm text-white bg-gray-background-dark px-2 mx-2"
              onClick={() => {
                reset({ [name]: value });
                cancelRef.current.classList.remove('bg-red');
                cancelRef.current.classList.add('bg-gray-background-dark');
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const Settings: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppSettingsRequest({ type: 'email' }));
  }, []);

  const onSubmit = (data: any) => {
    const payload = {
      data: {
        message: props.appSettings?.message,
        system: { ...props.appSettings?.system, ...data },
      },
    };
    dispatch(updateSettiingsRequest(payload));
  };

  return (
    <div>
      <Header heading={'Settings'} />
      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-7">
          {props.pending && <Spinner />}
          <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
            <div className=" font-bold col-span-5">
              <p className="pl-10 capitalize">Name</p>
            </div>
            <div className=" col-span-7 ">Type </div>
          </div>
          <div className="w-full  overflow-y-scroll">
            {props.appSettings?.system ? (
              LOCVARIABLES?.map((variable: string) => (
                <IndividualSetting
                  key={variable}
                  name={variable}
                  value={props.appSettings?.system[variable]}
                  onSubmit={onSubmit}
                />
              ))
            ) : (
              <p className="text-xl font-normal blue-primary  py-2 ml-3 capitalize">No Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    appSettings: state.Client.AppSettings?.data?.data || null,
    pending: state.Client?.pending || null,
  };
};

export default connect(mapStateToProps)(Settings);
