import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPut } from '../api/axios';

export interface User {
    administrator_role?: AdministratorRole
    spirituality?: string
    street_address_2: string
    id: number
    proposed_display_name: any
    client_administrator_role_id: any
    api_key: string
    how_did_you_hear: string
    date_of_birth: string
    display_name_rejected: boolean
    needs_password: boolean
    user_subscription: any
    first_name: string
    mobile_phone_verification_started_at: any
    mobile_phone_verification_token: any
    test_user: boolean
    zip_code: string
    mobile_phone: string
    listener_role: any
    caller_role?: CallerRole
    languages: any[]
    state?: string
    client_administrator_role: any
    relationship_type: string
    pronoun?: string
    relationship?: string
    status: string
    family?: string
    mobile_phone_verified: boolean
    email_address: string
    military_role: any
    counseling_configuration: any
    street_address_1: string
    military_branch: any
    password_reset_token?: string
    created_at: string
    last_active_at: string
    access_role?: string
    is_partial: boolean
    city: string
    administrator_role_id?: number
    password_reset_token_created_at?: string
    dialcare_processing_date: any
    current_app_version: any
    timezone: string
    last_name: string
    gender?: string
    caller_role_id?: number
    listener_role_id: any
    is_text_compatible_phone: boolean
    race?: string
    display_name: string
    client_name?: string
    client_id?: number
  }
  
  export interface AdministratorRole {
    type: string
  }
  
  export interface CallerRole {
    type: string
    notifications_resources: boolean
    notifications_listener_online: boolean
    id: number
    stripe_payment_failed: boolean
    tag_ids: any
    stripe_payment_failure_code: any
    notifications_incoming_call: boolean
    tag_group_ids: any[]
    total_available_credit: number
    listener_preferences: ListenerPreferences
    notifications_check_ins: boolean
    created_at: string
    stripe_customer_id: any
    stripe_payment_method_id: any
    status: string
  }
  
  export interface ListenerPreferences {
    topics: number[]
    genders: Genders
    age_range: AgeRange
    topic_tags: number[]
    preferred_language: string
  }
  
  export interface Genders {
    male: boolean
    other: boolean
    female: boolean
  }
  
  export interface AgeRange {
    to: number
    from: number
  }

const getUsers = async (page: number, search: string): Promise<User[]> => {
    return await axiosGet(`/users/?limit=10&page=${page}&search=${search}`, null, 'v2').then((userResponse) => userResponse.data);
};

const getUser = async (userId: number): Promise<User> => {
    return await axiosGet(`/users/${userId}`, null, 'v2').then((userResponse) => userResponse.data);
};

const updateUserRequest = async (userId: number, data: any): Promise<User> => {
    return await axiosPut(`/users/${userId}`, data, 'v2').then((response: { data: any; }) => response.data);
};



export const useUsers = ({page, search}: {page: number; search: string;}) => {
    const { data, isLoading, error, refetch, isFetching } = useQuery<User[]>(['Users', page], () => getUsers(page, search));
    
    return {
        data,
        isLoading,
        error,
        refetch,
        isFetching,
    };
};

export const useUser = (userId: number) => {
    const { data, isLoading, error, refetch } = useQuery<User>(['user', userId], () => getUser(userId));

    return {
      data,
      isLoading,
      error,
      refetch,
  };
};

export const useUpdateUser = (userId: number) => {
    const queryClient = useQueryClient();
    const updateUser = useMutation(
        (data: any) => updateUserRequest(userId, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
            },
            onError: (error) => {
                queryClient.invalidateQueries(['Users']);
                console.log(error);
            },
            onMutate: (data) => {
                queryClient.setQueryData(['Users'], (oldData: any) => {
                    if (oldData) {
                        return oldData.map((user: User) => {
                            if (user.id === userId) {
                                return { ...user, ...data };
                            }
                            return user;
                        });
                    }
                    return oldData;
                }
        )}  
        }
    );

    return {
        updateUser,
    };
};


