import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  fetchAuthenticateWithTokenRequest,
  fetchDeepLinkDataSuccess,
  fetchLoginRequest,
  fetchUserDetailsRequest,
  postRequestPasswordResetRequest,
} from './login-redux/actions';
import { ROUTE_PATH } from '../../routes/paths';
import { CustomInput, Spinner } from '../common';
import { LOCLogo } from '../../assets/images';
import { toast } from 'react-toastify';
import { Checkbox } from '@kindlyhuman/component-library';

type props = {
  login: any;
  pending: boolean;
  userDetails: any;
  authenticateWithToken: any;
  authenticateWithTokenError: any;
};

declare global {
  interface Window {
    branch: any;
  }
}
const LoginDetails: React.FunctionComponent<props> = ({
  login,
  pending,
  userDetails,
  authenticateWithToken,
  authenticateWithTokenError,
}): JSX.Element => {
  const [userName, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [trusted, setTrusted] = useState<boolean>(false);
  const [formError, setFormError] = useState<any>();
  const [loader, setloader] = useState(true);

  const history = useHistory();
  const authorizationtoken = localStorage.getItem('authorization_token');
  const roleType = localStorage.getItem('role_type');

  const dispatch = useDispatch();

  const validate = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (userName === '' || userName === undefined || !emailRegex.test(userName)) {
      return setFormError({ userName: 'Enter valid email address' });
    } else if (password === '' || password === undefined || password.length < 8) {
      if (password !== undefined && password === '') {
        return setFormError({ password: 'Enter password' });
      } else if (password !== undefined && password.length < 8) {
        return setFormError({ password: 'Enter min. 8 characters' });
      }
    } else {
      setFormError({});
    }
    const data = {
      email_address: userName,
      password: password,
      trusted: trusted,
    };

    dispatch(fetchLoginRequest(data));
  };

  const handelForgotPassword = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (userName === '' || userName === undefined || !emailRegex.test(userName)) {
      return setFormError({ userName: 'Enter valid email address' });
    }
    setFormError({});
    dispatch(
      postRequestPasswordResetRequest({
        email_address: userName,
        from_location: 'switchboard',
      })
    );
  };

  useEffect(() => {
    window.addEventListener('load', () => {
      var options = { no_journeys: true };
      window?.branch?.init(
        process.env.REACT_APP_BRANCH_KEY,
        options,
        function (err: string, data: any) {
          setloader(false);
          const action = data.data_parsed.$deeplink_action || null;
          if (action) localStorage.setItem('branchAction', action);
          if (action === 'complete_password_forgotten') {
            localStorage.setItem('resetToken', data.data_parsed.$password_reset_token);
            history.push('/reset-password');
          } else if (data.data_parsed.$user_token) {
            localStorage.setItem('deeplinkData', data.data);
            dispatch(fetchDeepLinkDataSuccess(data.data_parsed));
            dispatch(
              fetchAuthenticateWithTokenRequest({
                token: data.data_parsed.$user_token,
                userId: data.data_parsed.$user_id,
              })
            );
            // history.push(ROUTE_PATH.ONBOARDING);
          } else if (roleType === 'administrator' && authorizationtoken) {
            history.push(ROUTE_PATH.DASHBOARD);
          } else if (roleType === 'client_administrator' && authorizationtoken) {
            history.push(ROUTE_PATH.SNAPSHOT);
          } else if (roleType === 'invited' && authorizationtoken) {
            history.push(ROUTE_PATH.ONBOARDING);
          } else if (!authorizationtoken && !authenticateWithTokenError) {
            history.push('/');
          }
        }
      );
    });

    setTimeout(() => {
      setloader(false);
    }, 1000);
    // }, 100);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (roleType === 'administrator' && authorizationtoken) {
      history.push(ROUTE_PATH.DASHBOARD);
    } else if (roleType === 'client_administrator' && authorizationtoken) {
      history.push(ROUTE_PATH.SNAPSHOT);
    } else if (roleType === 'invited' && authorizationtoken) {
      history.push(ROUTE_PATH.ONBOARDING);
    } else if (roleType === 'null' && login) {
      history.push('/notify-message');
    }
  }, [login, authorizationtoken, roleType, userDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenticateWithToken?.data) {
      const userData = authenticateWithToken?.data;
      if (
        userData.client_administrator_role.status === 'enabled' &&
        userData.client_administrator_role.type === 'client_administrator'
      ) {
        history.push(ROUTE_PATH.SNAPSHOT);
      } else if (
        userData.client_administrator_role.status === 'invited' &&
        userData.client_administrator_role.type === 'client_administrator'
      ) {
        dispatch(fetchUserDetailsRequest({}));
        history.push(ROUTE_PATH.ONBOARDING);
      }
    }
  }, [authenticateWithToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenticateWithTokenError?.status === 404) {
      history.push(ROUTE_PATH.REQUESTNEWINVITE);
    } else if (authenticateWithTokenError?.status === 400) {
      history.push(ROUTE_PATH.INVITATION_COMPLETED);
    }
  }, [authenticateWithTokenError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      onKeyPress={(e: any) => {
        if (e.key === 'Enter') {
          validate();
        }
      }}
      tabIndex={0}
    >
      <div className="flex h-screen bg-gray-100">
        {pending || loader ? (
          <Spinner />
        ) : (
          <div className="w-98 m-auto bg-white p-10">
            <div className="flex justify-center items-center">
              <img className="w-4/5" src={LOCLogo} alt="loc-logo" />
            </div>
            <div className="grid grid-cols-1 gap-5 mt-8">
              <CustomInput
                inputType={'text'}
                value={userName}
                setValue={setUsername}
                Label={'Username'}
                Error={formError?.userName}
                ErrorMessage={formError?.userName}
                labelClass="font-normal"
              />
              <CustomInput
                inputType={'password'}
                value={password}
                setValue={setPassword}
                Label={'Password'}
                Error={formError?.password}
                ErrorMessage={formError?.password}
                labelClass="font-normal"
              />
              <div className="flex gap-x-4 pt-4 pl-2">
                <Checkbox
                  variant="DEFAULT"
                  defaultChecked={trusted}
                  checked={trusted}
                  onChange={() => setTrusted((prev) => !prev)}
                />
                <div className="text-gray-900 text-base">Trust this device</div>
              </div>
              <button
                type="button"
                className="bg-bright-blue w-full h-11.5 rounded-full text-white font-bold tracking-wide"
                onClick={validate}
              >
                Submit
              </button>
              <div
                className="w-full text-center blue-primary underline font-bold"
                role="button"
                onClick={handelForgotPassword}
              >
                Forgot Password
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    login: state.login?.loginDetails?.data || null,
    pending: state.login.pending,
    userDetails: state.login.UserDetails,
    authenticateWithToken: state.login?.authenticateWithToken,
    authenticateWithTokenError: state.login.authenticateWithTokenError,
  };
};

export default connect(mapStateToProps)(LoginDetails);
