import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_FAILURE,
  FETCH_LOGIN_SUCCESS,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_BY_ID_REQUEST,
  FETCH_USER_DETAILS_BY_ID_FAILURE,
  FETCH_USER_DETAILS_BY_ID_SUCCESS,
  POST_REQUEST_PASSWORD_REQUEST,
  POST_REQUEST_PASSWORD_SUCCESS,
  POST_REQUEST_PASSWORD_FAILURE,
  POST_REDEEM_PASSWORD_RESET_REQUEST,
  POST_REDEEM_PASSWORD_RESET_SUCCESS,
  POST_REDEEM_PASSWORD_RESET_FAILURE,
  FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST,
  FETCH_AUTHENTICATE_WITH_TOKEN_SUCCESS,
  FETCH_AUTHENTICATE_WITH_TOKEN_FAILURE,
  FETCH_SAVE_DEEP_LINK_DATA_REQUEST,
  FETCH_SAVE_DEEP_LINK_DATA_SUCCESS,
  FETCH_SAVE_DEEP_LINK_DATA_FAILURE,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_FAILURE,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_SUCCESS,
  POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_SUCCESS,
  POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_FAILURE,
  FETCH_EMAIL_AVAILABLE_REQUEST,
  FETCH_EMAIL_AVAILABLE_SUCCESS,
  FETCH_EMAIL_AVAILABLE_FAILURE,
  FETCH_MOBILE_VALID_REQUEST,
  FETCH_MOBILE_VALID_SUCCESS,
  FETCH_MOBILE_VALID_FAILURE,
} from './actionTypes';
import {
  FetchLoginRequest,
  FetchLoginSuccess,
  FetchLoginSuccessPayload,
  FetchLoginFailure,
  FetchLoginFailurePayload,
  FetchUserDetailsRequest,
  FetchUserDetailsSuccess,
  FetchUserDetailsSuccessPayload,
  FetchUserDetailsFailure,
  FetchUserDetailsFailurePayload,
  FetchUserDetailsByIdRequest,
  FetchUserDetailsByIdSuccess,
  FetchUserDetailsByIdFailure,
  FetchUserDetailsByIdFailurePayload,
  PostRequestPasswordResetRequest,
  PostRequestPasswordResetSuccess,
  PostRequestPasswordResetFailure,
  PostRequestPasswordResetSuccessPayload,
  PostRequestPasswordResetFailurePayload,
  PostRedeemPasswordResetRequest,
  PostRedeemPasswordResetSuccess,
  PostRedeemPasswordResetFailure,
  PostRedeemPasswordResetSuccessPayload,
  PostRedeemPasswordResetFailurePayload,
  FetchAuthenticateWithTokenRequest,
  FetchAuthenticateWithTokenSuccessPayload,
  FetchAuthenticateWithTokenSuccess,
  FetchOnBoardingFailurePayload,
  FetchAuthenticateWithTokenFailure,
  FetchDeepLinkDataRequest,
  FetchDeepLinkDataSuccessPayload,
  FetchDeepLinkDataSuccess,
  FetchDeepLinkDataFailurePayload,
  FetchDeepLinkDataFailure,
  PostResendSwitchboardOnboardingLinkFailurePayload,
  PostResendSwitchboardOnboardingLinkFailure,
  PostResendSwitchboardOnboardingLinkSuccessPayload,
  PostResendSwitchboardOnboardingLinkSuccess,
  PostResendSwitchboardOnboardingLinkRequest,
  PostClinetAdminOnboardCompleteFailure,
  PostClinetAdminOnboardCompleteFailurePayload,
  PostClinetAdminOnboardCompleteSuccessPayload,
  PostClinetAdminOnboardCompleteSuccess,
  PostClinetAdminOnboardCompleteRequest,
} from './types';

export const fetchLoginRequest = (payload: any): FetchLoginRequest => ({
  type: FETCH_LOGIN_REQUEST,
  payload,
});

export const fetchLoginSuccess = (payload: FetchLoginSuccessPayload): FetchLoginSuccess => ({
  type: FETCH_LOGIN_SUCCESS,
  payload,
});

export const fetchLoginFailure = (payload: FetchLoginFailurePayload): FetchLoginFailure => ({
  type: FETCH_LOGIN_FAILURE,
  payload,
});

export const fetchUserDetailsRequest = (payload: any): FetchUserDetailsRequest => ({
  type: FETCH_USER_DETAILS_REQUEST,
  payload,
});

export const fetchUserDetailsSuccess = (
  payload: FetchUserDetailsSuccessPayload
): FetchUserDetailsSuccess => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload,
});

export const fetchUserDetailsFailure = (
  payload: FetchUserDetailsFailurePayload
): FetchUserDetailsFailure => ({
  type: FETCH_USER_DETAILS_FAILURE,
  payload,
});

export const fetchUserDetailsByIdRequest = (payload: any): FetchUserDetailsByIdRequest => ({
  type: FETCH_USER_DETAILS_BY_ID_REQUEST,
  payload,
});

export const fetchUserDetailsByIdSuccess = (payload: any): FetchUserDetailsByIdSuccess => ({
  type: FETCH_USER_DETAILS_BY_ID_SUCCESS,
  payload,
});

export const fetchUserDetailsByIdFailure = (
  payload: FetchUserDetailsByIdFailurePayload
): FetchUserDetailsByIdFailure => ({
  type: FETCH_USER_DETAILS_BY_ID_FAILURE,
  payload,
});

export const postRequestPasswordResetRequest = (payload: any): PostRequestPasswordResetRequest => ({
  type: POST_REQUEST_PASSWORD_REQUEST,
  payload,
});

export const postRequestPasswordResetSuccess = (
  payload: PostRequestPasswordResetSuccessPayload
): PostRequestPasswordResetSuccess => ({
  type: POST_REQUEST_PASSWORD_SUCCESS,
  payload,
});

export const postRequestPasswordResetFailure = (
  payload: PostRequestPasswordResetFailurePayload
): PostRequestPasswordResetFailure => ({
  type: POST_REQUEST_PASSWORD_FAILURE,
  payload,
});

export const postRedeemPasswordResetRequest = (payload: any): PostRedeemPasswordResetRequest => ({
  type: POST_REDEEM_PASSWORD_RESET_REQUEST,
  payload,
});

export const postRedeemPasswordResetSuccess = (
  payload: PostRedeemPasswordResetSuccessPayload
): PostRedeemPasswordResetSuccess => ({
  type: POST_REDEEM_PASSWORD_RESET_SUCCESS,
  payload,
});

export const postRedeemPasswordResetFailure = (
  payload: PostRedeemPasswordResetFailurePayload
): PostRedeemPasswordResetFailure => ({
  type: POST_REDEEM_PASSWORD_RESET_FAILURE,
  payload,
});

export const fetchDeepLinkDataRequest = (payload: any): FetchDeepLinkDataRequest => ({
  type: FETCH_SAVE_DEEP_LINK_DATA_REQUEST,
  payload,
});

export const fetchDeepLinkDataSuccess = (payload: any): FetchDeepLinkDataSuccess => ({
  type: FETCH_SAVE_DEEP_LINK_DATA_SUCCESS,
  payload,
});

export const fetchDeepLinkDataFailure = (
  payload: FetchDeepLinkDataFailurePayload
): FetchDeepLinkDataFailure => ({
  type: FETCH_SAVE_DEEP_LINK_DATA_FAILURE,
  payload,
});

export const fetchAuthenticateWithTokenRequest = (
  payload: any
): FetchAuthenticateWithTokenRequest => ({
  type: FETCH_AUTHENTICATE_WITH_TOKEN_REQUEST,
  payload,
});

export const fetchAuthenticateWithTokenSuccess = (
  payload: any
): FetchAuthenticateWithTokenSuccess => ({
  type: FETCH_AUTHENTICATE_WITH_TOKEN_SUCCESS,
  payload,
});

export const fetchAuthenticateWithTokenFailure = (
  payload: any
): FetchAuthenticateWithTokenFailure => ({
  type: FETCH_AUTHENTICATE_WITH_TOKEN_FAILURE,
  payload,
});

export const postResendSwitchboardOnboardingLinkRequest = (
  payload: any
): PostResendSwitchboardOnboardingLinkRequest => ({
  type: POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_REQUEST,
  payload,
});

export const postResendSwitchboardOnboardingLinkSuccess = (
  payload: PostResendSwitchboardOnboardingLinkSuccessPayload
): PostResendSwitchboardOnboardingLinkSuccess => ({
  type: POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_SUCCESS,
  payload,
});

export const postResendSwitchboardOnboardingLinkFailure = (
  payload: PostResendSwitchboardOnboardingLinkFailurePayload
): PostResendSwitchboardOnboardingLinkFailure => ({
  type: POST_RESEND_SWITCH_BOARD_ONBOARDING_LINK_FAILURE,
  payload,
});

export const postClinetAdminOnboardCompleteRequest = (
  payload: any
): PostClinetAdminOnboardCompleteRequest => ({
  type: POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_REQUEST,
  payload,
});

export const postClinetAdminOnboardCompleteSuccess = (
  payload: PostClinetAdminOnboardCompleteSuccessPayload
): PostClinetAdminOnboardCompleteSuccess => ({
  type: POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_SUCCESS,
  payload,
});

export const postClinetAdminOnboardCompleteFailure = (
  payload: PostClinetAdminOnboardCompleteFailurePayload
): PostClinetAdminOnboardCompleteFailure => ({
  type: POST_CLIENT_ADMIN_ONBOARDING_COMPLETE_FAILURE,
  payload,
});

export const fetchEmailAvailableRequest = (payload: any) => ({
  type: FETCH_EMAIL_AVAILABLE_REQUEST,
  payload,
});

export const fetchEmailAvailableSuccess = (payload: any) => ({
  type: FETCH_EMAIL_AVAILABLE_SUCCESS,
  payload,
});

export const fetchEmailAvailableFailure = (payload: FetchOnBoardingFailurePayload) => ({
  type: FETCH_EMAIL_AVAILABLE_FAILURE,
  payload,
});

export const fetchMobileValidRequest = (payload: any) => ({
  type: FETCH_MOBILE_VALID_REQUEST,
  payload,
});

export const fetchMobileValidSuccess = (payload: any) => ({
  type: FETCH_MOBILE_VALID_SUCCESS,
  payload,
});

export const fetchMobileValidFailure = (payload: any) => ({
  type: FETCH_MOBILE_VALID_FAILURE,
  payload,
});
