import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';
import { AxiosResponse } from 'axios';

type Tag = {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: string;
  name: string;
  sort_order?: number;
  tag_type: 'trait' | 'topic';
};

export type TagGroup = {
  children: TagGroup[];
  description?: string;
  id: number;
  key: string;
  name: string;
  parent_id?: number;
  tag_group_type: 'trait' | 'topic';
  tags: Tag[];
};

const getProfileTagGroups = async (): Promise<TagGroup[]> => {
  return await axiosGet(
    `/tag_groups/?keys=GENDER,PRONOUN,FAMILY,RELATIONSHIP,RACE/ETHNICITY,SPIRITUALITY,LANGUAGE`,
    {}
  ).then((profileTagGroupsResponse: AxiosResponse<TagGroup[]>) => profileTagGroupsResponse.data);
};

export const useProfileTagGroups = () => {
  const { data, isLoading, error, refetch } = useQuery<TagGroup[]>(
    ['profile_tag_groups'],
    getProfileTagGroups,
  );


  const getProfileTagGroupByKey = (
    key: 'GENDER' | 'PRONOUN' | 'FAMILY' | 'RELATIONSHIP' | 'RACE%2FETHNICITY' | 'SPIRITUALITY'
  ) => {
    return data?.filter((profileTagGroup) => profileTagGroup.key === key);
  };

  const getTagsByKey = (
    key: 'GENDER' | 'PRONOUN' | 'FAMILY' | 'RELATIONSHIP' | 'RACE/ETHNICITY' | 'SPIRITUALITY' | 'LANGUAGE'
  ) => {
    return data?.filter((profileTagGroup) => profileTagGroup.key === key)?.[0]?.tags?.map((item: Tag) => {
      return { name: item.name, id: item.id };
    });
  };

  const getProfileTagGroupByName = (name: string) => {
    return data?.find((profileTagGroup) => profileTagGroup.name === name);
  };

  return {
    data,
    isLoading,
    error,
    refetch,
    getProfileTagGroupByKey,
    getProfileTagGroupByName,
    getTagsByKey,
  };
};
