export const FETCH_TOS_REQUEST = 'FETCH_TOS_REQUEST';
export const FETCH_TERMS_OF_SERVICE_TOS_SUCCESS = 'FETCH_TERMS_OF_SERVICE_TOS_SUCCESS';
export const FETCH_ABOUT_LOC_TOS_SUCCESS = 'FETCH_ABOUT_LOC_TOS_SUCCESS';
export const FETCH_PRIVACY_POLICY_TOS_SUCCESS = 'FETCH_PRIVACY_POLICY_TOS_SUCCESS';
export const FETCH_LISTENER_FAQ_SUCCESS = 'FETCH_LISTENER_FAQ_SUCCESS';
export const FETCH_PEER_FAQ_SUCCESS = 'FETCH_PEER_FAQ_SUCCESS';
export const FETCH_CLIENT_ADMIN_TERMS_OF_SERVICE_TOS_SUCCESS =
  'FETCH_CLIENT_ADMIN_TERMS_OF_SERVICE_TOS_SUCCESS';
export const FETCH_SYSTEM_WELCOME_VIDEO_SUCCESS = 'FETCH_SYSTEM_WELCOME_VIDEO_SUCCESS';
export const FETCH_TOS_FAILURE = 'FETCH_TOS_FAILURE';

export const UPDATE_TOS_REQUEST = 'UPDATE_TOS_REQUEST';
export const UPDATE_TOS_SUCCESS = 'UPDATE_TOS_SUCCESS';
export const UPDATE_TOS_FAILURE = 'UPDATE_TOS_FAILURE';
