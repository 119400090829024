import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput, SectionLabel, ToggleButton } from '../../../components/common';
import AccordionTile from '../Accordion';
import { postUpdatePackageRequest } from '../clients-redux/actions';

type props = {
  data: any;
  UpdateSaveOption: (data: boolean) => void;
};

const Promotion: React.FunctionComponent<props> = (props): JSX.Element => {
  const [promo, setpromo] = useState(false);
  const [promoURL, setpromoURL] = useState('');
  const [promoLabel, setpromoLabel] = useState('');
  const [image, setimgPreview] = useState<any>();
  const [home, setHome] = useState(false);
  const [activeCall, setActiveCall] = useState(false);
  const [preCall, setPreCall] = useState(false);
  const [clientTile, setclientTile] = useState(false);
  const [productId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [preloadedImage, setpreloadedImage] = useState(false);
  const [enableSaveOption, setenableSaveOption] = useState(false);

  const [id] = useState();
  const dispatch = useDispatch();
  const [Style] = React.useState([
    { label: 'Select', value: '' },
    { label: 'Image', value: 'Image' },
  ]);
  const fileInput: any = React.createRef();

  const onFileChange = async (event: any) => {
    try {
      //

      if (event.target.files && event.target.files[0]) {
        // setimage(event.target.files[0]);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          setimgPreview(e.target.result);
          setpreloadedImage(false);
        };
        reader.readAsDataURL(event.target.files[0]);
      }

      // Update the state
    } catch (error) {
      console.error('err--', error);
    }
  };

  const hadleImageinpuy = (e: any) => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (props.data) {
      const branding = props.data?.branding_configuration;
      if (branding) {
        setpromo(branding?.status === 'enabled' ? true : false);
        setpromoURL(branding.url);
        setpromoLabel(branding.button_label);
        const buttonlabels = branding.placements ? branding.placements : [];
        setActiveCall(buttonlabels.includes('active_call'));
        setHome(buttonlabels.includes('home'));
        setclientTile(buttonlabels.includes('client_tile'));
        setPreCall(buttonlabels.includes('pre_call'));
        if (branding.image_url) {
          setimgPreview(branding.image_url);
          setpreloadedImage(true);
        }
      } else {
        setpromoLabel('VISIT PARTNER');
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setpackageId(props.data.id);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    const placements = [];

    if (activeCall) {
      placements.push('active_call');
    }
    if (home) {
      placements.push('home');
    }
    if (clientTile) {
      placements.push('client_tile');
    }
    if (preCall) {
      placements.push('pre_call');
    }

    const payload = {
      branding_configuration: {
        button_label: promoLabel ? promoLabel : 'VISIT PARTNER',
        enabled: promo,
        image_url: !preloadedImage ? image : undefined,
        placements: placements,
        type: 'image',
        url: promoURL,
        video_url: null,
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };

    if (packageId) {
      dispatch(postUpdatePackageRequest({ linkInput, payload }));
    }
  };

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccordionTile
      setStatus={(data: boolean) => {
        !enableSaveOption && setenableSaveOption(true);
        setpromo(data);
      }}
      Label={'PROMO '}
      buttonText={'SAVE'}
      status={promo}
      id={'promo'}
      initialstatus={true}
      disableButton={enableSaveOption}
      onButtonClick={() => {
        if (enableSaveOption) onSave();
      }}
      InnerComponent={
        <div className="flex justify-evenly gap-7 my-10">
          <div className="w-full ">
            <div className="py-4">
              <CustomInput
                inputType={'text'}
                value={promoURL}
                setValue={setpromoURL}
                Label={'Promo URL'}
              />
            </div>
            <div className="py-4">
              <CustomInput
                inputType={'text'}
                value={promoLabel}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setpromoLabel(data);
                }}
                Label={'Button Label'}
              />
            </div>

            <SectionLabel Label="Promo Placements" />
            <div className="flex justify-between w-full">
              <div className="flex justify-between w-full">
                <div className="w-full flex justify-between  py-4">
                  <div className="w-full">
                    <p className="py-2 text-blue-dark font-medium text-sm">Home</p>
                  </div>
                  <div className="w-full">
                    <ToggleButton
                      status={home}
                      setStatus={(data) => {
                        !enableSaveOption && setenableSaveOption(true);
                        setHome(data);
                      }}
                      initialstatus={true}
                      id={'home'}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between  py-4">
                  <div className="w-full">
                    <p className="py-2 text-blue-dark font-medium text-sm">Active-Call</p>
                  </div>
                  <div className="w-full">
                    <ToggleButton
                      status={activeCall}
                      setStatus={(data) => {
                        !enableSaveOption && setenableSaveOption(true);
                        setActiveCall(data);
                      }}
                      initialstatus={true}
                      id={'activeCall'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex justify-between w-full">
                <div className="w-full flex justify-between  py-4">
                  <div className="w-full">
                    <p className="py-2 text-blue-dark font-medium text-sm">Pre-Call</p>
                  </div>
                  <div className="w-full">
                    <ToggleButton
                      status={preCall}
                      setStatus={(data) => {
                        !enableSaveOption && setenableSaveOption(true);
                        setPreCall(data);
                      }}
                      initialstatus={true}
                      id={'preCall'}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between  py-4">
                  <div className="w-full">
                    <p className="py-2 text-blue-dark font-medium text-sm">Client Tile Override</p>
                  </div>
                  <div className="w-full">
                    <ToggleButton
                      status={clientTile}
                      setStatus={(data) => {
                        !enableSaveOption && setenableSaveOption(true);
                        setclientTile(data);
                      }}
                      initialstatus={true}
                      id={'clientTile'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div>
              <div className="py-4">
                <div className="flex justify-between w-full">
                  <div className="w-full">
                    <div className=" w-full">
                      <div className="block text-sm leading-4  font-medium text-blue-dark pb-2">
                        Style
                      </div>
                      <div className="relative inline-flex w-full mb-2">
                        <svg
                          className="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 412 232"
                        >
                          <path
                            d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                            fill="#648299"
                            fill-rule="nonzero"
                          />
                        </svg>
                        <select className=" py-2 px-3 w-full h-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none appearance-none">
                          {Style.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-4">
              <SectionLabel Label={'Promo Image'} />
            </div>
            <div className="bg-gray-200 w-full h-64 rounded-sm flex justify-center items-center">
              {image ? (
                <>
                  <img className="w-full h-full" src={image} alt="clientImage" />
                </>
              ) : (
                <div>
                  <button
                    className="rounded-full h-6 w-6 text-3xl bg-white flex justify-center items-center"
                    onClick={hadleImageinpuy}
                  >
                    +
                  </button>
                  <input
                    ref={fileInput}
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={onFileChange}
                  />
                </div>
              )}
            </div>
            {image && (
              <button
                className="stacked-bar-blue-400 rounded-sm  text-center  text-sm text-white  p-4 w-full my-2"
                onClick={() => {
                  setimgPreview(null);
                }}
              >
                REMOVE
              </button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default Promotion;
