import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export const listClientsQueryKeys = {
    listClients: ['ListClients'] as const,
}

export interface Client {
  is_test: boolean;
  notes?: string;
  id?: number;
  eligibility_config: EligibilityConfig;
  name: string;
  description: string;
  logo_background_file_url?: string;
  status: string;
  logo_overlay_file_url?: string;
  quickbooks_customer_id: any;
  per_member_rate: any;
  archived_at: any;
  use_global_resources?: boolean;
  promo_configuration?: PromoConfiguration;
  logo_file_url?: string;
  contract_date?: string;
  allowed_listener_tags?: number[];
  retired_at: any;
  reseller?: string;
  landing_page_content?: string;
  group_id: any;
  sso_configuration?: SsoConfiguration;
  invoice_terms: any;
  packages: Package[];
  reporting_configuration: any;
  go_live_date?: string;
  member_self_enrollment: boolean;
  monthly_cap: any;
  video_configuration?: VideoConfiguration;
  comms_configuration?: CommsConfiguration;
  created_at: string;
  comms_logo_file_url?: string;
}

export interface EligibilityConfig {
  transfer_type?: string;
  default_package: any;
  hide_client_admin_eligibility: boolean;
}

export interface PromoConfiguration {
  type: string;
  image_file: any;
  video_file: any;
  enabled?: boolean;
  image_url?: string;
  video_url?: string;
  url?: string;
  placements?: string[];
  button_label?: string;
}

export interface SsoConfiguration {
  allow_sign_out: boolean;
  collect_password: boolean;
  sso_error_action: string;
  allow_signup_exit: boolean;
  authentication_url: any;
  allow_invitation_expired_recovery: boolean;
}

export interface Package {
  archived_at: any;
  package_products: PackageProduct[];
  campaign_subscriptions: CampaignSubscription[];
  branding_configuration?: BrandingConfiguration;
  can_manage_dependents: boolean;
  dependents_configuration: DependentsConfiguration;
  web_app_url?: string;
  collect_user_address: boolean;
  domain_whitelist?: string;
  id?: number;
  member_count: number;
  code: string;
  name: string;
  client_id?: number;
  force_web_app?: boolean;
  benefits_description?: string;
  theme_name?: string;
  external_plan_id?: number;
  created_at: string;
  subscriptions: Subscription[];
}

export interface PackageProduct {
  credit_balance: number;
  configuration: Configuration;
  product_id: number;
  status: string;
  package_id: number;
  product: Product;
  unused_credit_balance: number;
  id: number;
  created_at: string;
}

export interface Configuration {
  expires_after?: number;
  hide_payment_tiles?: boolean;
  auto_renew_frequency?: string;
  auto_renew_maximum_uses?: number;
  maximum_minutes_per_user?: number;
  maximum_redemptions_allowed?: number;
  cadence?: Cadence;
  onboarding?: Onboarding;
  cadence_time?: string;
  interim_question?: string;
  primary_question?: string;
  engagement_points: any;
  interim_question_delay?: number;
  primary_question_timeout: any;
  image_file?: string;
  reward_value: any;
  required_points: any;
  tremendous_campaign_id?: string;
  tremendous_organization_id?: string;
  image_url?: string;
  rate?: number;
  minutes: any;
  auto_renew?: boolean;
  email_template?: string;
  discount_enabled?: boolean;
  additional_discount: any;
  pto_reward_value: any;
  maximum_minutes_all_users: any;
  discount_percentage: any;
}

export interface Cadence {
  on: string;
  period: string;
}

export interface Onboarding {
  delay: number;
  system_message_id: any;
  enabled?: boolean;
}

export interface Product {
  key: string;
  configuration: ProductConfiguration;
  id: number;
  name: string;
  description: string;
  product_type: string;
}

export interface ProductConfiguration {
  component: string;
  expires_after: any;
  hide_payment_tiles?: boolean;
  auto_renew_frequency?: string;
  auto_renew_maximum_uses?: number;
  maximum_minutes_per_user?: number;
  maximum_minutes_all_users: any;
  maximum_redemptions_allowed?: number;
  cadence?: Cadence;
  onboarding?: Onboarding;
  cadence_time?: string;
  interim_question?: string;
  primary_question?: string;
  engagement_points?: number;
  interim_question_delay?: number;
  primary_question_timeout?: boolean;
  image_url?: string;
  image_file?: string;
  reward_value?: string;
  required_points: any;
  tremendous_campaign_id?: string;
  tremendous_organization_id?: string;
  rate?: number;
  minutes?: number;
  auto_renew?: boolean;
  email_template: any;
  discount_enabled?: boolean;
  additional_discount?: number;
  pto_reward_value?: number;
  discount_percentage?: number;
}

export interface CampaignSubscription {
  campaign_template_id: number;
  package_id: number;
  campaign_template: CampaignTemplate;
  id: number;
  created_at: string;
}

export interface CampaignTemplate {
  script: any;
  type: string;
  id: number;
  name: string;
  sms_channel_id: any;
  archived_at: any;
  description: string;
  created_at: string;
}

export interface BrandingConfiguration {
  url: string;
  type: string;
  enabled: boolean;
  placements: string[];
  button_label: string;
  video_url?: string;
  image_url?: string;
}

export interface DependentsConfiguration {
  enabled: boolean;
  dependent_count: number;
  members_can_add_dependents: boolean;
}

export interface Subscription {
  details?: string;
  discount: number;
  id: number;
  minutes: number;
  code: string;
  price: number;
  banner_text: string;
  cents_per_minute: number;
  name: string;
  description?: string;
}

export interface VideoConfiguration {
  video_file?: string;
  title?: string;
  enabled?: boolean;
  image_url?: string;
  video_url?: string;
  author_name?: string;
  author_image_file: any;
  image_file: any;
  author_image_url?: string;
}

export interface CommsConfiguration {
  clever_webhook_process: boolean;
}

const getListClients = async (): Promise<Client[]> => {
  return await axiosGet(
    `/clients/?status=active&include_packages=true&order_by=name&order_by_direction=asc`,
    {},
    'v2'
  ).then((clientResponse) => clientResponse.data);
};

export const useListClients = () => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Client[]>(listClientsQueryKeys.listClients, () =>
    getListClients()
  );
  return { data, isLoading, error, refetch, isFetching };
};
