import React, { forwardRef, useImperativeHandle, useState } from 'react';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';

type Simpleprops = {
  count: number;
  selected: number;
  setselected: (data: any) => void;
};

const SimplePagination: React.FunctionComponent<Simpleprops> = ({
  count,
  selected,
  setselected,
}): JSX.Element => {
  return (
    <div className="flex w-full relative justify-evenly ">
      {Array.from(Array(count).keys()).map((item: any, idx: number) => (
        <button
          key={`page_${idx}`}
          className={
            ' text-sm text-bold ' +
            (item === selected ? ' blue-primary font-bold' : ' text-gray-dark ')
          }
          onClick={() => {
            setselected(item);
          }}
        >
          {item + 1}
        </button>
      ))}
    </div>
  );
};

const SimpleEllipsisPagination: React.FunctionComponent<Simpleprops> = ({
  count,
  selected,
  setselected,
}): JSX.Element => {
  return (
    <div className="flex w-full relative justify-evenly ">
      {Array.from(Array(count).keys())
        .slice(0, 5)
        .map((item: any) => (
          <button
            className={
              'text-sm text-bold' +
              (item === selected ? ' blue-primary font-bold ' : ' text-gray-dark ')
            }
            onClick={() => {
              setselected(item);
            }}
          >
            {item + 1}
          </button>
        ))}
      <div>....</div>
      {Array.from(Array(count).keys())
        .slice(count - 5, count)
        .map((item: any) => (
          <button
            className={
              'text-sm text-bold' +
              (item === selected ? ' blue-primary font-bold' : ' text-gray-dark ')
            }
            onClick={() => {
              setselected(item);
            }}
          >
            {item + 1}
          </button>
        ))}
    </div>
  );
};

const EllipsisPagination: React.FunctionComponent<Simpleprops> = ({
  count,
  selected,
  setselected,
}): JSX.Element => {
  return (
    <div className="flex w-full relative justify-evenly ">
      <button
        className={'text-sm text-gray-dark '}
        onClick={() => {
          setselected(1);
        }}
      >
        {1}
      </button>

      <div>....</div>
      {Array.from(Array(5).keys()).map((item: any) => (
        <button
          className={'text-sm   ' + (item === 2 ? ' blue-primary font-bold' : ' text-gray-dark ')}
          onClick={() => {
            setselected(selected - 2 + item);
          }}
        >
          {selected - 1 + item}
        </button>
      ))}
      <div>....</div>
      <button
        className={'text-sm text-gray-dark '}
        onClick={() => {
          setselected(count);
        }}
      >
        {count}
      </button>
    </div>
  );
};

type props = {
  updatePageNumber?: (data: number) => void;
  pageCount: number;
};

interface refProps {
  selectPage: (page: number) => void;
}

const Pagination = forwardRef<refProps, props>((props, ref) => {
  const [selected, setselected] = useState<number>(0);
  const [count] = useState(props.pageCount || 0);

  const handleLeft = () => {
    if (selected > 0) {
      props.updatePageNumber && props.updatePageNumber(selected - 1);
      setselected(selected - 1);
    }
  };
  const handleRight = () => {
    if (selected < count - 1) {
      props.updatePageNumber && props.updatePageNumber(selected + 1);
      setselected(selected + 1);
    }
  };

  const handlingClicks = (selected: number) => {
    props.updatePageNumber && props.updatePageNumber(selected);
    setselected(selected);
  };

  const selectPage = (page: number) => {
    setselected(page - 1);
  };

  useImperativeHandle(ref, () => ({
    selectPage,
  }));

  // useEffect(() => {

  // }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full">
      <div className="relative  mx-7 py-3 flex justify-between items-cnter">
        <div className="flex items-center">
          <button className="rounded-full h-6 w-6 blue-primary " onClick={handleLeft}>
            <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>
          </button>
        </div>
        {count <= 10 ? (
          <SimplePagination count={count} selected={selected} setselected={handlingClicks} />
        ) : selected > 4 && selected < count - 5 ? (
          <EllipsisPagination count={count} selected={selected} setselected={handlingClicks} />
        ) : (
          <SimpleEllipsisPagination
            count={count}
            selected={selected}
            setselected={handlingClicks}
          />
        )}
        <div className="flex items-center">
          <button
            className="rounded-full h-6 w-6 blue-primary  transform rotate-180 "
            onClick={handleRight}
          >
            <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
});

export default Pagination;
