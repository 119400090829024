import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  postTagsSuccess,
  postTagsFailure,
  fetchTagsListSuccess,
  fetchTagsListFailure,
  removeTagsSuccess,
  removeTagsFailure,
  fetchTagSuccess,
  fetchTagFailure,
  fetchTagRequest,
  fetchTagsCountSuccess,
  fetchTagsCountFailure,
  fetchTagsGroupListFailure,
  fetchTagsGroupListSuccess,
  postTagsGroupFailure,
  postTagsGroupSuccess,
  removeTagsGroupFailure,
  removeTagsGroupSuccess,
  fetchTagsGroupFailure,
  fetchTagsGroupSuccess,
  fetchTagsGroupRequest,
} from './actions';
import {
  POST_TAGS_REQUEST,
  FETCH_TAGS_LIST_REQUEST,
  UPDATE_TAGS_REQUEST,
  REMOVE_TAGS_REQUEST,
  FETCH_TAG_REQUEST,
  FETCH_TAGS_COUNT_REQUEST,
  FETCH_TAG_SORT_REQUEST,
  FETCH_TAGS_GROUP_COUNT_REQUEST,
  FETCH_TAGS_GROUP_LIST_REQUEST,
  POST_TAGS_GROUP_REQUEST,
  REMOVE_TAGS_GROUP_REQUEST,
  UPDATE_TAGS_GROUP_REQUEST,
  FETCH_TAGS_GROUP_REQUEST,
} from './action-types';
import { toast } from 'react-toastify';

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* postTagsSaga(action: any): any {
  try {
    const response = yield call(Api.createTags(action.payload));
    yield put(postTagsSuccess(response));
    toast.success('Tag Created Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postTagsFailure(JSON.parse(e)));
  }
}

function* fetchTagsListSaga(action: any): any {
  try {
    const response = yield call(Api.getAlltags(action.payload));
    yield put(
      fetchTagsListSuccess({
        TagsList: response,
      })
    );
  } catch (e: any) {
    yield put(fetchTagsListFailure(JSON.parse(e)));
  }
}

function* UpdateTagsSaga(action: any): any {
  try {
    yield call(Api.updateTags(action.payload));
    toast.success('Tag Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  yield put(
    fetchTagRequest({ id, payload: { all_tier_versions: true, include_unpublished: true } })
  );
}
function* removeTagsSaga(action: any): any {
  try {
    const response = yield call(Api.deleteTags(action.payload));
    yield put(removeTagsSuccess(response));
    toast.success(`tag removed sucessfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data?.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeTagsFailure(JSON.parse(e)));
  }
}

function* fetchTagSaga(action: any): any {
  try {
    const res = Api.fetchTags(action.payload);
    const response = yield call(res);
    yield put(fetchTagSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchTagFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchTagCountSaga(action: any): any {
  try {
    const response = yield call(Api.fetchTagsCount(action.payload));
    yield put(fetchTagsCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchTagsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchTagGroupCountSaga(action: any): any {
  try {
    const response = yield call(Api.fetchTagsGroupCount(action.payload));
    yield put(fetchTagsCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchTagsCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchTagSortSaga(action: any): any {
  try {
    const response = yield call(Api.updateTagsSort(action.payload));
  } catch (e: unknown) {
    yield put(
      fetchTagFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* postTagsGroupSaga(action: any): any {
  try {
    const response = yield call(Api.createTagsGroup(action.payload));
    yield put(postTagsGroupSuccess(response));
    toast.success('Tag Group Created Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postTagsGroupFailure(JSON.parse(e)));
  }
}

function* fetchTagsGroupListSaga(action: any): any {
  try {
    const response = yield call(Api.getAlltagGroups(action.payload));
    yield put(
      fetchTagsGroupListSuccess({
        TagsGroupList: response,
      })
    );
  } catch (e: any) {
    yield put(fetchTagsGroupListFailure(JSON.parse(e)));
  }
}

function* UpdateTagsGroupSaga(action: any): any {
  try {
    yield call(Api.updateTagsGroup(action.payload));
    toast.success('Tag Group Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  yield put(fetchTagsGroupRequest({ id, payload: { include_hidden: true } }));
}
function* removeTagsGroupSaga(action: any): any {
  try {
    const response = yield call(Api.deleteTagsGroup(action.payload));
    yield put(removeTagsGroupSuccess(response));
    toast.success(`Tag Group removed sucessfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data?.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeTagsGroupFailure(JSON.parse(e)));
  }
}

function* FetchTagsGroupSaga(action: any): any {
  try {
    const response = yield call(Api.fetchTagsGroup(action.payload));
    yield put(
      fetchTagsGroupSuccess({
        TagsGroup: response,
      })
    );
  } catch (e: any) {
    yield put(fetchTagsGroupFailure(JSON.parse(e)));
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(FETCH_TAG_SORT_REQUEST, fetchTagSortSaga),
    takeLatest(POST_TAGS_REQUEST, postTagsSaga),
    takeLatest(FETCH_TAGS_COUNT_REQUEST, fetchTagCountSaga),
    takeLatest(FETCH_TAGS_LIST_REQUEST, fetchTagsListSaga),
    takeLatest(UPDATE_TAGS_REQUEST, UpdateTagsSaga),
    takeLatest(REMOVE_TAGS_REQUEST, removeTagsSaga),
    takeLatest(FETCH_TAG_REQUEST, fetchTagSaga),
    takeLatest(FETCH_TAG_SORT_REQUEST, fetchTagSortSaga),
    takeLatest(POST_TAGS_GROUP_REQUEST, postTagsGroupSaga),
    takeLatest(FETCH_TAGS_GROUP_COUNT_REQUEST, fetchTagGroupCountSaga),
    takeLatest(FETCH_TAGS_GROUP_LIST_REQUEST, fetchTagsGroupListSaga),
    takeLatest(UPDATE_TAGS_GROUP_REQUEST, UpdateTagsGroupSaga),
    takeLatest(REMOVE_TAGS_GROUP_REQUEST, removeTagsGroupSaga),
    takeLatest(FETCH_TAGS_GROUP_REQUEST, FetchTagsGroupSaga),
  ]);
}

export default paymentSaga;
