import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  fetchSystemMessageGroupsSuccess,
  fetchSystemMessageGroupsFailure,
  postCommsSuccess,
  postCommsFailure,
  fetchCommsListSuccess,
  fetchCommsListFailure,
  updateCommsFailure,
  updateCommsSuccess,
  removeCommsSuccess,
  removeCommsFailure,
  postRepairTemplateSuccess,
  postTestCommsSuccess,
  postTestCommsFailure,
  postCampaignsSuccess,
  postCampaignsFailure,
  fetchCampaignsListSuccess,
  fetchCampaignsListFailure,
  updateCampaignsFailure,
  updateCampaignsSuccess,
  removeCampaignsSuccess,
  removeCampaignsFailure,
  postTestCampaignsSuccess,
  postTestCampaignsFailure,
} from './actions';
import {
  FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST,
  POST_COMMS_REQUEST,
  FETCH_COMMS_LIST_REQUEST,
  UPDATE_COMMS_REQUEST,
  REMOVE_COMMS_REQUEST,
  POST_REPAIR_TEMPLATE_REQUEST,
  POST_TEST_COMMS_REQUEST,
  POST_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_LIST_REQUEST,
  UPDATE_CAMPAIGNS_REQUEST,
  REMOVE_CAMPAIGNS_REQUEST,
  POST_TEST_CAMPAIGNS_REQUEST,
} from './action-types';
import { toast } from 'react-toastify';

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* postCommsSaga(action: any): any {
  try {
    const response = yield call(Api.createComms(action.payload));
    yield put(postCommsSuccess(response));
  } catch (e: any) {
    yield put(postCommsFailure(JSON.parse(e)));
  }
}

// function* fetchCommsSaga(action: any): any {
//   try {
//     const response = yield call(Api.authenticate(action.payload));
//     yield put(fetchCommsSuccess(response));
//   } catch (e: any) {
//     yield put(
//       fetchCommsFailure(JSON.parse(e))
//     );
//   }
// }
function* FetchSystemMessageGroupsSaga(action: any): any {
  try {
    const response = yield call(Api.fetchSystemMessagesGroups(action.payload));
    yield put(fetchSystemMessageGroupsSuccess(response));
  } catch (e: any) {
    yield put(fetchSystemMessageGroupsFailure(JSON.parse(e)));
  }
}

function* fetchCommsListSaga(action: any): any {
  try {
    yield delay(50);
    const response = yield call(Api.getCommsList(action.payload));
    yield put(
      fetchCommsListSuccess({
        CommsList: response,
      })
    );
  } catch (e: any) {
    yield put(fetchCommsListFailure(JSON.parse(e)));
  }
}

function* UpdateCommsSaga(action: any): any {
  try {
    const response = yield call(Api.updateComms(action.payload));
    yield put(updateCommsSuccess(response));
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(updateCommsFailure(JSON.parse(e)));
  }
}
function* removeCommsSaga(action: any): any {
  try {
    const response = yield call(Api.deleteCommsTemplate(action.payload));
    yield put(removeCommsSuccess(response));
    toast.success(`${action.payload?.name} removed successfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeCommsFailure(JSON.parse(e)));
  }
}

function* PostRepairTemplateSaga(action: any): any {
  try {
    const response = yield call(Api.repairTemplates(action.payload));

    yield put(postRepairTemplateSuccess(response));
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postRepairTemplateSuccess(JSON.parse(e)));
  }
}

function* PostTestCommsSaga(action: any): any {
  try {
    const response = yield call(Api.testComms(action.payload));
    if (response.status === 200) {
      localStorage.setItem('commsTestEmail', action.payload.payload.email_address);
    }
    yield put(postTestCommsSuccess(response));
    toast.success('Message sent Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postTestCommsFailure(JSON.parse(e)));
  }
}

function* postCampaignsSaga(action: any): any {
  try {
    const response = yield call(Api.createCampaigns(action.payload));
    yield put(postCampaignsSuccess(response));
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postCampaignsFailure(JSON.parse(e)));
  }
}

function* fetchCampaignsListSaga(action: any): any {
  try {
    yield delay(50);
    const response = yield call(Api.getAllCampaigns(action.payload));
    yield put(
      fetchCampaignsListSuccess({
        CampaignsList: response,
      })
    );
  } catch (e: any) {
    yield put(fetchCampaignsListFailure(JSON.parse(e)));
  }
}

function* UpdateCampaignsSaga(action: any): any {
  try {
    const response = yield call(Api.updateCampaigns(action.payload));
    yield put(updateCampaignsSuccess(response));
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(updateCampaignsFailure(JSON.parse(e)));
  }
}
function* removeCampaignsSaga(action: any): any {
  try {
    const response = yield call(Api.deleteCampaigns(action.payload));
    yield put(
      removeCampaignsSuccess({
        RemovePayment: response,
      })
    );
    toast.success(`${action.payload?.name} removed successfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        const { message, affected_plans } = error?.data.description;
        const plans = affected_plans.map((item: any) => item.plan);
        toast.error(message + plans.toString());
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(removeCampaignsFailure(JSON.parse(e)));
  }
}

function* PostTestCampaignsSaga(action: any): any {
  try {
    const response = yield call(Api.testCampaigns(action.payload));
    yield put(postTestCampaignsSuccess(response));
    toast.success('Message Sent Successfully');
  } catch (e: any) {
    const alertMessage = JSON.parse(e);
    yield put(postTestCampaignsFailure(alertMessage));
    toast.error(alertMessage.data.description);
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(POST_COMMS_REQUEST, postCommsSaga),
    //  takeLatest(FETCH_ELIGILITY_REQUEST, fetchCommsSaga),
    takeLatest(FETCH_SYSTEM_MESSAGE_GROUPS_REQUEST, FetchSystemMessageGroupsSaga),
    takeLatest(FETCH_COMMS_LIST_REQUEST, fetchCommsListSaga),
    takeLatest(UPDATE_COMMS_REQUEST, UpdateCommsSaga),
    takeLatest(REMOVE_COMMS_REQUEST, removeCommsSaga),
    takeLatest(POST_REPAIR_TEMPLATE_REQUEST, PostRepairTemplateSaga),
    takeLatest(POST_TEST_COMMS_REQUEST, PostTestCommsSaga),
    takeLatest(POST_CAMPAIGNS_REQUEST, postCampaignsSaga),
    takeLatest(FETCH_CAMPAIGNS_LIST_REQUEST, fetchCampaignsListSaga),
    takeLatest(UPDATE_CAMPAIGNS_REQUEST, UpdateCampaignsSaga),
    takeLatest(REMOVE_CAMPAIGNS_REQUEST, removeCampaignsSaga),
    takeLatest(POST_TEST_CAMPAIGNS_REQUEST, PostTestCampaignsSaga),
  ]);
}

export default paymentSaga;
