import ToggleButton from '../../../components/common/toggle-button';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import AccordionTile from '../Accordion';
import { CustomInput } from '../../../components/common';
import {
  postAvailableCreditRequest,
  postCreatePackageProductRequest,
  postUpdatePackageProductRequest,
} from '../clients-redux/actions';
import Model from '../../../components/common/model';

type props = {
  client: any;
  data: any;
  default: any;
  UpdateSaveOption: (data: boolean) => void;
};

const UnlimitedAcess: React.FunctionComponent<props> = (props): JSX.Element => {
  const [discount, setdiscount] = useState(0);
  const [hidePayment, sethidePayment] = useState(false);
  const [unlimitedAccess, setunlimitedAccess] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);
  const [reservedBalance, setReservedBalance] = useState(0);
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [clientId, setclientId] = useState<any>();
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState<any>({});
  const [popUperrormessage, setpopUperrormessage] = useState('');
  const [id, setid] = useState();
  const dispatch = useDispatch();

  const Validation = () => {
    if (unlimitedAccess) {
      if (!discount && discount !== 0) {
        setErrorMessage({ discount: 'Field is required' });
        return false;
      } else if (discount.toString().indexOf('.') !== -1) {
        setErrorMessage({
          discount: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (discount <= 0 || discount > 20000) {
        setErrorMessage({ discount: 'Must be a number between 1 and 20000' });
        return false;
      }
    }

    setErrorMessage({});
    return true;
  };

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;

      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'UNLIMITED_ACCESS') {
          return true;
        }
        return false;
      });
      if (selectedItem) {
        setunlimitedAccess(selectedItem?.status === 'enabled' ? true : false);
        const configuration = selectedItem.configuration;
        setdiscount(
          configuration.maximum_minutes_all_users ? configuration.maximum_minutes_all_users : 0
        );
        sethidePayment(configuration?.hide_payment_tiles);
        setAccountBalance(selectedItem.credit_balance);
        setReservedBalance(selectedItem.unused_credit_balance);
        setproductId(selectedItem.product_id);
        setid(selectedItem.id);
      } else {
        setunlimitedAccess(false);
        const configuration = props.default.configuration;
        setdiscount(
          configuration.maximum_minutes_all_users ? configuration.maximum_minutes_all_users : 0
        );
        sethidePayment(configuration?.hide_payment_tiles);
        setAccountBalance(0);
        setReservedBalance(0);
        setpackageId(props.data.id);
        setid(props.default.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    const payload = {
      status: unlimitedAccess ? 'enabled' : 'disabled',
      configuration: {
        hide_payment_tiles: hidePayment,
        maximum_minutes_all_users: +discount,
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };
    if (Validation()) {
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  return (
    <>
      <AccordionTile
        setStatus={(data: boolean) => {
          if ((!data && Validation()) || data) {
            !enableSaveOption && setenableSaveOption(true);
            setunlimitedAccess(data);
          }
        }}
        Label={'UNLIMITED ACCESS'}
        status={unlimitedAccess}
        id={'UnlimitedAcess'}
        buttonText={'SAVE'}
        initialstatus={true}
        disableButton={enableSaveOption}
        onButtonClick={() => {
          if (enableSaveOption) onSave();
        }}
        InnerComponent={
          <div>
            <div className="w-full flex justify-start pt-2 ">
              <div className="w-1/3 flex  items-center px-2">
                <p className="text-blue-dark font-medium text-sm">
                  Reserved Balance: {`$ ${(reservedBalance / 100).toFixed(2)}`}
                </p>
              </div>
              <div className="flex w-1/3 items-center">
                <p className="text-blue-dark font-medium text-sm px-4">
                  Hide Payment Setup Requests
                </p>
                <ToggleButton
                  setStatus={(data) => {
                    !enableSaveOption && setenableSaveOption(true);
                    sethidePayment(data);
                  }}
                  status={hidePayment}
                  id={'hidepaymentStatus'}
                  initialstatus={true}
                />
              </div>
            </div>
            {!productId && (
              <p className="block text-sm leading-4  font-medium text-blue-dark mb-2 pt-2">
                You must save this product to add credit
              </p>
            )}

            <div className="w-1/2 px-2 py-4">
              <CustomInput
                inputType={'number'}
                value={discount}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setdiscount(data);
                }}
                Label={'MAX MINUTES Across All Members'}
                Error={ErrorMessage?.discount?.length > 0 ? true : false}
                ErrorMessage={ErrorMessage?.discount}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(UnlimitedAcess);
