import {
  POST_BLACKLIST_REQUEST,
  POST_BLACKLIST_FAILURE,
  POST_BLACKLIST_SUCCESS,
  FETCH_BLACKLIST_LIST_REQUEST,
  FETCH_BLACKLIST_LIST_FAILURE,
  FETCH_BLACKLIST_LIST_SUCCESS,
  UPDATE_BLACKLIST_REQUEST,
  UPDATE_BLACKLIST_FAILURE,
  UPDATE_BLACKLIST_SUCCESS,
  REMOVE_BLACKLIST_REQUEST,
  REMOVE_BLACKLIST_FAILURE,
  REMOVE_BLACKLIST_SUCCESS,
  FETCH_BLACKLIST_COUNT_REQUEST,
  FETCH_BLACKLIST_COUNT_FAILURE,
  FETCH_BLACKLIST_COUNT_SUCCESS,
  FETCH_USERLIST_REQUEST,
  FETCH_USERLIST_FAILURE,
  FETCH_USERLIST_SUCCESS,
  FETCH_BLOCKED_COUNT_FAILURE,
  FETCH_BLOCKED_COUNT_REQUEST,
  FETCH_BLOCKED_COUNT_SUCCESS,
  FETCH_BLOCKED_LIST_FAILURE,
  FETCH_BLOCKED_LIST_REQUEST,
  FETCH_BLOCKED_LIST_SUCCESS,
  POST_BLOCKED_FAILURE,
  POST_BLOCKED_REQUEST,
  POST_BLOCKED_SUCCESS,
  REMOVE_BLOCKED_FAILURE,
  REMOVE_BLOCKED_REQUEST,
  REMOVE_BLOCKED_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  FETCH_BLOCKED_FAILURE,
  FETCH_BLOCKED_REQUEST,
  FETCH_BLOCKED_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
} from './action-types';

import {
  FetchBlackListCountRequest,
  FetchBlackListCountSuccess,
  FetchBlackListCountSuccessPayload,
  FetchBlackListCountFailure,
  FetchBlackListCountFailurePayload,
  PostBlackListRequest,
  PostBlackListSuccess,
  PostBlackListSuccessPayload,
  PostBlackListFailure,
  PostBlackListFailurePayload,
  FetchBlackListListRequest,
  FetchBlackListListSuccess,
  FetchBlackListListSuccessPayload,
  FetchBlackListListFailure,
  FetchBlackListListFailurePayload,
  UpdateBlackListRequest,
  UpdateBlackListSuccess,
  UpdateBlackListSuccessPayload,
  UpdateBlackListFailure,
  UpdateBlackListFailurePayload,
  RemoveBlackListRequest,
  RemoveBlackListSuccess,
  RemoveBlackListSuccessPayload,
  RemoveBlackListFailure,
  RemoveBlackListFailurePayload,
  FetchUserListRequest,
  FetchUserListSuccess,
  FetchUserListSuccessPayload,
  FetchUserListFailure,
  FetchUserListFailurePayload,
  FetchBlockedCountFailure,
  FetchBlockedCountFailurePayload,
  FetchBlockedCountRequest,
  FetchBlockedCountSuccess,
  FetchBlockedCountSuccessPayload,
  FetchBlockedListFailure,
  FetchBlockedListFailurePayload,
  FetchBlockedListRequest,
  FetchBlockedListSuccess,
  FetchBlockedListSuccessPayload,
  PostBlockedFailure,
  PostBlockedFailurePayload,
  PostBlockedRequest,
  PostBlockedSuccess,
  PostBlockedSuccessPayload,
  RemoveBlockedFailure,
  RemoveBlockedFailurePayload,
  RemoveBlockedRequest,
  RemoveBlockedSuccess,
  RemoveBlockedSuccessPayload,
  UpdateUserFailure,
  UpdateUserFailurePayload,
  UpdateUserRequest,
  UpdateUserSuccess,
  UpdateUserSuccessPayload,
  FetchBlockedFailure,
  FetchBlockedFailurePayload,
  FetchBlockedRequest,
  FetchBlockedSuccess,
  FetchBlockedSuccessPayload,
  UpdateSettiingsFailure,
  UpdateSettiingsFailurePayload,
  UpdateSettiingsRequest,
  UpdateSettiingsSuccess,
  UpdateSettiingsSuccessPayload,
} from './types';

export const postBlackListRequest = (payload: any): PostBlackListRequest => ({
  type: POST_BLACKLIST_REQUEST,
  payload,
});

export const postBlackListSuccess = (
  payload: PostBlackListSuccessPayload
): PostBlackListSuccess => ({
  type: POST_BLACKLIST_SUCCESS,
  payload,
});

export const postBlackListFailure = (
  payload: PostBlackListFailurePayload
): PostBlackListFailure => ({
  type: POST_BLACKLIST_FAILURE,
  payload,
});

export const fetchBlackListListRequest = (payload: any): FetchBlackListListRequest => ({
  type: FETCH_BLACKLIST_LIST_REQUEST,
  payload,
});

export const fetchBlackListListSuccess = (
  payload: FetchBlackListListSuccessPayload
): FetchBlackListListSuccess => ({
  type: FETCH_BLACKLIST_LIST_SUCCESS,
  payload,
});

export const fetchBlackListListFailure = (
  payload: FetchBlackListListFailurePayload
): FetchBlackListListFailure => ({
  type: FETCH_BLACKLIST_LIST_FAILURE,
  payload,
});

export const updateBlackListRequest = (payload: any): UpdateBlackListRequest => ({
  type: UPDATE_BLACKLIST_REQUEST,
  payload,
});

export const updateBlackListSuccess = (
  payload: UpdateBlackListSuccessPayload
): UpdateBlackListSuccess => ({
  type: UPDATE_BLACKLIST_SUCCESS,
  payload,
});

export const updateBlackListFailure = (
  payload: UpdateBlackListFailurePayload
): UpdateBlackListFailure => ({
  type: UPDATE_BLACKLIST_FAILURE,
  payload,
});

export const removeBlackListRequest = (payload: any): RemoveBlackListRequest => ({
  type: REMOVE_BLACKLIST_REQUEST,
  payload,
});

export const removeBlackListSuccess = (
  payload: RemoveBlackListSuccessPayload
): RemoveBlackListSuccess => ({
  type: REMOVE_BLACKLIST_SUCCESS,
  payload,
});

export const removeBlackListFailure = (
  payload: RemoveBlackListFailurePayload
): RemoveBlackListFailure => ({
  type: REMOVE_BLACKLIST_FAILURE,
  payload,
});

export const fetchBlackListCountRequest = (payload: any): FetchBlackListCountRequest => ({
  type: FETCH_BLACKLIST_COUNT_REQUEST,
  payload,
});

export const fetchBlackListCountSuccess = (
  payload: FetchBlackListCountSuccessPayload
): FetchBlackListCountSuccess => ({
  type: FETCH_BLACKLIST_COUNT_SUCCESS,
  payload,
});

export const fetchBlackListCountFailure = (
  payload: FetchBlackListCountFailurePayload
): FetchBlackListCountFailure => ({
  type: FETCH_BLACKLIST_COUNT_FAILURE,
  payload,
});

export const fetchUserListRequest = (payload: any): FetchUserListRequest => ({
  type: FETCH_USERLIST_REQUEST,
  payload,
});

export const fetchUserListSuccess = (
  payload: FetchUserListSuccessPayload
): FetchUserListSuccess => ({
  type: FETCH_USERLIST_SUCCESS,
  payload,
});

export const fetchUserListFailure = (
  payload: FetchUserListFailurePayload
): FetchUserListFailure => ({
  type: FETCH_USERLIST_FAILURE,
  payload,
});

export const postBlockedRequest = (payload: any): PostBlockedRequest => ({
  type: POST_BLOCKED_REQUEST,
  payload,
});

export const postBlockedSuccess = (payload: PostBlockedSuccessPayload): PostBlockedSuccess => ({
  type: POST_BLOCKED_SUCCESS,
  payload,
});

export const postBlockedFailure = (payload: PostBlockedFailurePayload): PostBlockedFailure => ({
  type: POST_BLOCKED_FAILURE,
  payload,
});

export const fetchBlockedListRequest = (payload: any): FetchBlockedListRequest => ({
  type: FETCH_BLOCKED_LIST_REQUEST,
  payload,
});

export const fetchBlockedListSuccess = (
  payload: FetchBlockedListSuccessPayload
): FetchBlockedListSuccess => ({
  type: FETCH_BLOCKED_LIST_SUCCESS,
  payload,
});

export const fetchBlockedListFailure = (
  payload: FetchBlockedListFailurePayload
): FetchBlockedListFailure => ({
  type: FETCH_BLOCKED_LIST_FAILURE,
  payload,
});

export const updateUserRequest = (payload: any): UpdateUserRequest => ({
  type: UPDATE_USER_REQUEST,
  payload,
});

export const updateUserSuccess = (payload: UpdateUserSuccessPayload): UpdateUserSuccess => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailure = (payload: UpdateUserFailurePayload): UpdateUserFailure => ({
  type: UPDATE_USER_FAILURE,
  payload,
});

export const removeBlockedRequest = (payload: any): RemoveBlockedRequest => ({
  type: REMOVE_BLOCKED_REQUEST,
  payload,
});

export const removeBlockedSuccess = (
  payload: RemoveBlockedSuccessPayload
): RemoveBlockedSuccess => ({
  type: REMOVE_BLOCKED_SUCCESS,
  payload,
});

export const removeBlockedFailure = (
  payload: RemoveBlockedFailurePayload
): RemoveBlockedFailure => ({
  type: REMOVE_BLOCKED_FAILURE,
  payload,
});

export const fetchBlockedCountRequest = (payload: any): FetchBlockedCountRequest => ({
  type: FETCH_BLOCKED_COUNT_REQUEST,
  payload,
});

export const fetchBlockedCountSuccess = (
  payload: FetchBlockedCountSuccessPayload
): FetchBlockedCountSuccess => ({
  type: FETCH_BLOCKED_COUNT_SUCCESS,
  payload,
});

export const fetchBlockedCountFailure = (
  payload: FetchBlockedCountFailurePayload
): FetchBlockedCountFailure => ({
  type: FETCH_BLOCKED_COUNT_FAILURE,
  payload,
});

export const fetchBlockedRequest = (payload: any): FetchBlockedRequest => ({
  type: FETCH_BLOCKED_REQUEST,
  payload,
});

export const fetchBlockedSuccess = (payload: FetchBlockedSuccessPayload): FetchBlockedSuccess => ({
  type: FETCH_BLOCKED_SUCCESS,
  payload,
});

export const fetchBlockedFailure = (payload: FetchBlockedFailurePayload): FetchBlockedFailure => ({
  type: FETCH_BLOCKED_FAILURE,
  payload,
});

export const updateSettiingsRequest = (payload: any): UpdateSettiingsRequest => ({
  type: UPDATE_SETTINGS_REQUEST,
  payload,
});

export const updateSettiingsSuccess = (
  payload: UpdateSettiingsSuccessPayload
): UpdateSettiingsSuccess => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload,
});

export const updateSettiingsFailure = (
  payload: UpdateSettiingsFailurePayload
): UpdateSettiingsFailure => ({
  type: UPDATE_SETTINGS_FAILURE,
  payload,
});
