import React, { useState } from 'react';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Searchbar from '../../components/common/searchbar';
import {
  fetchTagsListRequest,
  fetchTagsCountRequest,
  fetchTagsSortListRequest,
} from './redux-tags/actions';
import { Pagination } from '../../components/common';
import { Headeroptions } from './tag-groups';
import SwitchBar from '../../components/switch-bar';

export const TagTypes = {
  All: 'all',
  Trait: 'trait',
  Topic: 'topic',
};

type props = {
  tagsList: any;
  pending: any;
  TagsCount: any;
};

type FilterProps = {
  addFunctionality: () => void;
  fetchData: (data: any) => void;
  count: number | undefined;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const history = useHistory();
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
    tag_type: 'all',
    include_hidden: true,
  });

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/3">
        <div className="w-full h-8 flex">
          <div className="w-3/4">
            <Searchbar
              search={(data) => {
                setfilters((prev) => {
                  return { ...prev, page: 1, search: data };
                });
                fetchData({ ...filters, page: 1, search: data });
              }}
            />
          </div>
          <button
            className="bg-white rounded-full w-1/4 h-full py-1 mx-2 text-center "
            onClick={() => {
              history.push(`${ROUTE_PATH.TAGS_DETAILS}/?tag-id=new`);
            }}
          >
            ADD +
          </button>
        </div>
      </div>
      <div className="w-1/4 ">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) => {
            setfilters((filter) => {
              return { ...filter, page: pages + 1 };
            });
            fetchData({ ...filters, page: pages + 1 });
          }}
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(TagTypes).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (TagTypes[item] === filters.tag_type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  //@ts-ignore
                  setfilters((filter) => {
                    //@ts-ignore
                    return { ...filter, tag_type: TagTypes[item], page: 1 };
                  });
                  //@ts-ignore
                  fetchData({ ...filters, tag_type: TagTypes[item], page: 1 });
                }}
              >
                {/* @ts-ignore */}
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TierListing: React.FunctionComponent<props> = (props): JSX.Element => {
  const [tagType, settagType] = useState('all');
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchData = (filter: any) => {
    settagType(filter.tag_type);
    dispatch(fetchTagsListRequest(filter));
  };

  return (
    <div>
      <Header heading={'Tags'} />
      <SwitchBar heading={Headeroptions} position={1} />

      <Filters count={props.TagsCount} fetchData={fetchData} addFunctionality={() => {}} />

      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-7">
          {props.pending && <Spinner />}
          <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
            <div className=" font-bold col-span-5">
              <p className="pl-4">Tag Name</p>
            </div>
            <div className=" col-span-3 ">Type </div>
            <div className=" col-span-4 ">Status </div>
          </div>

          {props.tagsList?.length > 0 &&
            props.tagsList?.map((tag: any) => (
              <div
                className="w-full bg-gray-200 rounded-xl text-gray-dark text-left h-12 border-y my-2 grid grid-cols-12  items-center cursor-pointer"
                onClick={() => {
                  history.push(`${ROUTE_PATH.TAGS_DETAILS}/?tag-id=${tag.id}`);
                }}
              >
                <div
                  className={` font-bold flex items-center col-span-5 ${
                    tagType === 'all' ? 'pl-4' : ''
                  }`}
                >
                  <p className="ml-4"> {tag.name}</p>
                </div>

                <div className=" col-span-3 ">{tag.tag_type}</div>
                <div className=" col-span-4 flex justify-between mr-2 items-center">
                  <div> {`${tag.is_visible ? 'Visible' : 'Hidden'}`}</div>
                  <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                    <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tagsList: state.tags.TagsList?.data?.data || null,
    TagsCount: state.tags.TagsList?.data?.count || null,
    pending: state.tags?.pending || null,
  };
};

export default connect(mapStateToProps)(TierListing);
