import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';

import {
  fetchSnapshotSuccess,
  fetchSnapshotFailure,
  fetchEngagementDetailsSuccess,
  fetchEngagementDetailsFailure,
} from './actions';
import { FETCH_SNAPSHOT_REQUEST, FETCH_ENGAGEMENT_DETAILS_REQUEST } from './actionTypes';
/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchSnapshotSaga(action: any): any {
  try {
    const res = Api.getSnapShot(action.payload);
    const response = yield call(res);

    yield put(fetchSnapshotSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchSnapshotFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchEngagementDetailsSaga(action: any): any {
  try {
    const payload = action.payload;
    let response: any = [];
    if (payload.linkInput.packageId) {
      response = yield call(Api.getPackageReports(action.payload));
    } else {
      response = yield call(Api.getReports(action.payload));
    }

    yield put(fetchEngagementDetailsSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchEngagementDetailsFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

/*
  Starts worker saga on latest dispatched `FETCH_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* todoSaga() {
  yield all([
    takeLatest(FETCH_SNAPSHOT_REQUEST, fetchSnapshotSaga),
    takeLatest(FETCH_ENGAGEMENT_DETAILS_REQUEST, fetchEngagementDetailsSaga),
  ]);
}

export default todoSaga;
