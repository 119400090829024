import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { connect, useDispatch } from 'react-redux';
import { fetchListenerCountRequest, fetchListenersRequest } from './listeners-redux/actions';
import Spinner from '../../components/common/spinner';
import { Pagination } from '../../components/common';
import Searchbar from '../../components/common/searchbar';
import { sortArrow } from '../../assets/images';
import { listenerFilters } from './listener-constants';
import moment from 'moment';
import { ROUTE_PATH } from '../../routes/paths';
import { useLocation } from 'react-router-dom';
import { convertObjectToParams, paramsToObjects } from '../../components/common/create-params';

export enum listenerType {
  PEER = 'peer',
  PEERLISTENER = 'peerlistener',
}

type props = {
  listeners: any;
  listenersCount: any;
  pending: boolean;
};

type FilterProps = {
  count: number | undefined;
  fetchData: (data: any) => void;
  initialState: any;
};

const Filters: React.FunctionComponent<FilterProps> = ({
  count,
  fetchData,
  initialState,
}): JSX.Element => {
  const params = paramsToObjects();
  const [filters, setfilters] = useState(params);
  const [selectedfilter, setselectedfilter] = useState(initialState);
  const pageRef: any = useRef();
  const searchRef: any = useRef();
  const { search } = useLocation();

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  useEffect(() => {
    const params = paramsToObjects();
    pageRef.current.selectPage(+params.page);
    searchRef.current.setSearch(params.search);
    setselectedfilter(
      params?.active_listeners === 'true'
        ? 'Active'
        : params?.active_listeners === 'false'
        ? 'Not Visible'
        : 'All'
    );
    setfilters(params);
  }, [search]);

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            ref={searchRef}
            initialValue={initialState?.search || ''}
            search={(data) => {
              setfilters((prev: any) => {
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Pagination
          ref={pageRef}
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) =>
            setfilters((filter: any) => {
              return { ...filter, page: pages + 1 };
            })
          }
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {listenerFilters.map((filter) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (filter.name === selectedfilter
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  const { include_hidden, active_listeners, ...rest } = filters;
                  setfilters({
                    ...rest,
                    ...filter.code,
                  });
                  setselectedfilter(filter.name);
                }}
              >
                {/* @ts-ignore */}
                {filter.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileList: React.FunctionComponent<props> = (props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = paramsToObjects();
  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 25,
    search: '',
    order_by_direction: 'desc',
    order_by: 'created_at',
    user_type: params.user_type,
  });
  const { search } = useLocation();
  const fetchData = (filter: any) => {
    setFilters(filter);
    history.push(
      (filter.user_type === listenerType.PEERLISTENER ? ROUTE_PATH.LISTENERS : ROUTE_PATH.PEERS) +
        convertObjectToParams(filter)
    );
  };

  const handleSort = (type: string) => {
    const filter = {
      ...filters,
      order_by: type,
      order_by_direction:
        filters.order_by === type
          ? filters?.order_by_direction === 'desc'
            ? 'asc'
            : 'desc'
          : 'desc',
    };
    fetchData(filter);
  };

  useEffect(() => {
    dispatch(fetchListenersRequest(params));
    dispatch(fetchListenerCountRequest(params));
    setFilters(params);
  }, [search]);

  return (
    <div>
      {props.pending && <Spinner />}
      <Header
        heading={params.user_type === listenerType.PEERLISTENER ? 'Peer Listeners' : 'Peers'}
      />
      <Filters fetchData={fetchData} count={props.listenersCount?.count} initialState={params} />

      <div className="max-window-height  overflow-y-auto  px-7 ">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              <th className="px-2 py-1 w-3/12 sticky top-0  bg-white  h-full mb-4">
                <div
                  className="flex items-center pl-1 "
                  role="button"
                  onClick={() => {
                    handleSort('first_name');
                  }}
                >
                  {params.user_type === listenerType.PEERLISTENER ? 'Peer Listeners' : 'Peers'}
                  {filters.order_by === 'first_name' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 cursor-pointer ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="profile"
                    ></img>
                  )}
                </div>
              </th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4">User ID</th>
              {params?.user_type === listenerType.PEERLISTENER && (
                <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">
                  <div
                    className="flex items-center pl-1 "
                    role="button"
                    onClick={() => {
                      handleSort('earnings');
                    }}
                  >
                    Earnings
                    {filters.order_by === 'earnings' && (
                      <img
                        src={sortArrow}
                        className={`w-4 h-4 ml-2 cursor-pointer ${
                          filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                        }`}
                        alt="profile"
                      ></img>
                    )}
                  </div>
                </th>
              )}
              <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4">
                <div
                  className="flex items-center"
                  role="button"
                  onClick={() => {
                    handleSort('created_at');
                  }}
                >
                  Creation Date{' '}
                  {filters.order_by === 'created_at' && (
                    <img
                      src={sortArrow}
                      className={`w-4 h-4 ml-2 ${
                        filters?.order_by_direction === 'desc' ? `transform rotate-180` : ``
                      }`}
                      alt="profile"
                    ></img>
                  )}
                </div>
              </th>
              <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4 z-10"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {props.listeners?.length > 0 &&
              props.listeners.map((item: any) => {
                return (
                  <tr
                    role="button"
                    className="bg-gray-200 rounded text-gray-dark text-left h-24 border-y border-white border-4 space-y-6"
                    onClick={() => {
                      localStorage.setItem('peerFilters', JSON.stringify(params));
                      history.push(
                        (filters.user_type === listenerType.PEERLISTENER
                          ? ROUTE_PATH.LISTENERS_PROFILE
                          : ROUTE_PATH.PEERS_PROFILE) +
                          convertObjectToParams({
                            listenerRoleId: item.id,
                            listenerId: item.user.id,
                            listenerType: item.can_take_calls ? 'listener' : 'peer',
                            listenerName: item.user.first_name,
                          })
                      );
                    }}
                  >
                    <td className="  w-3/12 ">
                      <div className="flex w-full h-full items-center">
                        <div
                          className={`${
                            item.can_take_calls ? 'bg-blue-primary' : 'bg-gray-dark'
                          } min-w-2 h-24 mr-4 rounded-l `}
                        ></div>
                        {item.current_profile_photo || item.proposed_profile_photo ? (
                          <img
                            src={
                              item.current_profile_photo?.file_url ??
                              item.proposed_profile_photo?.file_url
                            }
                            className="w-16 h-16 "
                            alt="profile"
                          ></img>
                        ) : (
                          <div className="w-16 h-16">&nbsp;</div>
                        )}
                        <p className="px-5 py-1  text-gray-dark  font-bold truncate ">
                          <p>
                            {item.user.first_name ? item.user.first_name : item.user.email_address}
                          </p>
                          <p>
                            {' '}
                            aka{' '}
                            {item.user.display_name ? (
                              item.user.display_name
                            ) : (
                              <i className="text-gray-500">--</i>
                            )}
                          </p>
                        </p>
                      </div>
                    </td>
                    <td className="px-2 py-1 w-1/12">{item?.user?.id}</td>

                    {params?.user_type === listenerType.PEERLISTENER && (
                      <td className="px-2 py-1 w-2/12">{` $ ${((item?.earnings || 0) / 100).toFixed(
                        2
                      )}`}</td>
                    )}
                    <td className="px-2 py-1 w-1/12">
                      {moment(item?.user?.created_at).format('MMM D, YYYY')}
                    </td>
                    <td className="px-2 py-1 w-1/12">
                      <div className="flex items-center justify-end">
                        <button className="rounded-full h-8 w-8 blue-primary  transform rotate-180 ">
                          <img className="h-8 w-8" src={RightArrow} alt="left Arrow"></img>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listeners: state.Listeners?.listeners?.data || null,
    listenersCount: state.Listeners?.listenersCount?.data || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(ProfileList);
