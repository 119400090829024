export const Headeroptions = [
  { heading: 'Profile', path: '/listeners/profile' },
  { heading: 'Engagement', path: '/listeners/engagement' },
  { heading: 'Connections', path: '/listeners/connection' },
  { heading: 'Availability', path: '/listeners/availabilty' },
  { heading: 'Audio', path: '/listeners/audio' },
  { heading: 'Payout', path: '/listeners/payout' },
  { heading: 'Log', path: '/listeners/log' },
];

export const PeerHeaderoptions = [
  { heading: 'Profile', path: '/peers/profile' },
  { heading: 'Engagement', path: '/peers/engagement' },
  { heading: 'Audio', path: '/peers/audio' },
  { heading: 'Payout', path: '/peers/payout' },
];

export const weeks = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const listenerTypes = {
  All: 'All',
  Active: 'active_listeners',
  'Peer Listeners': 'peerlistener',
  Peers: 'peer',
};

export const listenerFilters = [
  { name: 'Not Visible', code: { active_listeners: false } },
  { name: 'Active', code: { active_listeners: true } },
  { name: 'All', code: {} },
];
