import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
//@ts-ignore
import { fetchClientCountRequest } from '../../containers/clients/clients-redux/actions';
import { clientTypes } from './client-constants';
import debounce from '../common/debounce';
import Searchbar from '../common/searchbar';

type props = {
  selectedClientType: any;
  addFunctionality: any;
  ClientsCount: any;
};

const ClientHeader: React.FunctionComponent<props> = (props): JSX.Element => {
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();
  const [state, setstate] = useState([0, 0, 0, 0]);
  const [filters, setfilters] = useState({
    type: clientTypes[0],
    search: '',
  });

  useEffect(() => {
    dispatch(fetchClientCountRequest({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(props.ClientsCount)) {
      props.ClientsCount?.length > 3 && setstate(props.ClientsCount);
    }
  }, [props.ClientsCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full bg-gray-background-light px-6  h-14 ">
      <div className="flex justify-between items-center  gray-background-light text-gray-dark h-full">
        <div className="w-1/2 flex h-full py-2">
          <div className="w-full h-full">
            <Searchbar
              search={(data) => {
                setfilters((prev) => ({ ...prev, search: data }));
                props.selectedClientType(filters.type, data);
              }}
            />
          </div>
          <div className="w-1/4">
            <button
              className="bg-white rounded-full w-full h-full py-1 mx-2 text-center"
              onClick={props.addFunctionality}
            >
              ADD +
            </button>
          </div>
        </div>

        <div className="flex font-medium justify-between items-center text-gray-dark ">
          <div className="flex justify-between items-center py-1  gray-background-dark">
            {clientTypes.map((item, index) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  (index === active
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setActive(index);
                  setfilters((prev) => ({ ...prev, type: item }));
                  props.selectedClientType(item, filters.search);
                }}
              >
                {` ${item} (${state[index]}) `}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ClientsCount: state.Client?.clientCount || null,
  };
};

export default connect(mapStateToProps)(ClientHeader);
