import React from 'react';

type props = {
  bigNumber: string | number;
  header?: string;
  subHeader?: string;
  eligiblePercent?: string;
  activePercent?: string;
  otherNumber?: string;
  otherText?: string;
  color?: string;
};

const NumericDisplayLarge: React.FunctionComponent<props> = ({
  bigNumber,
  header,
  subHeader,
  eligiblePercent,
  activePercent,
  otherNumber,
  otherText,
  color,
}): JSX.Element => {
  return (
    <div className="relative mx-2">
      <div style={{minHeight: '5rem'}}>
        <div className="text-base font-bold text-gray-dark">{header}</div>
        <div className="text-base font-montseerat font-normal text-gray-dark">{subHeader}</div>
      </div>
      <div className={`text-4xl font-bold ${color ?? "blue-primary"} font-montseerat my-2`}>
        {bigNumber.toLocaleString ? bigNumber.toLocaleString() : bigNumber}
      </div>
      {eligiblePercent && <div>
        <span className={`text-base font-bold ${color ?? "blue-primary"}`}>{eligiblePercent}%&nbsp;</span>
        <span className="text-base text-gray-dark">of Eligible Members</span>
      </div>}
      {activePercent&& <div>
        <span className={`text-base font-bold ${color ?? "blue-primary"}`}>{activePercent}%&nbsp;</span>
        <span className="text-base text-gray-dark">of Activated Members</span>
      </div>}
      {otherNumber&& <div>
        <span className={`text-base font-bold ${color ?? "blue-primary"}`}>{otherNumber}&nbsp;</span>
        <span className="text-base text-gray-dark">{otherText}</span>
      </div>}
    </div>
  );
};

export default NumericDisplayLarge;
