import React from 'react';
import { useHistory } from 'react-router-dom';
import { LOCLogo } from '../../assets/images';
import { ROUTE_PATH } from '../../routes/paths';
import { useDispatch } from 'react-redux';
import { DESTROY_SESSION } from '../../components/account-login/login-redux/actionTypes';

const InvitationCompleted: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: DESTROY_SESSION });
    localStorage.clear();
    history.push(ROUTE_PATH.LOGIN);
  };
  return (
    <div>
      <div className="w-full h-screen bg-gray-background-light flex justify-center items-center">
        <div className="w-96 bg-white px-8 pt-8 pb-12">
          <div className="text-primary w-full">
            <div className="flex justify-center pt-4">
              <img src={LOCLogo} alt="kindly-logo" className="w-56" />
            </div>
            <h3 className="text-bright-blue pt-10 text-xl">Enrollment Completed</h3>
            <h3 className="text-gray-dark text-sm pt-4">
              You have already completed enrollment in our system.
            </h3>
            <h3 className="text-gray-dark text-sm pt-4">
              Click Sign In below to access your group as an administrator.
            </h3>
            <div className="flex justify-center mt-8">
              <button
                type="button"
                className="bg-bright-blue rounded-full py-1.5 px-12 text-white"
                onClick={handleClose}
              >
                SIGN IN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationCompleted;
