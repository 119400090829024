import {
  FETCH_SNAPSHOT_REQUEST,
  FETCH_SNAPSHOT_FAILURE,
  FETCH_SNAPSHOT_SUCCESS,
  FETCH_ENGAGEMENT_DETAILS_REQUEST,
  FETCH_ENGAGEMENT_DETAILS_FAILURE,
  FETCH_ENGAGEMENT_DETAILS_SUCCESS,
} from './actionTypes';
import {
  FetchSnapshotRequest,
  FetchSnapshotSuccess,
  FetchSnapshotSuccessPayload,
  FetchSnapshotFailure,
  FetchSnapshotFailurePayload,
  FetchEngagementDetailsRequest,
  FetchEngagementDetailsSuccess,
  FetchEngagementDetailsSuccessPayload,
  FetchEngagementDetailsFailure,
  FetchEngagementDetailsFailurePayload,
} from './types';

export const fetchSnapshotRequest = (payload: any): FetchSnapshotRequest => ({
  type: FETCH_SNAPSHOT_REQUEST,
  payload,
});

export const fetchSnapshotSuccess = (
  payload: FetchSnapshotSuccessPayload
): FetchSnapshotSuccess => ({
  type: FETCH_SNAPSHOT_SUCCESS,
  payload,
});

export const fetchSnapshotFailure = (
  payload: FetchSnapshotFailurePayload
): FetchSnapshotFailure => ({
  type: FETCH_SNAPSHOT_FAILURE,
  payload,
});

export const fetchEngagementDetailsRequest = (payload: any): FetchEngagementDetailsRequest => ({
  type: FETCH_ENGAGEMENT_DETAILS_REQUEST,
  payload,
});

export const fetchEngagementDetailsSuccess = (
  payload: FetchEngagementDetailsSuccessPayload
): FetchEngagementDetailsSuccess => ({
  type: FETCH_ENGAGEMENT_DETAILS_SUCCESS,
  payload,
});

export const fetchEngagementDetailsFailure = (
  payload: FetchEngagementDetailsFailurePayload
): FetchEngagementDetailsFailure => ({
  type: FETCH_ENGAGEMENT_DETAILS_FAILURE,
  payload,
});
