import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

type Tag = {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: string;
  name: string;
  sort_order?: number;
  tag_type: 'trait' | 'topic';
};

export type TagGroup = {
  children: TagGroup[];
  description?: string;
  id: number;
  key: string;
  name: string;
  parent_id?: number;
  tag_group_type: 'trait' | 'topic';
  tags: Tag[];
};

const getTagGroups = async (): Promise<TagGroup[]> => {
  return await axiosGet(
    `/tag_groups/?keys=COMMUNITY,WORK,FINANCES,HEALTH,RELATIONSHIPS,EMOTIONS`,
    {}
  ).then((tagGroupsResponse) => tagGroupsResponse.data);
};

export const useTagGroups = () => {
  const { data, isLoading, error, refetch } = useQuery<TagGroup[]>(['tag_groups'], getTagGroups, {
    staleTime: Infinity,
  });

  const getById = (id: number) => {
    return data?.find((tagGroup) => tagGroup.id === id);
  };

  const getParentByParentId = (parentId: number) => {
    return data?.find((tagGroup) => tagGroup.id === parentId);
  };

  const getTagGroupByTagId = (tagId: number) => {
    return data?.find(
      (tagGroup) =>
        tagGroup.children
          ?.map((subjectTagGroup) => subjectTagGroup.tags?.map((tag) => tag.id))
          .flat()
          .includes(tagId)
    );
  };

  const getTagGroupByKey = (
    key: 'COMMUNITY' | 'WORK' | 'FINANCE' | 'HEALTH' | 'RELATIONSHIPS' | 'EMOTIONS'
  ) => {
    return data?.filter((tagGroup) => tagGroup.key === key);
  };

  const getTagGroupByName = (name: string) => {
    return data?.find((tagGroup) => tagGroup.name === name);
  };

  const formatTagsAndTagGroups = (tagGroups: number[], tags: number[]) => {
    /* 
      validation to each list of tags and tag groups to remove items that aren't higher up in the tree
      e.g. if a tag is present, but the correspending subject isn't selected, that tag is removed here
      */
    const includedChallengeAreas = data?.filter((challengeArea) =>
      tagGroups.includes(challengeArea.id)
    );

    const tag_group_ids = tagGroups.filter(
      (id) =>
        includedChallengeAreas
          ?.map((challengeArea) => challengeArea.children.map((subject) => subject.id))
          .flat()
          .includes(id) || includedChallengeAreas?.map((area) => area.id).includes(id)
    );

    const tag_ids = tags.filter((tagId) =>
      tag_group_ids.includes(getTagGroupByTagId(tagId)?.id ?? -1)
    );

    return {
      tag_group_ids,
      tag_ids,
    };
  };

  return {
    data,
    isLoading,
    error,
    refetch,
    getById,
    getTagGroupByKey,
    getParentByParentId,
    getTagGroupByName,
    getTagGroupByTagId,
    formatTagsAndTagGroups,
  };
};
