import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LOCLogo } from '../../assets/images';
import {
  fetchAuthenticateWithTokenFailure,
  fetchAuthenticateWithTokenSuccess,
  postResendSwitchboardOnboardingLinkRequest,
} from '../../components/account-login/login-redux/actions';
import { ROUTE_PATH } from '../../routes/paths';
import { DESTROY_SESSION } from '../../components/account-login/login-redux/actionTypes';
import { Spinner } from '../../components/common';

type props = {
  deepLinkData: any;
  resendSwitchBoardOnBoardingLink: any;
  resendSwitchBoardOnBoardingLinkError: any;
  pending: boolean;
};

const InvitationExpired: React.FunctionComponent<props> = (props): JSX.Element => {
  const { resendSwitchBoardOnBoardingLink, resendSwitchBoardOnBoardingLinkError } = props;
  const deeplinkData = localStorage.getItem('deeplinkData');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleResendEmail = () => {
    const user = deeplinkData !== null ? JSON.parse(deeplinkData) : null;
    if (user) {
      const input = {
        userId: user?.$user_id,
        token: user?.$user_token,
      };
      dispatch(postResendSwitchboardOnboardingLinkRequest(input));
    }
  };

  useEffect(() => {
    if (resendSwitchBoardOnBoardingLink?.status === 200) {
      toast.success(`Invite sent successfully`);
      goToLogin();
    }
  }, [resendSwitchBoardOnBoardingLink]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resendSwitchBoardOnBoardingLinkError?.data) {
      dispatch({ type: DESTROY_SESSION });
      history.push(ROUTE_PATH.INVITATION_COMPLETED);
    }
  }, [resendSwitchBoardOnBoardingLinkError]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToLogin = () => {
    localStorage.clear();
    dispatch(fetchAuthenticateWithTokenSuccess({}));
    dispatch(fetchAuthenticateWithTokenFailure({}));
    history.push(ROUTE_PATH.LOGIN);
  };

  return (
    <div>
      {props.pending && <Spinner />}
      <div className="w-full h-screen bg-gray-background-light flex justify-center items-center">
        <div className="w-96 bg-white px-8 pt-8 pb-12">
          <div className="text-primary w-full">
            <div className="flex justify-center pt-4">
              <img src={LOCLogo} alt="kindly-logo" className="w-56" />
            </div>
            <h3 className="text-bright-blue pt-10 text-xl">Invitation Expired</h3>
            <h3 className="text-gray-dark text-sm pt-4">
              Your invitation has expired. Request a new invitation below.
            </h3>
            <h3 className="text-gray-dark text-sm pt-4">
              The invitation will be valid for 24 hours.
            </h3>
            <div className="flex justify-center mt-8">
              <button
                type="button"
                className="bg-bright-blue rounded-full py-1.5 px-12 text-white"
                onClick={handleResendEmail}
              >
                REQUEST NEW INVITE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    deepLinkData: state.login?.deepLinkData,
    resendSwitchBoardOnBoardingLink: state.login?.resendSwitchBoardOnBoardingLink,
    resendSwitchBoardOnBoardingLinkError: state.login?.resendSwitchBoardOnBoardingLinkError,
    pending: state.login.pending,
  };
};

export default connect(mapStateToProps)(InvitationExpired);
