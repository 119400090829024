import React from 'react';
import moment from 'moment';
import { XCricle, Refresh } from '../../assets/images';
import { UserRole } from '../../containers/navigation-bar/roles';

type props = {
  data: any;
  tableType: string;
  handleEligibilityFileViewModel: (id: number, type: string) => void;
  isACtiveAndScheduleShow?: boolean;
  handleDeleteEligibility?: (id: number) => void;
  handleProcessEligibility?: (id: number) => void;
  handleReplaceEligibility?: (id: number, date: string) => void;
  handleTranferEligibility?: (id: number) => void;
  handleRefresh?: (type: string) => void;
};
const EligibilityTableList: React.FunctionComponent<props> = (props): JSX.Element => {
  const { data } = props;
  const userType = localStorage.getItem('role_type');
  return (
    <div>
      <div className="px-7 ">
        <table className="table-fixed table-row-format w-full relative border-collapse">
          <thead className="bg-white  h-full ">
            <tr className=" text-left  w-full px-7">
              {props.tableType === 'scheduled' && userType === UserRole.CLIENTS ? (
                <>
                  <th className="px-2 py-1 w-1/2 sticky top-0  bg-white  h-full mb-4 font-normal">
                    Live Date
                  </th>
                  <th className="w-1/2"></th>
                </>
              ) : (
                <>
                  <th className="px-2 py-1 w-2/12 sticky top-0  bg-white  h-full mb-4 font-normal">
                    Members
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Live Date
                  </th>

                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    New
                  </th>

                  <th className="px-2 py-1  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Existing
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Removed
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Invalid
                  </th>
                  <th className="px-2 py-2  w-2/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    Uploaded / Pulled
                  </th>
                  {props.isACtiveAndScheduleShow && (
                    <>
                      <th
                        className={`${
                          userType === UserRole.ADMIN ? 'w-2/5' : 'w-20'
                        } px-2 py-2 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal`}
                      ></th>
                    </>
                  )}
                  <th className="px-2 py-2  w-1/12 sticky top-0 bg-white  h-full mb-4 text-gray-dark text-base font-normal">
                    {userType === UserRole.ADMIN ? (
                      <button
                        type="button"
                        className="w-full h-full flex justify-center items-center"
                        onClick={() => props.handleRefresh && props.handleRefresh(props.tableType)}
                      >
                        <img src={Refresh} alt="refresh-icon" className="w-6 h-6" />
                      </button>
                    ) : null}
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr className="h-2"></tr>
            {data?.length ? (
              data.map((item: any, index: any) => {
                return (
                  <tr
                    key={item.id}
                    className="rounded text-gray-dark text-left h-12 border-y border-4 space-y-6 border-white"
                  >
                    {props.tableType === 'scheduled' && userType === UserRole.CLIENTS ? (
                      <>
                        <td className="py-1 w-2/12 blue-primary border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {props.isACtiveAndScheduleShow
                                ? moment(item?.billing_cycle).format('YYYY-MM-DD')
                                : moment(item.completed_at).format('YYYY-MM-DD')}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <button
                            type="button"
                            className="ml-2 flex items-center"
                            onClick={() =>
                              props.handleDeleteEligibility &&
                              props.handleDeleteEligibility(item.id)
                            }
                          >
                            <img src={XCricle} alt="x-circle" />
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="py-1 w-2/12 text-gray-dark text-bold font-bold border-bottom">
                          {item.validation_report?.record_count}
                        </td>
                        <td className="py-1 w-2/12 blue-primary border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {props.isACtiveAndScheduleShow
                                ? moment(item?.billing_cycle).format('YYYY-MM-DD')
                                : moment(item.completed_at).format('YYYY-MM-DD')}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.members_to_add?.length}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.existing_members?.length}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.members_to_remove?.length}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2">
                              {item.validation_report?.invalid_records?.length}
                            </p>
                          </div>
                        </td>
                        <td className="py-1 w-2/12 border-bottom">
                          <div className="flex">
                            <p className="text-gray-dark text-bold font-bold pr-2 capitalize">
                              {item.process_type}
                            </p>
                          </div>
                        </td>
                        {props.isACtiveAndScheduleShow && (
                          <>
                            <td className={`py-1 border-bottom`}>
                              <div className="w-full flex items-center">
                                {userType === UserRole.ADMIN ? (
                                  <>
                                    {item.status === 'transfer_pending' ? (
                                      <button
                                        className="px-2 py-1 text-sm btn-darkblue"
                                        onClick={() =>
                                          props.handleTranferEligibility &&
                                          props.handleTranferEligibility(item.id)
                                        }
                                      >
                                        IMPORT
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          className="px-2 py-1 text-sm btn-darkblue"
                                          onClick={() =>
                                            props.handleProcessEligibility &&
                                            props.handleProcessEligibility(item.id)
                                          }
                                        >
                                          PROCESS
                                        </button>
                                        <button
                                          className="px-2 py-1 text-sm btn-darkblue ml-2"
                                          type="button"
                                          onClick={() =>
                                            props.handleReplaceEligibility &&
                                            props.handleReplaceEligibility(
                                              item.id,
                                              item.scheduled_at
                                            )
                                          }
                                        >
                                          REPLACE
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : null}
                                <button
                                  type="button"
                                  className="ml-2 flex items-center"
                                  onClick={() =>
                                    props.handleDeleteEligibility &&
                                    props.handleDeleteEligibility(item.id)
                                  }
                                >
                                  <img src={XCricle} alt="x-circle" />
                                </button>
                              </div>
                            </td>
                          </>
                        )}
                        <td className="py-1 w-1/12 border-bottom">
                          {item.status !== 'transfer_pending' && (
                            <button
                              className="blue-primary"
                              onClick={() =>
                                props.handleEligibilityFileViewModel(item.id, props.tableType)
                              }
                            >
                              View
                            </button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center ext-gray-dark h-20">
                  {props.tableType === 'active' && 'No Active Eligibility'}
                  {props.tableType === 'scheduled' && 'No Scheduled Eligibility'}
                  {props.tableType === 'historical' && 'No Historic Eligibility'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default EligibilityTableList;
