import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPut, axiosPost, axiosDelete } from '../api/axios';

export const resourceQueryKeys = {
  globalResources: ['globalResources'] as const,
  resource: ['resource'] as const,
};

export interface Resource {
  notes: string;
  sort_weight: number;
  url_description?: string;
  client_id?: any;
  identifier: string;
  text_number?: string;
  status: string;
  url: string;
  image_url?: string;
  resource_tags?: ResourceTag[];
  id?: number;
  name: string;
  description: string;
  long_description?: string;
  phone_number: string;
  challenge_areas: number[] | null;
  subject_areas: number[] | null;
}

export interface ResourceTag {
  topic_id?: number;
  topic?: Topic;
  type: string;
  id: number;
  resource_id: number;
  subdivision?: string;
  city?: string;
  country?: string;
  partner: any;
  partner_id: any;
}

export interface Topic {
  audio_file_path?: string;
  identifier: string;
  morphii_metadata: MorphiiMetadaum[];
  id: number;
  sample_2_theme: any;
  is_featured: boolean;
  sample_1_audio_file_path: string;
  morphii: string[];
  theme: string;
  button_image_selected_file_path: string;
  sample_2_audio_file_path: any;
  is_required: boolean;
  sort_weight: number;
  status: string;
  button_image_file_path: string;
  sample_1_theme?: string;
  name: string;
  description: string;
  tag_id: number;
  is_default: boolean;
  is_primary: boolean;
}

export interface MorphiiMetadaum {
  id: string;
  label: string;
  delighted: Delighted;
  disappointed: Disappointed;
}

export interface Delighted {
  polarity: number;
}

export interface Disappointed {
  polarity: number;
}

const getGlobalResources = async (search: string, includeHidden: boolean): Promise<Resource[]> => {
  const data: any = {};
  if (includeHidden) {
    data['include_hidden'] = true;
  }
  return await axiosGet(
    `/resources/?search=${search}`,
    {
      ...data,
    },
    'v2'
  ).then((resourceResponse) => resourceResponse.data);
};

const getResource = async (resourceId: number): Promise<Resource> => {
  return await axiosGet(`/resources/${resourceId}`, null, 'v2').then(
    (resourceResponse) => resourceResponse.data
  );
};

const addResourceRequest = async (data: any): Promise<Resource> => {
  return await axiosPost(`/resources/`, data, 'v2').then(
    (response: { data: any }) => response.data
  );
};

const updateResourceRequest = async (resourceId: number, data: any): Promise<Resource> => {
  return await axiosPut(`/resources/${resourceId}`, data, 'v2').then(
    (response: { data: any }) => response.data
  );
};

const removeResourceRequest = async (resourceId: number): Promise<Resource> => {
  return await axiosDelete(`/resources/${resourceId}`, null, 'v2').then(
    (response: { data: any }) => response.data
  );
};

export const useGlobalResources = (search: string, includeHidden: boolean) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Resource[]>(
    ['globalResources', search, includeHidden],
    () => getGlobalResources(search, includeHidden)
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useResource = (resourceId: number) => {
  const { data, isLoading, error, refetch, isFetching } = useQuery<Resource>(
    [resourceQueryKeys.resource],
    () => getResource(resourceId)
  );
  return { data, isLoading, error, refetch, isFetching };
};

export const useAddResource = () => {
  const queryClient = useQueryClient();
  const addResource = useMutation((data: any) => addResourceRequest(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.globalResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.globalResources);
      console.log(error);
    },
    onMutate: (data) => {
      queryClient.setQueryData(resourceQueryKeys.globalResources, (oldData: any) => {
        if (oldData) {
          return [...oldData, data];
        }
        return oldData;
      });
    },
  });

  return {
    addResource,
  };
};

export const useUpdateResource = (resourceId: number) => {
  const queryClient = useQueryClient();
  const updateResource = useMutation((data: any) => updateResourceRequest(resourceId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.globalResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.globalResources);
      console.log(error);
    },
    onMutate: (data) => {
      queryClient.setQueryData(resourceQueryKeys.globalResources, (oldData: any) => {
        if (oldData) {
          return oldData.map((resource: Resource) => {
            if (resource.id === resourceId) {
              return { ...resource, ...data };
            }
            return resource;
          });
        }
        return oldData;
      });
    },
  });

  return {
    updateResource,
  };
};

export const useRemoveResource = (resourceId: number) => {
  const queryClient = useQueryClient();
  const removeResource = useMutation(() => removeResourceRequest(resourceId), {
    onSuccess: () => {
      queryClient.invalidateQueries(resourceQueryKeys.globalResources);
    },
    onError: (error) => {
      queryClient.invalidateQueries(resourceQueryKeys.globalResources);
      console.log(error);
    },
    onMutate: () => {
      queryClient.setQueryData(resourceQueryKeys.globalResources, (oldData: any) => {
        if (oldData) {
          return oldData.filter((resource: Resource) => resource.id !== resourceId);
        }
        return oldData;
      });
    },
  });

  return {
    removeResource,
  };
};
