import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
// import { deleteClientDetailsRequest, fetchClientsRequest } from './clients-redux/actions';
import Spinner from '../../../components/common/spinner';
import { ROUTE_PATH } from '../../../routes/paths';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import {
  fetchTagRequest,
  postTagsRequest,
  postTagsSuccess,
  removeTagsRequest,
  removeTagsSuccess,
  updateTagsRequest,
  fetchTagsListRequest,
  fetchTagsGroupRequest,
  postTagsGroupRequest,
  updateTagsGroupRequest,
  postTagsGroupSuccess,
  removeTagsGroupSuccess,
  fetchTagsGroupSuccess,
  removeTagsGroupRequest,
} from '../redux-tags/actions';
import { CustomInput, UseFormDropDown, UseFormToggle } from '../../../components/common';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { onBlur } from '../../../components/common/useForm';
import HeadBack from '../../clients/redirect';
import Model from '../../../components/common/model';

type props = {
  tags: any;
  pending: any;
  tagGroup: any;
  removeTags: any;
  newTag: any;
};

const TagListing: React.FunctionComponent<props> = (props): JSX.Element => {
  const [isShowAlertmodel, setIsShowAlertModel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const TagId = new URLSearchParams(search).get('tag-id');
  const [tagType, settagType] = useState('');
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [tagsList, setTags] = useState<any>([]);
  const [modeltagsList, setModeltagsList] = useState<any>([]);
  const [addTagspopUp, setaddTagspopUp] = useState(false);

  useEffect(() => {
    dispatch(fetchTagsListRequest({ include_hidden: true }));
    return () => {
      dispatch(fetchTagsGroupSuccess({ TagsGroup: [] }));
      dispatch(postTagsGroupSuccess({ TagsGroup: {} }));
      dispatch(removeTagsGroupSuccess({ RemoveTagsGroup: {} }));
      dispatch(removeTagsSuccess({ RemoveTags: {} }));
    };
  }, []);

  useEffect(() => {
    if (TagId && TagId !== 'new') {
      dispatch(
        fetchTagsGroupRequest({
          id: TagId,
          payload: { include_hidden: true },
        })
      );
    } else if (TagId === 'new') {
      setModeltagsList([]);
      setTags([]);
    }
  }, [TagId]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      if (props.tags && TagId) {
        const Tag = props.tagGroup;
        if (TagId === 'new') {
          return {
            name: '',
            tag_group_type: 'trait',
          };
        } else {
          return {
            ...Tag,
          };
        }
      }
    }, [props.tagGroup, TagId]),
  });
  useEffect(() => {
    setenableSaveOption(false);
    if (TagId) {
      const Tag = props.tagGroup;
      if (TagId === 'new') {
        reset({
          name: '',
          tag_group_type: 'topic',
        });
      } else if (props.tagGroup) {
        reset({
          ...Tag,
        });
      }
    }
  }, [props.tagGroup, TagId]);

  const onSubmit = (data: any) => {
    const payload = {
      ...data,
      tags: undefined,
      tag_ids: tagsList.filter((tag: any) => tag.status).map((tag: any) => tag.id),
    };
    if (TagId === 'new') {
      dispatch(
        postTagsGroupRequest({
          tag_group_type: data.tag_group_type,
          name: data.name,
          tag_ids: tagsList.filter((tag: any) => tag.status).map((tag: any) => tag.id),
        })
      );
    } else {
      dispatch(updateTagsGroupRequest({ id: TagId, payload }));
    }
  };

  const handleDelete = () => {
    setIsShowAlertModel(false);
    dispatch(removeTagsGroupRequest({ id: TagId }));
  };

  useEffect(() => {
    if (props.removeTags) {
      history.push(ROUTE_PATH.TAG_GROUPS);
    }
  }, [props.removeTags]);

  useEffect(() => {
    if (props.newTag) {
      history.push(ROUTE_PATH.TAG_GROUPS);
    }
  }, [props.newTag]);

  const setToggle = (status: any, id: any, from: string = 'edit') => {
    const updatedTags = modeltagsList.map((item: any) => {
      if (item.id === id) {
        return { ...item, status };
      } else {
        return item;
      }
    });
    from === 'edit' && setTags(updatedTags);
    setModeltagsList(updatedTags);
    setenableSaveOption(true);
  };

  const handleAddTags = () => {
    setaddTagspopUp(false);
    setTags(modeltagsList);
  };
  const handleCancel = () => {
    setaddTagspopUp(false);
    setModeltagsList(tagsList);
  };

  useEffect(() => {
    if (props.tags?.length > 0 && (props.tagGroup || TagId === 'new')) {
      const selectedTags: any[] =
        props.tagGroup?.tags?.length > 0 ? props.tagGroup.tags.map((item: any) => item.id) : [];

      const tagsArr: any[] = props.tags?.map((item: any) => ({
        ...item,
        status: !!selectedTags?.includes(item.id),
      }));
      settagType(TagId === 'new' ? 'topic' : props.tagGroup.tag_group_type);
      setTags(tagsArr);
      setModeltagsList(tagsArr);
      setenableSaveOption(false);
    }
  }, [props.tags, props.tagGroup]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'tag_group_type' && type === 'change') {
        settagType(value.tag_group_type);
        const resetTags = props.tags?.map((item: any) => ({
          ...item,
          status: false,
        }));
        setTags(resetTags);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <Header
        heading={
          <HeadBack
            clientName={TagId === 'new' ? 'Tag Group' : getValues('name')}
            headback={ROUTE_PATH.TAG_GROUPS}
          />
        }
      />
      <div className="h-10 bg-gray-background-light flex px-7 justify-end items-center">
        <div className="flex">
          {' '}
          <button
            className=" rounded-full w-20 text-center h-6 text-sm text-white bg-bright-blue px-2"
            onClick={() => {
              TagId === 'new' ? history.push(ROUTE_PATH.TAG_GROUPS) : setIsShowAlertModel(true);
            }}
          >
            {TagId === 'new' ? 'CANCEL' : 'REMOVE'}
          </button>
          <div className={` ${!isDirty && !enableSaveOption && 'cursor-not-allowed'}`}>
            <button
              className={` rounded-full w-20 text-center h-6 text-sm text-white px-2 ml-2 ${
                isDirty || enableSaveOption
                  ? 'bg-bright-blue'
                  : 'bg-gray-background-dark pointer-events-none  '
              }`}
              type="submit"
              form="member-profile-form"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="member-profile-form">
        <div className="max-window-height pb-10 overflow-y-auto ">
          <div className="w-full px-3">
            {props.pending ? (
              <Spinner />
            ) : (
              <div className="w-full grid grid-cols-2 gap-x-6 px-7 mt-5 gap-y-6">
                <div className=" flex flex-col space-y-4">
                  <CustomInput
                    inputType="text"
                    Label="Tag Group Name"
                    register={{
                      ...register('name', {
                        required: 'Please provide Tag name',
                        onBlur: (e: any) => onBlur(e, 'name', setValue),
                      }),
                    }}
                    //@ts-ignore
                    Error={errors?.name?.message?.length ? true : false}
                    ErrorMessage={errors.name?.message}
                  />

                  {TagId === 'new' ? (
                    <UseFormDropDown label={'Type'}>
                      <select
                        className="select-box w-full"
                        defaultValue="trait"
                        {...register('tag_group_type')}
                      >
                        <option value={'trait'}>Trait</option>
                        <option value={'topic'}>Topic</option>
                      </select>
                    </UseFormDropDown>
                  ) : (
                    <>
                      <p className=" ">Type</p>
                      <p className=" font-bold capitalize">{tagType}</p>
                    </>
                  )}
                </div>
                <div className="row-span-3">
                  <div className=" leading-4 text-gray-dark mb-2 border-b border-gray-dark py-1 flex justify-between h-10 items-center">
                    <p>Tags Associated with Group</p>
                    <button
                      className="bg-gray-dark text-white py-2 rounded-full px-3 "
                      type="button"
                      onClick={() => {
                        setaddTagspopUp(true);
                      }}
                    >
                      + ADD
                    </button>
                  </div>
                  <div className=" grid grid-cols-2 gap-x-2 ">
                    {tagsList?.length > 0 &&
                    tagsList.filter(
                      (tag: any) => tag.tag_type.toLowerCase() === tagType && tag.status
                    ) ? (
                      tagsList
                        .filter((tag: any) => tag.tag_type.toLowerCase() === tagType && tag.status)
                        .map((item: any) => {
                          return (
                            <button
                              className={`bg-blue-primary text-white text-sm font-bold mx-2 my-1 w-full`}
                            >
                              <div className=" px-3 py-2 flex  justify-between ">
                                <div className="  pr-2">{item.name}</div>
                                <button
                                  type="button"
                                  className="text-white font-bold"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false, item.id);
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </button>
                          );
                        })
                    ) : (
                      <p className="m-2"> No Listener Tags </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <Model
        isModel={addTagspopUp}
        InnerComponent={
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                    Add Tag(s) to Group
                  </h3>
                  <h3 className="text-lg leading-6  text-gray-900  mb-3" id="modal-title">
                    Select tag(s) from the list below to add them to this group.
                  </h3>
                  <div className=" grid grid-cols-2 gap-x-2 max-window-height-audio-reviews overflow-y-scroll ">
                    {modeltagsList?.length > 0 &&
                      modeltagsList
                        .filter((tag: any) => tag.tag_type.toLowerCase() === tagType)
                        .map((item: any, index: number) => {
                          return (
                            <button
                              className={`${
                                item.status ? ' bg-blue-primary ' : ' bg-gray-background-light '
                              } text-white  text-sm font-bold space-y-2 mt-2`}
                              onClick={(e) => {
                                e.preventDefault();
                                setToggle(!item.status, item.id, 'modal');
                              }}
                            >
                              <div className="flex px-3 py-2 justify-between">
                                <div className="  pr-2">{item.name}</div>
                                <button
                                  type="button"
                                  className="text-white font-bold"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setToggle(false, item.id, 'modal');
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </button>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-evenly mx-20 space-x-10">
              <button
                className={
                  'w-full py-1 bg-white rounded-full border-2 border-blue-primary  text-blue-primary font-bold'
                }
                onClick={handleCancel}
              >
                CANCEL
              </button>
              <button
                onClick={handleAddTags}
                className={'w-full py-1 text-white rounded-full  font-bold bg-blue-primary border'}
              >
                ADD TAGS
              </button>
            </div>
          </div>
        }
      />
      <AlertBox
        visible={isShowAlertmodel}
        handlePrompt={handleDelete}
        closeModal={() => setIsShowAlertModel(false)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove ${getValues('name')}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tags: state.tags.TagsList?.data?.data || null,
    tagGroup: state.tags.TagsGroup?.data || null, //
    removeTags: state.tags.RemoveTagsGroup?.data || null, // newTagGroup
    pending: state.tags?.pending || null,
    newTag: state.tags.newTagGroup?.data || null, //
  };
};

export default connect(mapStateToProps)(TagListing);
