import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_CONNECTIONS_FAILURE,
  FETCH_MEMBERS_CONNECTIONS_SUCCESS,
  FETCH_MEMBERS_CONNECTIONS_REQUEST,
  FETCH_MEMBERS_CONNECTIONS_COUNT_FAILURE,
  FETCH_MEMBERS_CONNECTIONS_COUNT_SUCCESS,
  FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST,
  GET_MEMBER_LOGS_REQUEST,
  GET_MEMBER_LOGS_SUCCESS,
  GET_MEMBER_LOGS_FAILURE,
  FETCH_MEMBERS_LOGS_COUNT_REQUEST,
  FETCH_MEMBERS_LOGS_COUNT_SUCCESS,
  FETCH_MEMBERS_LOGS_COUNT_FAILURE,
} from './actionTypes';
import {
  FETCH_MEMBERS_COUNT_REQUEST,
  FETCH_MEMBERS_COUNT_SUCCESS,
  FETCH_MEMBERS_COUNT_FAILURE,
} from './actionTypes';
import {
  GET_MEMBER_PROFILE_REQUEST,
  GET_MEMBER_PROFILE_SUCCESS,
  GET_MEMBER_PROFILE_FAILURE,
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILURE,
  POST_REDEEM_PARTNER_CODE_REQUEST,
  POST_REDEEM_PARTNER_CODE_SUCCESS,
  POST_REDEEM_PARTNER_CODE_FAILURE,
} from './actionTypes';

import { MembersActions, MembersState } from './types';

const initialState: MembersState = {
  pending: false,
  MEMBERS: [],
  error: null,
  memberProfile: [],
  memberProfileError: null,
  topics: [],
  topicsError: null,
  redeemPartnerCode: [],
  redeemPartnerCodeError: null,
};

const Reducer = (state = initialState, action: MembersActions) => {
  switch (action.type) {
    case FETCH_MEMBERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        pending: false,
        MEMBERS: action.payload,
        error: null,
      };
    case FETCH_MEMBERS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_MEMBERS_CONNECTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBERS_CONNECTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        MEMBERS_CONNECTIONS: action.payload,
        error: null,
      };
    case FETCH_MEMBERS_CONNECTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_MEMBERS_CONNECTIONS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBERS_CONNECTIONS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        MEMBERS_CONNECTIONS_COUNT: action.payload,
        error: null,
      };
    case FETCH_MEMBERS_CONNECTIONS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case GET_MEMBER_PROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_MEMBER_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        memberProfile: action.payload,
        memberProfileError: null,
      };
    case GET_MEMBER_PROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        memberProfileError: action.payload,
      };
    // fetch topic list
    case FETCH_TOPICS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_TOPICS_SUCCESS:
      return {
        ...state,
        pending: false,
        topics: action.payload,
        topicsError: null,
      };
    case FETCH_TOPICS_FAILURE:
      return {
        ...state,
        pending: false,
        topicsError: action.payload,
      };

    case GET_MEMBER_LOGS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_MEMBER_LOGS_SUCCESS:
      return {
        ...state,
        pending: false,
        MemberLogs: action.payload,
        MemberLogsError: null,
      };
    case GET_MEMBER_LOGS_FAILURE:
      return {
        ...state,
        pending: false,
        MemberLogsError: action.payload,
      };
    case POST_REDEEM_PARTNER_CODE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case POST_REDEEM_PARTNER_CODE_SUCCESS:
      return {
        ...state,
        pending: false,
        redeemPartnerCode: action.payload,
        redeemPartnerCodeError: null,
      };
    case POST_REDEEM_PARTNER_CODE_FAILURE:
      return {
        ...state,
        pending: false,
        redeemPartnerCodeError: action.payload,
      };
    case FETCH_MEMBERS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBERS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        MEMBERS_COUNT: action.payload,
        error: null,
      };
    case FETCH_MEMBERS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FETCH_MEMBERS_LOGS_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_MEMBERS_LOGS_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        MembersLogCount: action.payload,
        error: null,
      };
    case FETCH_MEMBERS_LOGS_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
