import React, { useEffect } from 'react';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from '../clients/clients-constants';
import { useDispatch, connect } from 'react-redux';
import CalendarPaginator from '../../components/calender-paginator';
import { NumericLabel } from '../../components/common';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { StackedBarChart } from '../../components/charts';
import { VerticalBarChart } from '../../components/charts';
import { getClientDetailsRequest } from '../clients/clients-redux/actions';
type props = {
  client: any;
};
const options = {
  title: '',
  chartArea: { width: '50%', height: '90%' },
  colors: ['#1D1183'],
  legend: { position: 'none' },
  hAxis: {
    title: '',
    minValue: 0,
  },
  vAxis: {
    title: '',
  },
};

const data = [
  ['City', '2010 Population', { role: 'style' }, { role: 'annotation' }],
  ['New York City', 8175500, '', 8175500],
  ['New York City', 8175500, '', 8175500],
  ['New York Citym', 8174000, '', 8174000],
  ['Los Angeles', 3792300, '', 3792300],
  ['L A', 3792200, '', 3792200],
  ['Los Angels', 3791000, '', 3791000],
  ['Chicago', 2695900, '', 2695900],
  ['Chiccago', 2695400, '', 2695400],
  ['Chicago0', 2695100, '', 2695100],
  ['Houstoni', 2098000, '', 2098000],
  ['Housten', 2097000, '', 2097000],
  ['Houstren', 2097000, '', 2097000],
  ['Philadelphia', 1526000, '', 1526000],
  ['Philadelphib', 1525000, '', 1525000],
  ['Philadelphic', 1524000, '', 1524000],
];

const Connections = () => {
  return (
    <div className="w-full py-3">
      <div className=" flex justify-between w-full  h-2/3 items-end">
        <StackedBarChart xwidth={'14%'} backGroundColor={'stacked-bar-red-primary'} />
        <StackedBarChart xwidth={'63%'} backGroundColor={'stacked-bar-orange-primary'} />
        <StackedBarChart xwidth={'23%'} backGroundColor={'stacked-bar-green-primary'} />
      </div>
      <div className="text-sm text-gray-dark flex items-center py-1">
        {' '}
        Negative / Neutral / Positive
      </div>
    </div>
  );
};

const Configure: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();

  const clientId = localStorage.getItem('clientid');

  useEffect(() => {
    if (clientId) {
      dispatch(getClientDetailsRequest({ clientId }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* <Header userName={"Jason"} heading={<HeadBack clientName={ClientName}/>} /> */}
      <div className=" bg-gray-background-light px-2 py-4 border-b gray-border-line h-20">
        <div className="flex justify-between items-center py-2 px-4 gray-background-light ">
          <div className="text-xl font-bold blue-primary flex items-center">
            <img className="h-8 w-8 mr-3" src={RightArrow} alt="left Arrow"></img>Sample Group
          </div>

          <div className="flex font-medium text-sm justify-between items-center text-gray-dark">
            <button className="px-5 py-1   text-white  rounded-full  stacked-bar-orange-primary mx-5">
              SET UP
            </button>
            <div className="px-3">Dec 14th 2021 </div>
            <div className="px-3">{`Hello, Jason`}</div>
            <button className="rounded-full text-sm bg-white px-5 py-2">LOG OUT </button>
          </div>
        </div>
      </div>
      <SwitchBar heading={HeaderOptions} position={3} />
      <CalendarPaginator />

      <div className="max-window-height-group overflow-y-auto px-7">
        <div className="w-full ">
          <div className="flex justify-between py-4">
            <div className="w-1/4 text-gray-dark font-bold font-base border-b border-gray-dark ">
              Total Eligible Members
            </div>
            <div className="w-1/4 text-gray-dark font-bold font-base border-b border-gray-dark ">
              Activated Members
            </div>
            <div className="w-1/4 text-gray-dark font-bold font-base border-b border-gray-dark ">
              Engaged Members
            </div>
            <div className="w-1/4 text-gray-dark font-bold font-base  border-b border-gray-dark ">
              Connected Members
            </div>
          </div>
          <div className="flex justify-between">
            <div className="w-1/4 ">
              <NumericLabel numericValue={'15,382'} label={''} />
            </div>
            <div className="w-1/4  ">
              <NumericLabel numericValue={'9,754'} label={'63%'} />
            </div>
            <div className="w-1/4  ">
              <NumericLabel numericValue={'5,766'} label={'37%'} />
            </div>
            <div className="w-1/4  ">
              <NumericLabel numericValue={'3,651'} label={'23%'} />
            </div>
          </div>
        </div>

        <div className="flex justify-between ">
          <div className="w-full flex-col">
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Check-ins
            </div>
            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-2xl py-3 blue-primary">6439</div>
              <div className="flex-col">
                <div className="text-2xl py-3 blue-primary">19%</div>
                <span>36,455 sent</span>
              </div>
            </div>

            <div className="relative  w-full  py-3 border-b text-base font-bold text-gray-dark">
              Mood Distribution
            </div>
            <Connections />

            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Story Listens
            </div>
            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-2xl py-3 blue-primary">6439</div>
              <div className="flex-col">
                <div className="text-2xl py-3 blue-primary">19%</div>
                <span>Of Members</span>
              </div>
            </div>

            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark">
              Calls
            </div>
            <div className="w-3/4 flex justify-between">
              {/* <Histogram error={false} data={data} options={options} /> */}
              <div className="text-2xl py-3 blue-primary">6439</div>
              <div className="flex-col">
                <div className="text-2xl py-3 blue-primary">19%</div>
                <span>Of Members</span>
              </div>
            </div>

            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark flex">
              Engagement by Tag
            </div>
            <div className="w-3/4">
              {/* <WeeklyChart error={false} /> */}
              <VerticalBarChart data={data} options={options} />
            </div>
          </div>
          <div className="w-full px-7">
            <div className="relative  w-full  py-3 border-b gray-border-line text-base font-bold text-gray-dark flex">
              Topics of Interests
            </div>
            <div className="w-1/2">
              {/* <WeeklyChart error={false} /> */}
              <VerticalBarChart data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    client: state.Client.ClientDetials?.data || null,
  };
};

export default connect(mapStateToProps)(Configure);
