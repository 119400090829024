import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { NavigationBar } from '../containers';
import { useLocation } from 'react-router-dom';

type props = {
  exact: boolean;
  path: string;
  component: any;
  role: string;
  selectedId: number;
};

const PrivateRoute: React.FunctionComponent<props> = ({
  exact,
  path,
  component: Component,
  role,
  selectedId,
}): JSX.Element => {
  const [isAuthenticated, setisAuthenticated] = useState<Boolean>(true);
  const location = useLocation();
  const checkAuthentication = () => {
    try {
      const token = localStorage.getItem('authorization_token');
      const roleType = localStorage.getItem('role_type');

      if (token && roleType === role) {
        setisAuthenticated(true);
      } else {
        localStorage.clear();
        setisAuthenticated(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return isAuthenticated ? (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <div className="flex  max-h-screen">
          <div className="flex">
            <NavigationBar selectedId={selectedId} />
          </div>
          <div className="flex-1 max-h-screen pl-64">
            <Component />
          </div>
        </div>
      )}
    />
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
