import {
  POST_TAGS_REQUEST,
  POST_TAGS_FAILURE,
  POST_TAGS_SUCCESS,
  FETCH_TAGS_LIST_REQUEST,
  FETCH_TAGS_LIST_FAILURE,
  FETCH_TAGS_LIST_SUCCESS,
  UPDATE_TAGS_REQUEST,
  UPDATE_TAGS_FAILURE,
  UPDATE_TAGS_SUCCESS,
  REMOVE_TAGS_REQUEST,
  REMOVE_TAGS_FAILURE,
  REMOVE_TAGS_SUCCESS,
  FETCH_TAGS_COUNT_REQUEST,
  FETCH_TAGS_COUNT_FAILURE,
  FETCH_TAGS_COUNT_SUCCESS,
  FETCH_TAG_REQUEST,
  FETCH_TAG_FAILURE,
  FETCH_TAG_SUCCESS,
  FETCH_TAG_SORT_REQUEST,
  FETCH_TAGS_GROUP_COUNT_FAILURE,
  FETCH_TAGS_GROUP_COUNT_REQUEST,
  FETCH_TAGS_GROUP_COUNT_SUCCESS,
  FETCH_TAGS_GROUP_LIST_FAILURE,
  FETCH_TAGS_GROUP_LIST_REQUEST,
  FETCH_TAGS_GROUP_LIST_SUCCESS,
  POST_TAGS_GROUP_FAILURE,
  POST_TAGS_GROUP_REQUEST,
  POST_TAGS_GROUP_SUCCESS,
  REMOVE_TAGS_GROUP_FAILURE,
  REMOVE_TAGS_GROUP_REQUEST,
  REMOVE_TAGS_GROUP_SUCCESS,
  UPDATE_TAGS_GROUP_FAILURE,
  UPDATE_TAGS_GROUP_REQUEST,
  UPDATE_TAGS_GROUP_SUCCESS,
  FETCH_TAGS_GROUP_FAILURE,
  FETCH_TAGS_GROUP_REQUEST,
  FETCH_TAGS_GROUP_SUCCESS,
} from './action-types';

import {
  FetchTagsCountRequest,
  FetchTagsCountSuccess,
  FetchTagsCountSuccessPayload,
  FetchTagsCountFailure,
  FetchTagsCountFailurePayload,
  PostTagsRequest,
  PostTagsSuccess,
  PostTagsSuccessPayload,
  PostTagsFailure,
  PostTagsFailurePayload,
  FetchTagsListRequest,
  FetchTagsListSuccess,
  FetchTagsListSuccessPayload,
  FetchTagsListFailure,
  FetchTagsListFailurePayload,
  UpdateTagsRequest,
  UpdateTagsSuccess,
  UpdateTagsSuccessPayload,
  UpdateTagsFailure,
  UpdateTagsFailurePayload,
  RemoveTagsRequest,
  RemoveTagsSuccess,
  RemoveTagsSuccessPayload,
  RemoveTagsFailure,
  RemoveTagsFailurePayload,
  FetchTagRequest,
  FetchTagSuccess,
  FetchTagSuccessPayload,
  FetchTagFailure,
  FetchTagFailurePayload,
  FetchTagsGroupCountFailure,
  FetchTagsGroupCountFailurePayload,
  FetchTagsGroupCountRequest,
  FetchTagsGroupCountSuccess,
  FetchTagsGroupCountSuccessPayload,
  FetchTagsGroupListFailure,
  FetchTagsGroupListFailurePayload,
  FetchTagsGroupListRequest,
  FetchTagsGroupListSuccess,
  FetchTagsGroupListSuccessPayload,
  PostTagsGroupFailure,
  PostTagsGroupFailurePayload,
  PostTagsGroupRequest,
  PostTagsGroupSuccess,
  PostTagsGroupSuccessPayload,
  RemoveTagsGroupFailure,
  RemoveTagsGroupFailurePayload,
  RemoveTagsGroupRequest,
  RemoveTagsGroupSuccess,
  RemoveTagsGroupSuccessPayload,
  UpdateTagsGroupFailure,
  UpdateTagsGroupFailurePayload,
  UpdateTagsGroupRequest,
  UpdateTagsGroupSuccess,
  UpdateTagsGroupSuccessPayload,
  FetchTagsGroupFailure,
  FetchTagsGroupFailurePayload,
  FetchTagsGroupRequest,
  FetchTagsGroupSuccess,
  FetchTagsGroupSuccessPayload,
} from './types';

export const postTagsRequest = (payload: any): PostTagsRequest => ({
  type: POST_TAGS_REQUEST,
  payload,
});

export const postTagsSuccess = (payload: PostTagsSuccessPayload): PostTagsSuccess => ({
  type: POST_TAGS_SUCCESS,
  payload,
});

export const postTagsFailure = (payload: PostTagsFailurePayload): PostTagsFailure => ({
  type: POST_TAGS_FAILURE,
  payload,
});

export const fetchTagsListRequest = (payload: any): FetchTagsListRequest => ({
  type: FETCH_TAGS_LIST_REQUEST,
  payload,
});

export const fetchTagsListSuccess = (
  payload: FetchTagsListSuccessPayload
): FetchTagsListSuccess => ({
  type: FETCH_TAGS_LIST_SUCCESS,
  payload,
});

export const fetchTagsListFailure = (
  payload: FetchTagsListFailurePayload
): FetchTagsListFailure => ({
  type: FETCH_TAGS_LIST_FAILURE,
  payload,
});

export const updateTagsRequest = (payload: any): UpdateTagsRequest => ({
  type: UPDATE_TAGS_REQUEST,
  payload,
});

export const updateTagsSuccess = (payload: UpdateTagsSuccessPayload): UpdateTagsSuccess => ({
  type: UPDATE_TAGS_SUCCESS,
  payload,
});

export const updateTagsFailure = (payload: UpdateTagsFailurePayload): UpdateTagsFailure => ({
  type: UPDATE_TAGS_FAILURE,
  payload,
});

export const removeTagsRequest = (payload: any): RemoveTagsRequest => ({
  type: REMOVE_TAGS_REQUEST,
  payload,
});

export const removeTagsSuccess = (payload: RemoveTagsSuccessPayload): RemoveTagsSuccess => ({
  type: REMOVE_TAGS_SUCCESS,
  payload,
});

export const removeTagsFailure = (payload: RemoveTagsFailurePayload): RemoveTagsFailure => ({
  type: REMOVE_TAGS_FAILURE,
  payload,
});

export const fetchTagsCountRequest = (payload: any): FetchTagsCountRequest => ({
  type: FETCH_TAGS_COUNT_REQUEST,
  payload,
});

export const fetchTagsCountSuccess = (
  payload: FetchTagsCountSuccessPayload
): FetchTagsCountSuccess => ({
  type: FETCH_TAGS_COUNT_SUCCESS,
  payload,
});

export const fetchTagsCountFailure = (
  payload: FetchTagsCountFailurePayload
): FetchTagsCountFailure => ({
  type: FETCH_TAGS_COUNT_FAILURE,
  payload,
});

export const fetchTagRequest = (payload: any): FetchTagRequest => ({
  type: FETCH_TAG_REQUEST,
  payload,
});

export const fetchTagSuccess = (payload: FetchTagSuccessPayload): FetchTagSuccess => ({
  type: FETCH_TAG_SUCCESS,
  payload,
});

export const fetchTagFailure = (payload: FetchTagFailurePayload): FetchTagFailure => ({
  type: FETCH_TAG_FAILURE,
  payload,
});

export const fetchTagsSortListRequest = (payload: any): any => ({
  type: FETCH_TAG_SORT_REQUEST,
  payload,
});

export const postTagsGroupRequest = (payload: any): PostTagsGroupRequest => ({
  type: POST_TAGS_GROUP_REQUEST,
  payload,
});

export const postTagsGroupSuccess = (
  payload: PostTagsGroupSuccessPayload
): PostTagsGroupSuccess => ({
  type: POST_TAGS_GROUP_SUCCESS,
  payload,
});

export const postTagsGroupFailure = (
  payload: PostTagsGroupFailurePayload
): PostTagsGroupFailure => ({
  type: POST_TAGS_GROUP_FAILURE,
  payload,
});

export const fetchTagsGroupListRequest = (payload: any): FetchTagsGroupListRequest => ({
  type: FETCH_TAGS_GROUP_LIST_REQUEST,
  payload,
});

export const fetchTagsGroupListSuccess = (
  payload: FetchTagsGroupListSuccessPayload
): FetchTagsGroupListSuccess => ({
  type: FETCH_TAGS_GROUP_LIST_SUCCESS,
  payload,
});

export const fetchTagsGroupListFailure = (
  payload: FetchTagsGroupListFailurePayload
): FetchTagsGroupListFailure => ({
  type: FETCH_TAGS_GROUP_LIST_FAILURE,
  payload,
});

export const updateTagsGroupRequest = (payload: any): UpdateTagsGroupRequest => ({
  type: UPDATE_TAGS_GROUP_REQUEST,
  payload,
});

export const updateTagsGroupSuccess = (
  payload: UpdateTagsGroupSuccessPayload
): UpdateTagsGroupSuccess => ({
  type: UPDATE_TAGS_GROUP_SUCCESS,
  payload,
});

export const updateTagsGroupFailure = (
  payload: UpdateTagsGroupFailurePayload
): UpdateTagsGroupFailure => ({
  type: UPDATE_TAGS_GROUP_FAILURE,
  payload,
});

export const removeTagsGroupRequest = (payload: any): RemoveTagsGroupRequest => ({
  type: REMOVE_TAGS_GROUP_REQUEST,
  payload,
});

export const removeTagsGroupSuccess = (
  payload: RemoveTagsGroupSuccessPayload
): RemoveTagsGroupSuccess => ({
  type: REMOVE_TAGS_GROUP_SUCCESS,
  payload,
});

export const removeTagsGroupFailure = (
  payload: RemoveTagsGroupFailurePayload
): RemoveTagsGroupFailure => ({
  type: REMOVE_TAGS_GROUP_FAILURE,
  payload,
});

export const fetchTagsGroupCountRequest = (payload: any): FetchTagsGroupCountRequest => ({
  type: FETCH_TAGS_GROUP_COUNT_REQUEST,
  payload,
});

export const fetchTagsGroupCountSuccess = (
  payload: FetchTagsGroupCountSuccessPayload
): FetchTagsGroupCountSuccess => ({
  type: FETCH_TAGS_GROUP_COUNT_SUCCESS,
  payload,
});

export const fetchTagsGroupCountFailure = (
  payload: FetchTagsGroupCountFailurePayload
): FetchTagsGroupCountFailure => ({
  type: FETCH_TAGS_GROUP_COUNT_FAILURE,
  payload,
});

export const fetchTagsGroupRequest = (payload: any): FetchTagsGroupRequest => ({
  type: FETCH_TAGS_GROUP_REQUEST,
  payload,
});

export const fetchTagsGroupSuccess = (
  payload: FetchTagsGroupSuccessPayload
): FetchTagsGroupSuccess => ({
  type: FETCH_TAGS_GROUP_SUCCESS,
  payload,
});

export const fetchTagsGroupFailure = (
  payload: FetchTagsGroupFailurePayload
): FetchTagsGroupFailure => ({
  type: FETCH_TAGS_GROUP_FAILURE,
  payload,
});
