import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput, SectionLabel } from '../../../components/common';
import AccordionTile from '../Accordion';
import {
  postCreatePackageProductRequest,
  postUpdatePackageProductRequest,
} from '../clients-redux/actions';

type props = {
  data: any;
  default: any;
  UpdateSaveOption: (data: boolean) => void;
};

const RewardsPTO: React.FunctionComponent<props> = (props): JSX.Element => {
  const [image, setimgPreview] = useState<any>();
  const fileInput: any = React.createRef();
  const [rewardPto, setrewardPto] = useState(true);
  const [rewardMilestone, setrewardMilestone] = useState(0);
  const [rewardMilestomeValue, setrewardMilestomeValue] = useState(0);
  const [productId, setproductId] = useState<any>();
  const [packageId, setpackageId] = useState<any>();
  const [preloadedImage, setpreloadedImage] = useState(false);
  const [clientId, setclientId] = useState<any>();
  const [enableSaveOption, setenableSaveOption] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState<any>({});

  const [id, setid] = useState();
  const dispatch = useDispatch();
  const onFileChange = async (event: any) => {
    try {
      //

      if (event.target.files && event.target.files[0]) {
        // setimage(event.target.files[0]);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          setimgPreview(e.target.result);
          setpreloadedImage(false);
        };
        reader.readAsDataURL(event.target.files[0]);
      }

      // Update the state
    } catch (error) {
      console.error('err--', error);
    }
  };

  const hadleImageinpuy = (e: any) => {
    fileInput.current.click();
  };

  useEffect(() => {
    enableSaveOption && props.UpdateSaveOption(enableSaveOption);
  }, [enableSaveOption]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.data) {
      const packageProducts = props.data.package_products;

      const selectedItem = packageProducts.find((item: any) => {
        if (item.product.key === 'REWARD_PTO') {
          return true;
        }
        return false;
      });

      if (selectedItem) {
        setrewardPto(selectedItem?.status === 'enabled' ? true : false);

        const configuration = selectedItem.configuration;
        setrewardMilestone(configuration.required_points);
        setrewardMilestomeValue(configuration.pto_reward_value);
        if (configuration.image_url) {
          setimgPreview(configuration.image_url);
          setpreloadedImage(true);
        }
        setpackageId(selectedItem && selectedItem.package_id);
        setproductId(selectedItem && selectedItem.product_id);
        setid(selectedItem && selectedItem.id);
      } else {
        const configuration = props.default.configuration;
        setrewardPto(false);
        setrewardMilestone(configuration.required_points);
        setrewardMilestomeValue(configuration.pto_reward_value);
        if (configuration.image_url) {
          setimgPreview(configuration.image_url);
          setpreloadedImage(true);
        }
        setpackageId(props.data.id);
        setid(props.default.id);
      }
      const clientId = localStorage.getItem('clientid');
      setclientId(clientId);
      setenableSaveOption(false);
    }
  }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const Validation = () => {
    // if (!rewardMilestone || !rewardMilestomeValue) {
    //     seterror(true);
    //     return false;
    // }
    if (rewardPto) {
      if (!rewardMilestone) {
        setErrorMessage({ rewardMilestone: 'Field is required' });
        return false;
      } else if (rewardMilestone.toString().indexOf('.') !== -1) {
        setErrorMessage({
          rewardMilestone: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (rewardMilestone < 1 || rewardMilestone > 50) {
        setErrorMessage({ rewardMilestone: 'Must be a number between 1 and 50' });
        return false;
      } else if (!rewardMilestomeValue) {
        setErrorMessage({ rewardMilestomeValue: 'Field is required' });
        return false;
      } else if (rewardMilestomeValue.toString().indexOf('.') !== -1) {
        setErrorMessage({
          rewardMilestomeValue: 'Number may not include decimals - whole numbers only',
        });
        return false;
      } else if (rewardMilestomeValue < 1 || rewardMilestomeValue > 50) {
        setErrorMessage({ rewardMilestomeValue: 'Must be a number between 1 and 50' });
        return false;
      }
    }
    setErrorMessage({});
    return true;
  };

  const onSave = () => {
    const payload = {
      status: rewardPto ? 'enabled' : 'disabled',
      configuration: {
        pto_reward_value: +rewardMilestomeValue,
        required_points: +rewardMilestone,
        image_url: !preloadedImage ? image : image === null ? null : undefined,
      },
    };

    const linkInput = {
      clientId,
      packageId,
      productId,
      id,
    };

    if (Validation()) {
      if (productId) {
        dispatch(postUpdatePackageProductRequest({ linkInput, payload }));
      } else {
        dispatch(postCreatePackageProductRequest({ linkInput, payload }));
      }
    }
  };

  return (
    <AccordionTile
      setStatus={(data: boolean) => {
        if ((!data && Validation()) || data) {
          !enableSaveOption && setenableSaveOption(true);
          setrewardPto(data);
        }
      }}
      Label={'REWARD PTO '}
      status={rewardPto}
      buttonText={'SAVE'}
      id={'rewardPto'}
      disableButton={enableSaveOption}
      onButtonClick={() => {
        if (enableSaveOption) onSave();
      }}
      initialstatus={true}
      InnerComponent={
        <div className="flex justify-evenly gap-7 my-10">
          <div className="w-full ">
            <div className="py-4">
              <CustomInput
                inputType={'number'}
                value={rewardMilestone}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setrewardMilestone(data);
                }}
                Label={'Reward Milestone'}
                Error={ErrorMessage?.rewardMilestone?.length > 0 ? true : false}
                ErrorMessage={ErrorMessage?.rewardMilestone}
              />
            </div>
            <div className="py-4">
              <CustomInput
                inputType={'number'}
                value={rewardMilestomeValue}
                setValue={(data) => {
                  !enableSaveOption && setenableSaveOption(true);
                  setrewardMilestomeValue(data);
                }}
                Label={'Milestone Reward  value'}
                Error={ErrorMessage?.rewardMilestomeValue?.length > 0 ? true : false}
                ErrorMessage={ErrorMessage?.rewardMilestomeValue}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="py-4">
              <SectionLabel Label="Reward Graphic" />
            </div>
            <div className="w-full flex">
              <div
                className="bg-gray-200 w-full aspect-ratio-container rounded-sm flex justify-center items-center cursor-pointer"
                onClick={hadleImageinpuy}
              >
                {image ? (
                  <img className="w-full h-full" src={image} alt="clientImage" />
                ) : (
                  <div className="h-56 flex justify-center items-center">
                    <button className="rounded-full h-6 w-6 text-3xl bg-white flex justify-center items-center">
                      +
                    </button>
                    <input
                      ref={fileInput}
                      id="file-upload"
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onClick={() => {
                        fileInput.current.value = null;
                      }}
                      onChange={(data) => {
                        !enableSaveOption && setenableSaveOption(true);
                        onFileChange(data);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="pl-6">
                {image && (
                  <button
                    className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                    onClick={() => {
                      setimgPreview(null);
                      !enableSaveOption && setenableSaveOption(true);
                    }}
                  >
                    REMOVE
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default RewardsPTO;
