import React, { useEffect, useState } from 'react';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import moment from 'moment';

type props = {
  changedDate?: (startdate: string, enddate: string, thisWeek: boolean) => void;
  handleDownload?: (data: any) => void;
  disableDownload?: boolean;
  dateLimit?: any;
};

const CalendarPaginator: React.FunctionComponent<props> = (props): JSX.Element => {
  const [dateType, setDateType] = useState('Week');
  const [dateCounter, setdateCounter] = useState(0);
  const [time, settime] = useState('time');
  const clientId = localStorage.getItem('clientId');
  const [disableLeftArrow, setdisableLeftArrow] = useState(false);

  const getDateLimit = (type: 'isoWeek' | 'year' | 'month') => {
    const date = moment(props?.dateLimit || '2021-08-02T07:26:03.744844+00:00');

    return date.startOf(type);
  };

  const calculatedDates = () => {
    let startdate: any = new Date();
    let enddate: any = new Date();
    let thisWeek = false;

    if (dateType === 'Week') {
      startdate = moment()
        .subtract(7 * dateCounter, 'days')
        .startOf('isoWeek');
      enddate = moment()
        .subtract(7 * dateCounter, 'days')
        .endOf('isoWeek');

      if (startdate < getDateLimit('isoWeek').add(7, 'days')) setdisableLeftArrow(true);
      else setdisableLeftArrow(false);

      if (dateCounter) {
        settime(`Week of ${startdate.format('MMMM D, YYYY')} - ${enddate.format('MMMM D, YYYY')}`);
      } else {
        settime('This Week');
        thisWeek = true;
      }
    }

    return { startdate, enddate, thisWeek };
  };

  const calculateTime = () => {
    const dates = calculatedDates();

    if (props.changedDate)
      props.changedDate(
        dates.startdate.format('YYYY-MM-DD'),
        dates.enddate.format('YYYY-MM-DD'),
        dates.thisWeek
      );
  };

  useEffect(() => {
    calculateTime();
  }, [dateCounter, dateType, clientId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setdisableLeftArrow(false);
  }, [setDateType]);

  return (
    <div className="w-full bg-gray-background-light px-6  h-10 ">
      <div className="flex justify-between items-center  gray-background-light text-gray-dark h-full w-1/2">
        <div
          className="flex justify-end  bg-white     rounded-full"
          onClick={() => {
            setdateCounter((counter: number) => counter + 1);
          }}
        >
          {!disableLeftArrow && <img className="h-6 w-6" src={RightArrow} alt="left Arrow"></img>}
        </div>

        <div className=" flex justify-center font-medium font-montserrat text-sm">{time}</div>
        <div className="flex justify-end  bg-white  transform rotate-180  rounded-full">
          {dateCounter > 0 && (
            <img
              className="h-6 w-6"
              src={RightArrow}
              alt="Right Arrow"
              onClick={() => {
                setdateCounter((counter: number) => counter - 1);
              }}
            ></img>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarPaginator;
