import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import {
  postBlackListFailure,
  fetchBlackListListSuccess,
  fetchBlackListListFailure,
  fetchUserListSuccess,
  fetchUserListFailure,
  fetchUserListRequest,
  fetchBlackListCountSuccess,
  fetchBlackListCountFailure,
  fetchBlockedListFailure,
  fetchBlockedListSuccess,
  postBlockedFailure,
  postBlockedSuccess,
  removeBlockedFailure,
  removeBlockedSuccess,
  fetchBlockedFailure,
  fetchBlockedSuccess,
  fetchBlockedRequest,
  fetchBlockedCountSuccess,
  fetchBlockedCountFailure,
  fetchBlackListListRequest,
  fetchBlockedListRequest,
} from './actions';
import {
  POST_BLACKLIST_REQUEST,
  FETCH_BLACKLIST_LIST_REQUEST,
  UPDATE_BLACKLIST_REQUEST,
  REMOVE_BLACKLIST_REQUEST,
  FETCH_USERLIST_REQUEST,
  FETCH_BLACKLIST_COUNT_REQUEST,
  FETCH_BLOCKED_COUNT_REQUEST,
  FETCH_BLOCKED_LIST_REQUEST,
  POST_BLOCKED_REQUEST,
  REMOVE_BLOCKED_REQUEST,
  UPDATE_USER_REQUEST,
  FETCH_BLOCKED_REQUEST,
  UPDATE_SETTINGS_REQUEST,
} from './action-types';
import { toast } from 'react-toastify';
import { Toast } from 'react-toastify/dist/components';
import { fetchResourceRequest } from '../../resources/redux-resources/actions';
import { fetchAppSettingsRequest } from '../../clients/clients-redux/actions';

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* postBlackListSaga(action: any): any {
  const { payload, filters, from } = action.payload;
  try {
    yield call(Api.createBlackList(payload));
    toast.success(' Blacklisted added succesfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postBlackListFailure(JSON.parse(e)));
  }
  if (from === 'users') {
    yield put(fetchUserListRequest(filters));
  } else {
    const response = yield call(Api.getAllBlackList(filters));
    yield put(
      fetchBlackListListSuccess({
        BlackListList: response,
      })
    );
  }
}

function* fetchBlackListListSaga(action: any): any {
  try {
    const response = yield call(Api.getAllBlackList(action.payload));
    yield put(
      fetchBlackListListSuccess({
        BlackListList: response,
      })
    );
  } catch (e: any) {
    yield put(fetchBlackListListFailure(JSON.parse(e)));
  }
}

function* UpdateBlackListSaga(action: any): any {
  try {
    yield call(Api.deleteBlackList(action.payload));
    toast.success('UserList Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  yield put(
    fetchUserListRequest({ id, payload: { all_tier_versions: true, include_unpublished: true } })
  );
}
function* removeBlackListSaga(action: any): any {
  const { id, filters } = action.payload;
  try {
    yield call(Api.deleteBlackList({ id }));
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data?.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const response = yield call(Api.getAllBlackList(filters));
  yield put(
    fetchBlackListListSuccess({
      BlackListList: response,
    })
  );
}

function* fetchUserListSaga(action: any): any {
  try {
    const res = Api.getAllUsers(action.payload);
    const response = yield call(res);
    yield put(fetchUserListSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchUserListFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchBlackListCountSaga(action: any): any {
  try {
    const response = yield call(Api.fetchBlackListCount(action.payload));
    yield put(fetchBlackListCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchBlackListCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* fetchBlockedCountSaga(action: any): any {
  try {
    const response = yield call(Api.fetchBlockedCount(action.payload));
    yield put(fetchBlockedCountSuccess(response));
  } catch (e: unknown) {
    yield put(
      fetchBlockedCountFailure({
        error: e instanceof Error ? e.message : JSON.stringify(e),
      })
    );
  }
}

function* updateUsersList(action: any): any {
  try {
    yield call(Api.updateUser(action.payload));
    toast.success('User Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { filterType } = action.payload;
  yield put(
    fetchUserListRequest({
      page: 1,
      limit: 25,
      search: '',
      status: filterType,
      include_ratings: true,
      include_topics: true,
      include_call_summary: true,
      include_user_safety: true,
      include_requests: true,
    })
  );
}

function* postBlockedSaga(action: any): any {
  try {
    const response = yield call(Api.deleteBlackList(action.payload));
    yield put(postBlockedSuccess(response));
    toast.success('Blocked Created Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
    yield put(postBlockedFailure(JSON.parse(e)));
  }
}

function* fetchBlockedListSaga(action: any): any {
  try {
    const response = yield call(Api.getAllBlocked(action.payload));
    yield put(
      fetchBlockedListSuccess({
        BlockedList: response,
      })
    );
  } catch (e: any) {
    yield put(fetchBlockedListFailure(JSON.parse(e)));
  }
}

function* UpdateUserSaga(action: any): any {
  try {
    yield call(Api.deleteBlackList(action.payload));
    toast.success('Blocked Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  yield put(fetchBlockedRequest({ id, payload: { include_hidden: true } }));
}
function* removeBlockedSaga(action: any): any {
  const { filters } = action.payload;
  try {
    yield call(Api.deleteBlocked(action.payload));
    toast.success(`Blocked removed sucessfully`);
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data?.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { page, limit, ...rest } = filters;
  yield put(fetchBlockedListRequest(filters));
  yield put(fetchBlockedListRequest(rest));
}

function* FetchBlockedSaga(action: any): any {
  try {
    const response = yield call(Api.deleteBlackList(action.payload));
    yield put(
      fetchBlockedSuccess({
        Blocked: response,
      })
    );
  } catch (e: any) {
    yield put(fetchBlockedFailure(JSON.parse(e)));
  }
}

function* UpdateSettiingsSaga(action: any): any {
  try {
    yield call(Api.UpdateSettiings(action.payload));
    //
    toast.success('Settings Updated Successfully');
  } catch (e: any) {
    try {
      const error = JSON.parse(e);
      if (error) {
        toast.error(error?.data.description);
      }
    } catch (error) {
      toast.error('Unknown error');
    }
  }
  const { id } = action.payload;
  if (id) {
    yield put(fetchResourceRequest({ id, payload: {} }));
  } else {
    yield put(fetchAppSettingsRequest({ type: 'email' }));
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(POST_BLACKLIST_REQUEST, postBlackListSaga),
    takeLatest(FETCH_BLACKLIST_COUNT_REQUEST, fetchBlackListCountSaga),
    takeLatest(FETCH_BLACKLIST_LIST_REQUEST, fetchBlackListListSaga),
    takeLatest(UPDATE_BLACKLIST_REQUEST, UpdateBlackListSaga),
    takeLatest(REMOVE_BLACKLIST_REQUEST, removeBlackListSaga),
    takeLatest(FETCH_USERLIST_REQUEST, fetchUserListSaga),
    takeLatest(UPDATE_USER_REQUEST, updateUsersList),
    takeLatest(POST_BLOCKED_REQUEST, postBlockedSaga),
    takeLatest(FETCH_BLOCKED_COUNT_REQUEST, fetchBlockedCountSaga),
    takeLatest(FETCH_BLOCKED_LIST_REQUEST, fetchBlockedListSaga),
    takeLatest(REMOVE_BLOCKED_REQUEST, removeBlockedSaga),
    takeLatest(FETCH_BLOCKED_REQUEST, FetchBlockedSaga),
    takeLatest(UPDATE_SETTINGS_REQUEST, UpdateSettiingsSaga),
  ]);
}

export default paymentSaga;
