import React, { useEffect, useState } from 'react';
import Header from '../../components/common/global-top-bar';
import { useDispatch, connect } from 'react-redux';
import {
  fetchTosRequest,
  updateTosRequest,
} from '../clients/text-blocks/text-blocks-redux/actions';
import RouteLeavingGuard from '../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../routes/paths';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../components/common';

type props = {
  pending: boolean;
  systemWelcomeVideo: any;
};

const Files = ({ pending, systemWelcomeVideo }: props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [welcomeVideo, setWelcomeVideo] = useState<any>();
  const [isDataModified, setIsDataModified] = useState(false);
  const [saveLocationPath, setSaveLocationPath] = useState<string>('');

  const fileInputWelcomeVideo: any = React.createRef();

  const hadleImageinput = (name: string) => {
    if (name === 'welcomeVideo') {
      fileInputWelcomeVideo.current.click();
    }
  }; // fileInputWelcomeAuthor

  const onFileChange = async (event: any) => {
    try {
      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          setWelcomeVideo(e.target.result);
          // setpreLoadedWelcomeVideo(false);
        };
        reader.readAsDataURL(event.target.files[0]);
        setIsDataModified(true);
      }
    } catch (error) {}
  };

  const saveWelcomeVideo = () => {
    const payload = {
      type: 'WELCOME_VIDEO',
      payload: {
        value: welcomeVideo,
      },
    };
    dispatch(updateTosRequest(payload));
  };

  useEffect(() => {
    if (systemWelcomeVideo?.WELCOME_VIDEO) {
      setWelcomeVideo(systemWelcomeVideo?.WELCOME_VIDEO);
      setIsDataModified(false);
      if (saveLocationPath !== '') {
        history.push(saveLocationPath);
      }
    }
  }, [systemWelcomeVideo]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchTosRequest({ type: 'WELCOME_VIDEO' }));
  }, []); // eslint-disable-line

  return (
    <div>
      {pending && <Spinner alignmentClass={'left-64 top-20 fixed z-100 inset-0 overflow-y-auto'} />}
      <RouteLeavingGuard
        when={isDataModified}
        navigate={(path: string) => history.push(path)}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.GROUPS_CONFIGURE) {
            return true;
          }
          return false;
        }}
        titleText={'Alert'}
        contentText={
          'You have unsaved changes. Do you want to leave this page and lose your changes?'
        }
        cancelButtonText={'Cancel'}
        confirmButtonText={'Disregard changes'}
        confirmSaveButtonText={welcomeVideo === null ? undefined : 'Continue and Save changes'}
        handleContinueSaveChange={(path) => {
          saveWelcomeVideo();
          setSaveLocationPath(path);
          setIsDataModified(false);
        }}
      />
      <Header heading={'Files'} />
      <div className="max-window-height pb-10 overflow-y-auto">
        <div className="w-full px-7">
          <p className="py-4 font-medium text-gray-dark">WELCOME VIDEO</p>
          <div className="flex">
            <div
              className={`${
                welcomeVideo ? '' : 'cursor-pointer'
              } bg-gray-background-light rounded-sm w-full aspect-ratio-container `}
              onClick={() => hadleImageinput('welcomeVideo')}
            >
              {welcomeVideo ? (
                <div>
                  <video width="100%" controls>
                    <source src={welcomeVideo} />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              ) : (
                <div className="h-56 flex justify-center items-center ">
                  <button className="rounded-full h-10 w-10 text-4xl bg-white flex justify-center items-center">
                    +
                  </button>
                  <input
                    ref={fileInputWelcomeVideo}
                    id="file-upload-promotion-overlay-video"
                    accept="video/mp4,video/x-m4v,video/*"
                    type="file"
                    className="hidden"
                    onChange={(e) => onFileChange(e)}
                    onClick={() => {
                      fileInputWelcomeVideo.current.value = null;
                    }}
                  />
                </div>
              )}
            </div>
            <div className="pl-4">
              {welcomeVideo && (
                <div className=" w-full aspect-ratio-container ">
                  <button
                    className="bg-gray-300 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                    onClick={() => {
                      setWelcomeVideo(null);
                      setIsDataModified(true);
                    }}
                  >
                    REMOVE
                  </button>
                  {isDataModified && (
                    <button
                      className="stacked-bar-blue-400 rounded-full  text-center  text-sm text-white  p-2 w-full my-2"
                      onClick={saveWelcomeVideo}
                    >
                      SAVE
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    systemWelcomeVideo: state.Tos.systemWelcomeVideo?.data || null,
    pending: state.Tos?.pending || null,
  };
};

export default connect(mapStateToProps)(Files);
