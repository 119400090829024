import { all, fork } from 'redux-saga/effects';
import LoginSaga from '../components/account-login/login-redux/sagas';
import ClientSaga from '../containers/clients/clients-redux/sagas';
import AdminSaga from '../containers/clients/admin-redux/sagas';
import ListenersSaga from '../containers/listeners/listeners-redux/sagas';
import MembersSaga from '../containers/members/members-redux/sagas';
import ClientPortalSaga from '../containers/client-configuration/client-portal-redux/sagas';
import EligibilitySaga from '../containers/clients/eligibility-redux/saga';
import CommsSaga from '../containers/templates/redux-templates/saga';
import TosSaga from '../containers/clients/text-blocks/text-blocks-redux/saga';
import TiersSaga from '../containers/listener-tiers/redux-templates/saga';
import TagsSaga from '../containers/tags-management/redux-tags/saga';
import ResourcesSaga from '../containers/resources/redux-resources/saga';
import SafetySaga from '../containers/user-safety/redux-tags/saga';
import ConnectionSaga from '../containers/connections/connections-redux/sagas';

export function* rootSaga() {
  yield all([
    fork(LoginSaga),
    fork(ClientSaga),
    fork(ListenersSaga),
    fork(MembersSaga),
    fork(ClientPortalSaga),
    fork(EligibilitySaga),
    fork(CommsSaga),
    fork(AdminSaga),
    fork(TosSaga),
    fork(TiersSaga),
    fork(TagsSaga),
    fork(ResourcesSaga),
    fork(SafetySaga),
    fork(ConnectionSaga),
  ]);
}
