import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import LoginLogo from '../../assets/images/login-logo.svg';

type props = {
  login: any;
};

const NotifyMessage: React.FunctionComponent<props> = ({ login }): JSX.Element => {
  const history = useHistory();
  const [webAppUrl, setwebAppUrl] = useState('');

  const handleClose = () => {
    localStorage.clear();
    history.push('/');
  };

  useEffect(() => {
    if (!login) history.push('/');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (login) {
      if (!login?.caller_role_id && login?.listener_role_id) {
        setwebAppUrl('https://listeners.app.link');
      } else {
        const switchBoardUrl = window.location.href.replace('/notify-message', '');
        const url =
          process.env.REACT_APP_ENVTYPE === 'DEVELOPMENT'
            ? `${switchBoardUrl.replace('sb', 'wa')}`
            : process.env.REACT_APP_ENVTYPE === 'PRODUCTION' &&
              `${switchBoardUrl.replace('switchboard.', '')}`;

        setwebAppUrl(url || '');
      }
    }
  }, [login]);

  return (
    <div>
      <div className="flex h-screen bg-gray-100">
        <div className="w-full max-w-xl m-auto bg-white  py-10 px-5">
          <div className="flex justify-center">
            <img className="= ml-24  w-3/4" src={LoginLogo} alt="login" />
          </div>
          <div className="w-full text-center text-lg ">
            <p className="text-blue-primary font-extrabold  py-5  text-3xl">Hmmm?</p>
            <p className="text-gray-500 font-medium text-xl  py-2">
              That account does not have access.
            </p>
            <p className=" text-gray-500  font-medium  text-xl  py-2 pb-8">
              To access Listeners On Call as a user, visit our app:
            </p>
            <a
              href={webAppUrl}
              className="bg-gray-700 stacked-bar-blue-400 px-10 py-2 my-5 font-bold rounded-lg text-white"
            >
              GO TO APP
            </a>
            <p className="text-gray-500  font-medium  py-5" role="button" onClick={handleClose}>
              {' '}
              Close{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    login: state.login?.loginDetails?.data || null,
  };
};

export default connect(mapStateToProps)(NotifyMessage);
