import React, { useState, useEffect } from 'react';
import { ToggleButton } from '../../components/common';

type props = {
  status: boolean;
  initialstatus: boolean;
  id: string;
  buttonText: string;
  disableButton?: boolean;
  setStatus: (data: any) => void;
  Label: string;
  InnerComponent: React.ReactNode;
  onButtonClick?: any;
  enableAddButton?: any;
  disableToggleButton?: any;
};

const AccordionTile: React.FunctionComponent<props> = ({
  setStatus,
  Label,
  status,
  id,
  initialstatus,
  InnerComponent,
  buttonText,
  onButtonClick,
  disableButton,
  disableToggleButton,
}): JSX.Element => {
  const [dropDownState, setdropDownState] = useState<any>();

  useEffect(() => {
    if (status !== undefined && status !== null) {
      setdropDownState(status);
    }
  }, [status]);

  return (
    <div>
      <div className="relative  w-full  py-3 border-b gray-border-line flex justify-between">
        <div className="text-xl font-bold text-gray-dark flex">
          <ToggleButton
            setStatus={setStatus}
            status={dropDownState}
            id={id}
            initialstatus={initialstatus}
            disabled={disableToggleButton}
          />
          {Label}
        </div>
        <div>
          <div className="w-full">
            {buttonText?.length > 0 && (
              <button
                className={
                  ' rounded-full w-full text-center h-6 text-sm text-white px-2 ' +
                  (disableButton
                    ? ' stacked-bar-blue-400  '
                    : '  bg-gray-background-light cursor-not-allowed ')
                }
                onClick={onButtonClick}
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          ' accordion-panel-close' + (status ? ' max-h-xlscreen accordion-panel' : ' max-h-0 ')
        }
      >
        {InnerComponent}
      </div>
    </div>
  );
};

export default AccordionTile;
